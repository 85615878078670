import { AUTH_TYPES } from 'configs/constants'
import { CLEAR_AUTH, UPDATE_AUTH, UPDATE_AUTH_MODAL } from 'store/actionTypes'
import { AuthType, DispatchType } from 'types'

const { SIGN_IN, SIGN_UP, FORGOT_PASSWORD } = AUTH_TYPES

export const signIn = (showModal: boolean) => (dispatch: DispatchType) => {
  dispatch({ type: UPDATE_AUTH_MODAL, payload: { show: showModal, type: SIGN_IN } })
}

export const signUp = (showModal: boolean) => (dispatch: DispatchType) => {
  dispatch({ type: UPDATE_AUTH_MODAL, payload: { show: showModal, type: SIGN_UP } })
}

export const forgotPassword = (showModal: boolean) => (dispatch: DispatchType) => {
  dispatch({
    type: UPDATE_AUTH_MODAL,
    payload: { show: showModal, type: FORGOT_PASSWORD },
  })
}

export const closeAuthModal = () => (dispatch: DispatchType) => {
  dispatch({ type: UPDATE_AUTH_MODAL, payload: { show: false } })
}

export const signOut = (preventClosePopup?: boolean) => (dispatch: DispatchType) => {
  dispatch({ type: CLEAR_AUTH, payload: { preventClosePopup } })
}

export const updateAuth = (payload: AuthType) => {
  return { type: UPDATE_AUTH, payload }
}
