import { isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { formatQuantity } from 'utils'

interface Props {
  className?: string
  icon?: string
  label?: string
  badge?: number | string
  onClick?: (event?: any) => void
  onMouseEnter?: any
  onMouseLeave?: any
  innerRef?: any
}

const AppToolItem: React.FC<Props> = (props: any) => {
  const { t } = useTranslation()
  const {
    className = '',
    icon = '',
    label = '',
    badge,
    onClick,
    onMouseEnter,
    onMouseLeave,
    innerRef,
    children,
  } = props

  return (
    <div
      className={`AppToolItem-container ${className}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={innerRef}
    >
      {icon && (
        <div className="AppToolItem-icon">
          <span className={`Ic-${icon}`} />
          {!isNil(badge) && badge !== 0 && (
            <span className="AppToolItem-badge">{formatQuantity(badge)}</span>
          )}
        </div>
      )}
      <span className="AppToolItem-lb">{t(label)}</span>
      {children}
    </div>
  )
}

export default AppToolItem
