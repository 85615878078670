/* eslint-disable */
import { Button, Input, Radio, Select } from 'components'
import {
  createAddressTypesList,
  CREATE_ADDRESS_TYPES,
  DELIVERY_METHODS,
  mappingDeliveryMethod,
} from 'configs/constants'
import { Form, FormikProvider } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'store/auth'
import { AddressItem, InputNamePhone, SelectAddressType, SelectCity } from 'components/common'
import { useHistory } from 'store/confirmRedirect'

const { NONE, GET_AT_STORE, GET_AT_ADDRESS } = DELIVERY_METHODS
const { NEW_ADDRESS, AVAILABLE_ADDRESS } = CREATE_ADDRESS_TYPES

interface Props {
  savedData: any
  formik: any
}

const FormAddress: React.FC<Props> = ({ savedData, formik }: any) => {
  const {
    deliveryMethod,
    setDeliveryMethod,
    addressType,
    setAddressType,
    addresses,
    selectedAddress,
    setSelectedAddress,
    loading,
    stores,
  } = savedData

  const { t } = useTranslation()
  const { isAuth } = useAuth()
  const { preventRedirect } = useHistory()

  const {
    handleSubmit,
    getFieldProps,
    values,
    validateForm,
    isValid,
    handleChange,
    setFieldValue,
  } = formik

  const renderNamePhone = (
    <div className="FormContainer">
      <InputNamePhone formik={formik} />
    </div>
  )

  const renderNote = (
    <Input
      fullWidth
      {...getFieldProps('note')}
      label={t('note')}
      rows={5}
      maxLength={200}
      onChange={(e) => {
        preventRedirect()
        handleChange(e)
      }}
    />
  )

  const renderGetAtStore = (
    <>
      <div>
        {stores.length === 1 ? (
          <>
            <div className="form-label mt" style={{ marginBottom: 8 }}>
              {t('delivery_address')}
            </div>
            <div className="label gray" style={{ marginBottom: 8 }}>
              {t('store')}
            </div>
            <div className="label store-address once">{`${values.selected_store?.store_name}: ${values.selected_store?.address}`}</div>
          </>
        ) : (
          <>
            <div className="form-label mt" style={{ marginBottom: 12 }}>
              {t('delivery_address')}
            </div>
            {stores?.map((item: any, index: number) => {
              return (
                <div className="d-f ai-c" key={item.id + index} style={{ marginBottom: 12 }}>
                  <Radio
                    name="radio-buttons"
                    checked={values.selected_store?.id === item.id}
                    onChange={() => {
                      setFieldValue('selected_store', item)
                    }}
                  />
                  <div className="store-address">{`${item.store_name}: ${item.address}`}</div>
                </div>
              )
            })}
          </>
        )}
      </div>
      {!isAuth && renderNamePhone}
      {renderNote}
    </>
  )

  const renderGetAtNewAddress = (label: string) => (
    <>
      <div className="form-label mt">{t(label)}</div>
      {renderNamePhone}
      <div className="FormContainer">
        <SelectAddressType formik={formik} />
        <SelectCity formik={formik} />
      </div>
      {renderNote}
    </>
  )

  const renderGetAtAuthAddress = (
    <div className="FormContainer">
      <Select
        selected={addressType}
        setSelected={(s) => setAddressType(s)}
        data={
          addresses.length > 0
            ? addresses.length >= 6
              ? createAddressTypesList.slice(0, 1)
              : createAddressTypesList
            : createAddressTypesList.slice(1)
        }
        fullWidth
        label="Chọn địa chỉ"
        placeholder="Tạo mới/Chọn địa chỉ đã tạo"
        required
      />
      {addressType === NEW_ADDRESS && renderGetAtNewAddress('createNew')}
    </div>
  )

  const renderGetAtAddress = isAuth
    ? renderGetAtAuthAddress
    : renderGetAtNewAddress('delivery_address')

  // Validate form when change delivery method or address type
  useEffect(() => {
    validateForm()
  }, [deliveryMethod, addressType])

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <div className="FormWrapper">
          <div className="">
            <div className="form-stack">
              <div className="form-label">{t('deliveryMethod')}</div>
              <div className="FormContainer">
                {stores.length === 0 ? (
                  <div className="method">{t(mappingDeliveryMethod[GET_AT_ADDRESS])}</div>
                ) : (
                  <Select
                    selected={deliveryMethod}
                    setSelected={(selected) => {
                      setDeliveryMethod(selected)
                      preventRedirect()
                    }}
                    data={[
                      { label: t(mappingDeliveryMethod[GET_AT_STORE]), value: GET_AT_STORE },
                      { label: t(mappingDeliveryMethod[GET_AT_ADDRESS]), value: GET_AT_ADDRESS },
                    ]}
                    fullWidth
                    label={t('deliveryMethod')}
                    required
                    placeholder={t('selectDeliveryMethod')}
                  />
                )}
              </div>
              {deliveryMethod !== null && (
                <>{deliveryMethod === GET_AT_STORE ? renderGetAtStore : renderGetAtAddress}</>
              )}
            </div>
          </div>

          {deliveryMethod === GET_AT_ADDRESS && addressType === AVAILABLE_ADDRESS && (
            <>
              <div className="AddressItems-wrapper">
                {addresses.map((item: any) => {
                  const { address, city, district, full_name, id, is_default, phone, town, type } =
                    item

                  return (
                    <AddressItem
                      checked={selectedAddress === id}
                      onClick={() => setSelectedAddress(id)}
                      key={id}
                      type={type}
                      is_default={is_default}
                    >
                      {full_name} <br />
                      {phone}
                      <br />
                      {address}, {town}, {district}, {city}
                    </AddressItem>
                  )
                })}
              </div>
              <div className="" style={{ marginTop: 12 }}>
                {renderNote}
              </div>
            </>
          )}
        </div>

        <div className="PaymentLayout-next">
          <Button
            type="submit"
            fullWidth
            icon="arrow"
            small
            disabled={deliveryMethod === NONE || !isValid}
            loading={loading}
          >
            Tiếp tục
          </Button>
        </div>
      </Form>
    </FormikProvider>
  )
}

export default FormAddress
