import { useTranslation } from 'react-i18next'

import { Button } from 'components'
import { ProductItem } from 'components/common'
import { useIsMobile } from 'store/mobile'

interface PropTypes {
  className?: string
  label?: string
  products?: any[]
  btnLabel?: string
  onClickBtn?: () => void
  center?: boolean
}

export default function RecommendProducts(props: PropTypes) {
  const { t } = useTranslation()
  const {
    className = '',
    label = '',
    products = [],
    btnLabel = '',
    onClickBtn = () => {},
    center,
  } = props

  const isMobile = useIsMobile()

  const btnViewmore = (
    <Button
      className={`ProductRecommendProducts-btnMore ${isMobile && center ? 'big' : 'small'}`}
      fullWidth={center && isMobile}
      color={center && isMobile ? 'stroke' : 'stroke'}
      icon="arrow-right"
      onClick={onClickBtn}
    >
      {t(btnLabel)}
    </Button>
  )

  return (
    <div className={`ProductRecommendProducts-container ${className}`}>
      {label && (
        <div className="ProductRecommendProducts-title">
          <h5 style={center ? { textAlign: 'center', width: '100%' } : undefined}>{t(label)}</h5>
          {btnLabel && !center && btnViewmore}
        </div>
      )}
      <div className="ProductRecommendProducts-list">
        {products.map((productItem, index) => (
          <ProductItem className="medium" key={index} product={productItem} />
        ))}
      </div>
      {center && btnLabel && (
        <div style={{ textAlign: 'center', margin: '24px 0' }}>{btnViewmore}</div>
      )}
    </div>
  )
}
