import { useMediaQuery } from '@mui/material'
import { loading } from 'configs/extensions'
import React from 'react'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useAuth } from 'store/auth'
import Sidebar from './Sidebar'

const MyOrder = React.lazy(() => import('./MyOrder'))
const MyOrderDetail = React.lazy(() => import('./MyOrderDetail'))
const MyAddresses = React.lazy(() => import('./myAddresses/MyAddresses'))
const MyVATs = React.lazy(() => import('./myAddresses/MyVATs'))
const MyInfo = React.lazy(() => import('./myInfo/MyInfo'))
const ChangePassword = React.lazy(() => import('./myInfo/ChangePassword'))

const UserLayout: React.FC = () => {
  const { t } = useTranslation()
  const { isAuth } = useAuth()
  const isMatch = useMediaQuery('(max-width: 860px)')

  if (!isAuth) return <Redirect to="/" />

  return (
    <div className="UserLayout">
      <div className="UserLayout-title">{t('account')}</div>
      <div className="UserLayout-area">
        {!isMatch && <Sidebar />}
        <div className="UserLayout-component">
          <Suspense fallback={loading}>
            <Switch>
              <Route path="/personal-information/my-order" exact component={MyOrder} />
              <Route path="/personal-information/my-order/:id" exact component={MyOrderDetail} />
              <Route path="/personal-information/my-addresses" exact component={MyAddresses} />
              <Route path="/personal-information/my-vats" exact component={MyVATs} />
              <Route path="/personal-information/my-info" exact component={MyInfo} />
              <Route
                path="/personal-information/my-info/change-password"
                exact
                component={ChangePassword}
              />
              <Redirect to="/personal-information/my-info" />
            </Switch>
          </Suspense>
        </div>
      </div>
    </div>
  )
}
export default UserLayout
