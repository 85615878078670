/*eslint-disable*/
import React, { useState } from 'react'
import { Box, Card, Tab, Tabs } from '@mui/material'
import GuideUsingStep from 'components/admin/guideUsingStep/GuideUsingStep'
import { renderBackTitle } from 'configs/extensions'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'store/confirmRedirect'

import list1 from '../../../../assets/images/UserUsing/list1.png'
import list2 from '../../../../assets/images/UserUsing/list2.png'
import list3 from '../../../../assets/images/UserUsing/list3.png'
import list4 from '../../../../assets/images/UserUsing/list4.png'
import list5 from '../../../../assets/images/UserUsing/list5.png'

import view1 from '../../../../assets/images/UserUsing/view1.png'
import view2 from '../../../../assets/images/UserUsing/view2.png'
import view3 from '../../../../assets/images/UserUsing/view3.png'

import edit1 from '../../../../assets/images/UserUsing/edit1.png'
import edit2 from '../../../../assets/images/UserUsing/edit2.png'
import edit3 from '../../../../assets/images/UserUsing/edit3.png'
import edit4 from '../../../../assets/images/UserUsing/edit4.png'
import edit5 from '../../../../assets/images/UserUsing/edit5.png'
import edit6 from '../../../../assets/images/UserUsing/edit6.png'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const UserUsing = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      {renderBackTitle(history, t('Quản lý người dùng - Hướng dẫn sử dụng'))}
      <Card sx={{ p: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="Danh sách người dùng" {...a11yProps(0)} />
            <Tab label="Xem thông tin người dùng " {...a11yProps(1)} />
            <Tab label="Sửa thông tin người dùng" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box>
            <GuideUsingStep
              descreption="<b>Trạng thái đăng ký</b>"
              orther={
                <Box margin={'30px'}>
                  <GuideUsingStep
                    descreption="Tài khoản có trạng thái <b>Đã đăng ký</b> là tài khoản được người dùng đăng ký trên trang <a target='__blank' href='https://mayno.vn'>https://mayno.vn/</a> và tài khoản này có thể đăng nhập vào hệ thống. "
                    image={[list1]}
                  />
                  <GuideUsingStep
                    descreption="Tài khoản có trạng thái <b>Chưa đăng ký</b> là tài khoản được tạo ra sau khi quản trị viên/người dùng đặt đơn hàng thành công bằng số điện thoại chưa được đăng ký tài khoản trước đó và tài khoản này không đăng nhập được vào hệ thống."
                    image={[list2]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Tìm kiếm, lọc người dùng</b>"
              orther={
                <Box margin={'30px'}>
                  <GuideUsingStep
                    descreption="Bạn có thể tìm kiếm người dùng theo <b>Họ tên, Số điện thoại</b> và lọc danh sách theo <b>Giới tính, Ngày sinh, Trạng thái đăng ký TK</b>:"
                    image={[list3]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Đơn hàng của người dùng</b>"
              orther={
                <Box margin={'30px'}>
                  <p style={{ fontSize: 18, marginBottom: 10 }}>
                    <b>Điều kiện:</b> Đã có ít nhất một người dùng ở màn Quản lý người dùng.
                  </p>
                  <GuideUsingStep
                    descreption="Nếu người dùng có số lượng đơn hàng = 0 thì màn hình <b>Đơn hàng của người dùng</b> sẽ hiển thị như hình ảnh bên dưới: "
                    image={[list4]}
                  />
                  <GuideUsingStep
                    descreption="Nếu người dùng có số lượng đơn hàng > 0 thì màn hình <b>Đơn hàng của người dùng</b> sẽ hiển thị như hình ảnh bên dưới: "
                    image={[list5]}
                  />
                  <GuideUsingStep
                    descreption="<b>Lưu ý: </b>"
                    note={[
                      {
                        title:
                          'Các thao tác được mô tả giống với file Hướng dẫn sử dụng trang Quản lý đơn hàng là:',
                        subNote: [
                          {
                            title:
                              'Thêm đơn hàng: Sau khi nhấn <b>THÊM ĐƠN HÀNG</b> tại màn Thêm đơn hàng của người dùng, đơn hàng sẽ được thêm thành công và hiển thị trong danh sách Đơn hàng của người dùng với trạng thái là Chưa thanh toán.',
                          },
                          {
                            title:
                              'Sửa đơn hàng: Sau khi nhấn <b>LƯU</b> tại màn Sửa đơn hàng của người dùng, thông tin đơn hàng sẽ được cập nhật theo thông tin vừa sửa ở màn hình Đơn hàng của người dùng.',
                          },
                          { title: 'In báo giá' },
                          { title: 'Hủy đơn hàng' },
                          { title: 'Xóa đơn hàng' },
                          { title: 'Xem chi tiết đơn hàng của người dùng' },
                          { title: 'Xem lịch sử in báo giá' },
                          { title: 'Chuyển trạng thái đơn hàng' },
                          { title: 'Gửi email xác nhận đơn hàng' },
                          { title: 'Tìm kiếm, Lọc đơn hàng' },
                          { title: 'Ẩn, hiện cột' },
                        ],
                      },
                    ]}
                  />
                </Box>
              }
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có ít nhất một người dùng ở màn Quản lý người dùng.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Xem</b> ở người dùng mà bạn cần xem thông tin."
              image={[view1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Xem</b>, màn hình thông tin của người dùng sẽ hiển thị như ảnh bên dưới:"
              image={[view2]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title: 'Bạn có thể nhấn nút quay lại để quay về màn Quản lý người dùng.',
                  image: [view3],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có ít nhất một người dùng ở màn Quản lý người dùng.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Sửa</b> ở người dùng mà bạn cần sửa thông tin."
              image={[edit1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Sửa</b>, màn hình Sửa người dùng sẽ hiển thị như ảnh bên dưới:"
              image={[edit2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Cập nhật thông tin vào các trường mà bạn muốn sửa ở màn hình Sửa người dùng, sau đó nhấn nút <b>LƯU</b>."
              image={[edit3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>LƯU</b>, thông tin người dùng sẽ được cập nhật theo thông tin vừa sửa:"
              image={[edit4]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Nếu sửa thông tin của người dùng có trạng thái là <b>Đã đăng ký</b> → Thông tin vừa chỉnh sửa sẽ được cập nhật ở trang <b>Thông tin cá nhân</b> của người dùng:',
                  image: [edit5],
                },
                {
                  title:
                    'Nếu sửa thông tin mã số khách hàng thì cần nhập mã số không trùng với mã số khách hàng đã nhập trước đó.',
                },
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác sửa người dùng:',
                  image: [edit6],
                },
              ]}
            />
          </Box>
        </TabPanel>
      </Card>
    </>
  )
}

export default UserUsing
