/*eslint-disable*/
import React, { useState } from 'react'
import { Box, Card, Tab, Tabs } from '@mui/material'
import GuideUsingStep from 'components/admin/guideUsingStep/GuideUsingStep'
import { renderBackTitle } from 'configs/extensions'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'store/confirmRedirect'

import add1 from '../../../../assets/images/Q&A/add1.png'
import add2 from '../../../../assets/images/Q&A/add2.png'
import add3 from '../../../../assets/images/Q&A/add3.png'
import add4 from '../../../../assets/images/Q&A/add4.png'
import add5 from '../../../../assets/images/Q&A/add5.png'
import add6 from '../../../../assets/images/Q&A/add6.png'
import add7 from '../../../../assets/images/Q&A/add7.png'
import add8 from '../../../../assets/images/Q&A/add8.png'

import edit1 from '../../../../assets/images/Q&A/edit1.png'
import edit2 from '../../../../assets/images/Q&A/edit2.png'
import edit3 from '../../../../assets/images/Q&A/edit3.png'
import edit4 from '../../../../assets/images/Q&A/edit4.png'
import edit5 from '../../../../assets/images/Q&A/edit5.png'
import edit6 from '../../../../assets/images/Q&A/edit6.png'

import delete1 from '../../../../assets/images/Q&A/delete1.png'
import delete2 from '../../../../assets/images/Q&A/delete2.png'
import delete3 from '../../../../assets/images/Q&A/delete3.png'
import delete4 from '../../../../assets/images/Q&A/delete4.png'
import delete5 from '../../../../assets/images/Q&A/delete5.png'
import delete6 from '../../../../assets/images/Q&A/delete6.png'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const QuestionsUsing = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <>
      {renderBackTitle(history, t('question_management_using'))}
      <Card sx={{ p: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="Thêm câu hỏi thường gặp" {...a11yProps(0)} />
            <Tab label="Sửa câu hỏi thường gặp " {...a11yProps(1)} />
            <Tab label="Xóa câu hỏi thường gặp" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Thêm câu hỏi</b> để thêm một câu hỏi mới."
              image={[add1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Thêm câu hỏi</b>, màn hình Thêm câu hỏi sẽ hiển thị như ảnh bên dưới:"
              image={[add2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Điền thông tin hợp lệ vào tất cả các trường trong màn hình Thêm câu hỏi, sau đó nhấn nút <b>THÊM</b>."
              image={[add3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>THÊM</b>, câu hỏi sẽ được thêm thành công và hiển thị trong danh sách:"
              image={[add4]}
            />
            <GuideUsingStep
              descreption="Đồng thời, câu hỏi sẽ hiển thị tại trang <b>Câu hỏi thường gặp:</b>"
              image={[add5]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                {
                  title: 'Bạn có thể tìm kiếm câu hỏi theo <b>cột Câu hỏi</b>:',
                  image: [add6],
                },
                {
                  title:
                    'Bạn có thể thêm định dạng cho nội dung của phần Trả lời. Định dạng được thêm sẽ được hiển thị tại chi tiết câu hỏi ở trang <b>Câu hỏi thường gặp</b>.',
                  image: [add7],
                },
                {
                  title:
                    'Bạn có thể nhấn nút quay lại để hủy thao tác thêm câu hỏi mới và quay về màn Quản lý câu hỏi thường gặp.',
                  image: [add8],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có ít nhất một câu hỏi ở màn Quản lý câu hỏi thường gặp.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Sửa</b> ở câu hỏi mà bạn cần sửa thông tin."
              image={[edit1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Sửa</b>, màn hình Sửa câu hỏi sẽ hiển thị như ảnh bên dưới:"
              image={[edit2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Cập nhật thông tin vào các trường mà bạn muốn sửa trong màn hình Sửa câu hỏi, sau đó nhấn nút <b>LƯU</b>."
              image={[edit3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>LƯU</b>, thông tin câu hỏi sẽ được cập nhật theo thông tin vừa sửa:"
              image={[edit4]}
            />
            <GuideUsingStep
              descreption="Câu hỏi vừa được sửa thông tin sẽ được cập nhật ở trang <b>Câu hỏi thường gặp</b>:"
              image={[edit5]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Bạn có thể nhấn nút quay lại để hủy thao tác sửa câu hỏi và quay về màn Quản lý câu hỏi thường gặp:',
                  image: [edit6],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có ít nhất một câu hỏi ở màn Quản lý câu hỏi thường gặp.
            </p>

            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Xóa</b> ở câu hỏi mà bạn cần xóa thông tin."
              image={[delete1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Xóa</b>, hộp thoại xác nhận Xóa câu hỏi sẽ hiển thị như ảnh bên dưới:"
              image={[delete2]}
            />
            <GuideUsingStep descreption="<b>Bước 2:</b> Nhấn nút <b>XÓA</b>." image={[delete3]} />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>XÓA</b>, câu hỏi sẽ bị xóa và không còn hiển thị trong danh sách:"
              image={[delete4]}
            />
            <GuideUsingStep
              descreption="Câu hỏi vừa bị xóa sẽ không còn hiển thị ở trang <b>Câu hỏi thường gặp</b>:"
              image={[delete5]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác xóa câu hỏi:',
                  image: [delete6],
                },
              ]}
            />
          </Box>
        </TabPanel>
      </Card>
    </>
  )
}

export default QuestionsUsing
