import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useApp } from 'store/app'
import { useAuth } from 'store/auth'
import { resolutionMobile } from 'store/mobile/actions'
import { useShoppingCart } from 'store/shoppingCart'
import { CreateCartItemType } from 'types'
import { clearShoppingCartData, getShoppingCartData, useRefresh } from 'utils'

const Updater: React.FC = () => {
  const dispatch = useDispatch()
  const { syncCart, fetchCart, checkCart } = useShoppingCart()
  const { isAuth, signOut } = useAuth()
  const refresh = useRefresh()
  const history = useHistory()
  const init = useRef(true)

  const { allCartItems } = getShoppingCartData()
  const { fetchArea, getAboutUs } = useApp()

  useEffect(() => {
    if (!isAuth) {
      // Check which products in cart is invalid
      checkCart()
      return
    }

    const cartSyncItems = allCartItems.map(
      ({ product_id, quantity, is_buy_later }: CreateCartItemType) => ({
        product_id,
        quantity,
        is_buy_later,
      })
    )

    // Sync cart from local storage, and fetch cart after this
    if (cartSyncItems.length) {
      syncCart(cartSyncItems, () => {
        // After sync => remove cart from local storage and fetch cart from server
        clearShoppingCartData()
        fetchCart()
      })
    } else {
      // Fetch cart when user is signed in
      fetchCart()
    }
  }, [isAuth, refresh])

  useEffect(() => {
    if (init.current) {
      init.current = false
      return
    }
    const pathname = window.location.pathname
    if (!isAuth && pathname !== '/' && !pathname.includes('/sign-in')) history.push('/')
  }, [isAuth])

  // Fetch all cities on initialization
  useEffect(() => {
    fetchArea()
  }, [])

  useEffect(() => {
    getAboutUs()
  }, [])

  useEffect(() => {
    const handleResize = () => dispatch(resolutionMobile(window.innerWidth <= 860))

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  /**
   * Check local storage every 2s to determine
   * whether the user is sign out from system in an other tab
   */
  useEffect(() => {
    if (!isAuth) return

    const interval = setInterval(() => {
      const data = localStorage.getItem('user')
      if (!data) signOut(window.location.pathname.includes('/admin'), true)
    }, 2000)

    return () => clearInterval(interval)
  }, [dispatch, isAuth])

  return null
}

export default Updater
