import { useState } from 'react'
import { useShoppingCart } from 'store/shoppingCart'

import AppToolItem from './AppToolItem'
import AppSearch from './AppSearch'
import AppSidebar from './AppSidebar'
import AppHeaderUserMenu from './AppHeaderUserMenu'
import { Link } from 'components'
import { useHistory } from 'store/confirmRedirect'

export default function AppHeaderTools() {
  const history = useHistory()
  const { cartItems } = useShoppingCart()

  const [isOpenMenu, setIsOpenMenu] = useState(false)

  return (
    <div className="AppHeaderTools-container">
      <div className="AppHeaderTools-main">
        <Link to="/" className="AppHeader-logo cursor-pointer" />
        <AppSearch className="pc" />
        <div className="AppHeaderTools-items">
          <AppToolItem
            className="cart"
            icon="shoppingCart"
            label="shoppingCart"
            badge={cartItems.length}
            onClick={() => history.push('/shopping-cart')}
          />
          <AppHeaderUserMenu />
          <AppToolItem className="menu" icon="menu" onClick={() => setIsOpenMenu(true)} />
        </div>
      </div>
      <AppSearch className="sm" />
      <AppSidebar open={isOpenMenu} onClose={() => setIsOpenMenu(false)} />
    </div>
  )
}
