import React from 'react'
import { BaseSelectProps, Select as MuiSelect } from '@mui/material'
import './index.scss'

interface Props extends BaseSelectProps<any> {
  [key: string]: any
}

const Select: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <MuiSelect
      size="small"
      {...rest}
      className="ProductsSelect"
      MenuProps={{ className: 'ProductsSelectMenu' }}
    >
      {children}
    </MuiSelect>
  )
}

export default Select
