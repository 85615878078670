/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Modal } from 'components'
import { Button, DatePicker, Input, RenderField } from 'components/admin'
import { Checkbox, DialogActions, DialogContent } from '@mui/material'
import { useAdminOrders } from './hook'
import { validatePhone, validateTypingName, validateTypingPhone } from 'utils'
import { dayAfterAYear, PAYMENT_METHODS } from 'configs/constants'
import UploadFile from 'components/admin/uploadFile/UploadFile'

interface Props {
  show: boolean
  id: string
  type?: 'create' | 'update' | string
  updateShipper?: boolean
  initialValues?: any
  onClose?: any
  onSuccess?: any
  paymentMethod?: number | string
  oldStatus?: number | string
}

const { PAYMENT_ON_DELIVERY } = PAYMENT_METHODS

const ModalShipper: React.FC<Props> = (props) => {
  const {
    show,
    id,
    onClose,
    onSuccess,
    updateShipper,
    type,
    initialValues,
    paymentMethod,
    oldStatus,
  } = props
  const { updateOrder } = useAdminOrders()

  const [dirty, setDirty] = useState<any>({})
  const [handoverShipping, setHandoverShipping] = useState<any>(null)
  const [shippingUnit, setShippingUnit] = useState('')
  const [expectedDelivery, setExpectedDelivery] = useState<any>(null)
  const [shipperName, setShipperName] = useState('')
  const [shipperPhone, setShipperPhone] = useState('')
  const [fileUrl, setFileUrl] = useState('')

  const [status, setStatus] = useState(false)

  const isUpdate = type === 'update'

  const update = () => {
    updateOrder(
      id,
      updateShipper
        ? {
            status: 5,
            old_status: oldStatus,
            handover_shipping: handoverShipping.getTime() / 1000,
            shipping_unit: shippingUnit,
            expected_delivery: expectedDelivery.getTime() / 1000,
            shipper_name: shipperName,
            shipper_phone: shipperPhone,
          }
        : {
            status: 6,
            old_status: oldStatus,
            delivered_date: expectedDelivery.getTime() / 1000,
            file_url: fileUrl,
            is_payment: paymentMethod === PAYMENT_ON_DELIVERY ? status : true,
          },
      () => {
        onClose?.()
        onSuccess?.()
      }
    )
  }

  useEffect(() => {
    if (!show) return

    const { handover_shipping, shipping_unit, expected_delivery, shipper_name, shipper_phone } =
      initialValues || {}

    const handoverShipping = handover_shipping ? new Date(handover_shipping * 1000) : null
    const expectedDelivery = expected_delivery ? new Date(expected_delivery * 1000) : null
    const shippingUnit = shipping_unit || ''
    const shipperName = shipper_name || ''
    const shipperPhone = shipper_phone || ''

    setHandoverShipping(handoverShipping)
    setExpectedDelivery(expectedDelivery)
    setShippingUnit(shippingUnit)
    setShipperName(shipperName)
    setShipperPhone(shipperPhone)

    setDirty({
      handoverShipping,
      expectedDelivery,
      shippingUnit,
      shipperName,
      shipperPhone,
    })
  }, [show])

  const errorPhone =
    !shipperPhone || validatePhone().test(shipperPhone) ? undefined : 'Số điện thoại không hợp lệ'

  const today = new Date()

  const isDirty =
    dirty.handoverShipping !== handoverShipping ||
    dirty.expectedDelivery !== expectedDelivery ||
    dirty.shippingUnit !== shippingUnit ||
    dirty.shipperName !== shipperName ||
    dirty.shipperPhone !== shipperPhone

  const minDate = new Date('01/01/2021')
  minDate.setFullYear(today.getFullYear() - 1)

  return (
    <Modal
      title={
        updateShipper
          ? `${isUpdate ? 'Sửa' : 'Nhập'} thông tin vận chuyển`
          : 'Nhập thời gian giao hàng thực tế'
      }
      show={show}
      size="sm"
      close={onClose}
    >
      <DialogContent style={{ paddingTop: 12 }}>
        {updateShipper ? (
          <>
            <RenderField title="Thời gian giao hàng cho đơn vị vận chuyển" required>
              <DatePicker
                rootPortal
                popperPlacement="bottom"
                placeholder="Chọn ngày"
                date={handoverShipping}
                setDate={setHandoverShipping}
                minDate={minDate}
                maxDate={expectedDelivery || dayAfterAYear}
              />
            </RenderField>
            <RenderField title="Đơn vị vận chuyển" required>
              <Input
                value={shippingUnit}
                onChange={(e) => setShippingUnit(e.target.value)}
                placeholder="Đơn vị vận chuyển"
                maxLength={100}
              />
            </RenderField>
            <RenderField title="Thời gian giao hàng dự kiến" required>
              <DatePicker
                rootPortal
                popperPlacement="bottom"
                placeholder="Chọn ngày"
                date={expectedDelivery}
                minDate={handoverShipping ? handoverShipping : today}
                setDate={setExpectedDelivery}
                maxDate={dayAfterAYear}
              />
            </RenderField>
            <RenderField title="Họ tên người vận chuyển">
              <Input
                value={shipperName}
                onChange={(e) => {
                  if (validateTypingName(e)) setShipperName(e.target.value)
                }}
                placeholder="Họ và tên"
                maxLength={50}
              />
            </RenderField>
            <RenderField title="Số điện thoại">
              <Input
                value={shipperPhone}
                onChange={(e) => {
                  if (e.target.value === '' || validateTypingPhone(e))
                    setShipperPhone(e.target.value)
                }}
                placeholder="Số điện thoại"
                error={errorPhone}
                maxLength={20}
              />
            </RenderField>
          </>
        ) : (
          <>
            <RenderField title="Ngày giao hàng" required>
              <div className="d-f jc-c">
                <DatePicker
                  fullWidth
                  rootPortal
                  popperPlacement="bottom"
                  placeholder="Chọn ngày"
                  date={expectedDelivery}
                  setDate={setExpectedDelivery}
                  minDate={handoverShipping || minDate}
                  maxDate={dayAfterAYear}
                />
              </div>
            </RenderField>
            <RenderField title="Hóa đơn đỏ (nếu có)">
              <UploadFile
                file_url={fileUrl}
                onSuccess={(url) => setFileUrl(url)}
                isUpdate={isUpdate}
              />
            </RenderField>
            {paymentMethod === PAYMENT_ON_DELIVERY && (
              <RenderField title="Trạng thái thanh toán" required>
                <div className="d-f ai-c">
                  <Checkbox value={status} onChange={() => setStatus(!status)} />
                  <p>Đã thanh toán</p>
                </div>
              </RenderField>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Hủy bỏ
        </Button>
        <Button
          onClick={update}
          variant="contained"
          color="primary"
          disabled={
            !isDirty ||
            (updateShipper
              ? !handoverShipping || !expectedDelivery || !shippingUnit || !!errorPhone
              : !expectedDelivery)
          }
        >
          {isUpdate ? 'Lưu' : 'Chuyển trạng thái'}
        </Button>
      </DialogActions>
    </Modal>
  )
}

export default ModalShipper
