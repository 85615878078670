/* eslint-disable */
import { MobileStepper } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'store/auth'
import { useBreadcrumbs } from 'store/breadcrumbs'
import FormWrapper from './FormWrapper'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import { useHistory } from 'store/confirmRedirect'
import { orderBy } from 'lodash'
import OrderInfo from './OrderInfo'
import { useIsMobile } from 'store/mobile'
import { DELIVERY_METHODS } from 'configs/constants'

const { GET_AT_ADDRESS, GET_AT_STORE } = DELIVERY_METHODS
const PaymentLayout: React.FC = () => {
  const { t } = useTranslation()
  const { apiGet } = useApis()
  const { showBreadcrumbs } = useBreadcrumbs()
  const isMobile = useIsMobile()
  const { isAuth, signIn, signUp } = useAuth()
  const [activeStep, setActiveStep] = useState(1)
  const { allowRedirect } = useHistory()
  const [showDropDown, setShowDropDown] = useState<any>(false)
  const [codeError, setCodeError] = useState('')

  const [deliveryMethod, setDeliveryMethod] = useState(GET_AT_STORE)

  const [finalPrice, setFinalPrice] = useState(0)

  /**
   * create state payment infor to save data from api payment
   */
  const [infoPayment, setInfoPayment] = useState<any>([])
  /**
   * create state vat infor to save data from api vat
   */
  const [vat, setVat] = useState<any>([])

  /**
   * create state vat infor to save default vat
   */
  const [chooseVat, setChooseVat] = useState({
    id: '',
    nameCompany: '',
    address: '',
    taxCode: '',
    isDefault: 0,
  })

  /**
   * handle next step
   */
  const handleNext = () => {
    window.scrollTo(0, 0)
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  /**
   * handle prev step
   */
  const handleBack = (step: number) => {
    window.scrollTo(0, 0)
    setActiveStep(step)
  }

  /**
   * get Information payment
   */
  const getInformationPayment = () => {
    apiGet(
      apiUrls.informationPayment(),
      {},
      ({ status, data }) => {
        if (status) {
          setInfoPayment(data.items)
        }
      }
      // () => setLoading(false)
    )
  }

  /**
   * get Information payment
   */
  const getVAT = useCallback(() => {
    apiGet(
      apiUrls.vat(),
      {},
      ({ data }) => {
        if (data) {
          const newData = orderBy(data.items, ['is_default'], ['desc'])
          setChooseVat({
            id: newData[0].id,
            nameCompany: newData[0].company_name,
            address: newData[0].company_address,
            taxCode: newData[0].tax_identification_number,
            isDefault: newData[0].is_default,
          })
          setVat(newData)
        }
      }
      // () => setLoading(false)
    )
  }, [chooseVat, vat])

  useEffect(() => {
    getInformationPayment()

    if (isAuth) getVAT()
    showBreadcrumbs([{ name: 'payment', path: '/' }])

    return () => {
      allowRedirect()
    }
  }, [])
  const [formikState, setFormikState] = useState<any>(null)

  return (
    <div className="PaymentLayout-wrapper" onClick={() => {}}>
      <div className="PaymentLayout-leftContent">
        <div className="PaymentLayout-title">{t('payment')}</div>
        {!isAuth && (
          <div className="PaymentLayout-checkLogin">
            <div className="label mt-12">
              {t('already_a_member')}
              <span className="label bold link" onClick={() => signIn()}>
                {t('click_here_to_login')}
              </span>
            </div>
            <div className="label mt-12">
              {t('register_as_a_member_for_faster_payment_and_convenient_order_management')}
              <span className="label bold link" onClick={signUp}>
                {t('register_now')}
              </span>
            </div>
          </div>
        )}

        <div className="PaymentLayout-progressArea">
          <div className="PaymentLayout-titleProgress">
            <div
              className={`PaymentLayout-titleItem cursor-pointer ${
                activeStep === 1 ? 'active' : ''
              } ${activeStep > 1 ? 'pass' : ''}`}
              onClick={() => handleBack(1)}
            >
              Bước 1/3
            </div>
            <div
              className={`PaymentLayout-titleItem cursor-pointer ${
                activeStep === 2 ? 'active' : ''
              } ${activeStep > 2 ? 'pass' : ''}`}
              onClick={() => (activeStep === 3 ? handleBack(2) : () => {})}
            >
              Bước 2/3
            </div>
            <div
              className={`PaymentLayout-titleItem cursor-pointer ${
                activeStep === 3 ? 'active' : ''
              } `}
            >
              Bước 3/3
            </div>
          </div>
          <MobileStepper
            variant="progress"
            steps={4}
            position="static"
            activeStep={activeStep}
            sx={{ maxWidth: 769, flexGrow: 1 }}
            backButton={undefined}
            nextButton={undefined}
            className="PaymentLayout-progress"
          />
        </div>

        <FormWrapper
          setFormikState={setFormikState}
          step={activeStep}
          setStep={setActiveStep}
          paymentInfor={infoPayment}
          vat={vat}
          handleNext={handleNext}
          showDropDown={showDropDown}
          setShowDropDown={setShowDropDown}
          chooseVat={chooseVat}
          setChooseVat={setChooseVat}
          codeError={codeError}
          setDeliveryMethod={setDeliveryMethod}
          deliveryMethod={deliveryMethod}
          finalPrice={finalPrice}
          childrenSummary={
            <OrderInfo
              formikState={formikState}
              setCodeError={setCodeError}
              deliveryMethod={deliveryMethod}
              codeError={codeError}
              setFinalPrice={setFinalPrice}
              finalPrice={finalPrice}
            />
          }
        />
      </div>
      {/* {isMobile ? <div style={{ marginBottom: 24 }} /> : <OrderInfo />} */}
      {isMobile ? (
        <div style={{ marginBottom: 24 }} />
      ) : (
        <OrderInfo
          formikState={formikState}
          setCodeError={setCodeError}
          codeError={codeError}
          deliveryMethod={deliveryMethod}
          setFinalPrice={setFinalPrice}
          finalPrice={finalPrice}
        />
      )}
    </div>
  )
}
export default PaymentLayout
