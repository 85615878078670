/*eslint-disable*/
import { Container } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'
import { useHistory } from 'store/confirmRedirect'
import { RootState } from 'types'

export default function HomeBrands() {
  const { t } = useTranslation()
  const [isDragging, setIsDragging] = useState(false)
  const history = useHistory()
  const engines = useSelector((state: RootState) => state.categories.menu) || []

  const brandMenu = engines[engines.length - 1]?.sub || []

  const settings = {
    dots: true,
    infinite: false,
    speed: 700,
    slidesToShow: 6,
    slidesToScroll: 6,
    arrows: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  const handleViewDetail = (e: any, link: string) => {
    if (isDragging) {
      e.stopPropagation()
      return
    }
    history.push(link)
  }

  return (
    <div className="HomeBrands-container">
      <Container>
        {brandMenu != null && brandMenu.length > 0 && (
          <Slider
            className="HomeBrands-list"
            {...settings}
            beforeChange={() => setIsDragging(true)}
            afterChange={() => setIsDragging(false)}
          >
            {brandMenu.map((brandItem: any, index: number) => (
              <div
                key={index}
                className="HomeBrands-item"
                onClick={(e) => {
                  handleViewDetail(e, brandItem?.linkFull)
                }}
              >
                <div
                  className="icon HomeBrands-image"
                  style={{ backgroundImage: `url("${brandItem?.image_url}")` }}
                />
              </div>
            ))}
          </Slider>
        )}
      </Container>
    </div>
  )
}
