/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import { Box, Card, Tab, Tabs } from '@mui/material'
import GuideUsingStep from 'components/admin/guideUsingStep/GuideUsingStep'
import { renderBackTitle } from 'configs/extensions'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'store/confirmRedirect'

import add1 from '../../../../../assets/images/DocumentTechnicalUsing/add1.png'
import add2 from '../../../../../assets/images/DocumentTechnicalUsing/add2.png'
import add3 from '../../../../../assets/images/DocumentTechnicalUsing/add3.png'
import add4 from '../../../../../assets/images/DocumentTechnicalUsing/add4.png'
import add5 from '../../../../../assets/images/DocumentTechnicalUsing/add5.png'
import add6 from '../../../../../assets/images/DocumentTechnicalUsing/add6.png'
import add7 from '../../../../../assets/images/DocumentTechnicalUsing/add7.png'

import view1 from '../../../../../assets/images/DocumentTechnicalUsing/view1.png'
import view2 from '../../../../../assets/images/DocumentTechnicalUsing/view2.mp4'

import edit1 from '../../../../../assets/images/DocumentTechnicalUsing/edit1.png'
import edit2 from '../../../../../assets/images/DocumentTechnicalUsing/edit2.png'
import edit3 from '../../../../../assets/images/DocumentTechnicalUsing/edit3.png'
import edit4 from '../../../../../assets/images/DocumentTechnicalUsing/edit4.png'
import edit5 from '../../../../../assets/images/DocumentTechnicalUsing/edit5.png'
import edit6 from '../../../../../assets/images/DocumentTechnicalUsing/edit6.png'

import delete1 from '../../../../../assets/images/DocumentTechnicalUsing/delete1.png'
import delete2 from '../../../../../assets/images/DocumentTechnicalUsing/delete2.png'
import delete3 from '../../../../../assets/images/DocumentTechnicalUsing/delete3.png'
import delete4 from '../../../../../assets/images/DocumentTechnicalUsing/delete4.png'
import delete5 from '../../../../../assets/images/DocumentTechnicalUsing/delete5.png'
import delete6 from '../../../../../assets/images/DocumentTechnicalUsing/delete6.png'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const DocumentTechnicalUsing = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      {renderBackTitle(history, t('technical_document_management_using'))}
      <Card sx={{ p: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="THÊM TÀI LIỆU KỸ THUẬT" {...a11yProps(0)} />
            <Tab label="XEM TÀI LIỆU KỸ THUẬT" {...a11yProps(1)} />
            <Tab label="SỬA TÀI LIỆU KỸ THUẬT" {...a11yProps(2)} />
            <Tab label="Xóa tài liệu kỹ thuật" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có ít nhất một danh mục tài liệu ở màn Quản lý danh mục tài liệu.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Thêm tài liệu</b> để thêm một tài liệu kỹ thuật mới."
              image={[add1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Thêm tài liệu</b>, hộp thoại Thêm tài liệu sẽ hiển thị như ảnh bên dưới:"
              image={[add2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Điền thông tin hợp lệ vào tất cả các trường trong hộp thoại Thêm tài liệu, sau đó nhấn nút <b>THÊM</b>. "
              image={[add3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>THÊM</b>, tài liệu kỹ thuật sẽ được thêm thành công và hiển thị trong danh sách:"
              image={[add4]}
            />
            <GuideUsingStep
              descreption="Đồng thời, tài liệu kỹ thuật sẽ hiển thị ở trang <b>Góc kỹ thuật</b>:"
              image={[add5]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Bạn có thể tìm kiếm tài liệu kỹ thuật theo <b>Tiêu đề</b> và lọc danh sách theo <b>Danh mục tài liệu, Ngày đăng tải</b>:',
                  image: [add6],
                },
                {
                  title:
                    'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác thêm tài liệu kỹ thuật mới:',
                  image: [add7],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có ít nhất một tài liệu kỹ thuật ở màn Quản lý tài liệu kỹ thuật.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Xem</b> ở tài liệu kỹ thuật mà bạn cần xem thông tin."
              image={[view1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Xem</b>, mở tài liệu kỹ thuật ở cửa sổ mới như video bên dưới:"
              orther={
                <div
                  className="d-f ai-c jc-c"
                  style={{ marginTop: '8px', flexDirection: 'column' }}
                >
                  <video width={'60%'} controls>
                    <source src={view2} type="video/mp4" />
                  </video>
                </div>
              }
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có ít nhất một tài liệu kỹ thuật ở màn Quản lý tài liệu kỹ thuật.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Sửa</b> ở tài liệu kỹ thuật mà bạn cần sửa thông tin."
              image={[edit1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Sửa</b>, hộp thoại Sửa tài liệu sẽ hiển thị như ảnh bên dưới:"
              image={[edit2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Cập nhật thông tin vào các trường mà bạn muốn sửa trong hộp thoại Sửa tài liệu, sau đó nhấn nút <b>LƯU</b>."
              image={[edit3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>LƯU</b>, thông tin tài liệu kỹ thuật sẽ được cập nhật theo thông tin vừa sửa:"
              image={[edit4]}
            />
            <GuideUsingStep
              descreption="Đồng thời, tài liệu kỹ thuật vừa được sửa thông tin sẽ được cập nhật ở trang <b>Góc kỹ thuật</b>:"
              image={[edit5]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác sửa tài liệu kỹ thuật:',
                  image: [edit6],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có ít nhất một tài liệu kỹ thuật ở màn Quản lý tài liệu kỹ thuật.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Xóa</b> ở tài liệu kỹ thuật mà bạn cần xóa thông tin."
              image={[delete1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Xóa</b>, hộp thoại xác nhận Xóa tài liệu sẽ hiển thị như ảnh bên dưới:"
              image={[delete2]}
            />
            <GuideUsingStep descreption="<b>Bước 2:</b> Nhấn nút <b>XÓA</b>. " image={[delete3]} />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>XÓA</b>, tài liệu kỹ thuật sẽ bị xóa và không còn hiển thị trong danh sách:"
              image={[delete4]}
            />
            <GuideUsingStep
              descreption="Tài liệu kỹ thuật đã bị xóa sẽ không hiển thị ở trang <b>Góc kỹ thuật</b>:"
              image={[delete5]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác xóa tài liệu kỹ thuật:',
                  image: [delete6],
                },
              ]}
            />
          </Box>
        </TabPanel>
      </Card>
    </>
  )
}

export default DocumentTechnicalUsing
