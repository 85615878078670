import { Button } from 'components'
import React from 'react'

interface Props {
  showModal?: any
}

const Sidebar: React.FC<Props> = ({ showModal }) => {
  return (
    <div className="QuestionsSidebar">
      <div className="QuestionsSidebar-wrapper">
        <div className="QuestionsSidebar-info">
          Dưới đây là những câu hỏi thường gặp khi sử dụng website của Mayno.vn. Nếu những câu trả
          lời vẫn chưa giúp được bạn, đừng ngại gọi cho chúng tôi theo số hotline hoặc gửi ý kiến để
          nhận được trợ giúp.
        </div>
      </div>
      <Button
        className="QuestionsSidebar-send"
        color="secondary"
        fullWidth
        icon="email"
        small
        onClick={showModal}
      >
        Gửi câu hỏi
      </Button>
    </div>
  )
}

export default Sidebar
