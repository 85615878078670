import { Button } from 'components'
import Checkbox from './Checkbox'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import Slider from './Slider'
import FilterBlock from './FilterBlock'
import { ProductsContext } from 'configs/constants'
import {
  FilterCategoryType,
  FilterManufacturerType,
  FilterModelType,
  FilterSpecificationType,
} from 'types'
import { Loading } from 'components/common'
import { cloneDeep } from 'lodash'

interface Props {
  hideFilter?: () => void
  filterOtherProduct?: boolean
  [key: string]: any
}

const SLIDER_MIN = 0
const SLIDER_MAX = 100

const hasChangeCheckbox = (item: any) => item.checked
const hasChangeSlider = (item: any) =>
  (item.min && item.min !== SLIDER_MIN) || (item.max && item.max !== SLIDER_MAX)

// Check array has checked item or change value of slider
const hasChange = (data: any[]) =>
  data.some((item) => hasChangeCheckbox(item) || hasChangeSlider(item))

// If has 1 category, manufacturer is checked => show models, specification list, otherwise, hide
const getFirstSelected = (data: any[]) => {
  const checkedList = data.filter((item) => item.checked)

  return checkedList.length === 1 ? checkedList[0] : null
}

const Filter: React.FC<Props> = ({ hideFilter, filterOtherProduct }) => {
  const { filter, onFilter } = useContext(ProductsContext)
  const [reset, setReset] = useState(0)

  const defaultFilter = useRef<any>({
    manufacturers: [],
    models: [],
    categories: [],
    specifications: [],
  })
  const [manufacturersFilter, setManufacturersFilter] = useState<FilterManufacturerType[]>([])
  const [modelsFilter, setModelsFilter] = useState<FilterModelType[]>([])
  const [categoriesFilter, setCategoriesFilter] = useState<FilterCategoryType[]>([])
  const [specificationsFilter, setSpecificationsFilter] = useState<FilterSpecificationType[]>([])

  useEffect(() => {
    setManufacturersFilter(filter.manufacturers)
    setCategoriesFilter(filter.categories)
    defaultFilter.current = {
      manufacturers: filter.manufacturers,
      models: [],
      categories: filter.categories,
      specifications: [],
    }
  }, [filter.categories, filter.manufacturers])

  const firstManufacturerSelection = useMemo(() => {
    const check = getFirstSelected(manufacturersFilter)
    // Check has 1 manufacturer is selected
    // If has 1 manufacturer is checked => show models list, otherwise, hide
    const newModels = check?.other_product
      ? [...check?.models, { id: 'ortherProduct', name: 'Sản phẩm khác' }]
      : check?.models || []
    // if (!keyword) newModels = newModels.filter((item: any) => item.product_count)
    setModelsFilter(newModels)

    return check
  }, [manufacturersFilter])
  const firstCategorySelection = useMemo(() => {
    const check = getFirstSelected(categoriesFilter)
    // Check has 1 category is selected
    // If has 1 category is checked => show specification list, otherwise, hide
    const newSpecifications = (check?.specifications || [])
      .filter((item: any) => item.min)
      .map((item: any) => {
        const min = item.min
        const max = item.max
        return { ...item, min, max, valueMin: min, valueMax: max }
      })
    setSpecificationsFilter(newSpecifications)
    return check
  }, [categoriesFilter])

  // Change value of filter brand
  const handleChangeManufacturersFilter = (index: number, value: boolean) => {
    const newItems = cloneDeep(manufacturersFilter)
    newItems[index].checked = value
    setManufacturersFilter(newItems)

    // If has 1 manufacturer is checked => show models list, otherwise, hide
    setModelsFilter(getFirstSelected(newItems)?.models || [])
  }

  // Change value of filter model
  const handleChangeModelsFilter = (index: number, value: boolean) => {
    const newItems = cloneDeep(modelsFilter)
    newItems[index].checked = value
    setModelsFilter(newItems)
  }

  // Change value of filter category
  const handleChangeCategoriesFilter = (index: number, value: boolean) => {
    const newItems = cloneDeep(categoriesFilter)
    newItems[index].checked = value
    setCategoriesFilter(newItems)
  }

  // Change value of filter specifications
  const handleChangeSpecificationsFilter = (index: number, value: number[]) => {
    const newItems = cloneDeep(specificationsFilter)
    newItems[index].valueMin = value[0]
    newItems[index].valueMax = value[1]
    setSpecificationsFilter(newItems)
  }

  const showClearButton =
    hasChange(manufacturersFilter) ||
    hasChange(modelsFilter) ||
    hasChange(categoriesFilter) ||
    hasChange(specificationsFilter)

  /**
   * Reset filter to all checkbox none
   */
  const resetFilter = () => {
    const { manufacturers, models, categories, specifications } = defaultFilter.current
    setManufacturersFilter(manufacturers)
    setModelsFilter(models)
    setCategoriesFilter(categories)
    setSpecificationsFilter(specifications)
    setReset((reset) => reset + 1)
  }

  /**
   * Filter when click filter
   */
  const handleFilter = () => {
    let model_ids: any[] = []
    if (firstManufacturerSelection)
      model_ids = modelsFilter
        .filter(hasChangeCheckbox)
        .filter((i) => i.id !== 'ortherProduct')
        .map((item) => item.id)
    let specifications: any[] = []
    if (firstCategorySelection)
      specifications = specificationsFilter.filter(hasChangeSlider).map((item) => ({
        id: item.id,
        min: item.valueMin || SLIDER_MIN,
        max: item.valueMax || SLIDER_MAX,
      }))

    const hasOrtherProduct = modelsFilter
      .filter(hasChangeCheckbox)
      .some((i) => i.id === 'ortherProduct')

    onFilter(
      {
        category_ids: categoriesFilter.filter(hasChangeCheckbox).map((item) => item.id),
        manufacturer_ids: manufacturersFilter.filter(hasChangeCheckbox).map((item) => item.id),
        model_ids,
        specifications,
        page: 1,
      },
      false,
      true,
      {
        other_product: hasOrtherProduct ? true : filterOtherProduct ? true : undefined,
        filter_by_manufacturer: filterOtherProduct ? true : undefined,
      }
    )

    hideFilter?.()
  }

  if (!manufacturersFilter.length && !categoriesFilter.length) return null

  return (
    <div className="ProductsFilter">
      <div className="ProductsFilter-title">
        <div className="ProductsFilter-title__main">
          Bộ lọc sản phẩm <i className="mn-icon-filter" />
        </div>
        <div className="ProductsFilter-title__close" onClick={hideFilter}>
          <i className="mn-icon-close" />
        </div>
      </div>
      <div className="ProductsFilter-hr" />
      <div className="ProductsFilter-body">
        {filter.loading && <Loading />}
        <FilterBlock
          title="Hãng sản xuất"
          data={manufacturersFilter}
          reset={reset}
          renderContent={(item, index) => (
            <Checkbox
              key={item.id}
              {...item}
              onChange={(value: any) => handleChangeManufacturersFilter(index, value)}
            />
          )}
        />
        <FilterBlock
          title="Model"
          data={modelsFilter}
          reset={reset}
          renderContent={(item, index) => (
            <Checkbox
              key={item.id}
              {...item}
              onChange={(value: any) => handleChangeModelsFilter(index, value)}
            />
          )}
        />
        <FilterBlock
          title="Danh mục sản phẩm"
          data={categoriesFilter}
          reset={reset}
          renderContent={(item, index) => (
            <Checkbox
              key={item.id}
              {...item}
              onChange={(value: any) => handleChangeCategoriesFilter(index, value)}
            />
          )}
        />
        <FilterBlock
          title="Thông số kỹ thuật"
          data={specificationsFilter}
          reset={reset}
          renderContent={(item, index) => (
            <Slider
              key={item.id}
              min={item.min}
              max={item.max}
              {...item}
              value={[item.valueMin, item.valueMax]}
              onChange={(value: any) => handleChangeSpecificationsFilter(index, value)}
            />
          )}
        />
      </div>
      <div className="ProductsFilter-footer">
        {showClearButton && (
          <Button color="stroke" onClick={resetFilter}>
            Xóa
          </Button>
        )}
        <Button icon="arrow" onClick={handleFilter}>
          Áp dụng lọc
        </Button>
      </div>
    </div>
  )
}

export default Filter
