/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Modal } from 'components'
import { Button, Input } from 'components/admin'
import { DialogActions, DialogContent } from '@mui/material'
import { useAdminOrders } from './hook'

interface Props {
  show: boolean
  id: string
  onClose?: any
  onSuccess?: any
}

const ModalCancelOrder: React.FC<Props> = (props) => {
  const { show, id, onClose, onSuccess } = props
  const { cancelOrder } = useAdminOrders()

  const [reason, setReason] = useState('')

  const cancel = () => {
    cancelOrder(
      id,
      (reason || '').trim(),
      () => {
        onClose?.()
        onSuccess?.()
      },
      undefined,
      undefined,
      onClose
    )
  }

  useEffect(() => {
    setReason('')
  }, [show])

  return (
    <Modal title="Huỷ đơn hàng" show={show} size="sm" close={onClose}>
      <DialogContent>
        <div className="Admin-formTitle">Nhập lý do huỷ đơn hàng</div>
        <Input
          required
          rows={5}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          multiline
          maxLength={500}
        />
      </DialogContent>

      <DialogActions>
        <Button type="button" onClick={onClose} color="primary">
          Giữ đơn hàng
        </Button>
        <Button type="submit" onClick={cancel} variant="contained" color="primary">
          Huỷ đơn hàng
        </Button>
      </DialogActions>
    </Modal>
  )
}

export default ModalCancelOrder
