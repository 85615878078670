import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useApp } from 'store/app'
import { Button } from 'components'
import { useHistory } from 'store/confirmRedirect'

export default function HomeAdvertisement() {
  const { t } = useTranslation()
  const { push } = useHistory()
  const { advertisementData, resetAdvertisement } = useApp()
  const ref = useRef<any>()

  const { image, id } = advertisementData || {}

  useEffect(() => {
    if (image) document.body.classList.add('dialog')

    return () => {
      document.body.classList.remove('dialog')
    }
  }, [image])

  // Close when click outside
  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        resetAdvertisement()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  /**
   * Handle click buy now
   */
  const handleClickBuyNow = () => {
    push(`/tin-tuc-su-kien/${id}`)
  }

  if (!image) return null

  return (
    <div className="HomeAdvertisement-area">
      <div
        className="HomeAdvertisement-content"
        style={{ backgroundImage: `url("${image}")` }}
        ref={ref}
      >
        <span className="Ic-close icon" onClick={() => resetAdvertisement()} />
        <span className="HomeAdvertisement-img" />
        <Button className="HomeAdvertisement-btn" onClick={handleClickBuyNow}>
          {t('viewDetail')} <span className="Ic-next icon" />
        </Button>
      </div>
    </div>
  )
}
