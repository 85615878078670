import React from 'react'
import { useTranslation } from 'react-i18next'

interface PropTypes {
  className?: string
  data?: string
}

export default function DetailInfoTabContent(props: PropTypes) {
  const { t } = useTranslation()
  const { className = '', data = '' } = props

  const dataRegex = data.replaceAll('<a href', '<a target="_blank" href')

  return (
    <div className={`ProductDetailInfo-content ${className}`}>
      {data && data.toLowerCase() !== 'nan' ? (
        <div dangerouslySetInnerHTML={{ __html: dataRegex }} />
      ) : (
        <div className="ProductDetailInfo-updating">{t('updating')}</div>
      )}
    </div>
  )
}
