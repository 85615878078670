/*eslint-disable*/
import { borderRight } from '@mui/system'
import { FORMAT_DATE } from 'configs/constants'
import moment from 'moment'
import { formatPriceQuotes } from 'utils'

import LogoMaynoQuotes from '../../../../assets/images/LogoMaynoQuotes.png'
import LogoQuotes2 from '../../../../assets/images/logoQuotes2.png'
import backGroundLogo from '../../../../assets/images/backGroundLogo.png'

import './form.scss'

interface PropsType {
  componentToPrintRef: React.RefObject<any>
  data: any
  hidePrice?: boolean
}

const TemplateQuotation: React.FC<PropsType> = ({ componentToPrintRef, data, hidePrice }) => {
  const {
    customerName,
    customerPhone,
    customerAddress,
    customer_code,
    number_of_quotes,
    adminPhone,
    full_name,
    model,
    products_json,
    initPrice,
    finalPrice,
    email,
    phone,
    adminName,
    created_date,
  } = data

  return (
    <div style={{ display: 'none' }}>
      <div
        ref={componentToPrintRef}
        style={{
          width: '100% !important',
          margin: 0,
          padding: 0,
          backgroundColor: 'rgb(14, 13, 13)',
        }}
        className="test"
      >
        <div className="body">
          <div
            className="background"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              WebkitPrintColorAdjust: 'exact',
              opacity: '0.08',
              backgroundSize: 'cover',
              backgroundImage: `url(${backGroundLogo})`,
            }}
          />
          <div className="page">
            <div className="header">
              <div style={{ width: '55%' }}>
                <img alt="logo" className="logo" src={LogoMaynoQuotes} width="170px" />
                <h5 className="link_web" style={{ margin: 0, marginLeft: 10 }}>
                  CÔNG TY TNHH DỊCH VỤ KỸ THUẬT VÀ PHỤ TÙNG DPS
                </h5>
                <p style={{ margin: 0, marginLeft: 10, fontSize: 12 }}>
                  B15-03, Khu chức năng đô thị Thành phố Xanh, Phường Cầu Diễn, Quận Nam Từ Liêm,
                  Thành phố Hà Nội, Việt Nam
                </p>
              </div>
              <div style={{ marginTop: 30 }}>
                <h5 style={{ margin: 0 }}>
                  Web: <a href="https://mayno.vn">https://mayno.vn</a>
                </h5>
                <h5 style={{ margin: 0 }}>Hotline: 0813131309</h5>
                <h5 style={{ margin: 0 }}>Zalo: 0813131309</h5>
              </div>
            </div>
            <div className="content" style={{ paddingTop: 30 }}>
              <h3
                style={{ textAlign: 'center', fontFamily: 'Roboto, sans-serif', marginBottom: 20 }}
              >
                BÁO GIÁ PHỤ TÙNG
              </h3>
              <div
                style={{
                  display: 'flex',
                  border: '1px solid black',
                  padding: '0 20px',
                }}
              >
                <div style={{ width: '50%' }}>
                  <p style={{ fontSize: 12, margin: '8px 0' }}>Mã số KH: {customer_code}</p>
                  <p style={{ fontSize: 12, margin: '8px 0' }}>Tên khách hàng: {customerName}</p>
                  <p style={{ fontSize: 12, margin: '8px 0' }}>Địa chỉ: {customerAddress}</p>
                  <p style={{ fontSize: 12, margin: '8px 0' }}>Số điện thoại: {customerPhone}</p>
                  <p style={{ fontSize: 12, margin: '8px 0' }}>Người nhận: {full_name}</p>
                  <p style={{ fontSize: 12, margin: '8px 0' }}>Số Model: {model}</p>
                </div>
                <div style={{ borderRight: '1px solid black' }}></div>
                <div style={{ width: '50%', marginLeft: 20 }}>
                  <p style={{ fontSize: 12, margin: '8px 0' }}>Báo giá số: {number_of_quotes}</p>
                  <p style={{ fontSize: 12, margin: '8px 0' }}>
                    Ngày báo giá: {moment(created_date).format(FORMAT_DATE)}
                  </p>
                  <p style={{ fontSize: 12, margin: '8px 0' }}>
                    Ngày hết hạn: {moment(created_date).add(30, 'days').format(FORMAT_DATE)}
                  </p>
                  <p style={{ fontSize: 12, margin: '8px 0' }}>Người báo giá: {adminName}</p>
                  <p style={{ fontSize: 12, margin: '8px 0' }}>
                    Số điện thoại: {phone || adminPhone}
                  </p>
                  <p style={{ fontSize: 12, margin: '8px 0' }}>Email: {email}</p>
                </div>
              </div>
              <div style={{ marginTop: 20, fontSize: '12px !important' }}>
                <table
                  style={{
                    border: '1px solid',
                    fontSize: 12,
                    borderCollapse: 'collapse',
                    tableLayout: 'fixed',
                    wordWrap: 'break-word',
                    display: 'table !important',
                  }}
                >
                  <thead>
                    <tr style={{ fontWeight: 700, background: 'rgb(218, 229, 236)' }}>
                      <th style={{ border: '1px solid', fontSize: 11, width: '6%' }}>STT</th>
                      <th
                        style={{ border: '1px solid', fontSize: 11, padding: '6px 0', width: '8%' }}
                      >
                        Mã số yêu cầu
                      </th>
                      <th
                        style={{
                          border: '1px solid',
                          fontSize: 11,
                          padding: '6px 0',
                          width: hidePrice ? '11%' : '8%',
                        }}
                      >
                        Mã số cung cấp
                      </th>
                      <th
                        style={{
                          border: '1px solid',
                          fontSize: 11,
                          padding: '6px 0',
                          width: '20%',
                        }}
                      >
                        Diễn giải
                      </th>
                      <th
                        style={{ border: '1px solid', fontSize: 11, padding: '6px 0', width: '6%' }}
                      >
                        Đvt
                      </th>
                      <th
                        style={{ border: '1px solid', fontSize: 11, padding: '6px 0', width: '7%' }}
                      >
                        Số lượng
                      </th>
                      {!hidePrice && (
                        <th
                          style={{
                            border: '1px solid',
                            fontSize: 11,
                            padding: '6px 0',
                            width: '15%',
                          }}
                        >
                          Đơn giá
                        </th>
                      )}
                      {!hidePrice && (
                        <th
                          style={{
                            border: '1px solid',
                            fontSize: 11,
                            padding: '6px 0',
                            width: '15%',
                          }}
                        >
                          Thành tiền
                        </th>
                      )}
                      <th
                        style={{
                          border: '1px solid',
                          fontSize: 11,
                          padding: '6px 0',
                          width: '15%',
                        }}
                      >
                        Xuất xứ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {products_json?.map((item: any) => {
                      const nameSplice = item?.name?.trim()?.split(' ').slice(1).join(' ')
                      return (
                        <tr key={item.id} style={{ textAlign: 'center' }}>
                          <td style={{ border: '1px solid', fontSize: 11, padding: '0 5px' }}>
                            {item.number}
                          </td>
                          <td style={{ border: '1px solid', fontSize: 11, padding: '0 5px' }}>
                            {item.original_code}
                          </td>
                          <td style={{ border: '1px solid', fontSize: 11, padding: '0 5px' }}>
                            {item.provide_code}
                          </td>
                          <td style={{ border: '1px solid', fontSize: 11, padding: '0 5px' }}>
                            {nameSplice}
                          </td>
                          <td style={{ border: '1px solid', fontSize: 11, padding: '0 5px' }}>
                            {item.product_unit == '1' ? 'Cái' : 'Bộ'}
                          </td>
                          <td style={{ border: '1px solid', fontSize: 11, padding: '0 5px' }}>
                            {item.quantity}
                          </td>
                          {!hidePrice && (
                            <td
                              style={{
                                border: '1px solid',
                                fontSize: 11,
                                padding: '0 5px',
                                textAlign: 'right',
                              }}
                            >
                              {formatPriceQuotes(item.price)}
                            </td>
                          )}
                          {!hidePrice && (
                            <td
                              style={{
                                border: '1px solid',
                                fontSize: 11,
                                padding: '0 5px',
                                textAlign: 'right',
                              }}
                            >
                              {formatPriceQuotes(item.total)}
                            </td>
                          )}
                          <td style={{ border: '1px solid', fontSize: 11, padding: '0 5px' }}>
                            {item.manufacturer}
                          </td>
                        </tr>
                      )
                    })}

                    {finalPrice !== initPrice && !hidePrice && (
                      <tr>
                        <td
                          style={{
                            border: '1px solid',
                            fontSize: 11,
                            fontWeight: 700,
                            textAlign: 'right',
                            padding: '5px 15px',
                          }}
                          colSpan={7}
                        >
                          Tổng tiền trước giảm (VNĐ):
                        </td>
                        <td
                          colSpan={3}
                          style={{
                            border: '1px solid',
                            fontSize: 11,
                            textAlign: 'right',
                            paddingRight: '30px',
                          }}
                        >
                          {formatPriceQuotes(initPrice)}
                        </td>
                      </tr>
                    )}
                    {finalPrice - initPrice < 0 && !hidePrice && (
                      <tr>
                        <td
                          style={{
                            border: '1px solid',
                            fontSize: 11,
                            fontWeight: 700,
                            textAlign: 'right',
                            padding: '5px 15px',
                          }}
                          colSpan={7}
                        >
                          Giảm giá (VNĐ):
                        </td>
                        <td
                          colSpan={3}
                          style={{
                            border: '1px solid',
                            fontSize: 11,
                            textAlign: 'right',
                            paddingRight: '30px',
                          }}
                        >
                          {formatPriceQuotes(initPrice - finalPrice)}
                        </td>
                      </tr>
                    )}
                    {!hidePrice && (
                      <tr>
                        <td
                          style={{
                            border: '1px solid',
                            fontSize: 11,
                            fontWeight: 700,
                            textAlign: 'right',
                            padding: '5px 15px',
                          }}
                          colSpan={7}
                        >
                          Tổng cộng (VNĐ):
                        </td>
                        <td
                          colSpan={3}
                          style={{
                            border: '1px solid',
                            fontSize: 11,
                            textAlign: 'right',
                            paddingRight: '30px',
                          }}
                        >
                          {formatPriceQuotes(finalPrice)}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div>
                <h5 style={{ margin: '20px 0', fontSize: 12 }}>
                  Bảo hành/ Điều khoản giao hàng/ Phương thức thanh toán
                </h5>
                <div style={{ marginLeft: 15 }}>
                  <p style={{ fontSize: 12, margin: 0 }}>
                    <span style={{ width: 200, display: 'inline-block' }}>Chất lượng</span>
                    <span>: Hàng chính hãng mới 100%</span>
                  </p>
                  <p style={{ fontSize: 12, margin: 0 }}>
                    <span style={{ width: 200, display: 'inline-block' }}>Bảo hành</span>
                    <span>: Theo tiêu chuẩn của nhà sản xuất: 6 tháng kể từ ngày hóa đơn</span>
                  </p>
                  <p style={{ fontSize: 12, margin: 0 }}>
                    <span style={{ width: 200, display: 'inline-block' }}>Thời gian giao hàng</span>
                    <span>: 3-5 ngày sau thanh toán hoặc sau khi đơn đặt hàng được xác nhận</span>
                  </p>
                  <p style={{ fontSize: 12, margin: 0 }}>
                    <span style={{ width: 200, display: 'inline-block' }}>Địa điểm giao hàng</span>
                    <span>: Tại địa chỉ khách hàng</span>
                  </p>
                  <p style={{ fontSize: 12, margin: 0 }}>
                    <span style={{ width: 200, display: 'inline-block' }}>
                      Chứng từ kèm hàng hóa
                    </span>
                    <span>: Hóa đơn</span>
                  </p>
                  <p style={{ fontSize: 12, margin: 0 }}>
                    <span style={{ width: 200, display: 'inline-block' }}>
                      Phương thức thanh toán
                    </span>
                    <span>: Thanh toán 100% trước khi nhận hàng</span>
                  </p>
                  <h5 style={{ margin: '20px 0', fontSize: 12 }}>Thông tin tài khoản:</h5>
                  <div style={{ marginLeft: 45 }}>
                    <p style={{ fontSize: 12, margin: 0 }}>STK: 19038025485686</p>
                    <p style={{ fontSize: 12, margin: 0 }}>
                      Ngân hàng TMCP Kỹ thương Việt Nam - Chi nhánh Đông Đô
                    </p>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', paddingTop: 30 }}>
                <p style={{ width: '50%', textAlign: 'center', fontSize: 12 }}>Người báo giá</p>
                <p style={{ width: '50%', textAlign: 'center', fontSize: 12 }}>
                  Xác nhận của khách hàng
                </p>
              </div>
            </div>
          </div>
          <div className="footer">
            <img src={LogoMaynoQuotes} alt="" className="main_logo" />
            <p>Nhà phân phối sản phẩm chính hãng</p>
            <img src={LogoQuotes2} alt="" className="sub_logo" />
            <p>tại Việt Nam</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplateQuotation
