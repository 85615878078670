import { Button } from 'components'
import Checkbox from '../products/Checkbox'
import React, { useEffect, useState } from 'react'
import FilterBlock from '../products/FilterBlock'
import { DocumentCategoryType } from 'types'
import { cloneDeep } from 'lodash'
import '../products/index.scss'

interface Props {
  defaultFilter: DocumentCategoryType[]
  onFilter: (params: any) => void
  hideFilter?: () => void
  [key: string]: any
}

const hasChangeCheckbox = (item: any) => item.checked

// Check array has checked item or change value of slider
const hasChange = (data: any[]) => data.some((item) => hasChangeCheckbox(item))

const Filter: React.FC<Props> = ({ hideFilter, defaultFilter, onFilter }) => {
  const [reset, setReset] = useState(0)

  const [categoriesFilter, setCategoriesFilter] = useState<DocumentCategoryType[]>([])

  useEffect(() => {
    setCategoriesFilter(defaultFilter)
  }, [defaultFilter])

  // Change value of filter category
  const handleChangeCategoriesFilter = (index: number, value: boolean) => {
    const newItems = cloneDeep(categoriesFilter)
    newItems[index].checked = value
    setCategoriesFilter(newItems)
  }

  const showClearButton = hasChange(categoriesFilter)

  /**
   * Reset filter to all checkbox none
   */
  const resetFilter = () => {
    setCategoriesFilter(defaultFilter)
    setReset((reset) => reset + 1)
  }

  /**
   * Filter when click filter
   */
  const handleFilter = () => {
    onFilter(categoriesFilter.filter(hasChangeCheckbox).map((item) => item.id))

    hideFilter?.()
  }

  if (!categoriesFilter.length) return null

  return (
    <div className="ProductsFilter">
      <div className="ProductsFilter-title">
        <div className="ProductsFilter-title__main">
          Lọc tài liệu <i className="mn-icon-filter" />
        </div>
        <div className="ProductsFilter-title__close" onClick={hideFilter}>
          <i className="mn-icon-close" />
        </div>
      </div>
      <div className="ProductsFilter-hr" />
      <div className="ProductsFilter-body">
        <FilterBlock
          title="Danh mục tài liệu"
          data={categoriesFilter}
          reset={reset}
          renderContent={(item, index) => (
            <Checkbox
              key={item.id}
              {...item}
              onChange={(value: any) => handleChangeCategoriesFilter(index, value)}
            />
          )}
        />
      </div>
      <div className="ProductsFilter-footer">
        {showClearButton && (
          <Button color="stroke" onClick={resetFilter}>
            Xóa
          </Button>
        )}
        <Button icon="arrow" onClick={handleFilter}>
          Áp dụng lọc
        </Button>
      </div>
    </div>
  )
}

export default Filter
