import * as Yup from 'yup'
import { Button, Input } from 'components'
import { validatePhone, validateTypingPhone } from 'utils'
import { Form, FormikProvider, useFormik } from 'formik'
import { Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useApis } from 'services/api'
import { useEffect, useState } from 'react'
import { apiUrls } from 'configs/apis'
import { useAuth } from 'store/auth'

const validationSchema = (phoneNotExist: any) =>
  Yup.object().shape({
    phone: Yup.string()
      .required()
      .min(1, 'phone_format_is_incorrect')
      .notOneOf([phoneNotExist.phone], phoneNotExist.text)
      .matches(validatePhone(), 'phone_format_is_incorrect'),
  })

interface Props {
  next: () => void
  setSendEmailSuccess: any
}

export default function SignIn({ next, setSendEmailSuccess }: Props) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [phoneNotExist, setPhoneNotExist] = useState({ phone: '', text: '' })
  const { updateAuth } = useAuth()

  const { apiPost } = useApis()

  const formik = useFormik({
    initialValues: {
      phone: '',
    },
    validationSchema: validationSchema(phoneNotExist),
    onSubmit: ({ phone }) => {
      setLoading(true)
      apiPost(
        apiUrls.sendOTP(),
        { phone, action: 1 },
        ({ status, text, data }) => {
          if (status) {
            updateAuth({ phone, user_id: data?.user_id })
            setSendEmailSuccess(data?.verify_method === 2 ? true : false)
            next()
          } else {
            setLoading(false)
            setPhoneNotExist({ phone, text: text || '' })
            validateForm()
          }
        },
        () => setLoading(false)
      )
    },
  })

  const { errors, handleSubmit, getFieldProps, handleChange, isValid, validateForm } = formik

  // Validate to disable button on startup
  useEffect(() => {
    validateForm()
  }, [])

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <span className="label">
            Nhập số điện thoại của bạn để xác nhận việc đặt lại mật khẩu
          </span>
          <Input
            fullWidth
            autoFocus
            type="tel"
            autoComplete="new-password"
            {...getFieldProps('phone')}
            error={!!errors.phone && t(errors.phone?.toString())}
            label={t('phone')}
            onChange={(e) => {
              if (e.target.value === '' || validateTypingPhone(e)) handleChange(e)
            }}
            disabled={loading}
            maxLength={20}
            blurWhenEnter
            required
          />
          <Box>
            <Button
              type="submit"
              loading={loading}
              disabled={loading || !isValid}
              fullWidth
              icon="arrow"
            >
              {t('confirm')}
            </Button>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  )
}
