import { combineReducers } from 'redux'

import { authReducer } from './auth'
import { modalConfirmReducer } from './modalConfirm'
import { toastsReducer } from './toasts'
import { breadcrumbsReducer } from './breadcrumbs'
import { shoppingCartReducer } from './shoppingCart'
import { appReducer } from './app'
import { categoriesReducer } from './categories'
import { confirmRedirectReducer } from './confirmRedirect'
import { mobileReducer } from './mobile'

const rootReducer = combineReducers({
  modalConfirm: modalConfirmReducer,
  auth: authReducer,
  toasts: toastsReducer,
  breadcrumbsReducer,
  cart: shoppingCartReducer,
  appReducer,
  categories: categoriesReducer,
  confirmRedirect: confirmRedirectReducer,
  mobile: mobileReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
