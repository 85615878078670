import { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth } from 'store/auth'
import { useBreadcrumbs } from 'store/breadcrumbs'
import Sidebar from './Sidebar'

const Menu: React.FC = () => {
  const { showBreadcrumbs } = useBreadcrumbs()
  const { isAuth } = useAuth()

  useEffect(() => {
    showBreadcrumbs([{ name: 'account', path: '/' }])
  }, [])

  if (!isAuth) return <Redirect to="/" />

  return (
    <div className="ProfileMenu">
      <Sidebar />
    </div>
  )
}

export default Menu
