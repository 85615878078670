import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem, useMediaQuery, Paper, Grow, Menu, Popper } from '@mui/material'

import { useAuth } from 'store/auth'

import AppToolItem from './AppToolItem'
import { useHistory } from 'store/confirmRedirect'

export default function AppHeaderUserMenu() {
  const history = useHistory()
  const { t } = useTranslation()
  const { signIn, signUp, signOut, name, isAuth } = useAuth()
  const { goTo } = history

  const anchorRef = useRef<any>(null)
  const isMobile = useMediaQuery('(max-width: 860px)')
  const [open, setOpen] = useState(false)

  /**
   * Handle close user menu
   */
  const handleCloseUserMenu = () => {
    setOpen(false)
  }

  /**
   * Handle redirect
   */
  const handleRedirect = () => {
    if (isMobile) return history.push('/personal-menu')

    history.push('/personal-information')
  }

  const NewMenuItem = useCallback(
    ({ label, onClick }: any) => (
      <MenuItem
        onClick={(e) => {
          handleCloseUserMenu()
          onClick?.(e)
        }}
      >
        {t(label)}
      </MenuItem>
    ),
    []
  )

  const menuItemsAuth = [
    <NewMenuItem key="1" label="myAccount" onClick={handleRedirect} />,
    <NewMenuItem
      key="2"
      label="orders"
      onClick={() => history.push('/personal-information/my-order')}
    />,
    <NewMenuItem key="3" label="logout" onClick={() => goTo(signOut)} />,
  ]

  const menuItemNotAuth = [
    <NewMenuItem key="4" label="sign_in" onClick={() => signIn()} />,
    <NewMenuItem key="5" label="sign_up" onClick={signUp} />,
  ]

  const menuItems = isAuth ? menuItemsAuth : menuItemNotAuth

  if (isMobile)
    return (
      <>
        <AppToolItem
          className="user"
          icon="user"
          label={name || 'account'}
          onClick={() => setOpen(true)}
          innerRef={anchorRef}
        />
        <Menu
          className="AppHeaderTools-userMenu"
          anchorEl={anchorRef.current}
          open={open}
          onClose={handleCloseUserMenu}
          onClick={handleCloseUserMenu}
          autoFocus={false}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        >
          {menuItems}
        </Menu>
      </>
    )

  return (
    <AppToolItem
      className="user"
      icon="user"
      label={name || 'account'}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      innerRef={anchorRef}
    >
      {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Popper
        className="AppHeaderTools-userMenu"
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'left top' }}>
            <Paper>
              <div>{menuItems}</div>
            </Paper>
          </Grow>
        )}
      </Popper>
    </AppToolItem>
  )
}
