import { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import qs from 'qs'
import { useApis } from 'services/api'

import { useBreadcrumbs } from 'store/breadcrumbs'
import { useAuth } from 'store/auth'

import { Loading } from 'components/common'
import {
  ProductDetailInfo,
  ProductDetailIntro,
  ProductDetailSlider,
  RecommendProducts,
  PageNotFound,
} from 'components/screens'
import { apiUrls } from 'configs/apis'
import { ProductType } from 'types'
import { convertViewedProductsData } from 'utils'
import { MAX_RELATIVE_ITEMS } from 'configs/constants'
import { useCategories } from 'store/categories'
import { useModalConfirm } from 'store/modalConfirm'
import { useHistory } from 'store/confirmRedirect'
import { useShoppingCart } from 'store/shoppingCart'

export default function ProductDetail() {
  const history = useHistory()
  const { productSlug } = useParams<{ productSlug: string }>()
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()
  const { fetchCart, removeItemFromCart } = useShoppingCart()
  const { apiGet, apiPost } = useApis()
  const { showBreadcrumbs } = useBreadcrumbs()
  const { getBreadcrumbs } = useCategories()
  const location = useLocation()
  const { state } = location

  const { fromCategory, fromSearch, cart } = state || ({} as any)

  const { isAuth } = useAuth()

  const [products, setProducts] = useState<{ current: number; items: ProductType[] }>({
    current: 0,
    items: [],
  })

  // states
  const [isLoading, setIsLoading] = useState(true)
  const [similarProducts, setSimilarProducts] = useState({ items: [], total: 0 })
  const [viewedProducts, setViewedProducts] = useState({ items: [], total: 0 })

  const product: ProductType = products.items?.[products.current]
  const { id: productId, name = '', category, manufacturer } = product || {}

  const { id: categoryId = '' } = category || {}
  const { id: brandId = '' } = manufacturer || {}

  /**
   * Get relative products api
   *
   * @param categoryId Category id
   * @param brandId Brand id
   */
  const getRelativeProductsApi = useCallback(
    async (categoryId: string, brandId: string) => {
      try {
        const res = await apiPost(apiUrls.filterProducts(), {
          page_size: MAX_RELATIVE_ITEMS + 1,
          category_ids: [categoryId],
          manufacturer_ids: [brandId],
        })

        const { status, data } = res
        const { items, total } = data || {}
        if (status)
          setSimilarProducts({
            items: (items || [])
              .filter((item: any) => item.link_seo !== productSlug)
              .slice(0, MAX_RELATIVE_ITEMS),
            total: total - 1,
          })
      } catch (e) {
        // Exception
      }
    },
    [productSlug]
  )

  /**
   * Get viewed products api
   */
  const getViewedProductsApi = useCallback(async () => {
    try {
      const res = await apiGet(apiUrls.viewedProducts(), {
        page_size: MAX_RELATIVE_ITEMS,
      })

      const { status, data } = res
      const { items, total } = data || {}
      if (status) setViewedProducts({ items: convertViewedProductsData(items || []), total })
    } catch (e) {
      // Exception
    }
  }, [location])

  /**
   * Api get product detail by product key
   */
  const getProductDetailApi = useCallback(async () => {
    setIsLoading(true)

    try {
      const res = await apiGet(apiUrls.getProductDetail(productSlug))
      const { status, data, text } = res

      const { category, manufacturer } = data || {}
      const { id: categoryId = '' } = category || {}
      const { id: brandId = '' } = manufacturer || {}
      if (status) {
        setProducts({
          current: 0,
          items: [data, ...data.similar_products.filter((item: any) => item.manufacturer?.name)],
        })
        getRelativeProductsApi(categoryId, brandId)
      } else {
        setProducts({
          current: 0,
          items: [],
        })
        if (cart) {
          if (isAuth) fetchCart()
          else removeItemFromCart('', cart)
        }
        showModalConfirm({
          title: 'Thông tin',
          content: text,
          confirm: {
            action: () => {
              hideModalConfirm()
              history.goBack()
            },
            text: 'Đã hiểu',
          },
        })
      }
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }, [productSlug, products])

  useEffect(() => {
    getProductDetailApi()
  }, [productSlug, location])

  useEffect(() => {
    if (isAuth && productId) {
      getViewedProductsApi()

      // View product
      apiPost(apiUrls.viewedProducts(), { product_id: productId })
    }
  }, [products.items, location])

  useEffect(() => {
    let breadcrumbs: any
    const { keyword, pathname, search } = fromSearch || {}
    if (keyword) {
      breadcrumbs = [{ name: keyword, path: pathname + search }]
    } else breadcrumbs = getBreadcrumbs(fromCategory || categoryId)
    if (name && breadcrumbs?.length) breadcrumbs = [...breadcrumbs, { name, path: '/' }]
    showBreadcrumbs(breadcrumbs)
  }, [name, getBreadcrumbs, categoryId, fromCategory])

  /**
   * Handle view relative products
   */
  const handleViewSimilarProducts = () => {
    const requestParams = { categoryId, brandId }

    history.push({
      pathname: '/san-pham-tuong-tu',
      search: qs.stringify(requestParams),
    })
  }

  // If not exist product
  if (!isLoading && !productId) return <PageNotFound />

  return (
    <div className="ProductDetail-area">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="ProductDetailIntro-container">
            <ProductDetailSlider product={product} />
            <ProductDetailIntro product={product} products={products} setProducts={setProducts} />
          </div>
          <div className="ProductDetail-info">
            <ProductDetailInfo product={product} />
          </div>
          <div className="ProductDetail-products">
            {!!similarProducts.total && (
              <RecommendProducts
                label="similarProducts"
                btnLabel={similarProducts.total > MAX_RELATIVE_ITEMS ? 'loadMore' : ''}
                products={similarProducts.items}
                onClickBtn={handleViewSimilarProducts}
              />
            )}
            {isAuth && !!viewedProducts.total && (
              <RecommendProducts
                className="ProductDetail-favorites"
                label="viewedProducts"
                btnLabel={viewedProducts.total > MAX_RELATIVE_ITEMS ? 'loadMore' : ''}
                products={viewedProducts.items}
                onClickBtn={() => history.push('/san-pham-da-xem')}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}
