import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { useBreadcrumbs } from 'store/breadcrumbs'
import Sidebar from './Sidebar'
import Documents from './Documents'
import { useIsMobile } from 'store/mobile'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import { DocumentCategoryType, DocumentType, ObjectType } from 'types'
import qs from 'qs'
import './index.scss'
import { useHistory } from 'react-router-dom'
import { Button, InputSearch } from 'components'
import Header from './Header'
import { MAX_PAGE_SIZE } from 'configs/constants'
import { sortBy } from 'utils'

const DocumentsContainer: React.FC = () => {
  const history = useHistory()
  const { apiGet, apiPost } = useApis()
  const { showBreadcrumbsRightMenu } = useBreadcrumbs()
  const isMobile = useIsMobile()
  const { search: requestParams } = useLocation()

  const parsedRequestParams = qs.parse(requestParams, { ignoreQueryPrefix: true })
  const { keyword } = parsedRequestParams
  const page = Number(parsedRequestParams.page) || 1

  const [categories, setCategories] = useState<{ loading: boolean; items: DocumentCategoryType[] }>(
    { items: [], loading: true }
  )
  const [documents, setDocuments] = useState<{
    loading: boolean
    items: DocumentType[]
    totalPages: number
  }>({
    loading: true,
    items: [],
    totalPages: 0,
  })
  const categoriesFilter = useRef<string[]>([])

  const title = 'Tài liệu kỹ thuật'
  const searchPlaceholder = 'Tìm kiếm trong Tài liệu'

  const setLoading = (loading: boolean) => setDocuments((documents) => ({ ...documents, loading }))

  const goto = (params: ObjectType) => {
    const requestParams = { ...parsedRequestParams, ...params }

    history.push({
      pathname: '/goc-ky-thuat',
      search: qs.stringify(requestParams),
    })
  }

  const handleSearch = (keyword: string) => goto({ keyword, page: undefined })
  const handleChangePage = (page?: number) => goto({ page })

  useEffect(() => {
    showBreadcrumbsRightMenu(
      [{ name: title, path: '/goc-ky-thuat' }],
      <InputSearch
        isBreadcrumb
        placeholder={searchPlaceholder}
        onSubmit={handleSearch}
        maxLength={100}
      />
    )
  }, [])

  const getDocuments = () => {
    setLoading(true)
    apiPost(
      apiUrls.documents(),
      { page, page_size: 10, name: keyword, document_categories_id: categoriesFilter.current },
      ({ status, data }) => {
        if (status) {
          setDocuments({
            loading: false,
            items: data.items,
            totalPages: data.total_pages,
          })
        } else setLoading(false)
      }
    )
  }

  const filter = (categories: string[]) => {
    categoriesFilter.current = categories
    if (page !== 1) handleChangePage()
    else getDocuments()
  }

  useEffect(() => {
    getDocuments()
  }, [page, keyword])

  useEffect(() => {
    apiGet(
      apiUrls.documentCategories(),
      { page: 1, page_size: MAX_PAGE_SIZE },
      ({ status, data }) => {
        if (status) {
          setCategories({ items: data.items.sort(sortBy('name')), loading: false })
        }
      }
    )
  }, [])

  const hasFilter = !(categories.items.length === 0 || (!keyword && documents.items.length === 0))

  return (
    <>
      {isMobile && hasFilter && (
        <div style={{ backgroundColor: 'white', borderBottom: '1px solid var(--cl-light-gray)' }}>
          <Header filter={categories.items} onFilter={filter} />
        </div>
      )}
      <div>
        <h3 className="Page-title">{title}</h3>
        <div className="Documents">
          {hasFilter ? (
            <>
              <Sidebar
                items={categories.items}
                onSearch={handleSearch}
                searchPlaceholder={searchPlaceholder}
                onFilter={filter}
              />
              <div className="DocumentsContainer">
                <Documents
                  {...documents}
                  page={page}
                  onChangePage={handleChangePage}
                  isMobile={isMobile}
                />
              </div>
            </>
          ) : (
            !categories.loading && (
              <div className="card-noItems DocumentsContainer-noItems">
                <h3 className="card-title">Không có tài liệu kỹ thuật nào</h3>
                <Button color="stroke transparent" onClick={() => history.goBack()}>
                  Quay lại trang trước
                </Button>
              </div>
            )
          )}
        </div>
      </div>
    </>
  )
}

export default DocumentsContainer
