import { Box } from '@mui/material'
import Page from 'components/page/Page'
import { useTranslation } from 'react-i18next'
import { Route, RouteProps } from 'react-router-dom'

interface Props extends RouteProps {
  title?: string
  hideTitle?: boolean
}

export default function AdminPageRoute({ title = '', hideTitle, ...rest }: Props) {
  const { t } = useTranslation()
  title = t(title)

  return (
    <Page title={title}>
      {!hideTitle && (
        <Box mb={2.5} ml={5} mt={2}>
          <h2>{title}</h2>
        </Box>
      )}
      <Route {...rest} />
    </Page>
  )
}
