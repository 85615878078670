/* eslint-disable */
import { Container, useMediaQuery } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Filter from './Filter'
import './index.scss'
import Main from './Main'
import Header from './Header'
import qs from 'qs'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import { CATEGORY_TYPES, ProductsContext } from 'configs/constants'
import { ObjectType } from 'types'
import { useHistory } from 'store/confirmRedirect'

interface Props {
  parsedRequestParams: any
  category: any
  categories: any[]
  selected: string[]
  page: any
  keyword: string
}

let needReload = true

const { MANUFACTURER, MODEL } = CATEGORY_TYPES

const defaultParamsOld: any = {
  page_size: 24,
  category_ids: [],
  manufacturer_ids: [],
  model_ids: [],
}

const updateParams = (defaultParams: any, categories: any) =>
  categories.forEach((item: any) => {
    if (item.type === MANUFACTURER)
      defaultParams.manufacturer_ids = [...defaultParams.manufacturer_ids, item.id]
    else if (item.type === MODEL) defaultParams.model_ids = [...defaultParams.model_ids, item.id]
    else defaultParams.category_ids = [...defaultParams.category_ids, item.id]
  })

const mergeParams = (params1: any, params2: any) => {
  const newParams: any = {
    ...params1,
    ...params2,
    category_ids: [],
    manufacturer_ids: [],
    model_ids: [],
  }

  const filterNotIncludes = (items: any) =>
    items.filter((item: any) => !newParams.category_ids.includes(item))

  const setParams = (params: any) => {
    if (params?.category_ids)
      newParams.category_ids = [
        ...newParams.category_ids,
        ...filterNotIncludes(params?.category_ids),
      ]
    if (params?.manufacturer_ids)
      newParams.manufacturer_ids = [
        ...newParams.manufacturer_ids,
        ...filterNotIncludes(params?.manufacturer_ids),
      ]
    if (params?.model_ids)
      newParams.model_ids = [...newParams.model_ids, ...filterNotIncludes(params?.model_ids)]
  }

  setParams(params1)
  setParams(params2)
  return newParams
}

const ProductsWrapper: React.FC<Props> = ({
  parsedRequestParams,
  category,
  categories,
  // selected,
  page,
  keyword,
}) => {
  const defaultParams = { ...defaultParamsOld, keyword }
  if (categories) updateParams(defaultParams, categories)

  const isMobile = useMediaQuery('(max-width: 860px)')
  const { apiPost, apiGet } = useApis()
  const history = useHistory()

  const [filter, setFilter] = useState({ categories: [], manufacturers: [], loading: true })
  const [products, setProducts] = useState({
    total: 0,
    items: [],
    total_pages: 0,
    // page: Number(page) || 1,
    loading: true,
  })
  const filterParams = useRef<ObjectType>(defaultParams)

  const stopLoadingProducts = () =>
    setProducts((oldProducts) => ({
      ...oldProducts,
      loading: false,
    }))

  // get all products by filter
  const filterProducts = (
    params: any = {},
    reloadFilter?: boolean,
    applyFilter?: boolean,
    otherParams?: any
  ) => {
    if (!needReload) return

    needReload = false
    setTimeout(() => {
      needReload = true
    }, 50)

    const newParams = {
      page,
      sort_by: filterParams.current.sort_by,
      order: filterParams.current.order,
      ...mergeParams(
        applyFilter
          ? category?.other_product
            ? { ...defaultParams, manufacturer_ids: [category?.id] }
            : defaultParams
          : category?.other_product
          ? { ...filterParams.current, manufacturer_ids: [category?.id] }
          : filterParams.current,
        params
      ),
      ...otherParams,
    }

    // if (category) delete newParams.keyword
    setProducts({ ...products, loading: true })
    const newPage = params.page || page
    if (page !== newPage) {
      const newRequestParams = {
        ...parsedRequestParams,
        page: newPage,
      }
      history.push({ search: qs.stringify(newRequestParams) })
    }

    apiPost(
      keyword ? apiUrls.searchProducts() : apiUrls.filterProducts(),
      newParams,
      ({ status, data }) => {
        if (status) {
          setProducts({
            total: data.total,
            total_pages: data.total_pages,
            items: data.items,
            loading: false,
            // page: newPage,
          })

          keyword &&
            reloadFilter &&
            setFilter({
              categories: data.categories,
              manufacturers: data.manufacturers,
              loading: false,
            })

          if (applyFilter) setFilter((filter) => ({ ...filter, filtered: true }))

          // and save params filter
          filterParams.current = newParams
        } else stopLoadingProducts()
      },
      stopLoadingProducts
    )
  }

  useEffect(() => {
    filterParams.current = { ...defaultParams, page: page || 1 }

    const getFilter = () => {
      let data = {}
      if (category) {
        const { id, type } = category
        if (type === MANUFACTURER)
          data = { manufacturer_id: id, other_product: category?.other_product ? true : undefined }
        else if (type === MODEL) data = { model_id: id }
        else data = { category_id: id }
      }
      apiGet(
        apiUrls.getFilter(),
        data,
        ({ status, data }) => {
          if (status) {
            setFilter(data)
          }
        },
        () => {}
      )
    }

    // Get filter menu if not is search
    !keyword && getFilter()
    // And get all products
    filterProducts(filterParams.current, true, false, {
      other_product: category?.other_product ? true : undefined,
      filter_by_manufacturer: category?.other_product ? true : undefined,
    })
  }, [category, keyword])

  useEffect(() => {
    filterProducts({ ...filterParams.current, page }, false, false, {
      other_product: category?.other_product ? true : undefined,
      filter_by_manufacturer: category?.other_product ? true : undefined,
    })
    window.scrollTo(0, 0)
  }, [page])

  return (
    <ProductsContext.Provider
      value={{
        filter,
        selected: categories,
        products: { ...products, page },
        onFilter: (params, reloadFilter, applyFilter, otherParams) =>
          filterProducts(params, reloadFilter, applyFilter, otherParams),
        category,
        keyword,
      }}
    >
      {isMobile && (
        <div style={{ backgroundColor: 'white', borderBottom: '1px solid var(--cl-light-gray)' }}>
          <Container>
            <Header filterOtherProduct={category?.other_product} />
          </Container>
        </div>
      )}
      <Container>
        <div className="ProductsWrapper">
          {!isMobile && <Filter filterOtherProduct={category?.other_product} />}
          <Main isMobile={isMobile} />
        </div>
      </Container>
    </ProductsContext.Provider>
  )
}

export default ProductsWrapper
