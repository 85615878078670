import { useCallback, useEffect, useState } from 'react'

import { useBreadcrumbs } from 'store/breadcrumbs'
import { useShoppingCart } from 'store/shoppingCart'

import { Cart, EmptyCart, RecommendProducts } from 'components/screens'
import { useAuth } from 'store/auth'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import { MAX_RELATIVE_ITEMS } from 'configs/constants'
import { convertViewedProductsData } from 'utils'
import { useHistory } from 'store/confirmRedirect'
import { Loading } from 'components/common'

export default function ShoppingCart() {
  const history = useHistory()

  const { apiGet } = useApis()

  const { showBreadcrumbs } = useBreadcrumbs()
  const { totalItems, totalBuyLaterItems, checkCart, loading } = useShoppingCart()
  const { isAuth } = useAuth()

  const [viewedProducts, setViewedProducts] = useState({ items: [], total: 0 })

  useEffect(() => {
    showBreadcrumbs([{ name: 'Giỏ hàng', path: '/' }])
  }, [])

  /**
   * Get viewed products api
   */
  const getViewedProductsApi = useCallback(async () => {
    try {
      const res = await apiGet(apiUrls.viewedProducts(), {
        page_size: MAX_RELATIVE_ITEMS,
      })

      const { status, data } = res
      const { items, total } = data || {}
      if (status) setViewedProducts({ items: convertViewedProductsData(items || []), total })
    } catch (e) {
      // Exception
    }
  }, [])

  useEffect(() => {
    if (isAuth) getViewedProductsApi()
    checkCart()
  }, [isAuth])

  return (
    <div className="ShoppingCart-area">
      <h3 className="ShoppingCart-title">Giỏ hàng</h3>
      {totalItems || totalBuyLaterItems ? <Cart /> : loading ? <Loading /> : <EmptyCart />}
      {isAuth && !!viewedProducts.total && (
        <RecommendProducts
          className="Cart-recommend"
          label="viewedProducts"
          btnLabel={viewedProducts.total > MAX_RELATIVE_ITEMS ? 'loadMore' : ''}
          products={viewedProducts.items}
          onClickBtn={() => history.push('/san-pham-da-xem')}
        />
      )}
    </div>
  )
}
