export const { REACT_APP_API_URL: API_URL } = process.env

export const apiUrls: any = {
  template: () => `${API_URL}/template`,
  getUsers: () => 'https://reqres.in/api/users',
  register: () => '/api/v1/users',
  verifyEmail: (id?: string) => `/api/v1/users/verify_email${id ? `/${id}` : ''}`,
  refresh: () => '/api/v1/auth/refresh',
  checkOTPSignUp: () => '/api/v1/users/check/otp',
  sendOTP: () => '/api/v1/auth/send_otp',
  initPassword: () => '/api/v1/users/password/init',
  signIn: () => '/api/v1/auth/login',
  checkOTP: () => '/api/v1/auth/check_otp',
  resetPassword: () => '/api/v1/auth/reset_password',
  getBanners: () => '/api/v1/banners',
  getMenu: () => '/api/v1/menu',
  getFilter: () => '/api/v1/products/parameters',
  filterProducts: () => '/api/v1/products/filter',
  searchProducts: () => '/api/v1/products/search',
  getBestSeller: () => '/api/v1/products/best_selling',
  getProductDetail: (productKey: string) => `/api/v1/products/${productKey}`,
  viewedProducts: () => '/api/v1/products/seen',
  cart: (id?: string) => `/api/v1/carts${id ? `/${id}` : ''}`,
  syncCart: () => '/api/v1/carts/sync',
  checkCart: () => '/api/v1/carts/check',
  informationPayment: () => '/api/v1/payments',
  getStores: () => '/api/v1/stores',
  addresses: (id?: string) => `/api/v1/address${id ? `/${id}` : ''}`,
  setDefaultAddress: (id: string) => `/api/v1/address${id}/default`,
  orders: (id?: string) => `/api/v1/orders${id ? `/${id}` : ''}`,
  createDeliveryAddress: () => '/api/v1/address',
  vat: (id?: string) => `/api/v1/vat${id ? `/${id}` : ''}`,
  history: () => '/api/v1/histories/keywords',
  getRecommendProducts: () => '/api/v1/products/recommend',
  checkDiscount: () => '/api/v1/discounts',
  confirmOrders: (id: string) => `/api/v1/orders/${id}/confirm`,
  profile: () => '/api/v1/users/profile',
  changePassword: () => '/api/v1/users/password',
  articles: (id?: string) => `/api/v1/articles${id ? `/${id}` : ''}`,
  documents: () => '/api/v1/documents',
  documentCategories: () => '/api/v1/documents/category',
  questions: () => '/api/v1/questions',
  moveToBuyNow: () => '/api/v1/carts/move',
  buyAgain: () => '/api/v1/carts/repurchase',
  aboutUs: () => 'api/v1/about_us',
  calculateDiscountCode: () => '/api/v1/orders/calculate_discount_code',
  // Admin
  getListProducts: () => '/api/v1/manage/products/search',
  adminForgotPassword: () => '/api/v1/manage/users/password/forgot',
  adminChangePassword: () => '/api/v1/manage/users/password',
  adminProfile: () => '/api/v1/manage/users/profile',
  adminInitPassword: () => '/api/v1/manage/users/password/init',
  adminOrders: (id?: string) => `/api/v1/manage/orders${id ? `/${id}` : ''}`,
  adminProducts: (id?: string) => `/api/v1/manage/products${id ? `/${id}` : ''}`,
  adminCounpons: (id?: string) => `/api/v1/manage/discounts${id ? `/${id}` : ''}`,
  adminArticles: (id?: string) => `/api/v1/manage/articles${id ? `/${id}` : ''}`,
  adminManufacturerLeaf: (id?: string) => `/api/v1/manage/manufacturers${id ? `/${id}` : ''}`,
  adminCategoriesLeaf: (id?: string) => `/api/v1/manage/categories/leaf${id ? `/${id}` : ''}`,
  adminModelLeaf: (manufacturerId: string) =>
    `/api/v1/manage/models/leaf?manufacturer_id=${manufacturerId}`,
  adminCategories: (id?: string) => `/api/v1/manage/categories${id ? `/${id}` : ''}`,
  adminManufacturers: (id?: string) => `/api/v1/manage/manufacturers${id ? `/${id}` : ''}`,
  adminModels: (id?: string) => `/api/v1/manage/models${id ? `/${id}` : ''}`,
  adminDocumentCategories: (id?: string) =>
    `/api/v1/manage/document_categories${id ? `/${id}` : ''}`,
  adminDocumentTechnical: (id?: string) => `/api/v1/manage/documents${id ? `/${id}` : ''}`,
  adminQuestion: (id?: string) => `/api/v1/manage/questions${id ? `/${id}` : ''}`,
  // General
  generateFile: (category: string, nameFile: string) =>
    `/api/v1/upload?prefix=${category}&file_name=${nameFile}`,
  adminUsers: (id?: string) => `/api/v1/manage/users${id ? `/${id}` : ''}`,
  adminNormalUsers: (id?: string) => `/api/v1/manage/users/normal-user${id ? `/${id}` : ''}`,
  adminGroups: (id?: string) => `/api/v1/manage/groups${id ? `/${id}` : ''}`,
  adminResetPassword: (id: string) => `/api/v1/manage/users/${id}/reset`,
  adminPermissions: (id?: string) => `/api/v1/manage/roles${id ? `?group_id=${id}` : ''}`,
  adminMyPermissions: () => '/api/v1/manage/roles/personal',
  statisticRevenues: () => '/api/v1/manage/statistics/revenue',
  statisticProducts: () => '/api/v1/manage/statistics/products',
  statisticUsers: () => '/api/v1/manage/statistics/users',
  statisticCancelReasons: () => '/api/v1/manage/statistics/products/cancel',
  adminVerifyPassword: () => '/api/v1/manage/users/verify',
  adminImportProducts: () => '/api/v1/manage/products/import',
  adminStore: (id?: string) => `/api/v1/manage/stores${id ? `/${id}` : ''}`,
  adminSetting: () => '/api/v1/manage/users/setting',
  adminExportProducts: () => '/api/v1/manage/products/export',
  adminCalculateDiscountCode: () => '/api/v1/manage/orders/calculate_discount_code',
  adminSendEmailVATInvoice: (id?: string) =>
    `/api/v1/manage/orders/send_vat_invoice${id ? `/${id}` : ''}`,
  adminBranchs: (id?: string) => `/api/v1/manage/stores${id ? `/${id}` : ''}`,
  changePrioriry: () => '/api/v1/manage/stores/priority',
  adminPrintDelivery: (id?: string) => `/api/v1/manage/orders/print_delivery${id ? `/${id}` : ''}`,
  adminSendEmailOrder: (id?: string) =>
    `/api/v1/manage/orders/send_mail_for_order${id ? `/${id}` : ''}`,
  numberQuotes: () => '/api/v1/manage/orders/general_number_quotes',
  historyQuotes: (id: string) => `/api/v1/manage/orders/history_quotes/${id}`,
  adminRepresentative: () => '/api/v1/manage/orders/representative',

  checkPhoneGetEmail: (phone: string) => `/api/v1/users/${phone}/email`,
  sendEmailRecipient: (id: string) => `/api/v1/manage/orders/${id}/send_confirm`,
  adminAboutUs: () => 'api/v1/manage/about_us',
}
