import InputSearch from 'components/input/InputSearch'
import { ArticleType } from 'types'
import ArticleItem from './ArticleItem'

interface Props {
  onSearch: (keyword: string) => void
  items: ArticleType[]
  searchPlaceholder: string
}

const Sidebar: React.FC<Props> = ({ onSearch, items, searchPlaceholder }: Props) => {
  return (
    <div className="ArticlesSidebar">
      <div className="ArticlesSidebar-search">
        <InputSearch placeholder={searchPlaceholder} onSubmit={onSearch} />
      </div>
      <div className="ArticlesSidebar-newArticle">Bài viết mới</div>
      {items.map((item) => (
        <ArticleItem key={item.id} {...item} isSmall />
      ))}
    </div>
  )
}

export default Sidebar
