import { Collapse } from '@mui/material'
import React, { useState } from 'react'
import { QuestionType } from 'types'

const QuestionItem: React.FC<QuestionType> = ({ answer, question }) => {
  const [open, setOpen] = useState(false)

  return (
    <div className="QuestionItem">
      <div className="QuestionItem-btn" onClick={() => setOpen(!open)}>
        <div className="QuestionItem-btn__wrapper">
          <span className="QuestionItem-question">{question}</span>
          <i className={`mn-icon-${open ? 'minus' : 'plus'} icon`} />
        </div>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className="QuestionItem-answer">
          <div className="label" dangerouslySetInnerHTML={{ __html: answer }} />
        </div>
      </Collapse>
    </div>
  )
}

export default QuestionItem
