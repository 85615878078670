/* eslint-disable */

import React, { useContext, useEffect, useRef, useState } from 'react'

import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import {
  AdminContext,
  ADMIN_ALL_ROLES,
  DEFAULT_PAGE_SIZE,
  mappingDeliveryMethod,
  mappingPaymentMethod,
  MAX_PAGE_SIZE,
} from 'configs/constants'
import { addNumber } from 'utils/table'
import { Button, DataTable, DatePicker, InputSearch, TableSelect } from 'components/admin'
import { formatPriceAdmin } from 'utils/format'
import { Box, Stack } from '@mui/material'
import { AdminOrderType, TableDataType, TableFieldsType, TableSortType } from 'types'
import { useHistory } from 'store/confirmRedirect'
import {
  defaultModalCancel,
  defaultModalSendEmail,
  listAdminOrderStatuses,
  listCreatedUser,
} from './constants'
import ChangeOrderStatus from './ChangeOrderStatus'
import ModalCancelOrder from './ModalCancelOrder'
import { Link } from 'react-router-dom'

import './index.scss'
import { useAdminOrders } from './hook'
import { renderBackTitle } from 'configs/extensions'
import { useModalConfirm } from 'store/modalConfirm'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'utils'
import ModalSendEmail from './ModalSendEmail'

const defaultMinDate = new Date('01/01/2021')
const defaultMaxDate = new Date()

interface Props {
  userId?: string
  userFullName?: any
  userPhone?: any
}

const Orders: React.FC<Props> = ({ userId, userFullName, userPhone }) => {
  const { apiGet, apiPost, apiDelete } = useApis()
  const history = useHistory()
  const page = useRef(1)
  const { updateOrder } = useAdminOrders()
  const { hasPermission } = useContext(AdminContext)
  const permission = hasPermission(ADMIN_ALL_ROLES.MANAGE_ORDERS)
  const [modalSendEmail, setModalSendEmail] = useState(defaultModalSendEmail)

  const { showModalConfirm, hideModalConfirm } = useModalConfirm()

  const { t } = useTranslation()

  const [data, setData] = useState<TableDataType<AdminOrderType>>({
    items: [],
    loading: true,
    total: 0,
  })
  const [orderCode, setOrderCode] = useState<null | string>(null)
  const [customer, setCustomer] = useState('')
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [fromDate, setFromDate] = useState<any>(null)
  const [toDate, setToDate] = useState<any>(null)
  const [orderStatus, setOrderStatus] = useState('0')
  const [sort, setSort] = useState<TableSortType>({ field: 'ordered_date', order: 'desc' })
  const [createdUser, setCreatedUser] = useState('all')
  const [representative, setRepresentative] = useState('all')
  const [modalCancel, setModalCancel] = useState(defaultModalCancel)
  const [dataRepresentative, setDataRepresentative] = useState<any>([])

  const isFiltered = !!(
    orderCode !== null ||
    customer ||
    fromDate ||
    toDate ||
    orderStatus !== '0' ||
    createdUser !== 'all' ||
    representative !== 'all'
  )

  // Add column number to table
  const newAddNumber = (item: any, index: number) => addNumber(item, index, page.current, pageSize)

  // Get all orders from server
  const getOrders = () => {
    setLoading(true)
    apiGet(
      apiUrls.adminOrders(),
      {
        page: page.current,
        page_size: pageSize,
        order_code: orderCode || undefined,
        customer: customer || undefined,
        status: orderStatus || undefined,
        from_date: fromDate ? fromDate.getTime() / 1000 : undefined,
        to_date: toDate ? toDate.getTime() / 1000 + 86400 : undefined,
        sort:
          sort.field === 'customer_name'
            ? 'full_name'
            : sort.field === 'ordered_date'
            ? 'created_date'
            : sort.field === 'order_status'
            ? 'status'
            : sort.field,
        order_by: sort.order,
        user_id: userId || undefined,
        created_user: createdUser !== 'all' ? createdUser : undefined,
        representative: representative !== 'all' ? representative : undefined,
      },
      ({ status, data }) => {
        if (status)
          setData({
            items: data.items.map(newAddNumber).map((item: AdminOrderType, index: number) => ({
              ...item,
              full_name: item?.order_delivery_address?.full_name,
              phone: item.order_delivery_address.phone,
              ordered_date: item?.created_date,
            })),
            total: data.total,
            loading: false,
          })
        else setLoading(false)
      }
    )
  }

  const getDataUser = () => {
    apiGet(
      apiUrls.adminRepresentative(),
      {
        page: 1,
        page_size: MAX_PAGE_SIZE,
      },
      ({ status, data }) => {
        if (status) {
          const arrayUser = data?.map((item: any) => ({
            value: item.id,
            label: item.full_name,
          }))

          setDataRepresentative([{ value: 'all', label: 'Người đại diện' }, ...arrayUser])
        }
      }
    )
  }

  const setLoading = (loading: boolean) => setData((data) => ({ ...data, loading }))

  const updateStatus = (id: string, status: number, oldStatus: string) => {
    updateOrder(
      id,
      { status, old_status: oldStatus },
      getOrders,
      () => setLoading(true),
      () => setLoading(false)
    )
  }

  const handleDeleteOrder = (id: string) => {
    apiDelete(apiUrls.adminOrders(id), {}, ({ status, id, text }) => {
      if (status) {
        if (page.current > 1 && items.length === 1) {
          page.current = page.current - 1
        }
        getOrders()
      } else {
        showModalConfirm({
          title: 'Thông báo',
          content: text,
          confirm: {
            text: 'Đã hiểu',
            action: () => {
              hideModalConfirm()
            },
          },
        })
      }
    })
  }

  // Define all columns of table
  const fields: TableFieldsType = {
    number: { style: { width: '6%', paddingLeft: 20 } },
    order_code: { style: { width: '10%' }, sort: 'asc' },
    customer_name: {
      label: 'customer_name',
      style: { width: '15%' },
      sort: 'asc',
      renderContent: ({ full_name }) => full_name,
    },
    phone: { style: { width: '12%' }, sort: 'asc' },
    final_total_price: {
      style: { width: '10%' },
      renderContent: ({ final_total_price }) => formatPriceAdmin(final_total_price),
      sort: 'asc',
    },
    delivery_method: {
      style: { width: '12%' },
      renderContent: ({ delivery_method }) => t(mappingDeliveryMethod[delivery_method]),
      sort: 'asc',
    },
    payment_method: {
      style: { width: '15%' },
      renderContent: ({ payment_method }) => mappingPaymentMethod[payment_method],
      sort: 'asc',
    },
    ordered_date: {
      label: 'ordered_date',
      style: { width: '12%' },
      type: 'date',
      sort: 'asc',
    },
    expected_delivery: { style: { width: '12%' }, type: 'date', sort: 'asc' },
    delivered_date: { style: { width: '12%' }, type: 'date', sort: 'asc' },
    order_status: {
      label: 'order_status',
      style: { width: '18%' },
      sort: 'asc',
      renderContent: (item) => (
        <ChangeOrderStatus
          paymentMethod={item.payment_method}
          status={item.status}
          onChangeStatus={(s) => updateStatus(item.id, s, item.status)}
          disabled={!permission}
        />
      ),
    },
    representative: {
      style: { width: '12%' },
      renderContent: ({ representative_data }) => representative_data?.full_name,
    },
    created_user: {
      style: { width: '12%' },
      renderContent: ({ created_user }) => {
        if (!created_user) return 'Người dùng'
        else {
          if (created_user.type === 2 || created_user.type === 3) return 'Quản trị viên'
          else return 'Người dùng'
        }
      },
      sort: 'asc',
    },
    number_of_quotes: {
      style: { width: '8%' },
      renderContent: ({ number_of_quotes, id, order_code }) => (
        <b>
          {number_of_quotes ? (
            <Link to={{ pathname: `orders/${id}/history-quotes`, state: order_code }}>
              {number_of_quotes}
            </Link>
          ) : (
            number_of_quotes
          )}
        </b>
      ),
      sort: 'asc',
    },
    action: {
      style: { width: '20%' },
      type: 'actions',
      actions: [
        {
          icon: 'eye',
          action: (item) => history.push(`/admin/${userId ? 'users/' : ''}orders/${item.id}`),
          title: 'Xem',
        },
        {
          icon: 'pencil-alt',
          action: (item) => {
            const url = userId
              ? `/admin/users/order/edit/${userId}/${item.id}`
              : `/admin/orders/edit/${item.id}`
            history.push(url, {
              userFullName,
              userPhone,
            })
          },
          hidden: (item: any) => !permission || item.status > 4,
          title: 'Sửa',
        },
        {
          icon: 'file-alt',
          action: (item) => {
            history.push(`/admin/orders/quotation/${item.id}`)
          },
          hidden: (item: any) => !permission || item.status !== 1,
          title: 'In báo giá',
        },
        {
          icon: 'envelope',
          action: ({ user, id }) => {
            apiGet(apiUrls.checkPhoneGetEmail(user?.phone), {}, ({ data }) => {
              if (!data) {
                setModalSendEmail({ ...defaultModalSendEmail, show: true, type: 'recipient', id })
              } else {
                setModalSendEmail({
                  ...modalSendEmail,
                  email: data.email,
                  show: true,
                  type: 'recipient',
                  id,
                })
              }
            })
          },
          hidden: (item: any) => !permission || item.status !== 1,
          title: 'Gửi email xác nhận',
        },
        {
          icon: 'times-circle',
          action: (item) => setModalCancel({ id: item.id, show: true }),
          hidden: (item: any) => !permission || item.status >= 6,
          title: 'Hủy',
        },
        {
          icon: 'trash',
          action: ({ order_code, id }) =>
            showModalConfirm({
              title: 'Xóa đơn hàng',
              content: `Bạn có chắc muốn xóa đơn hàng ${order_code}?`,
              confirm: {
                text: 'Xóa',
                action: () => {
                  handleDeleteOrder(id)
                  hideModalConfirm()
                },
              },
              cancel: {
                text: 'Hủy bỏ',
                action: hideModalConfirm,
              },
            }),
          hidden: (item: any) =>
            !permission ||
            ((item.created_user?.type !== 3 || item.created_user?.type !== 2) && item.status !== 1),
          title: 'Xóa',
        },
      ],
    },
  }

  // When click change page, set page to selected page
  const handleChangePage = (newPage: number) => {
    page.current = newPage
    getOrders()
  }

  useEffect(() => {
    page.current = 1
    getOrders()
  }, [
    pageSize,
    orderCode,
    customer,
    orderStatus,
    fromDate,
    toDate,
    sort,
    createdUser,
    representative,
  ])

  useEffect(() => {
    getDataUser()
  }, [])

  const { items, loading, total } = data

  return (
    <div className="AdminOrders">
      {userId && (
        <Box mb={2.5} mt={2} className="d-f ai-c">
          {!!history && (
            <i
              className="mn-icon-arrow-left ic-b mr-2 admin-link"
              onClick={() => history.push('/admin/users')}
            />
          )}
          <h2>{`Đơn hàng của ${userFullName || ''}`}</h2>
        </Box>
      )}
      <ModalCancelOrder
        {...modalCancel}
        onClose={() => setModalCancel(defaultModalCancel)}
        onSuccess={getOrders}
      />
      <ModalSendEmail
        {...modalSendEmail}
        onClose={() => setModalSendEmail((prev) => ({ ...prev, show: false }))}
      />
      <DataTable
        name="orders"
        items={items}
        fields={fields}
        loading={loading}
        showPopupShowHideColumns
        showHideColumnsWidth={300}
        hiddenColumns={['created_user', 'number_of_quotes', 'representative']}
        sortBackend={{ ...sort, onChange: (params) => setSort(params) }}
        minWidth={1500}
        isFiltered={isFiltered}
        btnAdd={
          <>
            <Button
              style={{ marginRight: '10px', width: 168 }}
              variant="contained"
              onClick={() => history.push('/admin/orders/using')}
            >
              Hướng dẫn sử dụng
            </Button>
            {permission ? (
              <Button
                variant="contained"
                style={{ minWidth: 150 }}
                onClick={() => {
                  const url = userId ? `/admin/users/order/add/${userId}` : '/admin/orders/add'
                  history.push(url, {
                    userFullName,
                    userPhone,
                  })
                }}
              >
                Thêm đơn hàng
              </Button>
            ) : null}
          </>
        }
        header={
          <>
            <InputSearch
              style={{ minWidth: 120, width: 160 }}
              placeholder="Mã đơn hàng"
              onSearch={(keyword) => setOrderCode(keyword.trim())}
              disabled={loading}
            />
            <InputSearch
              style={{ minWidth: 120, width: 200 }}
              placeholder="Người mua hoặc SĐT"
              onSearch={(keyword) => setCustomer(keyword)}
              disabled={loading}
            />
            <TableSelect
              style={{ minWidth: 200 }}
              label="Người tạo"
              selected={createdUser}
              setSelected={setCreatedUser}
              data={listCreatedUser}
              disabled={loading}
              hideSelected
            />
            <TableSelect
              style={{ minWidth: 200 }}
              label="Trạng thái đơn hàng"
              selected={orderStatus}
              setSelected={setOrderStatus}
              data={listAdminOrderStatuses}
              disabled={loading}
              hideSelected
            />
            <TableSelect
              style={{ minWidth: 200 }}
              label="Người đại diện"
              selected={representative}
              setSelected={setRepresentative}
              data={dataRepresentative}
              disabled={loading}
              hideSelected
              sort
              noSortFirst
              popupSearch={{
                timeout: 200,
                searchFrontend: true,
                margin: true,
              }}
              maxHeight={220}
            />
            <Stack flexDirection="row" gap={1} alignItems="center">
              <span className={loading ? 'label-disabled' : ''}>Ngày đặt hàng </span>
              <DatePicker
                label="Từ ngày"
                date={fromDate}
                setDate={setFromDate}
                minDate={defaultMinDate}
                maxDate={toDate || defaultMaxDate}
                disabled={loading}
                rootPortal
              />
              <DatePicker
                label="Đến ngày"
                date={toDate}
                setDate={setToDate}
                minDate={fromDate || defaultMinDate}
                maxDate={defaultMaxDate}
                disabled={loading}
                rootPortal
              />
            </Stack>
          </>
        }
        pagination={{
          total,
          pageSize,
          page: page.current,
          onPageChange: handleChangePage,
          onPageSizeChange: setPageSize,
          itemsLabel: 'đơn hàng',
          extraLabel: 'nào',
        }}
      />
    </div>
  )
}

export default Orders
