import * as Yup from 'yup'
import { Button, Input } from 'components'
import { validatePhone, validateTypingName, validateTypingPhone } from 'utils'
import { Form, FormikProvider, useFormik } from 'formik'
import { Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import { useEffect } from 'react'
import { useAuth } from 'store/auth'

const validationSchema = (phoneExist: any) =>
  Yup.object().shape({
    full_name: Yup.string().required(),
    phone: Yup.string()
      .required()
      .min(1, 'phone_format_is_incorrect')
      .notOneOf([phoneExist.phone], phoneExist.text)
      .matches(validatePhone(), 'phone_format_is_incorrect'),
  })

interface Props {
  next: () => void

  setSendEmailSuccess: any
}

export default function SignUp({ next, setSendEmailSuccess }: Props) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [phoneExist, setPhoneExist] = useState({ phone: '', text: '' })
  const { signIn, updateAuth } = useAuth()
  const { apiPost } = useApis()

  const formik = useFormik({
    initialValues: {
      full_name: '',
      phone: '',
    },
    validationSchema: validationSchema(phoneExist),
    onSubmit: ({ full_name, phone }, { validateForm }) => {
      full_name = full_name?.trim()
      setLoading(true)
      apiPost(
        apiUrls.register(),
        { full_name, phone },
        ({ status, text, data }) => {
          if (status) {
            updateAuth({ phone, user_id: data?.user_id })
            setSendEmailSuccess(data?.verify_method === 2 ? true : false)
            next()
          } else {
            setLoading(false)
            setPhoneExist({ phone, text: text || '' })
            validateForm()
          }
        },
        () => setLoading(false)
      )
    },
  })

  const { errors, handleSubmit, getFieldProps, handleChange, values, isValid, validateForm } =
    formik

  // Validate to disable button on startup
  useEffect(() => {
    validateForm()
  }, [])

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <Input
            fullWidth
            autoFocus
            {...getFieldProps('full_name')}
            value={values.full_name}
            error={!!errors.full_name && t(errors.full_name?.toString())}
            label={t('full_name')}
            onChange={(e) => {
              if (e.target.value === '' || validateTypingName(e)) handleChange(e)
            }}
            maxLength={50}
            disabled={loading}
            required
          />
          <Input
            fullWidth
            type="tel"
            {...getFieldProps('phone')}
            error={!!errors.phone && t(errors.phone?.toString())}
            label={t('phone')}
            onChange={(e) => {
              if (e.target.value === '' || validateTypingPhone(e)) handleChange(e)
            }}
            maxLength={20}
            disabled={loading}
            required
            blurWhenEnter
          />
          <Box>
            <Button
              loading={loading}
              type="submit"
              fullWidth
              icon="arrow"
              disabled={loading || !isValid}
            >
              {t('continue')}
            </Button>
          </Box>
          <Box>
            <span className="label">Đã có tài khoản? </span>
            <span className="label bold link" onClick={() => signIn()}>
              Đăng nhập
            </span>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  )
}
