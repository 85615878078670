import { useEffect, useState } from 'react'
import Lightbox from 'react-image-lightbox'

import 'react-image-lightbox/style.css'

interface PropTypes {
  className?: string
  open?: boolean
  images: any
  currentImgIndex: number
  onClose?: () => void
}

export default function ImageViewer(props: PropTypes) {
  const { className = '', open, images = [], currentImgIndex, onClose = () => {} } = props
  const imgLength = images.length

  const [imgIndex, setImgIndex] = useState(currentImgIndex)

  useEffect(() => {
    if (open) setImgIndex(currentImgIndex)
  }, [open, currentImgIndex])

  return (
    <div>
      {open && (
        <Lightbox
          wrapperClassName={`ImageViewer-container ${className}`}
          reactModalStyle={{ overlay: { zIndex: 9999 } }}
          enableZoom={false}
          mainSrc={images[imgIndex]}
          nextSrc={imgLength > 1 ? images[imgIndex + 1] : undefined}
          prevSrc={imgLength > 1 ? images[imgIndex - 1] : undefined}
          onMovePrevRequest={() => imgIndex !== 0 && setImgIndex(imgIndex - 1)}
          onMoveNextRequest={() => imgIndex !== imgLength - 1 && setImgIndex(imgIndex + 1)}
          onCloseRequest={onClose}
          animationDuration={600}
        />
      )}
    </div>
  )
}
