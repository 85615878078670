import { useCallback, useEffect, useState } from 'react'
import { useApp } from 'store/app'

import {
  HomeBestSeller,
  // HomeBrands,
  HomeCategories,
  HomeSlider,
  HomeAdvertisement,
  RecommendProducts,
} from 'components/screens'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth } from 'store/auth'
import { MAX_RELATIVE_ITEMS } from 'configs/constants'
import { apiUrls } from 'configs/apis'
import { useApis } from 'services/api'
import { convertViewedProductsData } from 'utils'
import { Container } from '@mui/material'

export default function Home() {
  const { getBannersApi } = useApp()
  const location = useLocation()
  const { isAuth } = useAuth()
  const { apiGet } = useApis()
  const history = useHistory()

  const [viewedProducts, setViewedProducts] = useState({ items: [], total: 0 })

  useEffect(() => {
    getBannersApi()
  }, [])

  /**
   * Get viewed products api
   */
  const getViewedProductsApi = useCallback(async () => {
    try {
      const res = await apiGet(apiUrls.viewedProducts(), {
        page_size: MAX_RELATIVE_ITEMS,
      })

      const { status, data } = res
      const { items, total } = data || {}
      if (status) setViewedProducts({ items: convertViewedProductsData(items || []), total })
    } catch (e) {
      // Exception
    }
  }, [location])

  useEffect(() => {
    if (isAuth) {
      getViewedProductsApi()
    }
  }, [location, isAuth])

  return (
    <div className="Home-area">
      <HomeSlider />
      <HomeCategories />
      <HomeBestSeller />
      <HomeAdvertisement />
      {isAuth && !!viewedProducts.total && (
        <Container>
          <RecommendProducts
            center
            className="ProductDetail-favorites"
            label="viewedProducts"
            btnLabel={viewedProducts.total > MAX_RELATIVE_ITEMS ? 'loadMore' : ''}
            products={viewedProducts.items}
            onClickBtn={() => history.push('/san-pham-da-xem')}
          />
        </Container>
      )}
    </div>
  )
}
