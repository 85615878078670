import React from 'react'
import Button from './Button'

import './index.scss'

type PropTypes = {
  label?: string
  remove?: boolean
  active?: boolean
  onClick?: (param?: any) => void
}

const ButtonOption = (props: PropTypes) => {
  const { label, remove, onClick = () => {}, active } = props

  return (
    <Button
      className={`ButtonOptions-btn${active ? ' active' : ''}`}
      onClick={onClick}
      icon={remove ? 'x-circle' : undefined}
    >
      {label}
    </Button>
  )
}

export default ButtonOption
