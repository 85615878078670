import React, { useEffect } from 'react'
import { AppBar, Container } from '@mui/material'

import { AppHeaderExtra, AppMenu } from './modules'
import AppHeaderTools from './modules/AppHeaderTools'
import { useCategories } from 'store/categories'
import { useRefresh } from 'utils'
import { HomeBrands } from 'components/screens'
import HideOnScroll from './modules/HideOnScroll'
interface Props {
  hideBrands?: boolean
}

const Header: React.FC<Props> = ({ hideBrands }) => {
  const { getCategories } = useCategories()
  const resfresh = useRefresh()

  useEffect(() => {
    getCategories()
  }, [resfresh])

  return (
    <HideOnScroll>
      <AppBar className="AppHeader-area">
        <AppHeaderExtra />
        <Container className="AppHeader-container">
          <AppHeaderTools />
          <AppMenu />
        </Container>
        {!hideBrands && <HomeBrands />}
      </AppBar>
    </HideOnScroll>
  )
}
export default Header
