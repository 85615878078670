/* eslint-disable */
import axios, { Method, AxiosRequestConfig } from 'axios'
import { apiUrls, API_URL } from '../configs/apis'
import { configureStore } from 'store'
import {
  CLEAR_AUTH,
  CREATE_TOAST,
  HIDE_MODAL_CONFIRM,
  SHOW_MODAL_CONFIRM,
  UPDATE_AUTH,
} from 'store/actionTypes'
import { SUCCESS } from 'configs/constants'
import { removeUserLS, updateUserLS } from 'utils'
import { ErrorCallbackType, SuccessCallbackType } from 'types'

axios.defaults.baseURL = API_URL

interface RequestOptionsType {
  successCallback?: SuccessCallbackType
  errorCallback?: ErrorCallbackType
  hideToast?: boolean
  hideToastError?: boolean
  forceShowToast?: boolean
}

const refresh = async () => {
  const refreshToken = configureStore.getState().auth.refresh_token
  if (!refreshToken) return false
  const headers = { Authorization: `Bearer ${refreshToken}` }
  try {
    const response = await axios.post(apiUrls.refresh(), {}, { headers })
    const { data: responseData }: any = response
    const { message, data } = responseData
    if (message?.status === SUCCESS) {
      updateUserLS(data)
      configureStore.dispatch({
        type: UPDATE_AUTH,
        payload: data,
      })
      return true
    }
  } catch (e) {}
  // If cannot refresh => sign out
  configureStore.dispatch({
    type: SHOW_MODAL_CONFIRM,
    payload: {
      title: 'Thông báo',
      content: 'Phiên đăng nhập hết hạn',
      confirm: {
        text: 'Đã hiểu',
        action: () => {
          configureStore.dispatch({ type: HIDE_MODAL_CONFIRM })
          removeUserLS()
          configureStore.dispatch({ type: CLEAR_AUTH })
        },
      },
    },
  })
}

/**
 * Config request common
 *
 * @param {String} method Request method
 * @param {String} url Request URL
 * @param {Object} data Request params
 * @param {Object} options Config options
 */
const request = async (
  method: Method,
  url: string,
  data: any,
  options: RequestOptionsType,
  isRefresh?: boolean,
  contentType?: any
) => {
  const {
    successCallback = () => {},
    errorCallback = () => {},
    hideToast = false,
    hideToastError = false,
    forceShowToast = false,
    ...rest
  } = options

  const { download } = data || {}
  let filename: string
  if (download) {
    filename = data.filename
    delete data.filename
  }

  // config params
  const accessToken = configureStore.getState().auth.access_token
  let headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : ({} as any)

  if (contentType) headers = { ...headers, 'Content-Type': contentType }

  const defaultParams: AxiosRequestConfig = {
    headers,
    method,
    url,
    responseType: download ? 'blob' : undefined,
    ...rest,
  }

  const { upload } = data

  const paramConfigs: AxiosRequestConfig =
    method === 'get' ? { ...defaultParams, params: data } : { ...defaultParams, data: data }

  if (upload) {
    if (!upload.notHasPrefix) paramConfigs.url += `?prefix=${upload.prefix}`
    const formData = new FormData()
    formData.append('file', upload.file, upload.file_name.name)
    paramConfigs.data = formData
  }

  return new Promise<any>((resolve, reject) => {
    axios(paramConfigs)
      .then((res: any) => {
        if (download) {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename || 'file.xlsx')
          document.body.appendChild(link)
          link.click()
          window.URL.revokeObjectURL(url)
          document.body.removeChild(link)

          return
        }

        let { data = {} as any } = res
        const { code = 500, message = {} as any } = data
        const { id = '', status, text = '', show = false, duration = 0 } = message
        const newStatus = status === 'success' || status === true
        data = {
          code,
          id,
          status: newStatus,
          text,
          data: data.data,
        }
        if (forceShowToast || (show && !hideToast && (newStatus ? true : !hideToastError)))
          configureStore.dispatch({
            type: CREATE_TOAST,
            payload: { duration: (duration || 5) * 1000, type: status, message: { content: text } },
          })

        resolve(data)
        successCallback(data)
      })
      .catch(async (error) => {
        reject(error)
        errorCallback(error)
        const { response = {} } = error || {}
        const { status } = response

        if (status === 401 && !isRefresh) {
          const res = await refresh()
          if (res) {
            request(method, url, data, options, true)
          }
        }
      })
  })
}

/**
 * Request process callback with method GET
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Function} successCallback Success callback
 * @param {Function} errorCallback Error callback
 */
const apiGet = (
  url = '',
  params = {},
  successCallback?: SuccessCallbackType,
  errorCallback?: ErrorCallbackType,
  hideToast?: boolean
) => {
  return request('get', url, params, { successCallback, errorCallback, hideToast })
}

/**
 * Request process callback with method POST
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Function} successCallback Success callback
 * @param {Function} errorCallback Error callback
 */
const apiPost = (
  url = '',
  params = {},
  successCallback?: SuccessCallbackType,
  errorCallback?: ErrorCallbackType,
  hideToast?: boolean
) => {
  return request('post', url, params, { successCallback, errorCallback, hideToast })
}

/**
 * Request process callback with method PUT
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Function} successCallback Success callback
 * @param {Function} errorCallback Error callback
 */
const apiPut = (
  url = '',
  params = {},
  successCallback?: SuccessCallbackType,
  errorCallback?: ErrorCallbackType,
  hideToast?: boolean
) => {
  return request('put', url, params, { successCallback, errorCallback, hideToast })
}

/**
 * Request process callback with method DELETE
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Function} successCallback Success callback
 * @param {Function} errorCallback Error callback
 */
const apiDelete = (
  url = '',
  params = {},
  successCallback?: SuccessCallbackType,
  errorCallback?: ErrorCallbackType,
  hideToast?: boolean
) => {
  return request('delete', url, params, { successCallback, errorCallback, hideToast })
}

export const useApis = () => ({
  apiGet,
  apiPost,
  apiPut,
  apiDelete,
  requestApi: request,
})

export default { get: apiGet, post: apiPost, put: apiPut, delete: apiDelete, request }
