import React from 'react'
import { Link } from 'react-router-dom'
import { ArticleType } from 'types'
import { formatDateVI } from 'utils'

interface Props extends ArticleType {
  isSmall?: boolean
  [key: string]: any
}

const ArticleItem: React.FC<Props> = ({
  isSmall,
  summary,
  created_date,
  title,
  type,
  id,
  image_url,
  link,
  start_date,
}) => {
  const isEvent = type === 1
  if (isEvent) created_date = start_date

  const replaceBlockId = (summary: string) => {
    const blockIdRegex = /(\{block-(.*?)\})/g

    const summaryReplace = summary ? summary.replaceAll(blockIdRegex, '') : ''
    return summaryReplace
  }

  return (
    <Link
      className={`ArticleItem${isSmall ? ' small' : ''}`}
      to={{ pathname: isEvent ? `/tin-tuc-su-kien/${id}` : link }}
      target={isEvent ? undefined : '_blank'}
    >
      <div className="ArticleItem-img" style={{ backgroundImage: `url("${image_url}")` }}></div>
      <div className="ArticleItem-right">
        <div className="ArticleItem-type">{isEvent ? 'SỰ KIỆN' : 'TIN TỨC'}</div>
        <div className="ArticleItem-title">{title}</div>
        {!isSmall && <div className="ArticleItem-content">{replaceBlockId(summary)}</div>}
        <div className="ArticleItem-date">{formatDateVI(created_date)}</div>
      </div>
    </Link>
  )
}

export default ArticleItem
