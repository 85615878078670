import { Button, TableCell, TableRow } from '@mui/material'
import MaxLine from '../../maxLine/MaxLine'
import { DEFAULT_AVATAR } from 'configs/constants'
import moment from 'moment'
import React from 'react'
import { RowType, TableFieldsType } from 'types'
import { isFunction } from 'lodash'

interface Props {
  fields: TableFieldsType
  row: RowType
  onClick?: (item: RowType) => void
  onMiddleClick?: (item: RowType) => void
  dnd?: {
    innerRef?: any
    dragHandleProps?: any
    draggableProps?: any
    isDragging?: boolean
  }

  dndDisable?: boolean
}

const Row: React.FC<Props> = (props) => {
  const { row, fields, onClick, onMiddleClick, dnd, dndDisable } = props

  const { innerRef, dragHandleProps = {}, draggableProps = {}, isDragging } = dnd || {}

  const rowStyle = dnd ? { ...draggableProps.style, display: isDragging ? 'table' : '' } : {}

  return (
    <TableRow
      {...draggableProps}
      style={rowStyle}
      ref={innerRef}
      className={onClick ? 'TableRow-clickable' : ''}
      onClick={() => onClick?.(row)}
      onMouseDown={(e: any) => {
        if (onMiddleClick && e.button === 1) onMiddleClick(row)
      }}
    >
      {!!dnd && (
        <TableCell style={{ width: '5%', textAlign: 'center' }} {...dragHandleProps}>
          <i
            className="fas fa-arrows-alt-v"
            style={{
              color: dndDisable ? '#999' : '#727779',
              fontSize: 20,
              cursor: dndDisable ? 'default' : 'pointer',
            }}
          />
        </TableCell>
      )}
      {Object.keys(fields).map((cell: string, index: number) => {
        const columnName = cell
        const field = fields[columnName]
        const newContent = row[columnName]
        let newRenderContent
        switch (field.type) {
          case 'short-content':
            newRenderContent = !newContent ? (
              '-'
            ) : (
              <MaxLine numberOfLines={2} title={newContent} text={newContent} />
            )
            break

          case 'date':
            newRenderContent = !newContent ? '-' : moment(newContent * 1000).format('DD/MM/YYYY')
            break

          case 'avatar':
            newRenderContent = (
              <img alt="" src={newContent || DEFAULT_AVATAR} className="Row-avatar" />
            )
            break

          case 'image':
            newRenderContent = <img alt="" src={newContent} className="Row-image" />
            break

          case 'actions':
            newRenderContent = (
              <div className="Row-actions">
                {field.actions?.map((i) => {
                  let hidden = i.hidden
                  const title: any = i.title
                  if (isFunction(hidden)) hidden = hidden(row)

                  return (
                    <Button
                      style={{ visibility: hidden ? 'hidden' : 'visible', fontSize: 15 }}
                      disabled={i.disabled}
                      key={i.icon}
                      onClick={(e) => {
                        e.stopPropagation()
                        i.action(row)
                      }}
                      title={title}
                    >
                      <i className={`fas fa-${i.icon}`} />
                    </Button>
                  )
                })}
              </div>
            )
            break

          default:
            if (field.renderContent) newRenderContent = field.renderContent(row)
            else newRenderContent = newContent
            break
        }

        if (!field.style) field.style = {}
        if (field.right) field.style = { ...field.style, paddingRight: 8, textAlign: 'right' }
        if (field.bodyStyle) field.style = { ...field.style, ...field.bodyStyle }

        return (
          <TableCell key={index} style={field.style} className={field.className}>
            {field.disableMaxLine ? (
              newRenderContent
            ) : (
              <MaxLine
                title={field.defaultTitle ? newContent : undefined}
                text={newRenderContent}
              />
            )}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

export default Row
