import { useTranslation } from 'react-i18next'
import { Button } from 'components'
import { formatPrice, removePaymentLS } from 'utils'
import { useHistory } from 'store/confirmRedirect'
import { useState } from 'react'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import { useShoppingCart } from 'store/shoppingCart'
import { useAuth } from 'store/auth'
import { useModalConfirm } from 'store/modalConfirm'

interface PropTypes {
  className?: string
  totalMoney: number
}

export default function CartMoney(props: PropTypes) {
  const { t } = useTranslation()
  const history = useHistory()
  const { className = '', totalMoney } = props
  const [loading, setLoading] = useState(false)
  const { apiPost } = useApis()
  const { isAuth } = useAuth()
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()
  const { fetchCart, cartItems, updateCartItemsQuantity } = useShoppingCart()

  const handlePurchase = () => {
    setLoading(true)
    apiPost(
      apiUrls.checkCart(),
      cartItems.map((item) => ({ product_id: item.product_id, quantity: item.quantity })),
      ({ status, text, data }) => {
        if (!data) data = []
        setLoading(false)
        if (status) {
          localStorage.setItem('payment', 'ok')
          removePaymentLS()
          history.push('/payment')
        } else {
          showModalConfirm({
            title: 'Thông tin',
            content: text,
            confirm: {
              text: 'Đã hiểu',
              action: () => {
                hideModalConfirm()
                if (isAuth) fetchCart()
                else updateCartItemsQuantity(data)
              },
            },
          })
        }
      },
      () => setLoading(false),
      true
    )
  }

  return (
    <div className={`CartMoney-container ${className}`}>
      <div className="CartMoney-total">
        <span className="CartMoney-money">
          <span>{t('totalMoney')}:</span>
          <span className="CartMoney-price">{formatPrice(totalMoney)}</span>
        </span>
        <Button className="CartMoney-btn" loading={loading} onClick={handlePurchase} icon="arrow">
          {t('purchase')}
        </Button>
      </div>
    </div>
  )
}
