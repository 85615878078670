import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { MenuItem, ProductType } from 'types'
import { formatPrice, getProductTag, useMobile } from 'utils'

import imgProductTemp from 'assets/images/imgProductTemp.png'

import './ProductItem.scss'
import { useHistory } from 'store/confirmRedirect'

interface PropTypes {
  className?: string
  product?: ProductType
  breadcrumbs?: MenuItem[]
  fromCategory?: string
  fromSearch?: any
}

export default function ProductItem(props: PropTypes) {
  useMobile()
  const history = useHistory()
  const { t } = useTranslation()
  const { className = '', product = {}, fromCategory, fromSearch } = props
  const {
    name = 'Bộ công cụ sửa chữa cầm tay của Makita',
    link_seo = '',
    avatar_url,
    price = 5000000,
    final_price = 0,
  } = product || {}

  // Get some info
  const { oldPrice, newPrice, tag } = useMemo(() => {
    const oldPrice = final_price === price ? null : formatPrice(price)
    const newPrice = formatPrice(final_price)
    return {
      oldPrice,
      newPrice,
      tag: getProductTag(product),
    }
  }, [product])

  return (
    <div
      className={`ProductItemNew-container ${className}`}
      onClick={() => history.push(`/san-pham/${link_seo}`, { fromCategory, fromSearch })}
    >
      <div className="ProductItemNew-content">
        <span
          className="ProductItemNew-img"
          style={{ backgroundImage: `url("${avatar_url || imgProductTemp}")` }}
        />
        <h6 className="ProductItemNew-name">{name}</h6>
        {price === 0 ? (
          <div className="ProductItemNew-prices">
            <span className="currentPrice d-f ai-c">
              Liên hệ <i className="mn-icon-phone-call ml-1" />
            </span>
          </div>
        ) : (
          <div className="ProductItemNew-prices">
            {oldPrice && <span className="oldPrice">{oldPrice}</span>}
            <span className="currentPrice">{newPrice}</span>
          </div>
        )}
      </div>
      {tag && <span className={`ProductItemNew-tag ${tag}`}>{t(tag)}</span>}
    </div>
  )
}
