/* eslint-disable */
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { Input, SearchSelect } from 'components/admin'
import { apiUrls } from 'configs/apis'
import { defaultProductThumbnail, DEFAULT_PAGE_SIZE, DELIVERY_METHODS } from 'configs/constants'
import { isEqual, uniqWith } from 'lodash'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useApis } from 'services/api'
import { ProductType, TableDataType } from 'types'

import { formatCode, formatPrice } from 'utils'
import './BlockSelectedProducts.scss'
import ProductItemRow from './ProductItemRow'

interface PropTypes {
  cartItems?: any[]
  formik: any
  finalPrice?: number
  setFinalPrice?: any
  setDiscountCodeError: any
  discountCodeError: string
  ref: any
}

const { GET_AT_STORE, GET_AT_ADDRESS } = DELIVERY_METHODS

const BlockSelectedProducts: React.FC<PropTypes> = forwardRef((props, ref) => {
  const { formik, finalPrice = 0, setFinalPrice, setDiscountCodeError, discountCodeError } = props

  const renderLabelProduct = (item: any) => {
    const oldPrice = item.price
    const newPrice = item.final_price
    return `<div class='label-select'>
    <div class='product-content'>
      <span
        class="product-img"
        style=" background-image: url('${item.avatar_url || defaultProductThumbnail}');"
      ></span>
      <p class="product-name">${item.name}</p>
    </div>
    <div class='product-price'>
      ${
        oldPrice - newPrice > 0
          ? `<p>
          <del>${formatPrice(oldPrice)}</del>
        </p>`
          : ''
      }
      <p class="newPrice">${formatPrice(newPrice)}</p>
    </div>
  </div>`
  }

  const { t } = useTranslation()
  const { apiPost, apiPut } = useApis()
  const page = useRef(1)
  const [keyword, setKeyword] = useState('')
  const [products, setProducts] = useState<TableDataType<any>>({
    items: [],
    loading: false,
    total: 0,
  })
  const [productsSelected, setProductsSelected] = useState<any>([])
  const [discountCode, setDiscountCode] = useState('')
  const { id: idOrder } = useParams() as any

  const { setFieldValue, values, errors } = formik
  const order_products_second = useRef<any[]>([])
  const cityRef = useRef<string>('')
  const phoneRef = useRef<string>('')
  const phoneErrorRef = useRef<string>('')
  const { order_products, discount_code, payment_method } = values

  useImperativeHandle(ref, () => ({
    callProducts() {
      getDataProducts()
      addDiscount(discountCode, order_products)
    },
  }))

  useEffect(() => {
    setDiscountCode(discount_code)
  }, [discount_code])

  useEffect(() => {
    phoneRef.current = values.order_delivery_address.phone
  }, [values.order_delivery_address.phone])
  useEffect(() => {
    cityRef.current = values.order_delivery_address?.city
  }, [values.order_delivery_address?.city])

  useEffect(() => {
    phoneErrorRef.current = errors?.order_delivery_address?.phone
  }, [errors?.order_delivery_address?.phone])

  useEffect(() => {
    order_products_second.current = order_products
  }, [JSON.stringify(order_products)])

  const getDataProducts = useCallback(() => {
    apiPost(
      apiUrls.adminProducts('search'),
      { page: page.current, page_size: DEFAULT_PAGE_SIZE, keyword: keyword, is_active: true },
      ({ data, status }) => {
        if (status) {
          const newData = data.items?.map((item: any) => {
            return {
              value: item.id,
              label: renderLabelProduct(item),
              data: item,
            }
          })
          setProducts((prev: any) => {
            return {
              total: data.total_pages,
              loading: false,
              items: uniqWith([...prev.items, ...newData], isEqual),
            }
          })
        }
      }
    )
  }, [keyword, page])

  useEffect(() => {
    getDataProducts()
  }, [getDataProducts])

  const handleSelected = useCallback(
    (data: { id: string; products: string; data: ProductType }[]) => {
      const newData = data?.map((item) => {
        const findOrderProduct = order_products?.find((i: any) => item.id === i.product_id)
        return findOrderProduct
          ? findOrderProduct
          : { product_id: item.id, product: item.data, quantity: 1 }
      })
      order_products_second.current = newData
      discountCode && addDiscount(discountCode, newData)
      setFieldValue('order_products', newData)
    },
    [JSON.stringify(order_products)]
  )

  const updateQuantity = (productId: string, product: any) => {
    const newProducts = order_products.map((item: any) =>
      item.product_id === productId ? { ...item, ...product } : item
    )
    order_products_second.current = newProducts
    addDiscount(discountCode, newProducts)
    setFieldValue('order_products', newProducts)
  }

  const handleRemoveProduct = (id: string) => {
    const newProducts = order_products.filter((item: any) => item.product_id !== id)

    addDiscount(discountCode, newProducts)
    setFieldValue('order_products', newProducts)
    setProductsSelected(productsSelected.filter((item: any) => item.id !== id))
  }

  const { totalInit, newTotal } = useMemo(() => {
    let totalInit = 0
    let newTotal = 0

    order_products_second.current?.forEach((item: any) => {
      const initPrice = item.product?.price * item.quantity
      const finalPrice = item.product?.final_price * item.quantity
      totalInit += initPrice
      newTotal += finalPrice
    })

    return {
      totalInit,
      newTotal,
    }
  }, [JSON.stringify(order_products_second.current)])

  const addDiscount = (code: string, orderProducts: any[]) => {
    let newTotal = 0

    orderProducts?.forEach((item: any) => {
      const finalPrice = item.product?.final_price * item.quantity
      newTotal += finalPrice
    })

    if (!code) {
      setFinalPrice(newTotal)
      return
    }
    if (!!phoneErrorRef.current) {
      setDiscountCodeError('Vui lòng nhập số điện thoại để kiểm tra mã giảm giá')
      setFinalPrice(newTotal)
      return
    }
    apiPut(
      apiUrls.adminCalculateDiscountCode(),
      {
        order_id: idOrder,
        discount_code: code,
        list_product: orderProducts?.map((item: any) => ({
          id: item.product_id,
          quantity: item.quantity,
        })),
        phone: phoneRef.current,
        city: payment_method === GET_AT_ADDRESS ? cityRef.current : '',
      },
      ({ status, data, id, text }) => {
        if (status) {
          setFinalPrice(data.final_total_price)
          setDiscountCodeError('')
        } else {
          setFinalPrice(newTotal)
          if (id === '459' || id === '313') {
            setDiscountCodeError(text)
          }
        }
      }
    )
  }

  useEffect(() => {
    if (!discount_code) setFinalPrice(newTotal)
  }, [discount_code, newTotal])

  const { items, loading: loadingProducts, total } = products

  const handleIncreasePage = () => {
    if (!loadingProducts) {
      setProducts((prev) => ({ ...prev, loading: false }))
      page.current += 1
      getDataProducts()
    }
  }

  return (
    <div>
      <div className="searchProducts">
        <SearchSelect
          placeholder="Tìm kiếm sản phẩm"
          onSearch={(search) => {
            page.current = 1
            setProducts((prev) => ({ ...prev, items: [], loading: true }))

            setKeyword(search)
          }}
          data={items}
          checkbox={{
            name: 'products',
            selected: order_products.map((i: any) => ({ id: i.product_id })),
            onChange: (values) => handleSelected(values),
          }}
          maxLength={100}
          labelNoItems="Không có kết quả"
          loadingSearch={loadingProducts}
          onIncreasePage={() => handleIncreasePage()}
          showLoadmore={page.current < total}
        />
      </div>
      <Table className="CartItems-table">
        <TableHead>
          <TableRow>
            <TableCell width={130}>{t('product')}</TableCell>
            <TableCell width={50}>{t('unitPrice')}</TableCell>
            <TableCell style={{ textAlign: 'center' }} width={50}>
              {t('quantity')}
            </TableCell>
            <TableCell width={50}>Thành tiền</TableCell>
            <TableCell width={50}>{t('operations')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {order_products.length > 0 ? (
            <>
              {order_products?.map((cartItem: any, index: number) => (
                <ProductItemRow
                  key={index}
                  cartItem={cartItem}
                  updateQuantity={updateQuantity}
                  removeProduct={handleRemoveProduct}
                />
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan={5} sx={{ py: 5 }}>
                <div className="w-100" style={{ margin: '30px auto', textAlign: 'center' }}>
                  Không có sản phẩm được chọn
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {order_products.length > 0 && (
        <div className="d-f jc-sb" style={{ marginTop: 30 }}>
          <div className="inputDiscount">
            <p className="text">Mã giảm giá</p>
            <Input
              fullWidth
              value={discountCode}
              maxLength={50}
              onChange={(e) => {
                if (discountCodeError) setDiscountCodeError('')
                setDiscountCode(formatCode(e.target.value))
              }}
              onBlur={(e) => {
                e.persist()
                setFieldValue('discount_code', formatCode(e.target.value))
                addDiscount(formatCode(e.target.value), order_products_second.current)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setFieldValue('discount_code', discountCode)
                  addDiscount(discountCode, order_products_second.current)
                }
              }}
              isRunOnBlurWhenEnter
              blurWhenEnter
              error={discountCodeError}
            />
          </div>
          <div className="total">
            <div className="unit-total d-f jc-sb">
              <div className="label">Tổng tiền trước giảm:</div>
              <p>{formatPrice(totalInit)}</p>
            </div>
            {finalPrice - totalInit !== 0 && (
              <div className=" discount d-f jc-sb">
                <div className="label">Giảm giá:</div>
                <p>{formatPrice(finalPrice - totalInit)}</p>
              </div>
            )}
            <div className="total d-f jc-sb">
              <div className="label">Tổng cộng:</div>
              <p>{formatPrice(finalPrice)}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
})

export default BlockSelectedProducts
