import { MAX_ATTACHMENT_SIZE } from 'configs/constants'
import { useMemo } from 'react'
import { useApis } from 'services/api'
import { useModalConfirm } from 'store/modalConfirm'
import { trimFileName } from 'utils'

export const useUploadImage = () => {
  const { apiPost } = useApis()
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()

  const showPopupCantUpload = () =>
    showModalConfirm({
      title: 'Thông báo',
      content: 'Không thể upload file trên 50 MB',
      confirm: { action: hideModalConfirm, text: 'Đã hiểu' },
      small: true,
    })

  return useMemo(
    () => ({
      showPopupCantUpload,
      canUpload: (fileSize: number) => {
        if (fileSize > MAX_ATTACHMENT_SIZE) {
          showPopupCantUpload()
          return false
        }
        return true
      },
      upload: (
        file: any,
        prefix: string,
        onSuccess?: (url: string, id?: string, fileSize?: number) => void,
        onError?: (error: string, id?: string) => void,
        id?: string
      ) => {
        apiPost(
          '/api/v1/upload',
          { upload: { prefix, file: file, file_name: trimFileName(file.name) } },
          ({ status, data, text }) => {
            if (status) {
              onSuccess?.(data.file_url || '', id, file.size)
            } else onError?.(text, id)
          },
          () => onError?.('Error')
        )
      },
    }),
    [apiPost]
  )
}
