import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

import { LS_LANG, LANGUAGES } from 'configs/constants'
const { VI } = LANGUAGES

const detectLang = localStorage.getItem(LS_LANG)

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: detectLang || VI,
    backend: {
      /* translation file path */
      loadPath: '/lang/{{lng}}.json',
    },
    fallbackLng: VI,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      useSuspense: true,
    },
  })

export default i18n
