/*eslint-disable*/
import { Chip } from '@mui/material'
import { Button } from 'components/button'
import Input from 'components/input/Input'
import { apiUrls } from 'configs/apis'
import { DELIVERY_METHODS } from 'configs/constants'
import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useApis } from 'services/api'
import { useShoppingCart } from 'store/shoppingCart'
import { formatCode, updatePaymentLS } from 'utils'
import ProductItem from './ProductItem'
import UnitPrice from './UnitPrice'

const { GET_AT_ADDRESS } = DELIVERY_METHODS

export const OrderProducts: React.FC = () => {
  const { cartItems, totalItems } = useShoppingCart()

  return (
    <div className="PaymentLayout-rightContent__mobile">
      <div className="PaymentLayout-rightContent__title">
        Đơn hàng<span>({totalItems})</span>
      </div>
      {cartItems.map((cartItem) => {
        const { product, quantity, product_id = '' } = cartItem
        const {
          price,
          name,
          manufacturer,
          avatar_url,
          discount_value,
          discount_percent,
          final_price,
        } = product

        return (
          <ProductItem
            key={product_id}
            price={price}
            name={name}
            manufacturer={manufacturer?.name}
            discount_percent={discount_percent}
            discount_value={discount_value}
            image={avatar_url}
            amount={quantity}
            finalPrice={final_price}
          />
        )
      })}
    </div>
  )
}

export const OrderPrices: React.FC<any> = ({
  formikState,
  codeError,
  setCodeError,
  deliveryMethod,
  setFinalPrice,
  finalPrice,
}) => {
  const [code, setCode] = useState('')
  const { cartItems } = useShoppingCart()

  const [codeSuccess, setCodeSuccess] = useState('')

  const { apiPut } = useApis()

  const { t } = useTranslation()

  const { totalInit, newTotal } = useMemo(() => {
    let totalInit = 0
    let newTotal = 0

    cartItems?.forEach((item: any) => {
      const initPrice = item.product?.price * item.quantity
      const finalPrice = item.product?.final_price * item.quantity
      totalInit += initPrice
      newTotal += finalPrice
    })
    return {
      totalInit,
      newTotal,
    }
  }, [cartItems])

  const addDiscount = (code: string) => {
    if (!!formikState?.errors?.phone) {
      setCodeError('Vui lòng nhập số điện thoại để kiểm tra mã giảm giá')
      setFinalPrice(newTotal)
      return
    }

    apiPut(
      apiUrls.calculateDiscountCode(),
      {
        discount_code: code,
        list_product: cartItems?.map((item: any) => ({
          id: item.product_id,
          quantity: item.quantity,
        })),
        phone: formikState?.values.phone,
        city: deliveryMethod === GET_AT_ADDRESS ? formikState?.values.city?.name : '',
      },
      ({ status, data, id, text }) => {
        if (status) {
          setFinalPrice(data.final_total_price)
          updatePaymentLS({ discount_code: code })
          setCodeSuccess(code)
        } else if (id === '313' || id === '459') {
          setFinalPrice(newTotal)
          setCodeError(text)
        }
      }
    )
  }

  useEffect(() => {
    if (!code) setFinalPrice(newTotal)
  }, [code, newTotal])

  const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (codeError) setCodeError('')
    const value = formatCode(e.target.value)
    setCode(value)
  }

  const handelRemoveDiscountCode = () => {
    setCodeSuccess('')
    formikState?.setFieldValue('discount_code', '')
    setCode('')
    updatePaymentLS({}, ['discount_code'])
  }

  const renderCodeTrim = (name: string) => {
    if (name?.length > 16) {
      return name?.slice(0, 16) + '...'
    }

    return name
  }

  return (
    <div>
      <div className="Discount-code">
        <div className="input-discount">
          <Input
            label={t('discountCode')}
            fullWidth
            placeholder="Nhập mã để nhận ưu đãi"
            value={!!codeSuccess ? renderCodeTrim(code) : code}
            onChange={handleChangeCode}
            maxLength={50}
            error={codeError}
            disabled={!!codeSuccess}
            errorFocused
          />
          {!!codeSuccess && <i className="mn-icon-x-circle" onClick={handelRemoveDiscountCode} />}
        </div>
        <Button
          color="secondary"
          onClick={() => {
            formikState?.setFieldValue('discount_code', code)
            addDiscount(code)
          }}
          disabled={!!codeError || !code?.trim() || !!codeSuccess}
        >
          Áp dụng
        </Button>
      </div>
      <UnitPrice label="Tổng tiền trước giảm:" price={totalInit} />
      {finalPrice - totalInit !== 0 && (
        <UnitPrice label="Giảm giá:" price={finalPrice - totalInit} />
      )}
      <UnitPrice label="Tổng cộng:" price={finalPrice} large redColor />
    </div>
  )
}

const OrderInfo: React.FC<any> = ({
  formikState,
  codeError,
  setCodeError,
  deliveryMethod,
  finalPrice,
  setFinalPrice,
}) => {
  return (
    <div className="PaymentLayout-rightContent">
      <OrderProducts />
      <OrderPrices
        formikState={formikState}
        setCodeError={setCodeError}
        codeError={codeError}
        deliveryMethod={deliveryMethod}
        setFinalPrice={setFinalPrice}
        finalPrice={finalPrice}
      />
    </div>
  )
}

export default OrderInfo
