import React from 'react'
import AboutUsWrapper from './AboutUsWrapper'
import { TYPR_OF_ABOT_US } from './constant'

const { VISION } = TYPR_OF_ABOT_US

const VisionManagement = () => {
  return (
    <div>
      <AboutUsWrapper type={VISION} />
    </div>
  )
}

export default VisionManagement
