import React, { CSSProperties } from 'react'

import './index.scss'

interface PropTypes {
  className?: string
  fullScreen?: boolean
  style?: CSSProperties
  overlay?: boolean
}

export default function Loading(props: PropTypes) {
  const { className = '', fullScreen, style, overlay } = props

  return (
    <div
      style={style}
      className={`Loading-container ${fullScreen && 'full'} ${overlay && 'overlay'} ${className}`}
    >
      <div className="Loading-dot" />
    </div>
  )
}
