/*eslint-disable*/
import React, { useState } from 'react'
import { Box, Card, Tab, Tabs } from '@mui/material'
import GuideUsingStep from 'components/admin/guideUsingStep/GuideUsingStep'
import { renderBackTitle } from 'configs/extensions'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'store/confirmRedirect'

import add1 from '../../../../assets/images/CategoriesUsing/add1.png'
import add2 from '../../../../assets/images/CategoriesUsing/add2.png'
import add3 from '../../../../assets/images/CategoriesUsing/add3.png'
import add4 from '../../../../assets/images/CategoriesUsing/add4.png'
import add5 from '../../../../assets/images/CategoriesUsing/add5.png'
import add6 from '../../../../assets/images/CategoriesUsing/add6.png'
import add7 from '../../../../assets/images/CategoriesUsing/add7.png'
import add8 from '../../../../assets/images/CategoriesUsing/add8.png'
import add9 from '../../../../assets/images/CategoriesUsing/add9.png'
import add10 from '../../../../assets/images/CategoriesUsing/add10.png'
import add11 from '../../../../assets/images/CategoriesUsing/add11.png'

import edit1 from '../../../../assets/images/CategoriesUsing/edit1.png'
import edit2 from '../../../../assets/images/CategoriesUsing/edit2.png'
import edit3 from '../../../../assets/images/CategoriesUsing/edit3.png'
import edit4 from '../../../../assets/images/CategoriesUsing/edit4.png'
import edit5 from '../../../../assets/images/CategoriesUsing/edit5.png'
import edit6 from '../../../../assets/images/CategoriesUsing/edit6.png'
import edit7 from '../../../../assets/images/CategoriesUsing/edit7.png'
import edit8 from '../../../../assets/images/CategoriesUsing/edit8.png'

import delete1 from '../../../../assets/images/CategoriesUsing/delete1.png'
import delete2 from '../../../../assets/images/CategoriesUsing/delete2.png'
import delete3 from '../../../../assets/images/CategoriesUsing/delete3.png'
import delete4 from '../../../../assets/images/CategoriesUsing/delete4.png'
import delete5 from '../../../../assets/images/CategoriesUsing/delete5.png'
import delete6 from '../../../../assets/images/CategoriesUsing/delete6.png'
import delete7 from '../../../../assets/images/CategoriesUsing/delete7.png'

import video1 from '../../../../assets/images/CategoriesUsing/video1.mp4'
import video2 from '../../../../assets/images/CategoriesUsing/video2.mp4'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const CategoriesUsing = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <>
      {renderBackTitle(history, t('Quản lý danh mục sản phẩm - Hướng dẫn sử dụng'))}
      <Card sx={{ p: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="Thêm danh mục sản phẩm cấp 1 mới" {...a11yProps(0)} />
            <Tab label="Sửa thông tin danh mục sản phẩm cấp 1" {...a11yProps(1)} />
            <Tab label="Xóa danh mục sản phẩm cấp 1" {...a11yProps(2)} />
            <Tab label="Danh mục sản phẩm cấp 2" {...a11yProps(3)} />
            <Tab label="Danh mục sản phẩm cấp 3" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Thêm danh mục cấp 1</b> để thêm một danh mục sản phẩm cấp 1 mới. "
              image={[add1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Thêm danh mục cấp 1</b>, hộp thoại Thêm danh mục cấp 1 sẽ hiển thị như ảnh bên dưới:"
              image={[add2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Điền thông tin hợp lệ vào tất cả các trường trong hộp thoại Thêm danh mục cấp 1, sau đó nhấn nút <b>THÊM</b>."
              image={[add3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>THÊM</b>, danh mục sản phẩm cấp 1 sẽ được thêm thành công và hiển thị trong danh sách với <b>Số lượng danh mục cấp 2 = 0</b>:"
              image={[add4]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                {
                  title:
                    'Danh mục sản phẩm cấp 1 vừa thêm thành công sẽ được hiển thị ở màn <b>Trang chủ</b>:',
                  image: [add5],
                },
                {
                  title: 'Thêm thông số kỹ thuật ở hộp thoại Thêm danh mục cấp 1:',
                  subNote: [
                    {
                      title:
                        'Nếu bạn chọn vào mục Thêm thông số kỹ thuật, thì bạn cần phải điền thông tin về <b>Ký hiệu</b> và <b>Tên kích thước</b> của các thông số:',
                      image: [add6],
                    },
                    {
                      title:
                        'Chỉ cho phép điền 1 kí tự trong bảng chữ cái tiếng Anh (A-Z) vào trường Ký hiệu.',
                    },
                    {
                      title:
                        'Vì đang thêm mới danh mục cấp 1, nên bạn có thể thêm tối đa 6 thông số kỹ thuật cho danh mục cấp 1 này:',
                      image: [add7],
                    },
                    {
                      title: 'Ký hiệu và Tên kích thước ở các thông số không được trùng nhau:',
                      image: [add8],
                    },
                    {
                      title:
                        'Nếu đang hiển thị nhiều hơn 2 thông số kỹ thuật, bạn có thể nhấn nút Xóa ở các thông số kỹ thuật thứ 2 trở đi để xóa thông số kỹ thuật tương ứng:',
                      image: [add9],
                    },
                  ],
                },
                {
                  title:
                    'Bạn có thể tìm kiếm danh mục sản phẩm cấp 1 theo <b>Tên danh mục cấp 1</b>:',
                  image: [add10],
                },
                {
                  title:
                    'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác thêm danh mục sản phẩm cấp 1 mới:',
                  image: [add11],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <p style={{ fontSize: '18px', marginBottom: 20 }}>
              <b>Điều kiện:</b> Đã có ít nhất một danh mục sản phẩm cấp 1 ở màn Quản lý danh mục sản
              phẩm.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Sửa</b> ở danh mục sản phẩm cấp 1 mà bạn cần sửa thông tin."
              image={[edit1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Sửa</b>, hộp thoại Sửa danh mục cấp 1 sẽ hiển thị như ảnh bên dưới:"
              image={[edit2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Cập nhật thông tin vào các trường mà bạn muốn sửa trong hộp thoại Sửa danh mục cấp 1, sau đó nhấn nút <b>LƯU</b>. "
              image={[edit3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>LƯU</b>:"
              note={[
                {
                  title:
                    'Nếu bạn không sửa danh sách thông số kỹ thuật, hoặc sửa danh sách thông số kỹ thuật thuộc danh mục sản phẩm cấp 1 nhưng danh mục này chưa gắn với sản phẩm nào thì thông tin danh mục sản phẩm cấp 1 sẽ được cập nhật theo thông tin vừa sửa:',
                  image: [edit4],
                },
                {
                  title:
                    'Nếu bạn sửa danh sách thông số kỹ thuật và danh mục sản phẩm cấp 1 này đã gắn với sản phẩm → Bạn cần xác nhận nếu nhấn <b>LƯU</b>, thì tất cả các sản phẩm gắn với danh mục này sẽ bị ảnh hưởng và bạn cần chỉnh sửa các sản phẩm đó theo danh sách thông số kỹ thuật mới. Bạn có thể nhấn <b>LƯU</b> để xác nhận, hoặc <b>HỦY</b> để hủy bỏ thao tác chỉnh sửa danh mục cấp 1.',
                  image: [edit5],
                },
              ]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                {
                  title:
                    'Danh mục sản phẩm cấp 1 vừa sửa thành công sẽ được cập nhật ở các màn hình của người dùng.',
                },
                {
                  title:
                    'Nếu danh mục sản phẩm có danh mục sản phẩm cấp 2 (hoặc có cả danh mục sản phẩm cấp 3):',
                  subNote: [
                    {
                      title:
                        'Tổng số thông số kỹ thuật từ danh mục cấp 1 đến danh mục cấp 3 tương ứng (nếu có) chỉ được tối đa là 6 thông số kỹ thuật:',
                      image: [edit6],
                    },
                    {
                      title:
                        'Ký hiệu và tên kích thước ở các thông số kỹ thuật từ danh mục cấp 1 đến danh mục cấp 3 tương ứng (nếu có) không được trùng nhau:',
                      image: [edit7],
                    },
                  ],
                },
                {
                  title:
                    'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác sửa thông tin danh mục sản phẩm cấp 1:',
                  image: [edit8],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box>
            <p style={{ fontSize: '18px', marginBottom: 20 }}>
              <b>Điều kiện:</b> Đã có ít nhất một danh mục sản phẩm cấp 1 ở màn Quản lý danh mục sản
              phẩm.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Xóa</b> ở danh mục sản phẩm cấp 1 mà bạn cần xóa thông tin."
              image={[delete1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Xóa</b>, hộp thoại xác nhận Xóa danh mục sản phẩm sẽ hiển thị như ảnh bên dưới:"
              image={[delete2]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title: 'Bạn có thể nhấn <b>HỦY</b> để hủy thao tác xóa danh mục sản phẩm cấp 1:',
                  image: [delete3],
                },
              ]}
            />
            <GuideUsingStep descreption="<b>Bước 2:</b> Nhấn nút <b>XÓA</b>." image={[delete4]} />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>XÓA</b>, bạn cần nhập mật khẩu của tài khoản đang đăng nhập để xác nhận:"
              image={[delete5]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác xóa danh mục sản phẩm cấp 1:',
                  image: [delete6],
                },
              ]}
            />
            <GuideUsingStep
              descreption="<b>Bước 3:</b> Nhập chính xác mật khẩu của tài khoản đang đăng nhập, sau đó nhấn nút <b>XÓA</b>."
              image={[delete7]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>XÓA</b>, danh mục sản phẩm cấp 1 vừa xóa sẽ không còn hiển thị ở màn <b>Quản lý sản phẩm</b> và <b>các màn hình của người dùng</b>, đồng thời xóa tất cả danh mục sản phẩm cấp 2, cấp 3 và sản phẩm (nếu có) thuộc danh mục cấp 1 vừa xóa."
              noWrapper
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box>
            <p style={{ fontSize: '18px', marginBottom: 20 }}>
              <b>Điều kiện:</b> Đã có ít nhất một danh mục sản phẩm cấp 1 ở màn Quản lý danh mục sản
              phẩm.
            </p>
            <GuideUsingStep
              descreption="Nhấn vào một danh mục sản phẩm cấp 1 bạn có thể chuyển đến màn hình danh mục sản phẩm cấp 2 tương ứng của danh mục sản phẩm cấp 1 đó (ngoại trừ nút Sửa và Xóa):"
              orther={
                <div
                  className="d-f ai-c jc-c"
                  style={{ marginTop: '8px', flexDirection: 'column' }}
                >
                  <video width={'60%'} controls>
                    <source src={video1} type="video/mp4" />
                  </video>
                </div>
              }
            />
            <GuideUsingStep
              descreption="Cách thêm, sửa, xóa danh mục sản phẩm cấp 2 sẽ tương tự như thêm, sửa, xóa danh mục sản phẩm cấp 1."
              noWrapper
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Box>
            <p style={{ fontSize: '18px', marginBottom: 20 }}>
              <b>Điều kiện:</b> Đã có ít nhất một danh mục sản phẩm cấp 2 ở màn danh mục sản phẩm
              cấp 2.
            </p>
            <GuideUsingStep
              descreption="Nhấn vào một danh mục sản phẩm cấp 2 bạn có thể chuyển đến màn hình danh mục sản phẩm cấp 3 tương ứng của danh mục sản phẩm cấp 2 đó (ngoại trừ nút Sửa và Xóa):"
              orther={
                <div
                  className="d-f ai-c jc-c"
                  style={{ marginTop: '8px', flexDirection: 'column' }}
                >
                  <video width={'60%'} controls>
                    <source src={video2} type="video/mp4" />
                  </video>
                </div>
              }
            />
            <GuideUsingStep
              descreption="Cách thêm, sửa, xóa danh mục sản phẩm cấp 3 sẽ tương tự như thêm, sửa, xóa danh mục sản phẩm cấp 1."
              noWrapper
            />
            <GuideUsingStep
              descreption="Danh mục sản phẩm cấp 3 là cấp cuối cùng nên không thể nhấn vào danh mục sản phẩm cấp 3 để chuyển đến danh mục sản phẩm cấp nhỏ hơn được."
              noWrapper
            />
          </Box>
        </TabPanel>
      </Card>
    </>
  )
}

export default CategoriesUsing
