import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import qs from 'qs'

import { useApis } from 'services/api'
import { useBreadcrumbs } from 'store/breadcrumbs'

import { RelativeProducts } from 'components/screens'

import { apiUrls } from 'configs/apis'
import { BASIC_PAGE_SIZE } from 'configs/constants'
import { convertViewedProductsData } from 'utils'
import { ProductType } from 'types'
import { useHistory } from 'store/confirmRedirect'

export default function ViewedProducts() {
  const history = useHistory()
  const { showBreadcrumbs } = useBreadcrumbs()
  const { search } = useLocation()
  const { apiGet } = useApis()

  // states
  const [isLoading, setIsLoading] = useState(true)
  const [productsData, setProductsData] = useState<{
    products: ProductType[]
    total: number
    totalPages: number
  }>({
    products: [],
    total: 0,
    totalPages: 0,
  })

  const queryParams = qs.parse(search, { ignoreQueryPrefix: true })
  const { page: queryPage } = queryParams
  const queryPageNum = Number(queryPage || 1)

  /**
   * Get viewed products
   *
   * @param page Page number
   */
  const getViewedProductsApi = useCallback(async (page: number) => {
    setIsLoading(true)

    try {
      const res = await apiGet(apiUrls.viewedProducts(), {
        page,
        page_size: BASIC_PAGE_SIZE,
      })
      setIsLoading(false)

      const { status, data = {} } = res
      const { items, total_pages: totalPages } = data || {}
      if (status) {
        setProductsData({ ...data, products: convertViewedProductsData(items || []), totalPages })
      }
    } catch (e) {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    showBreadcrumbs([{ name: 'viewedProducts', path: '/' }])
  }, [])

  useEffect(() => {
    getViewedProductsApi(queryPageNum)
  }, [queryPageNum])

  /**
   * Handle change page
   *
   * @param page Page
   */
  const handleChangePage = (page: number) => {
    history.push({
      pathname: '/san-pham-da-xem',
      search: qs.stringify({ page }),
    })
  }

  return (
    <RelativeProducts
      {...productsData}
      title="viewedProducts"
      initialPage={queryPageNum}
      isLoading={isLoading}
      onChangePage={handleChangePage}
    />
  )
}
