/*eslint-disable*/
import React, { useState } from 'react'
import { Box, Card, Tab, Tabs } from '@mui/material'
import GuideUsingStep from 'components/admin/guideUsingStep/GuideUsingStep'
import { renderBackTitle } from 'configs/extensions'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'store/confirmRedirect'
import OrderDetailUsing from './OrderDetailUsing'

import add1 from '../../../../../assets/images/OrderUsing/add/add1.png'
import add2 from '../../../../../assets/images/OrderUsing/add/add2.png'
import add3 from '../../../../../assets/images/OrderUsing/add/add3.png'
import add4 from '../../../../../assets/images/OrderUsing/add/add4.png'
import add5 from '../../../../../assets/images/OrderUsing/add/add5.png'
import add6 from '../../../../../assets/images/OrderUsing/add/add6.png'
import add7 from '../../../../../assets/images/OrderUsing/add/add7.png'
import add8 from '../../../../../assets/images/OrderUsing/add/add8.png'
import add9 from '../../../../../assets/images/OrderUsing/add/add9.gif'
import add10 from '../../../../../assets/images/OrderUsing/add/add10.png'
import add11 from '../../../../../assets/images/OrderUsing/add/add11.png'
import add12 from '../../../../../assets/images/OrderUsing/add/add12.png'
import add13 from '../../../../../assets/images/OrderUsing/add/add13.png'
import add14 from '../../../../../assets/images/OrderUsing/add/add14.png'
import add15 from '../../../../../assets/images/OrderUsing/add/add15.png'

import list1 from '../../../../../assets/images/OrderUsing/list/list1.png'
import list2 from '../../../../../assets/images/OrderUsing/list/list2.png'
import list3 from '../../../../../assets/images/OrderUsing/list/list3.png'
import list4 from '../../../../../assets/images/OrderUsing/list/list4.png'
import list5 from '../../../../../assets/images/OrderUsing/list/list5.png'
import list6 from '../../../../../assets/images/OrderUsing/list/list6.png'
import list7 from '../../../../../assets/images/OrderUsing/list/list7.png'
import list8 from '../../../../../assets/images/OrderUsing/list/list8.gif'
import list9 from '../../../../../assets/images/OrderUsing/list/list9.png'
import list10 from '../../../../../assets/images/OrderUsing/list/list10.png'
import list11 from '../../../../../assets/images/OrderUsing/list/list11.png'
import list12 from '../../../../../assets/images/OrderUsing/list/list12.png'
import list13 from '../../../../../assets/images/OrderUsing/list/list13.png'
import list14 from '../../../../../assets/images/OrderUsing/list/list14.png'

import edit1 from '../../../../../assets/images/OrderUsing/edit/edit1.png'
import edit2 from '../../../../../assets/images/OrderUsing/edit/edit2.png'
import edit3 from '../../../../../assets/images/OrderUsing/edit/edit3.png'
import edit4 from '../../../../../assets/images/OrderUsing/edit/edit4.png'
import edit5 from '../../../../../assets/images/OrderUsing/edit/edit5.png'
import edit6 from '../../../../../assets/images/OrderUsing/edit/edit6.png'

import delete1 from '../../../../../assets/images/OrderUsing/delete/delete1.png'
import delete2 from '../../../../../assets/images/OrderUsing/delete/delete2.png'
import delete3 from '../../../../../assets/images/OrderUsing/delete/delete3.png'
import delete4 from '../../../../../assets/images/OrderUsing/delete/delete4.png'

import cancel1 from '../../../../../assets/images/OrderUsing/cancel/cancel1.png'
import cancel2 from '../../../../../assets/images/OrderUsing/cancel/cancel2.png'
import cancel3 from '../../../../../assets/images/OrderUsing/cancel/cancel3.png'
import cancel4 from '../../../../../assets/images/OrderUsing/cancel/cancel4.png'
import cancel5 from '../../../../../assets/images/OrderUsing/cancel/cancel5.png'
import cancel6 from '../../../../../assets/images/OrderUsing/cancel/cancel6.png'
import cancel7 from '../../../../../assets/images/OrderUsing/cancel/cancel7.png'

import quotation1 from '../../../../../assets/images/OrderUsing/quotation/quotation1.png'
import quotation2 from '../../../../../assets/images/OrderUsing/quotation/quotation2.png'
import quotation3 from '../../../../../assets/images/OrderUsing/quotation/quotation3.png'
import quotation4 from '../../../../../assets/images/OrderUsing/quotation/quotation4.png'
import quotation5 from '../../../../../assets/images/OrderUsing/quotation/quotation5.png'
import quotation6 from '../../../../../assets/images/OrderUsing/quotation/quotation6.png'
import quotation7 from '../../../../../assets/images/OrderUsing/quotation/quotation7.png'
import quotation8 from '../../../../../assets/images/OrderUsing/quotation/quotation8.png'
import quotation9 from '../../../../../assets/images/OrderUsing/quotation/quotation9.png'

import sendmail1 from '../../../../../assets/images/OrderUsing/sendmail/sendmail1.png'
import sendmail2 from '../../../../../assets/images/OrderUsing/sendmail/sendmail2.png'
import sendmail3 from '../../../../../assets/images/OrderUsing/sendmail/sendmail3.png'
import sendmail4 from '../../../../../assets/images/OrderUsing/sendmail/sendmail4.png'
import sendmail5 from '../../../../../assets/images/OrderUsing/sendmail/sendmail5.png'
import Lightbox from 'react-image-lightbox'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const orderUsing = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const [imgPreview, setImgPreview] = React.useState({ open: false, src: '' })

  const handleToggleImgPreview = (isOpen = false, image: any) => {
    setImgPreview({ open: isOpen, src: image })
  }

  return (
    <>
      {renderBackTitle(history, t('Quản lý đơn hàng - Hướng dẫn sử dụng'))}
      <Card sx={{ p: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="Thêm đơn hàng mới" {...a11yProps(0)} />
            <Tab label="Danh sách đơn hàng" {...a11yProps(1)} />
            <Tab label="Sửa đơn hàng" {...a11yProps(2)} />
            <Tab label="In báo giá " {...a11yProps(3)} />
            <Tab label="Gửi email xác nhận đơn hàng" {...a11yProps(4)} />
            <Tab label="Hủy đơn hàng" {...a11yProps(5)} />
            <Tab label="Xóa đơn hàng" {...a11yProps(6)} />
            <Tab label="Xem chi tiết đơn hàng" {...a11yProps(7)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Có ít nhất một sản phẩm có trạng thái <b>Kích hoạt</b> và có số
              lượng tồn {'>'} 0 ở màn Quản lý sản phẩm.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn nút <b>Thêm đơn hàng</b> để thêm đơn hàng mới."
              image={[add1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Thêm đơn hàng</b>, màn hình thêm thông tin đơn hàng sẽ hiển thị như sau:"
              image={[add2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Điền thông tin hợp lệ vào các trường bắt buộc và các trường khác (nếu muốn) tại màn thêm thông tin đơn hàng, sau đó nhấn nút <b>THÊM ĐƠN HÀNG</b>."
              image={[add3]}
            />
            <GuideUsingStep
              descreption="<b>Một số lưu ý khi điền dữ liệu vào các trường:</b>"
              note={[
                {
                  title:
                    '<b>Thông tin khách hàng</b>: Bạn có thể tìm kiếm và chọn một người dùng theo danh sách người dùng đang có ở màn Quản lý người dùng, hoặc nhập thông tin người dùng mới vào các trường Họ tên và Số điện thoại.',
                  subNote: [
                    {
                      title:
                        'Nếu nhập thông tin người dùng mới → Sau khi thêm đơn hàng thành công sẽ tự động tạo mới người dùng với trạng thái <b>Chưa đăng ký</b> và lưu đơn hàng vừa tạo vào danh sách đơn hàng của người dùng.',
                      image: [add4],
                    },
                  ],
                },
                {
                  title: '<b>Phương thức nhận hàng: </b>',
                  subNote: [
                    {
                      title:
                        'Nếu chọn phương thức nhận hàng là <b>Lấy tại cửa hàng</b> và đã có ít nhất một chi nhánh có trạng thái là <b>Hiển thị</b> ở màn Quản lý chi nhánh, bạn có thể chọn một trong số các chi nhánh đã có.',
                      image: [add5],
                    },
                    {
                      title:
                        'Nếu chọn phương thức nhận hàng là <b>Giao tận nơi</b> → Bạn cần nhập thông tin địa chỉ của người nhận hàng, bao gồm: Loại địa chỉ, Địa chỉ, Thành phố/Tỉnh, Quận/Huyện, Phường/Xã.',
                      image: [add6],
                    },
                  ],
                },
                {
                  title:
                    '<b>Phương thức thanh toán</b>: Nhấn vào Phương thức thanh toán để chọn một trong các phương thức thanh toán sau: ',
                  subNote: [
                    { title: 'Chuyển khoản qua ngân hàng' },
                    { title: 'Sử dụng ví Momo' },
                    { title: 'Thanh toán khi nhận hàng', image: [add7] },
                  ],
                },
                {
                  title:
                    '<b>Viết hoá đơn đỏ</b>: Bạn có thể nhấn chọn Viết hoá đơn đỏ để thêm thông tin hoá đơn đỏ.',
                  subNote: [
                    {
                      title:
                        'Sau khi nhấn chọn Viết hoá đơn đỏ cần điền các thông tin bắt buộc và Email nhận hoá đơn đỏ (nếu cần).',
                      image: [add8],
                    },
                  ],
                },
                {
                  title:
                    '<b>Tìm kiếm sản phẩm</b>: Bạn có thể tìm kiếm theo mã sản phẩm gốc, mã sản phẩm chính, tên sản phẩm, từ khóa tìm kiếm.',
                  subNote: [
                    {
                      title:
                        'Cho phép cuộn lên/xuống danh sách sản phẩm trong trường hợp có nhiều sản phẩm trong danh sách.',
                    },
                    { title: 'Cho phép chọn một hoặc nhiều sản phẩm cùng lúc.', image: [add9] },
                  ],
                },
                {
                  title: '<b>Danh sách sản phẩm</b>',
                  subNote: [
                    {
                      title:
                        'Bạn có thể nhấn nút <b>+/-</b> để tăng/giảm số lượng sản phẩm theo mong muốn.',
                      image: [add10],
                    },
                    {
                      title: 'Bạn có thể nhấn nút <b>XOÁ</b> ở mỗi sản phẩm để bỏ chọn.',
                      image: [add11],
                    },
                  ],
                },
                {
                  title:
                    '<b>Mã giảm giá</b>: Bạn có thể nhập mã giảm giá có trạng thái là Đang áp dụng ở màn Quản lý mã giảm giá.',
                  subNote: [
                    {
                      title:
                        'Nếu mã giảm giá thuộc loại <b>Miễn phí ship</b> → Giá trị đơn hàng không thay đổi, sau khi tạo đơn hàng thành công, sẽ hiển thị mã và ghi chú ở màn Chi tiết đơn hàng phía quản trị viên.',
                    },
                    {
                      title:
                        'Nếu mã giảm giá thuộc loại <b>Giảm % đơn hàng</b> nhưng không được áp dụng cho sản phẩm nào trong đơn hàng → Áp dụng mã không thành công và giá trị đơn hàng không thay đổi.',
                    },
                    {
                      title:
                        'Nếu mã giảm giá thuộc loại <b>Giảm % đơn hàng</b>, được áp dụng cho sản phẩm trong đơn hàng và trong danh sách sản phẩm không có sản phẩm nào được giảm giá → Cập nhật lại trường Tổng cộng, lưu lại mã giảm giá cho đơn hàng khi đơn hàng được tạo thành công.',
                    },
                    {
                      title:
                        'Nếu mã giảm giá thuộc loại <b>Giảm % đơn hàng</b> và được áp dụng cho sản phẩm trong đơn hàng và trong danh sách sản phẩm có sản phẩm đang được giảm giá thì:',
                      subNote: [
                        {
                          title: 'So sánh phần giảm giá khi áp mã và giảm giá của sản phẩm.',
                          subNote: [
                            {
                              title:
                                'Nếu giá trị bằng nhau hoặc giảm giá khi áp mã nhỏ hơn giảm giá của sản phẩm → Áp mã giảm giá không thành công, đơn hàng được tính giảm giá theo giảm giá của sản phẩm.',
                            },
                            {
                              title:
                                'Nếu giá trị giảm giá khi áp mã lớn hơn giảm giá của sản phẩm → Cập nhật lại trường Tổng cộng, lưu lại mã giảm giá cho đơn hàng khi đơn hàng được tạo thành công.',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>THÊM ĐƠN HÀNG</b>, đơn hàng sẽ được thêm thành công và hiển thị trong danh sách với trạng thái là Chưa thanh toán và thông tin người tạo là Quản trị viên."
              image={[add12]}
            />
            <GuideUsingStep
              descreption="Đồng thời, gửi thông báo cho admin về việc có đơn hàng mới thông qua:"
              note={[
                {
                  title: 'Ứng dụng Zalo (tham khảo ảnh dưới đây):',
                  orther: (
                    <div
                      className="d-f ai-c jc-c"
                      style={{ marginTop: '8px', flexDirection: 'column' }}
                    >
                      <img
                        src={add13}
                        alt=""
                        width={'40%'}
                        onClick={() => handleToggleImgPreview(true, add13)}
                      />
                    </div>
                  ),
                },
                { title: 'Ứng dụng Gmail (tham khảo ảnh dưới đây):', image: [add14] },
              ]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title: 'Bạn có thể nhấn <b>HỦY</b> để hủy bỏ thao tác thêm đơn hàng mới.',
                  image: [add15],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <GuideUsingStep
              descreption="Lưu ý: Sau khi người dùng đặt hàng thành công trên website, đơn hàng cũng sẽ được hiển thị tại màn Quản lý đơn hàng với trạng thái <b>Chưa thanh toán</b> và thông tin người tạo là Người dùng."
              image={[list1]}
            />
            <GuideUsingStep
              descreption="<b>Trạng thái đơn hàng</b>"
              orther={
                <Box margin={'30px'}>
                  <GuideUsingStep
                    descreption="Bạn có thể chuyển trạng thái đối với các đơn hàng đang có trạng thái được liệt kê dưới đây, các trạng thái còn lại không thể chuyển trạng thái ở ngoài màn hình Quản lý đơn hàng mà chỉ có thể chuyển trang thái ở màn hình Xem chi tiết đơn hàng:"
                    note={[
                      { title: 'Trạng thái “Chưa thanh toán”' },
                      { title: 'Trạng thái “Đã chuyển tiền”' },
                      { title: 'Trạng thái “Đã nhận tiền”' },
                    ]}
                  />
                  <GuideUsingStep
                    descreption="Đối với các đơn hàng có thể chuyển trạng thái sẽ hiển thị mũi tên hướng xuống ở trạng thái đơn hàng như ảnh bên dưới:"
                    image={[list2]}
                  />
                  <GuideUsingStep
                    descreption="<b>Bước 1:</b> Nhấn vào trạng thái đơn hàng."
                    image={[list3]}
                  />
                  <GuideUsingStep
                    descreption="Sau khi nhấn vào trạng thái đơn hàng, các trạng thái đơn hàng cho phép chuyển đổi sẽ hiển thị như ảnh bên dưới:"
                    image={[list4]}
                  />
                  <GuideUsingStep
                    descreption="<b>Bước 2:</b> Nhấn vào trạng thái đơn hàng muốn chuyển để hoàn thành thao tác chuyển trạng thái đơn hàng."
                    note={[
                      {
                        title:
                          'Nếu đơn hàng có phương thức thanh toán là Chuyển khoản qua ngân hàng hoặc Sử dụng ví Momo → Thứ tự chuyển trạng thái sẽ là: Chưa thanh toán → Đã chuyển tiền → Đã nhận tiền → Đang đóng gói.',
                      },
                      {
                        title:
                          'Nếu đơn hàng có phương thức thanh toán là Thanh toán khi nhận hàng → Thứ tự chuyển trạng thái sẽ là: Chưa thanh toán → Đang đóng gói.',
                      },
                      {
                        title:
                          'Trạng thái đơn hàng phía người dùng cũng sẽ được cập nhật tương ứng:',
                        subNote: [
                          {
                            title:
                              'Nếu chuyển trạng thái sang <b>Đã chuyển tiền</b> → Trạng thái đơn hàng phía người dùng chuyển sang <b>Chờ thanh toán</b>.',
                          },
                          {
                            title:
                              'Nếu chuyển trạng thái sang <b>Đã nhận tiền</b> → Trạng thái đơn hàng phía người dùng chuyển sang <b>Chờ lấy hàng</b>.',
                          },
                          {
                            title:
                              'Nếu chuyển trạng thái sang <b>Chưa thanh toán</b> → Trạng thái đơn hàng phía người dùng chuyển sang <b>Chờ thanh toán</b>.',
                          },
                          {
                            title:
                              'Nếu chuyển trạng thái sang <b>Đang đóng gói</b> → Trạng thái đơn hàng phía người dùng chuyển sang <b>Chờ lấy hàng</b>.',
                          },
                        ],
                      },
                    ]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Tìm kiếm, lọc đơn hàng</b>"
              orther={
                <Box margin={'30px'}>
                  <GuideUsingStep
                    descreption="Bạn có thể tìm kiếm đơn hàng theo Mã đơn hàng, Người mua hoặc SĐT và lọc danh sách theo Người tạo, Trạng thái đơn hàng, Người đại diện, Ngày đặt hàng."
                    image={[list5]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Cài đặt ẩn/hiện cột</b>"
              orther={
                <Box margin={'30px'}>
                  <GuideUsingStep
                    descreption="<b>Bước 1:</b> Nhấn vào nút <b>ẨN/HIỆN CỘT</b> để cài đặt hiển thị hoặc ẩn đi các cột trong bảng danh sách."
                    image={[list6]}
                  />
                  <GuideUsingStep
                    descreption="Sau khi nhấn <b>ẨN/HIỆN CỘT</b>, danh sách cột của màn Quản lý đơn hàng sẽ hiển thị như ảnh bên dưới:"
                    image={[list7]}
                  />
                  <GuideUsingStep
                    descreption="<b>Bước 2:</b> Chọn/bỏ chọn vào các ô có tên cột mà bạn muốn hiển thị/ẩn đi, sau đó nhấn <b>LƯU</b>. Sau khi nhấn <b>LƯU</b>, các cột tương ứng ở bảng quản lý sẽ hiển thị theo cài đặt của bạn."
                    noWrapper
                  />
                  <GuideUsingStep
                    descreption="<b>Lưu ý:</b>"
                    note={[
                      {
                        title:
                          'Nếu số lượng cột hiển thị vượt quá kích thước của màn hình, bạn cần di chuyển xuống cuối trang, kéo thanh cuộn ngang để xem hết các cột không hiển thị đủ trên màn hình.',
                        image: [list8],
                      },
                      {
                        title:
                          'Bạn có thể nhấn vào nút <b>Mặc định</b> ở trong danh sách cột ở cài đặt ẩn/hiện cột theo mặc định của hệ thống:',
                        image: [list9],
                      },
                      {
                        title:
                          'Sau khi nhấn <b>Mặc định</b>, các cột sau sẽ được hiển thị (các cột còn lại sẽ bị ẩn):',
                        subNote: [
                          { title: 'STT' },
                          { title: 'Mã đơn hàng' },
                          { title: 'Người mua' },
                          { title: 'Số điện thoại' },
                          { title: 'Tổng tiền (đ)' },
                          { title: 'Phương thức nhận hàng' },
                          { title: 'Phương thức thanh toán' },
                          { title: 'Ngày đặt hàng' },
                          { title: 'Thời gian giao hàng dự kiến' },
                          { title: 'Thời gian giao hàng thực tế' },
                          { title: 'Trạng thái đơn hàng' },
                          { title: 'Thao tác' },
                        ],
                      },
                      {
                        title: 'Bạn có thể nhấn <b>HỦY</b> để hủy cài đặt ẩn/hiện cột:',
                        image: [list10],
                      },
                    ]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Xem lịch sử in báo giá</b>"
              orther={
                <Box margin={'30px'}>
                  <p style={{ fontSize: 18, marginBottom: 10 }}>
                    <b>Điều kiện:</b> Có ít nhất một đơn hàng và đã được in báo giá trước đó.
                  </p>
                  <GuideUsingStep
                    descreption="<b>Bước 1:</b> Nhấn vào số lần in báo giá (>0) tại đơn hàng bạn cần xem lịch sử in báo giá."
                    image={[list11]}
                  />
                  <GuideUsingStep
                    descreption="Sau khi nhấn vào số lần in báo giá, màn Lịch sử in báo giá của đơn hàng sẽ hiển thị như bên dưới:"
                    image={[list12]}
                  />
                  <GuideUsingStep
                    descreption="<b>Lưu ý:</b>"
                    note={[
                      {
                        title:
                          'Bạn có thể nhấn vào nút <b>Xem</b> ở mỗi báo giá để xem chi tiết và in file Báo giá phụ tùng.',
                        image: [list13],
                      },
                      {
                        title: 'Bạn có thể nhấn nút quay lại để quay về màn hình Quản lý đơn hàng.',
                        image: [list14],
                      },
                    ]}
                  />
                </Box>
              }
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có đơn hàng được người dùng hoặc quản trị viên tạo và ở trạng
              thái là “Chưa thanh toán”, “Đã chuyển tiền”, “Đã nhận tiền”, “Đang đóng gói”.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Sửa</b> ở đơn hàng mà bạn cần sửa thông tin."
              image={[edit1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Sửa</b> sẽ hiển thị màn hình sửa thông tin đơn hàng như sau:"
              image={[edit2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Cập nhật thông tin vào các trường mà bạn muốn sửa tại màn hình sửa thông tin đơn hàng, sau đó nhấn nút <b>LƯU</b>."
              image={[edit3]}
            />
            <GuideUsingStep
              descreption="<b>Một số lưu ý khi điền dữ liệu vào các trường:</b>"
              note={[
                {
                  title:
                    '<b>Viết hoá đơn đỏ</b>: Bạn có thể nhấn chọn Viết hoá đơn đỏ để thêm thông tin hoá đơn đỏ nếu chưa có thông tin này trước đó.',
                  subNote: [
                    {
                      title:
                        'Sau khi nhấn chọn Viết hoá đơn đỏ cần điền các thông tin bắt buộc và Email nhận hoá đơn đỏ (nếu cần).',
                      image: [edit4],
                    },
                  ],
                },
                {
                  title:
                    '<b>Mã giảm giá</b>: Bạn có thể nhập mã giảm giá có trạng thái là Đang áp dụng ở màn Quản lý mã giảm giá.',
                  subNote: [
                    {
                      title:
                        'Nếu mã giảm giá thuộc loại <b>Miễn phí ship</b> → Giá trị đơn hàng không thay đổi, sau khi tạo đơn hàng thành công, sẽ hiển thị mã và ghi chú ở màn Chi tiết đơn hàng phía quản trị viên.',
                    },
                    {
                      title:
                        'Nếu mã giảm giá thuộc loại <b>Giảm % đơn hàng</b> nhưng không được áp dụng cho sản phẩm nào trong đơn hàng → Áp dụng mã không thành công và giá trị đơn hàng không thay đổi.',
                    },
                    {
                      title:
                        'Nếu mã giảm giá thuộc loại <b>Giảm % đơn hàng</b>, được áp dụng cho sản phẩm trong đơn hàng và trong danh sách sản phẩm không có sản phẩm nào được giảm giá → Cập nhật lại trường Tổng cộng, lưu lại mã giảm giá cho đơn hàng khi đơn hàng được tạo thành công.',
                    },
                    {
                      title:
                        'Nếu mã giảm giá thuộc loại <b>Giảm % đơn hàng</b> và được áp dụng cho sản phẩm trong đơn hàng và trong danh sách sản phẩm có sản phẩm đang được giảm giá thì:',
                      subNote: [
                        {
                          title: 'So sánh phần giảm giá khi áp mã và giảm giá của sản phẩm.',
                          subNote: [
                            {
                              title:
                                'Nếu giá trị bằng nhau hoặc giảm giá khi áp mã nhỏ hơn giảm giá của sản phẩm → Áp mã giảm giá không thành công, đơn hàng được tính giảm giá theo giảm giá của sản phẩm.',
                            },
                            {
                              title:
                                'Nếu giá trị giảm giá khi áp mã lớn hơn giảm giá của sản phẩm → Cập nhật lại trường Tổng cộng, lưu lại mã giảm giá cho đơn hàng khi đơn hàng được tạo thành công.',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>LƯU</b>, thông tin đơn hàng sẽ được cập nhật theo thông tin vừa sửa."
              noWrapper
            />
            <GuideUsingStep
              descreption="Đồng thời, thông tin cũng được cập nhật ở màn hình <b>Đơn hàng của tôi</b> phía người dùng."
              image={[edit5]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                {
                  title: 'Bạn có thể nhấn <b>HỦY</b> để hủy bỏ thao tác sửa đơn hàng.',
                  image: [edit6],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Có ít nhất một đơn hàng có trạng thái “Chưa thanh toán”.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>File</b> ở đơn hàng mà bạn cần in báo giá."
              image={[quotation1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>File</b>, màn hình In báo giá sẽ hiển thị như ảnh dưới đây:"
              image={[quotation2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Điền thông tin hợp lệ vào các trường mong muốn, sau đó nhấn nút <b>IN BÁO GIÁ</b>."
              image={[quotation3]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Bạn có thể nhấn <b>ẨN SỐ TIỀN</b> để ẩn các thông tin: Đơn giá, Thành tiền, Tổng tiền trước giảm, Giảm giá, Tổng cộng.',
                  image: [quotation4],
                  subNote: [
                    {
                      title:
                        'Sau khi nhấn <b>ẨN SỐ TIỀN</b> → Màn hình In báo giá sẽ hiển thị như ảnh bên dưới:',
                      image: [quotation5],
                    },
                    {
                      title:
                        'Bạn có thể nhấn <b>HIỆN SỐ TIỀN</b> để hiển thị lại các thông tin: Đơn giá, Thành tiền, Tổng tiền trước giảm, Giảm giá, Tổng cộng.',
                    },
                  ],
                },
              ]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>IN BÁO GIÁ</b>, hộp thoại cho phép xem trước thông tin Báo giá phụ tùng sẽ hiển thị như ảnh dưới đây:"
              image={[quotation6]}
            />
            <GuideUsingStep
              descreption="<b>Bước 3:</b> Nhấn nút <b>Print</b> để hoàn thành thao tác In báo giá."
              noWrapper
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Trường hợp chọn ẩn số tiền → Các thông tin Đơn giá, Thành tiền, Tổng tiền trước giảm, Giảm giá, Tổng cộng cũng sẽ được ẩn trong file báo giá phụ tùng.',
                },
                {
                  title:
                    'Trường hợp chưa kết nối với máy in → Bạn có thể lưu file báo giá dưới dạng PDF như ảnh bên dưới:',
                  image: [quotation7],
                  subNote: [
                    { title: 'Nhấn nút <b>Save</b> để hoàn thành thao tác lưu file báo giá.' },
                  ],
                },
                {
                  title: 'Bạn có thể nhấn nút <b>Cancel</b> để hủy thao tác in/lưu file báo giá.',
                  image: [quotation8],
                },
                {
                  title:
                    'Bạn có thể nhấn icon Quay lại để hủy thao tác in báo giá, đồng thời quay về màn Quản lý đơn hàng:',
                  image: [quotation9],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Có ít nhất một đơn hàng có trạng thái “Chưa thanh toán”.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1</b>: Nhấn vào nút <b>Email</b> ở đơn hàng mà bạn muốn gửi email xác nhận."
              image={[sendmail1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Email</b>, hộp thoại Xác nhận đơn hàng qua email sẽ hiển thị như ảnh bên dưới:"
              image={[sendmail2]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Nếu người dùng đã có email trước đó ở màn Quản lý người dùng → Hiển thị email người dùng trong hộp thoại và bạn có thể chỉnh sửa địa chỉ email.',
                },
                {
                  title:
                    'Nếu người dùng chưa có email trước đó ở màn Quản lý người dùng → Hiển thị trường Email rỗng và bạn cần điền thông tin email gửi xác nhận đơn hàng.',
                },
              ]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2</b>: Điền/Chỉnh sửa email nhận xác nhận đơn hàng, sau đó nhấn <b>GỬI</b>."
              image={[sendmail3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>GỬI</b>, email xác nhận đơn hàng sẽ được gửi về địa chỉ email đã nhập (tham khảo ảnh bên dưới)."
              image={[sendmail4]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác gửi email xác nhận đơn hàng.',
                  image: [sendmail5],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Có ít nhất một đơn hàng có một trong các trạng thái sau: “Chưa thanh
              toán”, “Đã chuyển tiền”, “Đã nhận tiền”, “Đang đóng gói” hoặc “Đang giao”.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Hủy</b> ở đơn hàng mà bạn muốn hủy."
              image={[cancel1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Hủy</b>, hộp thoại Hủy đơn hàng sẽ hiển thị như ảnh bên dưới:"
              image={[cancel2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Nhập lý do huỷ đơn hàng, sau đó nhấn nút <b>HỦY ĐƠN HÀNG</b>."
              image={[cancel3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>HỦY ĐƠN HÀNG</b>, trạng thái đơn hàng sẽ được cập nhật thành Đã hủy tại màn <b>Quản lý đơn hàng</b> và tại màn hình <b>Đơn hàng của tôi</b> phía người dùng."
              image={[cancel4, cancel5]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Không thể chỉnh sửa, in báo giá, gửi email xác nhận, hủy đơn hàng và xóa đơn hàng đã bị hủy.',
                  image: [cancel6],
                },
                {
                  title: 'Bạn có thể nhấn <b>GIỮ ĐƠN HÀNG</b> để huỷ thao tác huỷ đơn hàng.',
                  image: [cancel7],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Có ít nhất một đơn hàng được quản trị viên tạo trên hệ thống và có
              trạng thái là “Chưa thanh toán”.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn nút <b>Xóa</b> ở đơn hàng mà bạn cần xóa thông tin."
              image={[delete1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Xóa</b>, hộp thoại xác nhận <b>Xóa đơn hàng</b> sẽ hiển thị như ảnh bên dưới:"
              image={[delete2]}
            />
            <GuideUsingStep descreption="<b>Bước 2:</b> Nhấn nút <b>XÓA</b>." image={[delete3]} />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>XÓA</b>, đơn hàng sẽ bị xóa và không còn hiển thị trong danh sách."
              noWrapper
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Nếu đơn hàng vừa xóa thuộc người dùng đã đăng ký tài khoản → Đơn hàng không còn hiển thị ở màn <b>Đơn hàng của tôi</b> phía người dùng.',
                },
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác xóa đơn hàng.',
                  image: [delete4],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={7}>
          <OrderDetailUsing />
        </TabPanel>

        {imgPreview.open && (
          <Lightbox
            wrapperClassName={'ImageViewer-container'}
            reactModalStyle={{ overlay: { zIndex: 9999 } }}
            enableZoom={false}
            mainSrc={imgPreview.src}
            nextSrc={undefined}
            prevSrc={undefined}
            onMovePrevRequest={() => {}}
            onMoveNextRequest={() => {}}
            onCloseRequest={() => handleToggleImgPreview(false, imgPreview.src)}
            animationDuration={400}
          />
        )}
      </Card>
    </>
  )
}

export default orderUsing
