import React, { useEffect, useMemo } from 'react'

import { formatPrice, highlightKeyword } from 'utils'
import { ProductType, SuggestionsType } from 'types'
import { DEFAULT_SUGGESTIONS } from 'configs/constants'
// import { useCategories } from 'store/categories'
import { useHistory } from 'store/confirmRedirect'

import imgProductDefault from 'assets/images/imgProductTemp.png'

interface PropTypes {
  className?: string
  open?: boolean
  anchorEl?: any
  keyword?: string
  suggestions: SuggestionsType
  historySearch: string[]
  onClose?: () => void
  onRemoveHistory: (e: React.MouseEvent<HTMLElement>, keyword: string) => void
  onSearch: any
}

export default function AppSearchSuggestions(props: PropTypes) {
  const history = useHistory()
  const {
    className = '',
    open,
    anchorEl,
    keyword = '',
    suggestions = DEFAULT_SUGGESTIONS,
    historySearch,
    onClose = () => {},
    onRemoveHistory,
    onSearch,
  } = props
  // const { getCategoryById } = useCategories()

  // Get max 4 items product and 6 search key words
  const validSuggestions = useMemo(
    () => ({
      groups: suggestions.groups_recommend.slice(0, 5),
      products: suggestions.products_recommend.slice(0, 5),
    }),
    [suggestions]
  )

  const { groups, products } = validSuggestions

  const validHistorySearch = useMemo(() => historySearch.slice(0, 10), [historySearch])

  useEffect(() => {
    if (open) document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [open])

  /**
   * Handle document click outside suggestion
   *
   * @param e DOM Event
   */
  const handleClickOutside = (e: any) => {
    if (e.target?.className === 'mn-icon-close') return

    if (anchorEl && !anchorEl.contains(e.target)) {
      onClose()
    }
  }

  /**
   * Handle click product item
   */
  const handleClickProduct = (product: ProductType) => {
    history.push(`/san-pham/${product.link_seo}`)
    onClose()
  }

  /**
   * Handle click search item
   */
  const handleClickCategory = (item: { object_id: string; name: string }) => {
    // onSearch(item.name, getCategoryById(item.object_id)?.linkSeo)
    onSearch(item.name)
  }

  /**
   * Handle click history item
   */
  const handleClickHistoryItem = (item: string) => {
    onSearch(item)
  }

  if (!open) return null

  return (
    <div className={`AppSearchSuggestions-container ${open && 'open'} ${className}`}>
      {keyword ? (
        <>
          {groups.length > 0 && (
            <div>
              <div className="AppSearchSuggestions-header">Có phải bạn muốn tìm</div>
              {groups.map((item, index) => (
                <div
                  key={index}
                  className="AppSearchSuggestions-item"
                  onClick={() => handleClickCategory(item)}
                >
                  <div className="AppSearchSuggestions-history">
                    <div className="AppSearchSuggestions-history__left">
                      <span>{item.name}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {products.length > 0 && (
            <div>
              <div className="AppSearchSuggestions-header">Sản phẩm gợi ý</div>
              {products.map((item, index) => (
                <div
                  key={index}
                  className="AppSearchSuggestions-item"
                  onClick={() => handleClickProduct(item)}
                >
                  <div className="AppSearchSuggestions-item__left">
                    <span
                      className="AppSearchSuggestions-img"
                      style={{ backgroundImage: `url("${item.avatar_url || imgProductDefault}")` }}
                    />
                    <span
                      className="AppSearchSuggestions-text"
                      dangerouslySetInnerHTML={{
                        __html: highlightKeyword(item.name, keyword),
                      }}
                    />
                  </div>
                  <div className="AppSearchSuggestions-item__right">
                    {item.price !== item.final_price && (
                      <div className="old-price">{formatPrice(item.price || 0)}</div>
                    )}
                    <div className="new-price">{formatPrice(item.final_price || 0)}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        validHistorySearch.map((item) => (
          <div
            key={item}
            className="AppSearchSuggestions-item"
            onClick={() => handleClickHistoryItem(item)}
          >
            <div className="AppSearchSuggestions-history">
              <div className="AppSearchSuggestions-history__left">
                <i className="mn-icon-history" />
                <span>{item}</span>
              </div>
              <i className="mn-icon-close" onClick={(e) => onRemoveHistory(e, item)} />
            </div>
          </div>
        ))
      )}
    </div>
  )
}
