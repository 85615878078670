import { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { MenuItem, RootState } from 'types'

import { updateBreadcrumbs, updateBreadcrumbsRightMenu } from './actions'

export default function useBreadcrumbs() {
  const dispatch = useDispatch()
  const breadcrumbsReducer = useSelector(
    (state: RootState) => state.breadcrumbsReducer,
    shallowEqual
  )

  return useMemo(
    () => ({
      ...breadcrumbsReducer,
      showBreadcrumbs: (breadcrumbs: MenuItem[]) => dispatch(updateBreadcrumbs(breadcrumbs)),
      showBreadcrumbsRightMenu: (breadcrumbs: MenuItem[], rightMenu: any) =>
        dispatch(updateBreadcrumbsRightMenu(breadcrumbs, rightMenu)),
      hideBreadcrumbs: () => dispatch(updateBreadcrumbs()),
    }),
    [dispatch, breadcrumbsReducer]
  )
}
