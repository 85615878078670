import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Grow,
  Stack,
  Typography,
  Tooltip,
  ClickAwayListener,
} from '@mui/material'
import BankingForm from './BankingForm'
import { Button, Radio, Select, AntSwitch } from 'components'
import { Form, FormikProvider } from 'formik'
import FormVAT from './FormVAT'
import { AddressItem } from 'components/common'
import copy from 'copy-to-clipboard'
import { useEffect } from 'react'
import { useMobile } from 'utils'
// import { useEffect } from 'react'
// import { getPaymentLS } from 'utils'

interface Props {
  paymentInfor: any
  vat?: any
  handleNext?: any
  savedDataStep2?: any
  createVAT: any
  showDropDown?: any
  setShowDropDown?: (showDropDown: boolean) => any
  formik?: any
}

const FormPayment: React.FC<Props> = (props: Props) => {
  const { paymentInfor, vat, savedDataStep2, showDropDown, setShowDropDown, formik } = props

  const isMobile = useMobile()

  const {
    value,
    setValue,
    checked,
    setChecked,
    // copyCode,
    // setCode,
    copyContent,
    setCopyContent,
    bankTranfer,
    setBankTranfer,
    valueVat,
    setValueVat,
    chooseVat,
    // setChooseVat,
    content,
    orderCode,
    loadingPayment,
    // isAuth,
    handeClickVAT,
    setObjField,
    objField,
    finalPrice,
  } = savedDataStep2

  /**
   * create bank tranfer payment
   */
  const bankPayment: any = paymentInfor.filter((payment: any) => !payment.momo_phone)

  /**
   * create momo payment
   */
  const momoPayment: any = paymentInfor.filter((payment: any) => payment.momo_phone)

  // const momo = setBankTranfer(momoPayment[0])

  /**
   * handle switch button
   */
  const handleSwitch = () => {
    setChecked((prev: any) => !prev)
  }

  const { handleSubmit, isValid } = formik

  /**
   * handle Copy
   */
  const handleCopyContent = (e: any, content: string) => {
    e.stopPropagation()
    setCopyContent(true)
    copy(content)
    setTimeout(() => {
      setCopyContent(false)
    }, 1000)
  }

  /**
   *
   * @param arr
   * @returns
   */
  const renderForm = (arr: any) => {
    let form: any
    if (!arr.length) {
      form = (
        <div>
          <div className="FormPayment-select">
            <Select
              selected={valueVat}
              setSelected={(s) => setValueVat(s)}
              data={[{ label: 'Tạo mới', value: '0' }]}
              label="Thông tin hóa đơn đã có"
            />
          </div>

          {valueVat === '0' ? (
            <FormVAT formik={formik} setObjField={setObjField} objField={objField} />
          ) : (
            <div className="FormPayment-Bill">
              {vat.map((item: any) => {
                return (
                  <AddressItem
                    type={1}
                    key={item?.id}
                    is_default={item?.is_default}
                    companyName={item?.company_name}
                    onClick={() => handeClickVAT(item)}
                    checked={chooseVat.id === item.id}
                  >
                    <>
                      MST: {item?.tax_identification_number} <br />
                      Địa chỉ: {item?.company_address}
                      <br />
                      {item?.email ? `Email: ${item?.email}` : ''}
                    </>
                  </AddressItem>
                )
              })}
            </div>
          )}
        </div>
      )
    } else if (arr.length < 6) {
      form = (
        <div>
          <div className="FormPayment-select">
            <Select
              selected={valueVat}
              setSelected={(s) => setValueVat(s)}
              data={[
                { label: 'Các thông tin đã tạo', value: '1' },
                { label: 'Tạo mới', value: '0' },
              ]}
              label="Thông tin hóa đơn đã có"
            />
          </div>

          {valueVat === '0' ? (
            <FormVAT formik={formik} setObjField={setObjField} objField={objField} />
          ) : (
            <div className="FormPayment-Bill">
              {vat.map((item: any) => {
                return (
                  <AddressItem
                    type={1}
                    key={item?.id}
                    is_default={item?.is_default}
                    companyName={item?.company_name}
                    onClick={() => handeClickVAT(item)}
                    checked={chooseVat.id === item.id}
                  >
                    <>
                      MST: {item?.tax_identification_number} <br />
                      Địa chỉ: {item?.company_address}
                      <br />
                      {item?.email ? `Email: ${item?.email}` : ''}
                    </>
                  </AddressItem>
                )
              })}
            </div>
          )}
        </div>
      )
    } else if (arr.length >= 6) {
      form = (
        <div>
          <div className="FormPayment-select">
            <Select
              selected={valueVat}
              setSelected={(s) => setValueVat(s)}
              data={[{ label: 'Các thông tin đã tạo', value: '1' }]}
              label="Thông tin hóa đơn đã có"
            />
          </div>

          <div className="FormPayment-Bill">
            {vat.map((item: any) => {
              return (
                <AddressItem
                  type={1}
                  key={item?.id}
                  is_default={item?.is_default}
                  companyName={item?.company_name}
                  onClick={() => handeClickVAT(item)}
                  checked={chooseVat.id === item.id}
                >
                  <>
                    MST: {item?.tax_identification_number} <br />
                    Địa chỉ: {item?.company_address}
                    {item?.email ? `Email: ${item?.email}` : ''}
                  </>
                </AddressItem>
              )
            })}
          </div>
        </div>
      )
    }
    return form
  }

  useEffect(() => {
    if (value === 'banking') setBankTranfer(bankPayment[0])
  }, [])

  /**
   * handle change value
   * @param event
   */
  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value
    setValue(value)
    if (value === 'banking') setBankTranfer(bankPayment[0])
  }

  const handleChangeBank = (id: string) => {
    const index = bankPayment.findIndex((item: any) => item.id === id)
    setBankTranfer(bankPayment[index])
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <div className="FormPayment">
          <div className="FormWrapper">
            <div className="FormPayment-title">Phương thức thanh toán</div>
            <FormControl component="fieldset" className="FormPayment-selectMethodPayment">
              <RadioGroup
                aria-label="payment"
                name="customized-radios"
                value={value}
                onChange={handleChangeValue}
                className="FormPayment-radio"
              >
                <div className="FormPayment-FormControlLabelWrapper">
                  <FormControlLabel
                    value="banking"
                    control={<Radio />}
                    label="Chuyển khoản qua ngân hàng"
                    className="FormPayment-radio"
                  />
                  {value === 'banking' && (
                    <Grow
                      in={value === 'banking'}
                      style={{ transformOrigin: '0 0 0' }}
                      {...(checked ? { timeout: 1000 } : {})}
                    >
                      <div className="FormPayment-subForm">
                        <BankingForm
                          dropdown
                          showDropDown={showDropDown}
                          setShowDropDown={setShowDropDown}
                          bankList={bankPayment}
                          bankTranfer={bankTranfer}
                          handleChangeBank={handleChangeBank}
                          hideQR={isMobile}
                          content={content}
                          finalPrice={finalPrice}
                        />
                      </div>
                    </Grow>
                  )}
                </div>
                <div className="FormPayment-FormControlLabelWrapper">
                  <FormControlLabel
                    value="momo"
                    control={<Radio />}
                    label="Sử dụng ví Momo"
                    onClick={() => setBankTranfer(momoPayment[0])}
                  />
                  {value === 'momo' && (
                    <Grow
                      in={value === 'momo'}
                      style={{ transformOrigin: '0 0 0' }}
                      {...(checked ? { timeout: 1000 } : {})}
                    >
                      <div className="FormPayment-subForm">
                        <BankingForm bankTranfer={momoPayment[0]} hideQR />
                      </div>
                    </Grow>
                  )}
                </div>
                {value !== 'onDelivery' && (
                  <>
                    {' '}
                    <div className="FormPayment-title bold">Nội dung thanh toán</div>
                    <div className="BankingForm-contentWrapper">
                      <div className="BankingForm-contentLabel label bold">
                        Nội dung chuyển khoản
                      </div>
                      <div className="BankingForm-content label">{content}</div>
                      <div className="BankingForm-attention">
                        <div className="BankingForm-attentionBorder" />
                        <div className="BankingForm-attentionContent">
                          Vui lòng nhập chính xác nội dung chuyển khoản khi thanh toán
                        </div>
                      </div>
                      <div
                        className="BankingForm-copy"
                        onClick={(e: any) => handleCopyContent(e, `${content}`)}
                      >
                        <ClickAwayListener onClickAway={() => setCopyContent(false)}>
                          <div className="BankingForm-widthCopy">
                            <i className="mn-icon-copy" />

                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={() => setCopyContent(false)}
                              open={copyContent}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title="Sao chép"
                            >
                              <span className="label">Sao chép nội dung chuyển khoản</span>
                            </Tooltip>
                          </div>
                        </ClickAwayListener>
                      </div>
                    </div>
                    <div className="BankingForm-codeWrapper">
                      <div className="BankingForm-codeContent label">
                        Mã đơn hàng: <span className="label bold">{orderCode}</span>
                      </div>
                    </div>{' '}
                  </>
                )}
                <div className="FormPayment-FormControlLabelWrapper">
                  <FormControlLabel
                    value="onDelivery"
                    control={<Radio />}
                    label="Thanh toán khi nhận hàng"
                    // onClick={() => setBankTranfer(momoPayment[0])}
                  />
                </div>
              </RadioGroup>
            </FormControl>

            <div className="FormPayment-switch">
              <Stack direction="row" spacing={1} alignItems="center">
                <AntSwitch checked={checked} onChange={handleSwitch} />
                <Typography className={`label ${checked ? 'checked' : ''}`}>
                  Viết hoá đơn đỏ
                </Typography>
              </Stack>

              <Grow
                in={checked}
                style={{ transformOrigin: '0 0 0' }}
                {...(checked ? { timeout: 1000 } : {})}
              >
                {checked ? <div className="PaymentLayout-render">{renderForm(vat)}</div> : <div />}
              </Grow>
            </div>
          </div>

          <div className="PaymentLayout-next">
            <Button
              type="submit"
              fullWidth
              icon="arrow"
              small
              loading={loadingPayment}
              disabled={!value || (checked ? (valueVat === '1' ? !chooseVat.id : !isValid) : false)}
            >
              Tiếp tục
            </Button>
          </div>
        </div>
      </Form>
    </FormikProvider>
  )
}

export default FormPayment
