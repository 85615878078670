/* eslint-disable */
import { SelectType } from 'types'

export const ADMIN_ORDER_STATUSES = {
  ALL_STATUS: 0,
  UNPAID: 1,
  MONEY_TRANSFERRED: 2,
  RECEIVED_MONEY: 3,
  PACKING: 4,
  DELIVERING: 5,
  SUCCESSFUL_DELIVERY: 6,
  CANCELED: 7,
}

const {
  ALL_STATUS,
  UNPAID,
  MONEY_TRANSFERRED,
  RECEIVED_MONEY,
  PACKING,
  DELIVERING,
  SUCCESSFUL_DELIVERY,
  CANCELED,
} = ADMIN_ORDER_STATUSES

export const mappingAdminOrderStatus: any = {
  [ALL_STATUS]: 'Tất cả đơn hàng',
  [UNPAID]: 'Chưa thanh toán',
  [MONEY_TRANSFERRED]: 'Đã chuyển tiền',
  [RECEIVED_MONEY]: 'Đã nhận tiền',
  [PACKING]: 'Đang đóng gói',
  [DELIVERING]: 'Đang giao',
  [SUCCESSFUL_DELIVERY]: 'Đã giao',
  [CANCELED]: 'Đã hủy',
}

export const mappingAdminOrderStatusColor: any = {
  [UNPAID]: { background: '#ff2744f0', text: '#fff', border: '#ff2744f0' },
  [MONEY_TRANSFERRED]: { background: '#2196f3', text: '#fff', border: '#2196f3' },
  [RECEIVED_MONEY]: { background: '#5569ff', text: '#fff', border: '#5569ff' },
  [PACKING]: { background: '#ffa319', text: '#fff', border: '#ffa319' },
  [DELIVERING]: { background: '#9c27b0', text: '#fff', border: '#9c27b0' },
  [SUCCESSFUL_DELIVERY]: { background: '#57ca22dd', text: '#fff', border: '#57ca22' },
  [CANCELED]: { background: '#a1887f', text: '#fff', border: '#a1887f' },
}

// Status màn quản lí đơn hàng khi phương thức thanh toán
// là chuyển khoản.
export const mappingAdminOrderChange: any = {
  [UNPAID]: [MONEY_TRANSFERRED, RECEIVED_MONEY],
  [MONEY_TRANSFERRED]: [RECEIVED_MONEY, UNPAID],
  [RECEIVED_MONEY]: [PACKING, UNPAID],
  [PACKING]: [],
  [DELIVERING]: [],
  [SUCCESSFUL_DELIVERY]: [],
  [CANCELED]: [],
}

// Status màn quản lí đơn hàng khi phương thức thanh toán
// là khi nhận hàng.
export const mappingOrderChangeStatusPaymentDelivery: any = {
  [UNPAID]: [PACKING],
  [PACKING]: [],
  [DELIVERING]: [],
  [SUCCESSFUL_DELIVERY]: [],
  [CANCELED]: [],
}

// Status màn chi tiết đơn hàng khi phương thức thanh toán
// là chuyển khoản và giao hàng tận nơi.
export const mappingAdminOrderChangeTransfer: any = {
  [UNPAID]: [MONEY_TRANSFERRED, RECEIVED_MONEY],
  [MONEY_TRANSFERRED]: [RECEIVED_MONEY, UNPAID],
  [RECEIVED_MONEY]: [PACKING, UNPAID],
  [PACKING]: [DELIVERING],
  [DELIVERING]: [SUCCESSFUL_DELIVERY],
  [SUCCESSFUL_DELIVERY]: [],
  [CANCELED]: [],
}
// Status màn chi tiết đơn hàng khi phương thức thanh toán
// là chuyển khoản và lấy hàng tại cửa hàng.
export const mappingAdminOrderChangeTransfer2: any = {
  [UNPAID]: [MONEY_TRANSFERRED, RECEIVED_MONEY, SUCCESSFUL_DELIVERY],
  [MONEY_TRANSFERRED]: [RECEIVED_MONEY, UNPAID, SUCCESSFUL_DELIVERY],
  [RECEIVED_MONEY]: [PACKING, UNPAID, SUCCESSFUL_DELIVERY],
  [PACKING]: [SUCCESSFUL_DELIVERY],
  [DELIVERING]: [SUCCESSFUL_DELIVERY],
  [SUCCESSFUL_DELIVERY]: [],
  [CANCELED]: [],
}

// Status màn chi tiết đơn hàng khi phương thức thanh toán
// là khi nhận hàng và giao hàng tận nơi.
export const mappingAdminOrderChangePaymentDelivery: any = {
  [UNPAID]: [PACKING],
  [PACKING]: [DELIVERING],
  [DELIVERING]: [SUCCESSFUL_DELIVERY],
  [SUCCESSFUL_DELIVERY]: [],
  [CANCELED]: [],
}
// Status màn chi tiết đơn hàng khi phương thức thanh toán
// là khi nhận hàng và lấy hàng tại cửa hàng.
export const mappingAdminOrderChangePaymentDelivery2: any = {
  [UNPAID]: [PACKING, SUCCESSFUL_DELIVERY],
  [PACKING]: [SUCCESSFUL_DELIVERY],
  [SUCCESSFUL_DELIVERY]: [],
  [CANCELED]: [],
}

export const listAdminOrderStatuses: SelectType[] = Object.keys(mappingAdminOrderStatus).map(
  (key) => ({ value: key, label: mappingAdminOrderStatus[key] })
)

export const defaultModalCancel = { show: false, id: '' }
export const defaultModalUpdate: {
  show: boolean
  id: string
  type?: string
  updateShipper?: boolean
  initialValues?: any
} = { show: false, id: '', type: 'create', updateShipper: true }
export const defaultModalSendEmail = { show: false, id: '', email: '', attached_file: '', type: '' }
export const defaultModalDeliveryNote = { show: false, id: '', phone: '', fullName: '' }

export const defaultModalChangStatusSucsessfull: {
  show: boolean
  id: string
  type?: string
  initialValues?: any
} = { show: false, id: '', type: 'create' }

export const listPaymentMethod = [
  {
    value: 1,
    label: 'Chuyển khoản qua ngân hàng',
  },

  {
    value: 3,
    label: 'Sử dụng ví Momo',
  },
  {
    value: 2,
    label: 'Thanh toán khi nhận hàng',
  },
]

export const listCreatedUser = [
  {
    value: 'all',
    label: 'Người tạo',
  },

  {
    value: 2,
    label: 'Người dùng',
  },
  {
    value: 1,
    label: 'Quản trị viên',
  },
]

export const defaultInitialValues = {
  order_delivery_address: {
    full_name: '',
    phone: '',
    address: '',
    type: '',
    city: '' as any,
    town: '',
    district: '' as any,
    note: '',
  },
  order_store_address: {
    id: '',
    address: '',
    phone: '',
    store_name: '',
    store_address: { id: '' },
  },
  create_status: 2,
  delivery_method: 2,
  order_products: [],
  payment_method: 1,
  order_payment_info: {
    bank_name: '',
    account_name: '',
    bank_number: '',
    payment_content: '',
    momo_phone: '',
  },
  order_vat_invoice: {
    company_address: '',
    company_name: '',
    tax_identification_number: '',
    email: '',
  },
  has_invoice: false,
  city: [] as any,
  district: [] as any,
  town: [] as any,
  discount_code: '',
}

export const QuotationDefaultValues = {
  customer_code: '',
  number_of_quotes: '',
  adminPhone: '',
  full_name: '',
  model: '',
  products_json: [],
}
