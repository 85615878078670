import { Method } from 'axios'
import React from 'react'
import {
  AdminGroupType,
  AdminPermissionType,
  ErrorCallbackType,
  FilterCategoryType,
  FilterManufacturerType,
  ObjectType,
  SuccessCallbackType,
} from 'types'
export { default as defaultProductThumbnail } from 'assets/images/imgProductTemp.png'

export const {
  REACT_APP_PHONE_NUMBER: PHONE_NUMBER = '0813131309',
  REACT_APP_SHOW_IMPORT_PRODUCTS: SHOW_IMPORT_PRODUCTS = '1',
} = process.env

// LANGUAGES
export const LS_LANG = 'LANG'
export const LANGUAGES = {
  EN: 'en',
  VI: 'vi',
}

export const LS_HISTORY_SEARCH = 'HISTORY_SEARCH'
export const LS_ADVERTISEMENT = 'LS_ADVERTISEMENT'
export const LS_SIGN_IN_PASSWORD = 'LS_SIGN_IN_PASSWORD'
export const LS_PAYMENT = 'LS_PAYMENT'

export const LS_SHOWN_COLUMNS = 'shownColumns'

export const FORMAT_DATE = 'DD/MM/YYYY'

export const BRAND_MENU = [
  { name: 'Caterpillar', icon: 'caterpillar', path: '/', height: 26 },
  { name: 'FP Diesel', icon: 'fpDiesel', path: '/', height: 40 },
  { name: 'Komatsu', icon: 'komatsu', path: '/', height: 28 },
  { name: 'John Deere', icon: 'johnDeere', path: '/', height: 31 },
  { name: 'Cummins', icon: 'cummins', path: '/', height: 50 },
  { name: 'Detroit', icon: 'detroit', path: '/', height: 30 },
]

export const INFO_MENU = [
  { name: 'commonQuestions', path: '/cau-hoi-thuong-gap' },
  { name: 'technicalIssues', path: '/goc-ky-thuat' },
  { name: 'newsAndEvents', path: '/tin-tuc-su-kien' },
  { name: 'aboutUs', path: '/ve-chung-toi' },
]

export const ENGINE_PARTS_MENU = [
  { name: 'boGioang', path: '/' },
  { name: 'boHoi', path: '/' },
  { name: 'bacTrucCo', path: '/' },
  { name: 'bacBienBacCam', path: '/' },
  { name: 'matMay', path: '/' },
  { name: 'otherParts', path: '/' },
]

export const FILTERS_MENU = [
  { name: 'phinLocGio', path: '/' },
  { name: 'phinLocDau', path: '/' },
  { name: 'phinLocNhienLieu', path: '/' },
  { name: 'phinLocTachNuoc', path: '/' },
]

export const FUEL_SYSTEM_MENU = [
  { name: 'kimPhun', path: '/' },
  { name: 'bomCaoAp', path: '/' },
  { name: 'bomChuyenTiep', path: '/' },
]

export const MAIN_MENU = [
  { name: 'engineParts', path: '/', subMenu: ENGINE_PARTS_MENU },
  { name: 'filters', path: '/', subMenu: FILTERS_MENU },
  { name: 'fuelSystem', path: '/', subMenu: FUEL_SYSTEM_MENU },
  { name: 'turbochargers', path: '/' },
  { name: 'manufacturer', path: '/', subMenu: BRAND_MENU },
]
export const DEFAULT_AVATAR = 'https://forum.waka.vn/assets/avatars/default.svg'
export const DEFAULT_PAGE_SIZE = 15
export const MAX_PAGE_SIZE = 256
export const BASIC_PAGE_SIZE = 24

export const AUTH_TYPES = {
  SIGN_UP: 'SIGN_UP',
  SIGN_IN: 'SIGN_IN',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
}

export const AUTH_SCREENS = {
  ...AUTH_TYPES,
  ENTER_OTP: 'ENTER_OTP',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  DONE_PASSWORD: 'DONE_PASSWORD',
  ENTER_EMAIL: 'ENTER_EMAIL',
}

export const OTP_NUMBER_FIELDS = 6
export const PRODUCT_TAGS = {
  hot: 'bestSell',
  sale: 'sale',
  new: 'new',
}

export const SUCCESS = 'success'
export const ERROR = 'error'
export const MAX_QUANTITY = 9999
export const CART_TYPES = {
  ON_BOARD: 1,
  BUY_LATER: 2,
}
export const LS_SHOPPING_CART = 'LS_SHOPPING_CART'

export const ADMIN_AUTH_ROUTES = {
  SIGN_IN: 'SIGN_IN',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
}

export const USER_INFORMATION = [
  {
    id: 1,
    label: 'Thông tin cá nhân',
    path: '/my-info',
  },
  {
    id: 2,
    label: 'Địa chỉ nhận hàng',
    path: '/my-addresses',
  },
  {
    id: 3,
    label: 'Thông tin hóa đơn đỏ',
    path: '/my-vats',
  },
]

export const ORDER_STATUS: any = {
  1: 'Chờ thanh toán',
  2: 'Chờ lấy hàng',
  3: 'Đang giao',
  4: 'Đã giao',
  5: 'Đã huỷ',
}

const arrayStatus = Object.keys(ORDER_STATUS).map((key) => {
  return { id: Number(key), value: ORDER_STATUS[key] }
})

export const TABS = [{ id: 0, value: 'Tất cả các đơn' }, ...arrayStatus]

export const ProductsContext = React.createContext<{
  filter: {
    categories: FilterCategoryType[]
    manufacturers: FilterManufacturerType[]
    loading?: boolean
    filtered?: boolean
  }
  products: {
    total: number
    total_pages: number
    items: any[]
    page: number
    loading?: boolean
  }
  selected: any[]
  onFilter: (params: any, reloadFilter?: boolean, applyFilter?: boolean, otherParams?: any) => void
  category: ObjectType
  keyword: string
  /* eslint indent: 0 */
}>({
  filter: { categories: [], manufacturers: [] },
  products: { total: 0, total_pages: 0, items: [], page: 1 },
  selected: [],
  onFilter: () => {},
  category: {},
  keyword: '',
})

export const SORT_BY = {
  TIME: 'time',
  SALE: 'sales',
  PRICE: 'price',
}

export const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
}

const { TIME, SALE, PRICE } = SORT_BY
const { ASC, DESC } = SORT_ORDER

export const SORT_LIST = [
  { label: 'Mới nhất', value: { sort_by: TIME, order: DESC } },
  { label: 'Giá từ thấp đến cao', value: { sort_by: PRICE, order: ASC } },
  { label: 'Giá từ cao đến thấp', value: { sort_by: PRICE, order: DESC } },
  { label: 'Bán chạy nhất', value: { sort_by: SALE, order: DESC } },
]
// REGULAR EXPRESSION
export const REGEX_NUMBER = /^\d+$/

export const DELIVERY_METHODS = {
  NONE: null,
  GET_AT_STORE: 2,
  GET_AT_ADDRESS: 1,
}

const { GET_AT_STORE, GET_AT_ADDRESS } = DELIVERY_METHODS

export const mappingDeliveryMethod: any = {
  [GET_AT_STORE]: 'getAtStore',
  [GET_AT_ADDRESS]: 'getAtAddress',
}

export const PAYMENT_METHODS = {
  NONE: null,
  TRANSFER: 1,
  PAYMENT_ON_DELIVERY: 2,
  USE_MOMO: 3,
}

const { TRANSFER, PAYMENT_ON_DELIVERY, USE_MOMO } = PAYMENT_METHODS

export const mappingPaymentMethod: any = {
  [TRANSFER]: 'Chuyển khoản qua ngân hàng',
  [USE_MOMO]: 'Sử dụng ví Momo',
  [PAYMENT_ON_DELIVERY]: 'Thanh toán khi nhận hàng',
}

export const ADDRESS_TYPES = {
  HOME: 1,
  OFFICE: 2,
  OTHER: 3,
}

const { HOME, OFFICE, OTHER } = ADDRESS_TYPES

export const mappingAddressType = {
  [HOME]: 'Nhà riêng',
  [OFFICE]: 'Cơ quan',
  [OTHER]: 'Khác',
}

export const CREATE_ADDRESS_TYPES = {
  NEW_ADDRESS: 1,
  AVAILABLE_ADDRESS: 2,
}

const { NEW_ADDRESS, AVAILABLE_ADDRESS } = CREATE_ADDRESS_TYPES

export const createAddressTypesList = [
  { label: 'Địa chỉ đã tạo', value: AVAILABLE_ADDRESS },
  { label: 'Tạo mới', value: NEW_ADDRESS },
]

export const addressTypesList = Object.keys(mappingAddressType).map((item: any) => ({
  label: mappingAddressType[item],
  value: Number(item),
}))

export const DEFAULT_SUGGESTIONS = {
  groups_recommend: [],
  products_recommend: [],
}

export const MAX_RELATIVE_ITEMS = 6

export const CATEGORY_TYPES = {
  CATEGORY: 1,
  MANUFACTURER: 2,
  MODEL: 3,
}

export const CANCEL_BY: any = {
  1: 'Người bán',
  2: 'Người mua',
}

export const REASON_CANCEL: any = {
  0: 'Chưa sử dụng sản phẩm bao giờ',
  1: 'Giá sản phẩm đắt',
  2: 'Dịch vụ chăm sóc khách hàng không tốt',
  3: 'Thời gian giao hàng chậm',
  4: 'Sản phẩm không đầy đủ',
}

export const AdminContext = React.createContext<{
  loading: boolean
  startLoading: () => void
  stopLoading: () => void
  callApi: (params: {
    method: Method
    url: string
    data?: any
    successCallback?: SuccessCallbackType
    errorCallback?: ErrorCallbackType
    loading?: boolean
    popup?: (() => void) | boolean
    popupIds?: { id: string; confirm: () => void; small?: boolean }[]
    forceShowToast?: boolean
    sure?: {
      cancel?: {
        text?: string
        action?: any
      }
      confirm?: {
        text?: string
        action?: any
      }
    }
    contentType?: any
  }) => void
  groups: AdminGroupType[]
  getGroups: () => void
  mappingGroup: { [key: string]: AdminGroupType }
  roles: AdminPermissionType[]
  mappingRole: { [key: string]: AdminPermissionType }
  myRoles: AdminPermissionType[]
  hasPermission: (role: string) => boolean
}>({
  loading: false,
  startLoading: () => {},
  stopLoading: () => {},
  callApi: () => {},
  groups: [],
  getGroups: () => {},
  mappingGroup: {},
  roles: [],
  mappingRole: {},
  myRoles: [],
  hasPermission: () => false,
})

export const activityFields = [
  'Thợ sửa chữa',
  'Đại lý bán hàng',
  'Chủ thiết bị',
  'Quản lý thiết bị',
  'Khác',
].map((item) => ({
  value: item,
  label: item,
}))

export const today = new Date()
export const dayAfterAYear = new Date('12/31/2021')
dayAfterAYear.setFullYear(today.getFullYear() + 1)
export const minDate1900 = new Date('01/01/1900')

export const aMonthAgo = new Date()
aMonthAgo.setMonth(aMonthAgo.getMonth() - 1)

export const aYearAgo = new Date()
aYearAgo.setFullYear(aYearAgo.getFullYear() - 1)

export const defaultMinDate = new Date('01/01/2021')

export const MAX_ATTACHMENT_SIZE = 52428800
export const MAX_ATTACHMENT_FILES = 10
export const MAX_FILENAME_LENGTH = 50

export const GENDERS = {
  BOTH: undefined,
  MALE: true,
  FEMALE: false,
}

export const listGenders = [
  { value: undefined, label: 'Giới tính' },
  { value: 1, label: 'Nam' },
  { value: 0, label: 'Nữ' },
  { value: -1, label: 'Không xác định' },
]

export const ADMIN_ALL_ROLES = {
  SUPER_ADMIN: 'super_admin',
  VIEW_DASHBOARD: 'xem-thong-ke',
  VIEW_ORDERS: 'xem-don-hang',
  MANAGE_ORDERS: 'quan-ly-don-hang',
  VIEW_CUSTOMERS: 'xem-nguoi-dung',
  MANAGE_CUSTOMERS: 'quan-ly-nguoi-dung',
  VIEW_PRODUCTS: 'xem-san-pham',
  MANAGE_PRODUCTS: 'quan-ly-san-pham',
  VIEW_CATEGORIES: 'xem-danh-muc-san-pham',
  MANAGE_CATEGORIES: 'quan-ly-danh-muc-san-pham',
  VIEW_MANUFACTURERS: 'xem-hang-san-xuat-va-model',
  MANAGE_MANUFACTURERS: 'quan-ly-hang-san-xuat-va-model',
  VIEW_ARTICLES: 'xem-bai-viet',
  MANAGE_ARTICLES: 'quan-ly-bai-viet',
  VIEW_DOCUMENT_CATEGORIES: 'xem-danh-muc-tai-lieu',
  MANAGE_DOCUMENT_CATEGORIES: 'quan-ly-danh-muc-tai-lieu',
  VIEW_DOCUMENTS: 'xem-tai-lieu-ky-thuat',
  MANAGE_DOCUMENTS: 'quan-ly-tai-lieu-ky-thuat',
  VIEW_QUESTIONS: 'xem-cau-hoi',
  MANAGE_QUESTIONS: 'quan-ly-cau-hoi',
  VIEW_ADMINS: 'xem-quan-tri-vien',
  MANAGE_ADMINS: 'quan-ly-quan-tri-vien',
  VIEW_GROUPS: 'xem-nhom-quyen',
  MANAGE_GROUPS: 'quan-ly-nhom-quyen',
  VIEW_ROLES: 'xem-quyen',
  VIEW_COUPONS: 'xem-ma-giam-gia',
  MANAGE_COUPONS: 'quan-ly-ma-giam-gia',
  VIEW_BRANCHS: 'xem-chi-nhanh',
  MANAGE_BRANCHS: 'quan-ly-chi-nhanh',
  MANAGE_PROFILE: 'quan-ly-profile',
  MANAGE_ABOUT_US: 'quan-ly-noi-dung-ve-chung-toi',
  MANAGE_VISION: 'quan-ly-tam-nhin-va-su-menh',
  MANAGE_INTRODUCE: 'quan-ly-thong-tin-gioi-thieu',
}

const {
  SUPER_ADMIN,
  VIEW_DASHBOARD,
  VIEW_ORDERS,
  MANAGE_ORDERS,
  VIEW_CUSTOMERS,
  VIEW_PRODUCTS,
  MANAGE_PRODUCTS,
  VIEW_CATEGORIES,
  MANAGE_CATEGORIES,
  VIEW_MANUFACTURERS,
  MANAGE_MANUFACTURERS,
  VIEW_ARTICLES,
  MANAGE_ARTICLES,
  VIEW_DOCUMENTS,
  MANAGE_DOCUMENTS,
  VIEW_DOCUMENT_CATEGORIES,
  MANAGE_DOCUMENT_CATEGORIES,
  VIEW_QUESTIONS,
  MANAGE_QUESTIONS,
  VIEW_ADMINS,
  MANAGE_ADMINS,
  VIEW_GROUPS,
  MANAGE_GROUPS,
  VIEW_ROLES,
  VIEW_COUPONS,
  MANAGE_COUPONS,
  MANAGE_BRANCHS,
  VIEW_BRANCHS,
  MANAGE_PROFILE,
  MANAGE_CUSTOMERS,
  MANAGE_ABOUT_US,
  MANAGE_VISION,
  MANAGE_INTRODUCE,
} = ADMIN_ALL_ROLES

// Check first permission admin has, and redirect to corresponding location
export const priorityRoutes = [
  { permission: SUPER_ADMIN, path: 'dashboard' },
  { permission: VIEW_DASHBOARD, path: 'dashboard' },
  { permission: VIEW_ORDERS, path: 'orders' },
  { permission: MANAGE_ORDERS, path: 'orders' },
  { permission: VIEW_CUSTOMERS, path: 'users' },
  { permission: MANAGE_CUSTOMERS, path: 'users' },
  { permission: VIEW_PRODUCTS, path: 'products' },
  { permission: MANAGE_PRODUCTS, path: 'products' },
  { permission: VIEW_CATEGORIES, path: 'categories' },
  { permission: MANAGE_CATEGORIES, path: 'categories' },
  { permission: VIEW_MANUFACTURERS, path: 'manufacturers' },
  { permission: MANAGE_MANUFACTURERS, path: 'manufacturers' },
  { permission: VIEW_ARTICLES, path: 'news' },
  { permission: MANAGE_ARTICLES, path: 'news' },
  { permission: VIEW_DOCUMENT_CATEGORIES, path: 'document-categories' },
  { permission: MANAGE_DOCUMENT_CATEGORIES, path: 'document-categories' },
  { permission: VIEW_DOCUMENTS, path: 'technical-documents' },
  { permission: MANAGE_DOCUMENTS, path: 'technical-documents' },
  { permission: VIEW_QUESTIONS, path: 'questions' },
  { permission: MANAGE_QUESTIONS, path: 'questions' },
  { permission: VIEW_ADMINS, path: 'admins' },
  { permission: MANAGE_ADMINS, path: 'admins' },
  { permission: VIEW_GROUPS, path: 'groups' },
  { permission: MANAGE_GROUPS, path: 'groups' },
  { permission: VIEW_ROLES, path: 'permissions' },
  { permission: VIEW_COUPONS, path: 'coupons' },
  { permission: MANAGE_COUPONS, path: 'coupons' },
  { permission: MANAGE_BRANCHS, path: 'branchs' },
  { permission: VIEW_BRANCHS, path: 'branchs' },
  { permission: MANAGE_PROFILE, path: 'profile' },
  { permission: MANAGE_ABOUT_US, path: 'about-us' },
  { permission: MANAGE_VISION, path: 'vision' },
  { permission: MANAGE_INTRODUCE, path: 'introduce' },
]

export const ROUTE_PERMISSIONS = {
  DASHBOARD: [VIEW_DASHBOARD],
  ORDERS: [VIEW_ORDERS, MANAGE_ORDERS],
  CUSTOMERS: [VIEW_CUSTOMERS, MANAGE_CUSTOMERS],
  PRODUCTS: [VIEW_PRODUCTS, MANAGE_PRODUCTS],
  CATEGORIES: [VIEW_CATEGORIES, MANAGE_CATEGORIES],
  MANUFACTURERS: [VIEW_MANUFACTURERS, MANAGE_MANUFACTURERS],
  ARTICLES: [VIEW_ARTICLES, MANAGE_ARTICLES],
  DOCUMENTS_CATEGORIES: [VIEW_DOCUMENT_CATEGORIES, MANAGE_DOCUMENT_CATEGORIES],
  DOCUMENTS: [VIEW_DOCUMENTS, MANAGE_DOCUMENTS],
  QUESTIONS: [VIEW_QUESTIONS, MANAGE_QUESTIONS],
  ADMINS: [VIEW_ADMINS, MANAGE_ADMINS],
  GROUPS: [VIEW_GROUPS, MANAGE_GROUPS],
  ROLES: [VIEW_ROLES],
  COUPONS: [VIEW_COUPONS, MANAGE_COUPONS],
  BRANCHS: [VIEW_BRANCHS, MANAGE_BRANCHS],
  ABOUT_US: [MANAGE_ABOUT_US],
  VISION: [MANAGE_VISION],
  INTRODUCE: [MANAGE_INTRODUCE],
}

export const SHOW_COLUMNS_DEFAULT = {
  product: [
    'number',
    'code',
    'original_code',
    'name',
    'manufacturer_name',
    'category_name',
    'price',
    'discount',
    'final_price',
    'time_discount',
    'inventory_number',
    'is_active',
    'is_top',
    'action',
  ],
  orders: [
    'number',
    'order_code',
    'customer_name',
    'phone',
    'final_total_price',
    'delivery_method',
    'payment_method',
    'ordered_date',
    'expected_delivery',
    'delivered_date',
    'order_status',
    'action',
  ],
}
