/*eslint-disable*/
import React, { useState } from 'react'
import { Box, Card, Tab, Tabs } from '@mui/material'
import GuideUsingStep from 'components/admin/guideUsingStep/GuideUsingStep'
import { renderBackTitle } from 'configs/extensions'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'store/confirmRedirect'

import step1 from '../../../../assets/images/DashboardUsing/step1.png'
import step2 from '../../../../assets/images/DashboardUsing/step2.png'
import step3 from '../../../../assets/images/DashboardUsing/step3.png'
import step4 from '../../../../assets/images/DashboardUsing/step4.png'
import step5 from '../../../../assets/images/DashboardUsing/step5.png'
import step6 from '../../../../assets/images/DashboardUsing/step6.png'
import step7 from '../../../../assets/images/DashboardUsing/step7.png'

import video1 from '../../../../assets/images/DashboardUsing/video-13.mp4'
import video2 from '../../../../assets/images/DashboardUsing/video-14.mp4'
import video3 from '../../../../assets/images/DashboardUsing/video-15.mp4'
import video4 from '../../../../assets/images/DashboardUsing/video-16.mp4'
import video5 from '../../../../assets/images/DashboardUsing/video-17.mp4'
import video6 from '../../../../assets/images/DashboardUsing/video-18.mp4'
import video7 from '../../../../assets/images/DashboardUsing/video-19.mp4'
import video8 from '../../../../assets/images/DashboardUsing/video-20.mp4'
import video9 from '../../../../assets/images/DashboardUsing/video-21.mp4'
import video10 from '../../../../assets/images/DashboardUsing/video-22.mp4'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const DashboardUsing = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <>
      {renderBackTitle(history, t('Thống kê - Hướng dẫn sử dụng'))}
      <Card sx={{ p: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="Đồ thị doanh thu" {...a11yProps(0)} />
            <Tab label="Top 10 sản phẩm bán chạy" {...a11yProps(1)} />
            <Tab label="Phân loại người dùng" {...a11yProps(2)} />
            <Tab label="Lý do hủy đơn hàng" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Có ít nhất một đơn hàng ở trạng thái Đã giao ở màn Quản lý đơn hàng
              trong khoảng thời gian được chọn.
            </p>
            <GuideUsingStep
              descreption="<b>Đồ thị doanh thu</b> thể hiện biến động doanh thu và số lượng đơn hàng ở trạng thái <b>Đã giao</b> trong khoảng thời gian nhất định. Bạn có thể xem biểu đồ theo ngày, tháng hoặc năm."
              noWrapper
            />
            <GuideUsingStep
              descreption="Khi truy cập vào trang <b>Thống kê</b>, biểu đồ <b>Đồ thị doanh thu</b> sẽ hiển thị mặc định theo ngày và hiển thị dữ liệu trong 1 tháng gần nhất cho đến ngày hiện tại."
              image={[step1]}
            />
            <GuideUsingStep
              descreption="<b>Xem biểu đồ</b>"
              orther={
                <Box margin={'20px'}>
                  <GuideUsingStep
                    descreption="Xem biểu đồ theo ngày"
                    orther={
                      <Box margin={'20px'}>
                        <GuideUsingStep
                          descreption="<b>Bước 1:</b> Nhấn vào lựa chọn <b>Ngày</b>."
                          image={[step2]}
                        />
                        <GuideUsingStep
                          descreption="<b>Bước 2:</b> Nhấn vào trường thời gian <b>Từ ngày</b> hoặc <b>Đến ngày</b> sẽ hiển thị hộp thoại cho phép chọn ngày muốn xem dữ liệu biểu đồ:"
                          image={[step3]}
                        />
                        <GuideUsingStep
                          descreption="Sau khi chọn xong ngày ở trường thời gian <b>Từ ngày</b> hoặc <b>Đến ngày</b>, dữ liệu ở biểu đồ sẽ được cập nhật tương ứng theo thời gian đã chọn:"
                          orther={
                            <div
                              className="d-f ai-c jc-c"
                              style={{ marginTop: '8px', flexDirection: 'column' }}
                            >
                              <video width={'60%'} controls>
                                <source src={video1} type="video/mp4" />
                              </video>
                            </div>
                          }
                        />
                        <GuideUsingStep
                          descreption="Khi bạn di chuyển con trỏ chuột vào một ngày bất kì, màn hình sẽ hiển thị hộp thoại bao gồm các thông tin về ngày đang xem, doanh thu và số lượng đơn hàng tương ứng của ngày hôm đó:"
                          image={[step4]}
                        />
                        <GuideUsingStep
                          descreption="<b>Lưu ý:</b>"
                          note={[
                            { title: 'Chỉ có thể chọn ngày từ 01/01/2021 đến ngày hiện tại.' },
                            {
                              title:
                                'Giá trị ở trường <b>Từ ngày</b> phải < giá trị ở trường <b>Đến ngày</b>.',
                            },
                          ]}
                        />
                      </Box>
                    }
                  />
                  <GuideUsingStep
                    descreption="Xem biểu đồ theo tháng"
                    orther={
                      <Box margin={'20px'}>
                        <GuideUsingStep
                          descreption="Cách xem dữ liệu biểu đồ theo tháng sẽ tương tự như xem biểu đồ theo ngày."
                          orther={
                            <div
                              className="d-f ai-c jc-c"
                              style={{ marginTop: '8px', flexDirection: 'column' }}
                            >
                              <video width={'60%'} controls>
                                <source src={video2} type="video/mp4" />
                              </video>
                            </div>
                          }
                        />
                      </Box>
                    }
                  />
                  <GuideUsingStep
                    descreption="Xem biểu đồ theo năm"
                    orther={
                      <Box margin={'20px'}>
                        <GuideUsingStep
                          descreption="Cách xem dữ liệu biểu đồ theo năm sẽ tương tự như xem biểu đồ theo ngày."
                          orther={
                            <div
                              className="d-f ai-c jc-c"
                              style={{ marginTop: '8px', flexDirection: 'column' }}
                            >
                              <video width={'60%'} controls>
                                <source src={video3} type="video/mp4" />
                              </video>
                            </div>
                          }
                        />
                      </Box>
                    }
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b> Tải báo cáo Doanh thu</b>"
              orther={
                <Box margin={'20px'}>
                  <GuideUsingStep
                    descreption="Bạn có thể nhấn vào nút <b>TẢI BÁO CÁO</b> để tải báo cáo doanh thu:"
                    orther={
                      <div
                        className="d-f ai-c jc-c"
                        style={{ marginTop: '8px', flexDirection: 'column' }}
                      >
                        <video width={'60%'} controls>
                          <source src={video4} type="video/mp4" />
                        </video>
                      </div>
                    }
                  />
                  <GuideUsingStep
                    descreption="<b>Lưu ý:</b>"
                    note={[
                      {
                        title:
                          'Giá trị ngày/tháng/năm trong báo cáo bao gồm các ngày/tháng/năm có doanh thu và số lượng đơn hàng lớn hơn 0.',
                      },
                      {
                        title:
                          'Dữ liệu về doanh thu và số lượng đơn hàng hiển thị giống với dữ liệu ở biểu đồ.',
                      },
                    ]}
                  />
                </Box>
              }
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <GuideUsingStep
              descreption="<b>Điều kiện</b>: Có ít nhất một đơn hàng ở trạng thái Đã giao ở màn Quản lý đơn hàng trong khoảng thời gian đã chọn."
              noWrapper
            />
            <GuideUsingStep
              descreption="Bảng <b>Top 10 sản phẩm bán chạy</b> tổng hợp top 10 sản phẩm có số lượng bán ra nhiều nhất trong khoảng thời gian nhất định, được sắp xếp theo số lượng đã bán từ cao nhất đến thấp nhất."
              noWrapper
            />
            <GuideUsingStep
              descreption="Khi truy cập vào trang <b>Thống kê</b>, bảng <b>Top 10 sản phẩm bán chạy</b> sẽ mặc định hiển thị dữ liệu trong 1 tháng gần nhất cho đến ngày hiện tại."
              noWrapper
            />
            <GuideUsingStep
              descreption="<b>Xem danh sách top 10 sản phẩm bán chạy</b>"
              orther={
                <Box margin={'20px'}>
                  <GuideUsingStep
                    descreption="Nhấn vào trường thời gian <b>Từ ngày</b> hoặc <b>Đến ngày</b> sẽ hiển thị hộp thoại cho phép chọn ngày muốn xem dữ liệu tại bảng:"
                    image={[step5]}
                  />
                  <GuideUsingStep
                    descreption="Sau khi chọn xong ngày ở trường thời gian <b>Từ ngày</b> hoặc <b>Đến ngày</b>, dữ liệu ở bảng sẽ được cập nhật tương ứng theo thời gian đã chọn:"
                    orther={
                      <div
                        className="d-f ai-c jc-c"
                        style={{ marginTop: '8px', flexDirection: 'column' }}
                      >
                        <video width={'60%'} controls>
                          <source src={video5} type="video/mp4" />
                        </video>
                      </div>
                    }
                  />
                  <GuideUsingStep
                    descreption="<b>Lưu ý: </b>"
                    note={[
                      { title: 'Chỉ có thể chọn ngày từ 01/01/2021 đến ngày hiện tại.' },
                      {
                        title:
                          'Giá trị ở trường <b>Từ ngày</b> phải <= giá trị ở trường <b>Đến ngày</b>.',
                      },
                    ]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Tải báo cáo Danh sách sản phẩm bán chạy</b>"
              orther={
                <Box margin={'20px'}>
                  <GuideUsingStep
                    descreption="Bạn có thể nhấn vào nút <b>TẢI BÁO CÁO</b> để tải báo cáo danh sách sản phẩm bán chạy:"
                    orther={
                      <div
                        className="d-f ai-c jc-c"
                        style={{ marginTop: '8px', flexDirection: 'column' }}
                      >
                        <video width={'60%'} controls>
                          <source src={video6} type="video/mp4" />
                        </video>
                      </div>
                    }
                  />
                  <GuideUsingStep
                    descreption="<b>Lưu ý: </b>"
                    note={[
                      {
                        title:
                          'Ngoài top 10 sản phẩm bán chạy hiển thị trong bảng, danh sách sản phẩm trong báo cáo tải về bao gồm tất cả sản phẩm nằm trong danh sách sản phẩm của đơn hàng Đã giao, có thời gian đặt hàng là thời gian đã chọn.',
                      },
                    ]}
                  />
                </Box>
              }
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Có ít nhất một người dùng có trạng thái Đã đăng ký ở màn Quản lý
              người dùng và người dùng đã cập nhật các thông tin ở màn Thông tin cá nhân.
            </p>
            <GuideUsingStep
              descreption="Nhóm biểu đồ <b>Phân loại người dùng</b> tổng hợp kết quả phân loại người dùng dựa trên câu trả lời của tất cả người dùng ở màn Thông tin cá nhân tính đến thời điểm hiện tại, bao gồm 3 biểu đồ: Lĩnh vực, Thiết bị quan tâm, Tỉnh/thành phố."
              note={[
                {
                  title:
                    'Biểu đồ <b>Lĩnh vực</b> biểu thị số người dùng ứng với từng lĩnh vực hoạt động, bao gồm: “Thợ sửa chữa”, “Đại lý bán hàng”, “Chủ thiết bị”, “Quản lý thiết bị”, “Khác” và “Chưa chọn lĩnh vực”.',
                },
                {
                  title:
                    'Biểu đồ <b>Thiết bị quan tâm</b> biểu thị số người dùng ứng với từng thiết bị đang quan tâm, bao gồm 6 hãng sản xuất có số lượng người dùng chọn cao nhất, “Khác” và “Chưa chọn thiết bị”.',
                },
                {
                  title:
                    'Biểu đồ <b>Tỉnh/thành phố</b> biểu thị số người dùng ứng với từng tỉnh/thành phố, bao gồm: 8 tỉnh/thành phố có số lượng người dùng chọn cao nhất, “Khác” và “Chưa chọn tỉnh/thành phố”.',
                  image: [step6],
                },
              ]}
            />
            <GuideUsingStep
              descreption="Khi bạn di chuyển con trỏ chuột vào một cột dữ liệu bất kì, màn hình sẽ hiển thị hộp thoại bao gồm giá trị đang xem và số lượng người dùng tương ứng:"
              orther={
                <div
                  className="d-f ai-c jc-c"
                  style={{ marginTop: '8px', flexDirection: 'column' }}
                >
                  <video width={'60%'} controls>
                    <source src={video7} type="video/mp4" />
                  </video>
                </div>
              }
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                {
                  title: 'Một người dùng có thể chọn nhiều lĩnh vực hoặc nhiều thiết bị quan tâm.',
                },
              ]}
            />
            <GuideUsingStep
              descreption="<b>Tải báo cáo Phân loại người dùng</b>"
              orther={
                <Box margin={'20px'}>
                  <GuideUsingStep
                    descreption="Bạn có thể nhấn vào nút <b>TẢI BÁO CÁO</b> để tải báo cáo phân loại người dùng:"
                    orther={
                      <div
                        className="d-f ai-c jc-c"
                        style={{ marginTop: '8px', flexDirection: 'column' }}
                      >
                        <video width={'60%'} controls>
                          <source src={video8} type="video/mp4" />
                        </video>
                      </div>
                    }
                  />
                  <GuideUsingStep
                    descreption="<b>Lưu ý: </b>"
                    note={[
                      {
                        title:
                          'Báo cáo bao gồm dữ liệu của cả 3 biểu đồ được chia thành 3 trang khác nhau. File báo cáo tải về hiển thị dữ liệu giống với dữ liệu trên biểu đồ.',
                      },
                    ]}
                  />
                </Box>
              }
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Có ít nhất một đơn hàng do người dùng đặt và người dùng đã hủy đơn
              hàng.
            </p>
            <GuideUsingStep
              descreption="Biểu đồ <b>Lý do hủy đơn hàng</b> tổng hợp lý do hủy đơn hàng của tất cả các đơn hàng bị người dùng hủy tính đến thời điểm hiện tại, bao gồm: “Chưa sử dụng sản phẩm bao giờ”, “Giá sản phẩm đắt”, “Dịch vụ chăm sóc khách hàng không tốt”, “Thời gian giao hàng chậm”, “Sản phẩm không đầy đủ”, “Khác”."
              image={[step7]}
            />
            <GuideUsingStep
              descreption="Khi bạn di chuyển con trỏ chuột vào một cột dữ liệu bất kì, màn hình sẽ hiển thị hộp thoại bao gồm lý do đang xem và số lượng đơn hàng tương ứng:"
              orther={
                <div
                  className="d-f ai-c jc-c"
                  style={{ marginTop: '8px', flexDirection: 'column' }}
                >
                  <video width={'60%'} controls>
                    <source src={video9} type="video/mp4" />
                  </video>
                </div>
              }
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                {
                  title:
                    'Biểu đồ không tổng hợp lý do hủy đơn hàng của những đơn hàng bị hủy bởi quản trị viên.',
                },
                {
                  title:
                    'Lý do <b>“Khác”</b> bao gồm tất cả lý do mà người dùng nhập nội dung khác với các lý do còn lại.',
                },
              ]}
            />

            <GuideUsingStep
              descreption="<b>Tải báo cáo Lý do hủy đơn hàng</b>"
              orther={
                <Box margin={'20px'}>
                  <GuideUsingStep
                    descreption="Bạn có thể nhấn vào nút <b>TẢI BÁO CÁO</b> để tải báo cáo lý do hủy đơn hàng"
                    orther={
                      <div
                        className="d-f ai-c jc-c"
                        style={{ marginTop: '8px', flexDirection: 'column' }}
                      >
                        <video width={'60%'} controls>
                          <source src={video10} type="video/mp4" />
                        </video>
                      </div>
                    }
                  />
                  <GuideUsingStep
                    descreption="<b>Lưu ý: </b>"
                    note={[
                      {
                        title:
                          'Ngoài các lý do hiển thị trên biểu đồ, báo cáo bao gồm các lý do chi tiết mà người dùng đã nhập khi hủy đơn hàng.',
                      },
                      {
                        title:
                          'Báo cáo không tổng hợp lý do hủy đơn hàng của những đơn hàng bị hủy bởi quản trị viên.',
                      },
                    ]}
                  />
                </Box>
              }
            />
          </Box>
        </TabPanel>
      </Card>
    </>
  )
}

export default DashboardUsing
