import React, { useEffect, useState } from 'react'
import { useBreadcrumbs } from 'store/breadcrumbs'
import Sidebar from './Sidebar'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import { QuestionType } from 'types'
import './index.scss'
import QuestionItem from './QuestionItem'
import { Button, Modal } from 'components'
import SendQuestion from './SendQuestion'
import { useHistory } from 'store/confirmRedirect'
import { useIsMobile } from 'store/mobile'

const Questions: React.FC = () => {
  const history = useHistory()
  const { apiGet } = useApis()
  const { showBreadcrumbs } = useBreadcrumbs()
  const { goTo } = history
  const isMobile = useIsMobile()

  const [questions, setQuestions] = useState<{
    loading: boolean
    items: QuestionType[]
  }>({ loading: true, items: [] })
  const [showModalSend, setShowModalSend] = useState(false)

  const title = 'Câu hỏi thường gặp'

  useEffect(() => {
    showBreadcrumbs([{ name: title, path: '/cau-hoi-thuong-gap' }])
  }, [])

  const getQuestions = () => {
    apiGet(apiUrls.questions(), {}, ({ status, data }) => {
      if (status) {
        setQuestions({
          loading: false,
          items: data,
        })
      }
    })
  }

  useEffect(() => {
    getQuestions()
  }, [])

  const { loading, items } = questions

  const showModal = () => {
    if (isMobile) history.push('/gui-cau-hoi')
    else setShowModalSend(true)
  }
  const hideModal = () => setShowModalSend(false)

  return (
    <div>
      <Modal
        className="Modal-auth Modal-send"
        show={showModalSend}
        title="Gửi câu hỏi"
        close={() => goTo(hideModal)}
      >
        <SendQuestion show={showModalSend} onClose={hideModal} />
      </Modal>
      <h3 className="Page-title">{title}</h3>
      {!!items.length || loading ? (
        <div className="Questions">
          <Sidebar showModal={showModal} />

          <div className="QuestionsContainer">
            {items.map((item) => (
              <QuestionItem key={item.id} {...item} />
            ))}
          </div>
        </div>
      ) : (
        <div className="card-noItems DocumentsContainer-noItems" style={{ minHeight: 300 }}>
          <h3 className="card-title">Không có câu hỏi nào</h3>
          <Button color="secondary" icon="email" onClick={showModal}>
            Gửi câu hỏi
          </Button>
        </div>
      )}
    </div>
  )
}

export default Questions
