import React, { useEffect, useState } from 'react'

const MAX_LENGTH = 6

const FilterBlock: React.FC<{
  title: string
  data: any[]
  renderContent: (item: any, index: number) => any
  reset: number
}> = ({ title, data, renderContent, reset }) => {
  const [showMore, setShowMore] = useState(false)

  const newData = showMore ? data : data.slice(0, MAX_LENGTH)

  useEffect(() => {
    setShowMore(false)
  }, [reset])

  useEffect(() => {
    if (data.length) return
    setShowMore(false)
  }, [data])

  return (
    <div className="ProductsFilter-blockWrapper">
      {!!data.length && (
        <div className="ProductsFilter-block">
          <p className="ProductsFilter-block__title">{title}</p>
          <div className="ProductsFilter-block__content">{newData.map(renderContent)}</div>
          {data.length > MAX_LENGTH && (
            <div className="ProductsFilter-block__showMore">
              <span onClick={() => setShowMore(!showMore)}>
                {showMore ? 'Thu gọn' : 'Xem thêm'}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default FilterBlock
