import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components'

interface PropTypes {
  className?: string
  btnExecute?: string
  onExecute?: () => void
  btnDelete?: string
  onDelete?: () => void
}

export default function CartItemActions(props: PropTypes) {
  const { t } = useTranslation()

  const { className = '', btnExecute = '', onExecute, btnDelete = '', onDelete = () => {} } = props

  return (
    <div className={`CartItem-actions ${className}`}>
      {!!onExecute && (
        <Button color="stroke" onClick={onExecute}>
          {t(btnExecute)}
        </Button>
      )}
      <Button color="secondary" onClick={onDelete}>
        {t(btnDelete)}
      </Button>
    </div>
  )
}
