import { LS_SIGN_IN_PASSWORD } from 'configs/constants'
import { useMemo } from 'react'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import { RootState } from 'store'
import { clearCart } from 'store/shoppingCart/actions'
import { AuthType } from 'types'
import { isMobile, removeUserLS, updateUserLS } from 'utils'
import { closeAuthModal, forgotPassword, signIn, signOut, signUp, updateAuth } from './actions'

const needRedirectPaths = ['/sign-in', '/sign-up', '/forgot-password']

export default function useAuth() {
  const dispatch = useDispatch()
  const authReducer: AuthType = useSelector((state: RootState) => state.auth, shallowEqual)

  // Get 2 last words of fullname
  const splitNames = (authReducer?.full_name || '').split(' ')
  let name = ''
  const last = splitNames.pop()
  const first = splitNames.pop()
  if (first) name = first + ' ' + last
  else name = last || ''

  const history = useHistory()
  const mobile = isMobile()
  const { pathname = '' } = useLocation() || {}

  const newLocation = window.location.pathname.includes('payment') ? '/shopping-cart' : ''
  // Need redirect when current screen is not auth
  const needRedirect =
    needRedirectPaths.some((path) => matchPath(pathname, { path, exact: true })) || !!newLocation

  // Action when sign up, sign in, forgot password
  const authAction = (path: string, action: any, isPassword?: boolean) => {
    isPassword && localStorage.setItem(LS_SIGN_IN_PASSWORD, 'yes')
    if (mobile) {
      const newPathname = pathname.includes('payment') ? '/shopping-cart' : pathname
      // Save current pathname, login success will redirect again to this screen
      if (!needRedirectPaths.some((path) => matchPath(pathname, { path, exact: true })))
        localStorage.setItem('currentPathname', newPathname)
      history.push(path)
    } else dispatch(action(true))
  }

  return useMemo(
    () => ({
      ...authReducer,
      name,
      signUp: () => authAction('/sign-up', signUp),
      signIn: (isPassword?: boolean) => authAction('/sign-in', signIn, isPassword),
      forgotPassword: () => authAction('/forgot-password', forgotPassword),
      signInSuccess: (data: AuthType) => {
        // Save info to local storage to use in next session
        updateUserLS(data)
        dispatch(closeAuthModal())
        dispatch(updateAuth(data))
        // Redirect to old location
        const redirectPathname = localStorage.getItem('currentPathname') || '/'
        localStorage.removeItem('currentPathname')
        if (needRedirect) history.push(newLocation || redirectPathname)
      },
      signOut: (isAdmin?: boolean, preventClosePopup?: boolean) => {
        // Clear all cart, user info and redirect to home page
        dispatch(clearCart())
        removeUserLS()
        history.push(isAdmin ? '/admin/sign-in' : '/')
        dispatch(signOut(preventClosePopup))
      },
      closeAuthModal: () => dispatch(closeAuthModal()),
      updateAuth: (payload: any) => dispatch(updateAuth(payload)),
      isAuth: !!authReducer.access_token,
    }),
    [dispatch, authReducer, pathname]
  )
}
