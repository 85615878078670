import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from './Button'

import './index.scss'

type Option = {
  label?: string
  value: string | number
  disabled?: boolean
}

type PropTypes = {
  className?: string
  label?: string
  options: Option[]
  value?: any
  disabled?: boolean
  onClick?: (param?: any) => void
}

const ButtonOptions = (props: PropTypes) => {
  const { t } = useTranslation()
  const { className = '', label = '', value, options = [], disabled, onClick = () => {} } = props

  /**
   * Handle click button
   *
   * @param optionValue Button's value
   */
  const handleClick = (optionValue: any) => {
    if (optionValue === value) return

    onClick && onClick(optionValue)
  }

  return (
    <div className={`ButtonOptions-container ${className}`}>
      {label && <label className="ButtonOptions-lb label gray">{t(label)}</label>}
      <div className="ButtonOptions-list">
        {options.map((option, index) => (
          <Button
            className={`ButtonOptions-btn ${option.value === value ? 'active' : ''}`}
            disabled={disabled || option.disabled}
            key={index}
            type="button"
            onClick={() => handleClick(option.value)}
          >
            {option.label}
          </Button>
        ))}
      </div>
    </div>
  )
}

export default ButtonOptions
