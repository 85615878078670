import * as Yup from 'yup'
import { Button, Input } from 'components'
import {
  isString,
  validateEmail,
  validateTypingName,
  emailKeydownPreventSpace,
  removeAccentsKeepSpecialEmail,
} from 'utils'
import { Form, FormikProvider, useFormik } from 'formik'
import { apiUrls } from 'configs/apis'
import { useAuth } from 'store/auth'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useApis } from 'services/api'
import sendSuccess from 'assets/images/send-success.png'
import { useHistory } from 'store/confirmRedirect'

const validationSchema = Yup.object().shape({
  full_name: isString,
  email: Yup.string().required().matches(validateEmail(), 'email_is_invalid'),
  title: isString,
  content: isString,
})

interface Props {
  show?: boolean
  onClose: any
}

const SendQuestion: React.FC<Props> = ({ show, onClose }: Props) => {
  const { t } = useTranslation()
  const { isAuth } = useAuth()
  const { apiPost } = useApis()
  const { allowRedirect, preventRedirect } = useHistory()

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const formik = useFormik({
    initialValues: {
      full_name: isAuth ? 'full_name' : '',
      email: isAuth ? 'nguyenkhanh@gmail.com' : '',
      title: '',
      content: '',
    },
    validationSchema,
    onSubmit: ({ full_name, email, title, content }) => {
      setLoading(true)
      let body: any = {
        title,
        content,
      }
      if (!isAuth) body = { ...body, full_name, email }

      apiPost(
        apiUrls.questions(),
        body,
        ({ status }) => {
          if (status) {
            setSuccess(true)
            allowRedirect()
          } else {
            setLoading(false)
          }
        },
        () => setLoading(false)
      )
    },
  })

  const {
    errors,
    handleSubmit,
    getFieldProps,
    handleChange,
    isValid,
    validateForm,
    dirty,
    resetForm,
  } = formik

  useEffect(() => {
    resetForm()
    validateForm()
  }, [show])

  useEffect(() => {
    if (dirty) preventRedirect()
    else allowRedirect()
  }, [dirty])

  useEffect(() => {
    return () => {
      allowRedirect()
    }
  }, [])

  return (
    <div className={success ? 'SendQuestion-formSuccess' : 'SendQuestion-form'}>
      {!success ? (
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <div className="label SendQuestion-label" style={{ marginBottom: 16 }}>
              Vui lòng điền ý kiến của bạn.
            </div>
            <div className="FormContainer form-stack">
              {!isAuth && (
                <>
                  <Input
                    fullWidth
                    {...getFieldProps('full_name')}
                    label={t('full_name')}
                    onChange={(e) => {
                      if (e.target.value === '' || validateTypingName(e)) handleChange(e)
                    }}
                    maxLength={50}
                    required
                  />
                  <Input
                    fullWidth
                    {...getFieldProps('email')}
                    error={!!errors.email && t(errors.email?.toString())}
                    label={t('email')}
                    maxLength={50}
                    required
                    onKeyDown={emailKeydownPreventSpace}
                    onChange={(e) => {
                      const value = e.target.value

                      e.target.value = removeAccentsKeepSpecialEmail(value)
                      handleChange(e)
                    }}
                  />
                </>
              )}
              <Input
                fullWidth
                {...getFieldProps('title')}
                label="Tiêu đề"
                required
                maxLength={100}
              />
              <Input
                fullWidth
                {...getFieldProps('content')}
                label="Nội dung"
                required
                maxLength={500}
                rows={5}
              />
              <Button
                loading={loading}
                type="submit"
                fullWidth
                icon="email"
                disabled={!isValid}
                // color="secondary"
              >
                Gửi câu hỏi
              </Button>
            </div>
          </Form>
        </FormikProvider>
      ) : (
        <div className="SendQuestion-success">
          <img alt="" src={sendSuccess} />
          <div className="card-title">Gửi thành công</div>
          <div className="label gray">
            Câu hỏi của bạn đã được gửi. Chúng tôi sẽ phản hổi trong 48 giờ làm việc
          </div>
          <Button fullWidth onClick={onClose}>
            Xác nhận
          </Button>
        </div>
      )}
    </div>
  )
}

export default SendQuestion
