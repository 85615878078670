import InputSearch from 'components/input/InputSearch'
import { DocumentCategoryType } from 'types'
import Filter from './Filter'

interface Props {
  onSearch: (keyword: string) => void
  onFilter: (params: any) => void
  items: DocumentCategoryType[]
  searchPlaceholder: string
}

const Sidebar: React.FC<Props> = ({ onSearch, searchPlaceholder, items, onFilter }: Props) => {
  return (
    <div className="DocumentsSidebar">
      <div className="DocumentsSidebar-search">
        <InputSearch placeholder={searchPlaceholder} onSubmit={onSearch} maxLength={100} />
      </div>
      <Filter defaultFilter={items} onFilter={onFilter} />
    </div>
  )
}

export default Sidebar
