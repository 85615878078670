import { LS_HISTORY_SEARCH, LS_PAYMENT, MAX_FILENAME_LENGTH } from 'configs/constants'
import { isArray } from 'lodash'
import { LS_SHOPPING_CART } from 'configs/constants'
import { AuthType, CartType, PaymentType } from 'types'
import qs from 'qs'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import * as Yup from 'yup'

/**
 * Detect user agent is mobile or not
 *
 * @return Boolean
 */
export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

/**
 * Capitalize first letter in word
 * @param str
 * @returns
 */
export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Capitalize each first letter of word in sentence
 * @param string
 * @returns
 */
export const capitalizeWords = (string: string) => {
  if (!string) return ''
  return string
    .split(' ')
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .reduce((x, y) => x + ' ' + y)
}

/**
 * Get user from local storage
 */
export const getUserLS = () => {
  const oldUser = localStorage.getItem('user') || '{}'
  let parsedUser
  try {
    parsedUser = JSON.parse(oldUser)
  } catch (e) {}
  return (parsedUser || {}) as AuthType
}

/**
 * Update user to local storage
 * @param user
 */
export const updateUserLS = (user: AuthType) => {
  const oldUser = getUserLS()
  localStorage.setItem('user', JSON.stringify({ ...oldUser, ...user }))
}

/**
 * Remove user from local storage
 * @param user
 */
export const removeUserLS = () => {
  localStorage.removeItem('user')
}

/**
 * Get history search from local storage
 * @returns
 */
export const getHistorySearch = () => {
  const historySearch = localStorage.getItem(LS_HISTORY_SEARCH) || '[]'
  let parsedHistorySearch
  try {
    parsedHistorySearch = JSON.parse(historySearch)
  } catch (e) {}
  return (parsedHistorySearch || []) as string[]
}

/**
 * Fetch keywords to local storage
 * @param keyword
 * @returns
 */
export const fetchHistorySearch = (data: any[]) => {
  data = data.map(({ keyword }) => keyword)
  localStorage.setItem(LS_HISTORY_SEARCH, JSON.stringify(data))
  return data
}

/**
 * Add keyword to local storage if it does not exist, or swap to head if it exists
 * @param keyword
 * @returns
 */
export const addHistorySearch = (keyword: string) => {
  let historySearch = getHistorySearch()
  const index = historySearch.findIndex((item) => item === keyword)
  if (index !== -1) historySearch.splice(index, 1)
  historySearch = [keyword, ...historySearch]
  localStorage.setItem(LS_HISTORY_SEARCH, JSON.stringify(historySearch))
  return historySearch
}

/**
 * Remove keyword from local storage if it exists
 * @param keyword
 * @returns
 */
export const removeHistorySearch = (keyword: string) => {
  const historySearch = getHistorySearch()
  const index = historySearch.findIndex((item) => item === keyword)
  if (index !== -1) {
    historySearch.splice(index, 1)
    localStorage.setItem(LS_HISTORY_SEARCH, JSON.stringify(historySearch))
    return [...historySearch]
  }
  return historySearch
}

/**
 * Store shopping cart data to local storage
 *
 * @param shoppingCart Shopping cart data
 */
export const storeShoppingCart = (cartItems: any = []) => {
  localStorage.setItem(LS_SHOPPING_CART, JSON.stringify(cartItems))
}

/**
 * Get shopping cart data from local storage
 */
export const getShoppingCartData = () => {
  let data: any = localStorage.getItem(LS_SHOPPING_CART) || '[]'

  const shoppingCart: CartType = {} as CartType
  try {
    data = JSON.parse(data)
    if (!isArray(data)) data = []
  } catch (e) {
    data = []
    // Exception
  }
  shoppingCart.allCartItems = data

  return shoppingCart as CartType
}

/**
 * Clear shopping cart data from local storage
 */
export const clearShoppingCartData = () => {
  localStorage.removeItem(LS_SHOPPING_CART)
}

export const getProductsURL = (linkSeo: string) => {
  return `/danh-sach-san-pham?${qs.stringify({ category: linkSeo })}`
}

export const removeAccentsCode = (name: string) => {
  return (
    name
      .trim()
      .toUpperCase()
      // Tách chữ có dấu thành 2 thành phần: chữ và dấu
      .normalize('NFD')
      // Chuyển đ thành d
      .replace(/Đ/g, 'D')
      // Xóa toàn bộ dấu
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z·])/g, '')
  )
}

export const removeAccents = (name: string) => {
  return (
    name
      .trim()
      .toLowerCase()
      // Tách chữ có dấu thành 2 thành phần: chữ và dấu
      .normalize('NFD')
      // Chuyển đ thành d
      .replace(/đ/g, 'd')
      // Xóa toàn bộ dấu
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z ])/g, '')
  )
}

export const removeAccentsKeepSpecial = (name: string) => {
  if (!name) return ''

  return (
    name
      .trim()
      .toLowerCase()
      .normalize('NFD')
      .replace(/đ/g, 'd')
      // eslint-disable-next-line no-useless-escape
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z`~!@#$%^&*(),.?'":;{}+=|<>_\-\\\/\[\]])/g, '')
  )
}

export const removeAccentsKeepSpecialEmail = (name: string) => {
  return (
    name
      .trim()
      .normalize('NFD')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D')
      // eslint-disable-next-line no-useless-escape
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z…—`~!@#$%^&*(),.?'":;{}+=|<>_\-\\\/\[\]·])/g, '')
  )
}

export const formatCode = (name: string) => {
  return (
    name
      .trim()
      .toLowerCase()
      .normalize('NFD')
      .replace(/đ/g, 'd')
      // eslint-disable-next-line no-useless-escape
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z·])/g, '')
      .toUpperCase()
  )
}

export const formatCompare = (name: string) => {
  if (!name) return ''

  return (
    name
      .trim()
      .toLowerCase()
      .normalize('NFD')
      .replace(/đ/g, 'd~')
      // eslint-disable-next-line no-useless-escape
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z `~!@#$%^&*(),.?'":;{}+=|<>_\-\\\/\[\]])/g, '')
  )
}

export const formatFilename = (name: string) => {
  return (
    name
      .trim()
      .normalize('NFD')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D')
      // eslint-disable-next-line no-useless-escape
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z·. ])/g, '')
  )
}

// .replace(/\s/g, '_')

export const trimFileName = (fileName: any = '', maxLength?: number) => {
  const maxFileNameLength = maxLength || MAX_FILENAME_LENGTH
  // Normalize NFC on safari => fix bug germany character
  fileName = formatFilename(fileName)
  if (fileName.length > maxFileNameLength) {
    const indexExtension = fileName.lastIndexOf('.')

    // if extension length > 5 => only take 4 characters
    const ext = indexExtension > -1 ? fileName.substr(indexExtension).substr(0, 5) : ''
    const newName = fileName.substr(0, maxFileNameLength - ext.length)
    fileName = newName + ext
  }
  return fileName
}

/**
 *
 * @param file
 * @returns
 */
export const renderFileName = (file: string, maxLength?: number) => {
  const maxFileName = maxLength || 20
  const newName = file.split('/')

  const newFileName = newName[newName.length - 1]

  if (newFileName.length > maxFileName) {
    return newFileName.slice(0, maxFileName) + '...'
  }

  return newFileName
}

export const createLinkSeo = (name: string) => {
  return (
    removeAccents(name)
      // Chuyển hết dấu cách thành gạch ngang
      .replace(/\s+/g, '-')
  )
}

// Get last link seo from pathname
export const getLinkSeo = (pathname: string) => pathname.split('/').pop() || ''

//get size Mobile
export const useMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 860)
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 860)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return isMobile
}

/**
 * Get user from local storage
 */
export const getPaymentLS = () => {
  const oldData = localStorage.getItem(LS_PAYMENT) || '{}'
  let parsedData
  try {
    parsedData = JSON.parse(oldData)
  } catch (e) {}
  return (parsedData || {}) as PaymentType
}

/**
 * Update user to local storage
 * @param user
 */
export const updatePaymentLS = (data: PaymentType, removeFields: string[] = []) => {
  const oldData = getPaymentLS()
  const newData: any = { ...oldData, ...data }
  removeFields.forEach((item: any) => {
    delete newData?.[item]
  })
  localStorage.setItem(LS_PAYMENT, JSON.stringify(newData))
  return newData as PaymentType
}

/**
 * Remove user from local storage
 * @param user
 */
export const removePaymentLS = () => {
  localStorage.removeItem(LS_PAYMENT)
}

const arrayStatus = ['Chưa kích hoạt', 'Kích hoạt']

/**
 * Convert status
 * @param user
 */
export const convertStatus = (index: number) => arrayStatus[index]

// Check page is refreshing or not: location is not changed
export const useRefresh = () => {
  const location = useLocation()
  const oldLocation = useRef(location)
  const [first, setFirst] = useState(true)
  const [refresh, setRefresh] = useState(0)

  useEffect(() => {
    if (first) {
      setFirst(false)
      return
    }

    const { pathname, search } = location
    const { pathname: oldPathname, search: oldSearch } = oldLocation.current
    if (pathname === oldPathname && search === oldSearch) setRefresh((refresh) => refresh + 1)
    oldLocation.current = location
  }, [location])

  return refresh
}

// Random id with 6 characters
export const randomId = () => Math.random().toString(36).substr(2, 6)

/**
 * check size screen
 * @returns
 */
export const useWindowSize = () => {
  const [size, setSize] = useState(0)
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize(window.innerWidth)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

export const isString = Yup.string().trim().required()
export const isObject = Yup.object().defined()

/**
 * Prevent user types space character in email input
 * @param e keyboard event
 */
export const emailKeydownPreventSpace = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === ' ') e.preventDefault()
}

// Check url is a blob
export const isBlob = (url: any = '') => url.includes('blob:')

export const sortBy = (field: string) => {
  return (a: any, b: any) => {
    a = formatCompare(a[field])
    b = formatCompare(b[field])

    return a > b ? 1 : -1
  }
}

// get next char
export const nextChar = (c: string) => {
  return String.fromCharCode(c.charCodeAt(0) + 1)
}

/*eslint-disable*/
export const removeVietnameseTones = (str: string) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '')
  str = str.replace(/\u02C6|\u0306|\u031B/g, '')
  str = str.replace(/ + /g, ' ')
  str = str.trim()
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|-|{|}|\||\\/g,
    ' '
  )
  return str
}
