import React, { useLayoutEffect } from 'react'
import { useHistory } from 'react-router'
import { Container } from '@mui/material'

import { useBreadcrumbs } from 'store/breadcrumbs'

import Header from './Header'
import Footer from './Footer'
import Breadcrumbs from '../breadcrumbs'

import './index.scss'

interface PropTypes {
  isFull?: boolean
  isNoPaddingSm?: boolean
  children: React.ReactNode
}

export default function AppLayout(props: PropTypes) {
  const history = useHistory()
  const { hideBreadcrumbs } = useBreadcrumbs()

  const { isFull, isNoPaddingSm, children } = props
  const { location } = history
  const { pathname = '' } = location

  const isHomePage = pathname === '/'

  useLayoutEffect(() => {
    hideBreadcrumbs()
  }, [pathname])

  return (
    <div className="App-layout">
      <div className="App-main">
        <Header hideBrands={!isHomePage} />
        <div className={`App-container ${isHomePage ? '' : 'hideBrands'}`}>
          <Breadcrumbs />
          {isFull ? (
            children
          ) : (
            <Container className={`${isNoPaddingSm ? 'noPadding-sm' : ''}`}>{children}</Container>
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}
