import { useTranslation } from 'react-i18next'
import { Container } from '@mui/material'

import { useSelector } from 'react-redux'
import { RootState } from 'types'
import { useHistory } from 'store/confirmRedirect'
import { CATEGORY_TYPES } from 'configs/constants'

export default function HomeCategories() {
  const { t } = useTranslation()
  const history = useHistory()
  const engines = useSelector((state: RootState) => state.categories.menu) || []

  return (
    <div className="HomeCategories-container">
      <Container>
        <p className="Home-smallTitle">{t('category')}</p>
        <h5 className="Home-title">{t('product')}</h5>
        <div className="HomeCategories-list">
          {engines
            .filter((i: any) => i.type === CATEGORY_TYPES.CATEGORY)
            .slice(0, 4)
            .map((category: any, index: number) => (
              <div
                key={index}
                className="HomeCategories-item"
                onClick={() => history.push(`${category?.linkFull}`)}
              >
                <span
                  className="HomeCategories-img"
                  style={{ backgroundImage: `url("${category?.image_url}")` }}
                />
                <span className="HomeCategories-txt">{t(category?.name)}</span>
              </div>
            ))}
        </div>
      </Container>
    </div>
  )
}
