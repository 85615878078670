import { Drawer } from '@mui/material'
import ButtonDrawerConfirm from '../button/ButtonDrawerConfirm'
import React, { useEffect, useState } from 'react'
import ReactMobileDatePicker from 'react-mobile-datepicker'
import './index.scss'

interface Props {
  open?: boolean
  date?: any
  min?: any
  max?: any
  onClose?: () => void
  onConfirm?: (date: any) => void
  [key: string]: any
}

const today = new Date()

const MobileDatePicker: React.FC<Props> = ({ open, onClose, date, min, max, onConfirm }) => {
  const [value, setValue] = useState(today)

  useEffect(() => {
    if (!open) return
    setValue(date || today)
  }, [open])

  const handleConfirm = () => {
    onConfirm?.(value)
    onClose?.()
  }

  return (
    <Drawer
      className="MobileDatePicker"
      open={open}
      anchor="bottom"
      onClose={onClose}
      onBackdropClick={onClose}
    >
      <div className="MobileDatePicker-wrapper">
        <ReactMobileDatePicker
          isPopup={false}
          isOpen={true}
          showHeader={false}
          showFooter={false}
          showCaption={true}
          dateConfig={{
            date: {
              format: 'DD',
              // caption: 'Day',
              step: 1,
            },
            month: {
              format: (date: any) => `Tháng ${date.getMonth() + 1}`,
              // caption: 'Mon',
              step: 1,
            },
            year: {
              format: 'YYYY',
              // caption: 'Year',
              step: 1,
            },
          }}
          value={value}
          min={min}
          max={max}
          onChange={(value: any) => setValue(value)}
        />
      </div>
      <ButtonDrawerConfirm onClick={handleConfirm} />
    </Drawer>
  )
}

export default MobileDatePicker
