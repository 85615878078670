import React, { useEffect, useState } from 'react'
import { Player } from 'react-tuby'
import 'react-tuby/css/main.css'
import './AboutUsContent.scss'
import Lightbox from 'react-image-lightbox'
import { isMobile } from 'utils'

interface Props {
  content?: string
  video_url?: string
}

const AboutUsContent: React.FC<Props> = ({ video_url, content }) => {
  const [imgPreview, setImgPreview] = useState({ open: false, src: '' })

  const handleToggleImgPreview = (isOpen = false, image: any) => {
    setImgPreview({ open: isOpen, src: image })

    if (isOpen && isMobile()) {
      document.body.classList.add('mobile')
      return
    }
    document.body.classList.remove('mobile')
  }

  useEffect(() => {
    const clickFunction = (e: any) => {
      handleToggleImgPreview(true, e.target.currentSrc)
    }

    Array.from(document.querySelectorAll('.AboutUs-tabpanel img')).forEach((item) => {
      item.addEventListener('click', clickFunction)
    })
    return () => {
      Array.from(document.querySelectorAll('.AboutUs-tabpanel img')).forEach((item) => {
        item.removeEventListener('click', clickFunction)
      })
    }
  }, [])

  return (
    <div className="about_us_content">
      {video_url && (
        <div className="video">
          <Player
            src={video_url}
            internationalization={{
              settingsPlaybackSpeed: 'Tốc độ phát',
              settingsPlaybackSpeedNormal: 'Chuẩn',
              tooltipsPlay: 'Phát',
              tooltipsPause: 'Tạm dừng',
              tooltipsMute: 'Tắt tiếng',
              tooltipsUnmute: 'Bật âm thanh',
              tooltipsSettings: 'Cài đặt',
              tooltipsExitFullscreen: 'Thoát khỏi chế độ toàn màn hình',
              tooltipsFullscreen: 'Toàn màn hình',
            }}
          >
            {(ref, props) => <video ref={ref} {...props} autoPlay />}
          </Player>
        </div>
      )}
      <div className="content" dangerouslySetInnerHTML={{ __html: content || '' }} />

      {imgPreview.open && (
        <Lightbox
          wrapperClassName={'ImageViewer-container'}
          reactModalStyle={{ overlay: { zIndex: 9999 } }}
          enableZoom={false}
          mainSrc={imgPreview.src}
          nextSrc={undefined}
          prevSrc={undefined}
          onMovePrevRequest={() => {}}
          onMoveNextRequest={() => {}}
          onCloseRequest={() => handleToggleImgPreview(false, imgPreview.src)}
          animationDuration={600}
        />
      )}
    </div>
  )
}

export default AboutUsContent
