import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { useBreadcrumbs } from 'store/breadcrumbs'
import Sidebar from './Sidebar'
import Articles from './Articles'
import ArticleDetail from './ArticleDetail'
import { useIsMobile } from 'store/mobile'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import { ArticleType, ObjectType } from 'types'
import qs from 'qs'
import './index.scss'
import { useHistory } from 'react-router-dom'
import { useModalConfirm } from 'store/modalConfirm'
import { Button, InputSearch } from 'components'

const ArticlesContainer: React.FC = () => {
  const params: any = useParams()
  const history = useHistory()
  const { id } = params || {}
  const { apiGet } = useApis()
  const { showBreadcrumbsRightMenu } = useBreadcrumbs()
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()
  const isMobile = useIsMobile()
  const { search: requestParams } = useLocation()

  const parsedRequestParams = qs.parse(requestParams, { ignoreQueryPrefix: true })
  const { keyword } = parsedRequestParams
  const page = Number(parsedRequestParams.page) || 1

  const [newArticles, setNewArticles] = useState<ArticleType[]>([])
  const [articles, setArticles] = useState<{
    loading: boolean
    items: ArticleType[]
    totalPages: number
    item?: ArticleType
  }>({
    loading: true,
    items: [],
    totalPages: 0,
  })

  const searchPlaceholder = 'Tìm trong tin tức & sự kiện'
  const newsTitle = 'Tin tức & Sự kiện'
  const eventTitle = 'Sự kiện'
  const title = id ? eventTitle : newsTitle

  const setLoading = (loading: boolean) => setArticles((articles) => ({ ...articles, loading }))

  const goto = (params: ObjectType) => {
    const requestParams = { ...parsedRequestParams, ...params }

    history.push({
      pathname: '/tin-tuc-su-kien',
      search: qs.stringify(requestParams),
    })
  }

  const handleSearch = (keyword: string) => goto({ keyword, page: undefined })
  const handleChangePage = (page: number) => goto({ page })

  const getArticleDetail = () => {
    apiGet(apiUrls.articles(id), {}, ({ status, data }) => {
      if (status) {
        setArticles((articles) => ({ ...articles, loading: false, item: data, totalPages: 0 }))
      } else {
        showModalConfirm({
          title: 'Thông báo',
          content: 'Bài viết không tồn tại',
          confirm: {
            text: 'Đã hiểu',
            action: () => {
              hideModalConfirm()
              history.goBack()
            },
          },
        })
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    const arr = id ? [{ name: eventTitle, path: '/' }] : []
    showBreadcrumbsRightMenu(
      [{ name: newsTitle, path: '/tin-tuc-su-kien' }, ...arr],
      <InputSearch isBreadcrumb placeholder={searchPlaceholder} onSubmit={handleSearch} />
    )

    if (!id) return

    setLoading(true)
    getArticleDetail()
  }, [id])

  useEffect(() => {
    if (id) return

    setLoading(true)
    apiGet(apiUrls.articles(), { page, page_size: 10, title: keyword }, ({ status, data }) => {
      if (status) {
        setArticles({
          loading: false,
          items: data.items,
          totalPages: data.total_pages,
        })
      } else setLoading(false)
    })
  }, [id, page, keyword])

  useEffect(() => {
    if (isMobile && id) return

    apiGet(apiUrls.articles(), { page: 1, page_size: 5 }, ({ status, data }) => {
      if (status) {
        setNewArticles(data.items)
      }
    })
  }, [])

  const noItems = !keyword && articles.items.length === 0 && !articles.loading && !articles.item

  return (
    <div>
      <h3 className="Page-title">{title}</h3>
      <div className="Articles">
        {!noItems ? (
          <>
            <Sidebar
              items={newArticles}
              onSearch={handleSearch}
              searchPlaceholder={searchPlaceholder}
            />
            <div className="ArticlesContainer">
              {id ? (
                <ArticleDetail {...(articles.item as any)} getArticleDetail={getArticleDetail} />
              ) : (
                <Articles
                  {...articles}
                  page={page}
                  onChangePage={handleChangePage}
                  isMobile={isMobile}
                />
              )}
            </div>
          </>
        ) : (
          <div className="card-noItems ArticlesContainer-noItems">
            <h3 className="card-title">Không có bài viết nào</h3>
            <Button color="stroke transparent" onClick={() => history.goBack()}>
              Quay lại trang trước
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default ArticlesContainer
