/* eslint-disable */
import {
  CART_ADD_ITEM,
  CART_BUY_NOW,
  CART_CLEAR_ITEMS,
  CART_FETCH_ITEMS,
  CART_ORDER_SUCCESS,
  CART_REMOVE_ITEM,
  CART_UPDATE,
  CART_UPDATE_BUY_LATER,
  CART_UPDATE_ITEMS_QUANTITY,
  CART_UPDATE_ITEM_ID,
  CART_UPDATE_ITEM_QUANTITY,
  CART_ADD_ALL_ITEMS,
  CART_LOADING,
  CHANGE_CART_DATA,
} from 'store/actionTypes'

import { addItemToCart, getShoppingCartData } from 'utils'
import { ActionType, CartItemType, CartType } from 'types'

const { allCartItems: defaultCartItems = [] } = getShoppingCartData()

const defaultState = {
  cartItems: [],
  loading: false,
}

const initialState: CartType = {
  allCartItems: defaultCartItems,
  loading: false,
}

const shoppingCartReducer = (state: CartType = initialState, action: ActionType) => {
  const { type, payload } = action

  switch (type) {
    case CART_LOADING:
      return { ...state, loading: true }

    case CART_FETCH_ITEMS: {
      const allCartItems = payload as CartType
      return {
        ...state,
        loading: false,
        allCartItems,
      }
    }

    case CART_CLEAR_ITEMS: {
      return defaultState
    }

    case CART_ADD_ITEM: {
      return {
        ...state,
        allCartItems: addItemToCart(state.allCartItems, payload),
      }
    }

    case CART_UPDATE_ITEM_QUANTITY: {
      const { cartItemId, quantity, updateInventory } = payload
      const index = state.allCartItems.findIndex(
        (item) => item.id === cartItemId || item.product_id === cartItemId
      )
      if (index !== -1) {
        const newState = { ...state }
        const newAllCartItems = [...newState.allCartItems]
        newAllCartItems[index].quantity = quantity
        try {
          if (updateInventory) newAllCartItems[index].product.inventory_number = quantity
        } catch {}
        newState.allCartItems = newAllCartItems
        return newState
      }
      return state
    }

    case CART_REMOVE_ITEM: {
      return {
        ...state,
        allCartItems: state.allCartItems.filter(
          (item: CartItemType) => item.product_id !== payload
        ),
      }
    }

    case CART_UPDATE_ITEM_ID: {
      let isUpdate = false
      const allCartItems = [...state.allCartItems]
      const cartItem = allCartItems.find((item) => item.product_id === payload.productId)
      if (cartItem) {
        cartItem.id = payload.cartItemId
        isUpdate = true
      }
      if (!isUpdate) return state

      return {
        ...state,
        allCartItems,
      }
    }

    case CART_UPDATE_BUY_LATER: {
      const { productId, isBuyLater } = payload

      const newState = { ...state }
      const index = newState.allCartItems.findIndex((item) => item.product_id === productId)
      if (index !== -1 && newState.allCartItems[index].is_buy_later !== isBuyLater) {
        newState.allCartItems[index].is_buy_later = isBuyLater
        return newState
      }

      return state
    }

    case CART_BUY_NOW: {
      const { cartItemId, product, quantity } = payload
      const productId = product.id
      const newState = { ...state }
      if (!newState.allCartItems) newState.allCartItems = []
      newState.allCartItems = newState.allCartItems.filter((item) => item.product_id !== productId)
      newState.allCartItems = newState.allCartItems.map((item) => ({ ...item, is_buy_later: true }))
      newState.allCartItems.push({ id: cartItemId, product_id: productId, product, quantity })
      return newState
    }

    case CART_UPDATE:
      return { ...state, ...payload }

    case CART_ORDER_SUCCESS: {
      // After order success => delete all items except buy later in cart
      const allCartItems = state.allCartItems.filter((item) => item.is_buy_later)
      return { ...state, discountPercent: 0, discountCode: '', allCartItems }
    }

    case CART_UPDATE_ITEMS_QUANTITY: {
      let allCartItems = [...state.allCartItems]
      const items = payload as { product_id: string; quantity: number }[]
      items.forEach((item) => {
        const { product_id, quantity } = item
        // Quantity = -1 => this product has been deleted or disabled
        if (quantity === -1) allCartItems = allCartItems.filter((i) => i.product_id !== product_id)
        else {
          const cartItem = allCartItems.find((c) => c.product_id === product_id)
          if (cartItem) {
            cartItem.quantity = quantity
            cartItem.product.inventory_number = quantity
            // Quantity = 0 => move to buy later
            if (quantity === 0) cartItem.is_buy_later = true
          }
        }
      })
      return { ...state, allCartItems }
    }

    case CART_ADD_ALL_ITEMS: {
      const newState = { ...state }
      newState.allCartItems = newState.allCartItems.map((item) => ({
        ...item,
        is_buy_later: (item.product?.inventory_number || 0) === 0,
      }))
      return newState
    }

    case CHANGE_CART_DATA: {
      const newState = { ...state }
      newState.allCartItems = payload
      return newState
    }

    default:
      return state
  }
}

export default shoppingCartReducer
