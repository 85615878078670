/*eslint-disable*/
import { Box, Stack } from '@mui/material'
import { apiUrls } from 'configs/apis'
import { Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useApis } from 'services/api'
import { useAuth } from 'store/auth'
import { emailKeydownPreventSpace, removeAccentsKeepSpecialEmail, validateEmail } from 'utils'
import { Button, Input } from 'components'
import * as Yup from 'yup'

const validationSchema = (email: any) =>
  Yup.object().shape({
    email: Yup.string()
      .required()
      .min(1, 'email_is_invalid')
      .notOneOf([email.email], email.text)
      .matches(validateEmail(), 'email_is_invalid'),
  })

interface Props {
  next: () => void
}

const EnterEmail: React.FC<Props> = ({ next }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [emailExist, setEmailExist] = useState({ email: '', text: '' })
  const { user_id } = useAuth()
  const { apiPut } = useApis()

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema(emailExist),
    onSubmit: ({ email }, { validateForm }) => {
      setLoading(true)
      apiPut(
        apiUrls.verifyEmail(user_id),
        { email },
        ({ status, text, data }) => {
          if (status) {
            next()
          } else {
            setLoading(false)
            setEmailExist({ email, text: text || '' })
            validateForm()
          }
        },
        () => setLoading(false)
      )
    },
  })

  const { errors, handleSubmit, getFieldProps, handleChange, values, isValid, validateForm } =
    formik

  // Validate to disable button on startup
  useEffect(() => {
    validateForm()
  }, [])
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <span className="label">{'Nhập email của bạn để nhận mã xác nhận'}</span>
          <Input
            fullWidth
            autoFocus
            {...getFieldProps('email')}
            value={values.email}
            error={!!errors.email && t(errors.email?.toString())}
            label={t('email')}
            onKeyDown={emailKeydownPreventSpace}
            onChange={(e) => {
              const value = e.target.value
              e.target.value = removeAccentsKeepSpecialEmail(value)
              handleChange(e)
            }}
            maxLength={50}
            disabled={loading}
            required
          />
          <Box>
            <Button
              loading={loading}
              type="submit"
              fullWidth
              icon="arrow"
              disabled={loading || !isValid}
            >
              {t('continue')}
            </Button>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  )
}

export default EnterEmail
