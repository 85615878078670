import { AUTH_TYPES } from 'configs/constants'
import { CLEAR_AUTH, UPDATE_AUTH, UPDATE_AUTH_MODAL } from 'store/actionTypes'
import { ActionType, AuthType } from 'types'

const initialState: AuthType = {
  modal: {
    show: false,
    type: AUTH_TYPES.SIGN_IN,
  },
  access_token: '',
  refresh_token: '',
  avatar_url: '',
  email: '',
  full_name: '',
  name: '',
  gender: null,
  phone: '',
  isAdmin: false,
  isAuth: false,
}

const authReducer = (state = initialState, action: ActionType) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_AUTH:
      return { ...state, ...payload }

    case UPDATE_AUTH_MODAL:
      return { ...state, modal: { ...state.modal, ...payload } }

    case CLEAR_AUTH: {
      if (payload?.preventClosePopup) return { ...initialState, modal: state.modal }

      return initialState
    }

    default:
      return state
  }
}

export default authReducer
