/* eslint-disable indent */
import ApiRequest from 'services/api'
import { apiUrls } from 'configs/apis'
import { DispatchType, ObjectType, RequestType } from 'types'

import {
  APP_FETCH_AREA,
  APP_GET_ABOUT_US,
  APP_RESET_ADVERTISEMENT,
  APP_UPDATE_BANNERS,
  APP_UPDATE_BEST_SELLER,
} from '../actionTypes'

/**
 * Get list banners
 */
export const getBannersApi = () => (dispatch: DispatchType) => {
  return ApiRequest.get(apiUrls.getBanners(), {}, (res) => {
    const { status, data } = res

    if (status) dispatch({ type: APP_UPDATE_BANNERS, payload: data || [] })
  })
}

/**
 * Get list best seller products
 *
 * @param requestParams Request params
 */
export const getBestSellerApi = (requestParams: RequestType) => (dispatch: DispatchType) => {
  return ApiRequest.get(apiUrls.getBestSeller(), requestParams, (res) => {
    const { status, data } = res

    if (status)
      dispatch({
        type: APP_UPDATE_BEST_SELLER,
        payload: { ...data, page: requestParams.page } || {},
      })
  })
}

/**
 * Reset advertisement
 */
export const resetAdvertisement = () => (dispatch: DispatchType) => {
  dispatch({ type: APP_RESET_ADVERTISEMENT })
}

export const fetchArea = (area: ObjectType[]) => (dispatch: DispatchType) => {
  dispatch({ type: APP_FETCH_AREA, payload: area })
}
export const getAboutUs = (aboutUs: ObjectType) => (dispatch: DispatchType) => {
  dispatch({ type: APP_GET_ABOUT_US, payload: aboutUs })
}
