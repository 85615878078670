import { Modal } from 'components'
import { useMemo, useState } from 'react'
import { AUTH_SCREENS } from 'configs/constants'
import SignUp from './SignUp'
import EnterOTP from './EnterOTP'
import SignIn from './SignIn'
import UpdatePassword from './UpdatePassword'
import DonePassword from './DonePassword'
import ForgotPassword from './ForgotPassword'
import { AppLayout } from 'components/common'
import './index.scss'
import { Box, Container } from '@mui/material'
import { useAuth } from 'store/auth'
import { useEffect } from 'react'
import Page from 'components/page/Page'
import EnterEmail from './EnterEmail'

const {
  SIGN_UP,
  SIGN_IN,
  FORGOT_PASSWORD,
  ENTER_OTP,
  UPDATE_PASSWORD,
  DONE_PASSWORD,
  ENTER_EMAIL,
} = AUTH_SCREENS

interface Props {
  authType?: string
  modal?: boolean
}

export default function AuthLayout({ authType, modal }: Props) {
  const { closeAuthModal, modal: authModal } = useAuth()
  const { show, type } = authModal
  const [step, setStep] = useState(authType || type)
  const isSignIn = type === SIGN_IN
  const isSignUp = type === SIGN_UP
  const [sendEmailSuccess, setSendEmailSuccess] = useState(false)

  useEffect(() => {
    if (!modal || !show) return
    setStep(type)
  }, [type, show])

  const mappingStep = useMemo<{ [key: string]: any }>(
    () => ({
      [SIGN_UP]: {
        screen: (
          <SignUp next={() => setStep(ENTER_OTP)} setSendEmailSuccess={setSendEmailSuccess} />
        ),
        title: 'Đăng ký',
      },
      [SIGN_IN]: {
        screen: (
          <SignIn
            next={() => {
              setStep(ENTER_OTP)
            }}
            setSendEmailSuccess={setSendEmailSuccess}
          />
        ),
        title: 'Đăng nhập',
      },
      [FORGOT_PASSWORD]: {
        screen: (
          <ForgotPassword
            next={() => setStep(ENTER_OTP)}
            setSendEmailSuccess={setSendEmailSuccess}
          />
        ),
        title: 'Quên mật khẩu',
      },
      [ENTER_OTP]: {
        screen: (
          <EnterOTP
            next={() => setStep(UPDATE_PASSWORD)}
            toEmail={() => {
              setStep(ENTER_EMAIL)
              setSendEmailSuccess(false)
            }}
            sendEmailSuccess={sendEmailSuccess}
          />
        ),
        title: isSignIn ? 'Đăng nhập' : isSignUp ? 'Đăng ký' : 'Nhập mã xác nhận',
      },
      [ENTER_EMAIL]: {
        screen: (
          <EnterEmail
            next={() => {
              setSendEmailSuccess(true)
              setStep(ENTER_OTP)
            }}
          />
        ),
        title: 'Đăng ký',
      },
      [UPDATE_PASSWORD]: {
        screen: <UpdatePassword next={() => setStep(DONE_PASSWORD)} />,
        title: isSignUp ? 'Thiết lập mật khẩu' : 'Đặt lại mật khẩu',
      },
      [DONE_PASSWORD]: {
        screen: <DonePassword />,
        title: isSignUp ? 'Thiết lập mật khẩu' : 'Đặt lại mật khẩu',
      },
    }),
    [type, sendEmailSuccess]
  )

  const params = useMemo(() => mappingStep[step], [step])

  const { screen, title } = params

  if (modal)
    return (
      <Modal
        {...authModal}
        className="Modal-auth"
        title={title}
        close={() => {
          closeAuthModal()
          setSendEmailSuccess(false)
        }}
      >
        <Box mt={2}>{screen}</Box>
      </Modal>
    )

  return (
    <Page title={title}>
      <AppLayout isFull>
        <div className="AuthLayout">
          <div className="AuthLayout-header">
            <Container className="container">
              <p className="AuthLayout-header__mayno">Mayno.vn</p>
              <p className="AuthLayout-header__title">{title}</p>
            </Container>
          </div>
          <Container className="AuthLayout-body">
            <div className="AuthLayout-body__content">{screen}</div>
          </Container>
        </div>
      </AppLayout>
    </Page>
  )
}
