import React from 'react'
import { useTranslation } from 'react-i18next'

import { Collapse, List, ListItemButton } from '@mui/material'
import DetailInfoTabContent from './DetailInfoTabContent'

interface PropTypes {
  className?: string
  tabs?: any[]
  value?: number
  onChangeTab?: (tabIndex?: number) => void
}

export default function DetailInfoSm(props: PropTypes) {
  const { t } = useTranslation()
  const { tabs = [], value, onChangeTab = () => {} } = props

  return (
    <div className="ProductDetailInfoSm-container">
      <List className="ProductDetailInfoSm-list" component="nav">
        {tabs.map((tabItem, index) => {
          const isActive = value === index

          return (
            <div key={index} className="ProductDetailInfoSm-item">
              <ListItemButton
                className="ProductDetailInfoSm-btn"
                onClick={() => onChangeTab(isActive ? undefined : index)}
              >
                <span>{t(tabItem.name)}</span>
                <span className={`mn-icon-${isActive ? 'minus' : 'plus'} icon`} />
              </ListItemButton>
              <Collapse in={isActive} timeout="auto" unmountOnExit>
                <DetailInfoTabContent
                  className="ProductDetailInfoSm-content"
                  data={tabItem.content}
                />
              </Collapse>
            </div>
          )
        })}
      </List>
    </div>
  )
}
