/*eslint-disable*/
import React, { useState } from 'react'
import { Box, Card, Tab, Tabs } from '@mui/material'
import GuideUsingStep from 'components/admin/guideUsingStep/GuideUsingStep'
import { renderBackTitle } from 'configs/extensions'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'store/confirmRedirect'

import changeAvatar1 from '../../../../assets/images/ProfileUsing/changeAvatar1.png'
import changeAvatar2 from '../../../../assets/images/ProfileUsing/changeAvatar2.png'

import changName1 from '../../../../assets/images/ProfileUsing/changName1.png'
import changName2 from '../../../../assets/images/ProfileUsing/changName2.png'
import changName3 from '../../../../assets/images/ProfileUsing/changName3.png'
import changName4 from '../../../../assets/images/ProfileUsing/changName4.png'

import changPass1 from '../../../../assets/images/ProfileUsing/changPass1.png'
import changPass2 from '../../../../assets/images/ProfileUsing/changPass2.png'
import changPass3 from '../../../../assets/images/ProfileUsing/changPass3.png'
import changPass4 from '../../../../assets/images/ProfileUsing/changPass4.png'
import changPass5 from '../../../../assets/images/ProfileUsing/changPass5.png'

import changPhone1 from '../../../../assets/images/ProfileUsing/changPhone1.png'
import changPhone2 from '../../../../assets/images/ProfileUsing/changPhone2.png'
import changPhone3 from '../../../../assets/images/ProfileUsing/changPhone3.png'
import changPhone4 from '../../../../assets/images/ProfileUsing/changPhone4.png'
import changPhone5 from '../../../../assets/images/ProfileUsing/changPhone5.png'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const ProfileUsing = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <>
      {renderBackTitle(history, t('Thông tin quản trị viên - Hướng dẫn sử dụng '))}
      <Card sx={{ p: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="Thay đổi ảnh đại diện" {...a11yProps(0)} />
            <Tab label="Thay đổi họ và tên " {...a11yProps(1)} />
            <Tab label="Thay đổi số điện thoại" {...a11yProps(2)} />
            <Tab label="Đổi mật khẩu" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Di chuyển con trỏ chuột vào ảnh đại diện."
              image={[changeAvatar1]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Nhấn vào nút <b>Camera</b> để chọn file từ máy tính. Sau khi chọn xong file, ảnh vừa chọn sẽ được cập nhật vào ảnh đại diện của quản trị viên."
              image={[changeAvatar2]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Sửa</b> bên cạnh họ và tên."
              image={[changName1]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Điền họ và tên sau đó nhấn <b>LƯU</b>."
              image={[changName2]}
            />
            <GuideUsingStep
              descreption="Họ và tên sẽ được cập nhật theo đúng thông tin vừa chỉnh sửa."
              image={[changName3]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                { title: 'Họ và tên muốn chỉnh sửa phải khác với họ và tên hiện tại.' },
                {
                  title: 'Bạn có thể nhấn <b>HỦY</b> để hủy thao tác chỉnh sửa họ và tên.',
                  image: [changName4],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Sửa</b> bên cạnh số điện thoại của quản trị viên."
              image={[changPhone1]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Điền số điện thoại và nhấn <b>LƯU</b>."
              image={[changPhone2]}
            />
            <GuideUsingStep
              descreption="Số điện thoại sẽ được cập nhật theo đúng thông tin vừa chỉnh sửa."
              image={[changPhone3]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                {
                  title: 'Bạn có thể nhấn <b>HỦY</b> để hủy thao tác chỉnh sửa số điện thoại.',
                  image: [changPhone4],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn nút <b>Đổi mật khẩu</b> ở menu tab."
              image={[changPass1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Đổi mật khẩu</b>, màn hình Đổi mật khẩu hiển thị như ảnh dưới đây:"
              image={[changPass2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Nhập thông tin mật khẩu hợp lệ và nhấn <b>LƯU</b>."
              image={[changPass3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>LƯU</b>, tự động đăng xuất tài khoản và hiển thị màn hình <b>Đăng nhập</b>. Bạn có thể đăng nhập lại vào hệ thống bằng mật khẩu mới."
              image={[changPass4]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                { title: 'Để đổi mật khẩu thành công, mật khẩu mới cần khác mật khẩu hiện tại.' },
              ]}
            />
          </Box>
        </TabPanel>
      </Card>
    </>
  )
}

export default ProfileUsing
