import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ProductItem, Pagination, Loading } from 'components/common'

import { ProductType } from 'types'
import { showProductsCount } from 'configs/extensions'

interface PropTypes {
  className?: string
  title?: string
  products?: ProductType[]
  total?: number
  initialPage?: number
  totalPages?: number
  isLoading?: boolean
  onChangePage?: (page: number) => void
}

export default function RelativeProducts(props: PropTypes) {
  const { t } = useTranslation()
  const {
    className = '',
    title = '',
    products = [],
    initialPage,
    total = 0,
    totalPages = 1,
    isLoading,
    onChangePage = () => {},
  } = props
  const [page, setPage] = useState<number>(1)

  useEffect(() => {
    setPage(initialPage || 1)
  }, [initialPage])

  /**
   * Handle change page
   *
   * @param page Page
   */
  const handleChangePage = (page: number) => {
    setPage(page)

    onChangePage(page)
  }

  return (
    <div className={`RelativeProducts-area ${className}`}>
      <h3 className="RelativeProducts-title">{t(title)}</h3>
      {!isLoading && !total ? (
        <div className="RelativeProducts-noResults">
          <p>{t('noMatchingProducts')}</p>
        </div>
      ) : (
        <>
          {!!total && (
            <p className="RelativeProducts-desc label gray">
              {showProductsCount(products.length, total)}
            </p>
          )}
          <div className="RelativeProducts-list">
            {products.map((productItem) => (
              <ProductItem key={productItem.id} product={productItem} className="h-auto" />
            ))}
          </div>
          <div className="RelativeProducts-pagination">
            <Pagination count={totalPages} page={page} onChange={handleChangePage} />
          </div>
        </>
      )}
      {isLoading && <Loading fullScreen />}
    </div>
  )
}
