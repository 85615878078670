import React from 'react'
import { Slider as MuiSlider } from '@mui/material'
import './index.scss'

interface Props {
  description: string
  value: number[]
  onChange: (value: number[]) => void
  min: number
  max: number
}

const Slider: React.FC<Props> = ({ description, value, onChange, min, max }) => {
  // Change value of range
  const handleChange = (event: any, newValue: any) => {
    onChange(newValue)
  }

  return (
    <div className="ProductsSlider">
      <span className="ProductsSlider-title">{description}</span>
      <MuiSlider
        className="ProductsSlider-slider"
        value={value}
        onChange={handleChange}
        min={min}
        max={max}
        step={0.5}
      />
      <div className="ProductsSlider-value">
        <span>{value[0].toFixed(1)} mm</span>
        <span>{value[1].toFixed(1)} mm</span>
      </div>
    </div>
  )
}

export default Slider
