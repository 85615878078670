/*eslint-disable*/
import React, { useState } from 'react'
import { Box, Card, Tab, Tabs } from '@mui/material'
import GuideUsingStep from 'components/admin/guideUsingStep/GuideUsingStep'

import addNew1 from '../../../../assets/images/ArticleUsing/addnews/step1.png'
import addNew2 from '../../../../assets/images/ArticleUsing/addnews/step2.png'
import addNew3 from '../../../../assets/images/ArticleUsing/addnews/step3.png'
import addNew4 from '../../../../assets/images/ArticleUsing/addnews/step4.png'
import addNew5 from '../../../../assets/images/ArticleUsing/addnews/step5.png'
import addNew6 from '../../../../assets/images/ArticleUsing/addnews/step6.png'
import addNew7 from '../../../../assets/images/ArticleUsing/addnews/step7.png'

import addEvent1 from '../../../../assets/images/ArticleUsing/addEvent/step1.png'
import addEvent2 from '../../../../assets/images/ArticleUsing/addEvent/step2.png'
import addEvent3 from '../../../../assets/images/ArticleUsing/addEvent/step3.png'
import addEvent4 from '../../../../assets/images/ArticleUsing/addEvent/step4.png'
import addEvent5 from '../../../../assets/images/ArticleUsing/addEvent/step5.png'
import addEvent6 from '../../../../assets/images/ArticleUsing/addEvent/step6.png'
import addEvent7 from '../../../../assets/images/ArticleUsing/addEvent/step7.png'
import addEvent8 from '../../../../assets/images/ArticleUsing/addEvent/step8.gif'
import addEvent9 from '../../../../assets/images/ArticleUsing/addEvent/step9.png'
import addEvent10 from '../../../../assets/images/ArticleUsing/addEvent/step10.png'
import addEvent11 from '../../../../assets/images/ArticleUsing/addEvent/step11.png'
import addEvent11Gif from '../../../../assets/images/ArticleUsing/addEvent/step11Gif.gif'
import addEvent12 from '../../../../assets/images/ArticleUsing/addEvent/step12.png'
import addEvent13 from '../../../../assets/images/ArticleUsing/addEvent/step13.png'
import addEvent14 from '../../../../assets/images/ArticleUsing/addEvent/step14.png'
import addEvent15 from '../../../../assets/images/ArticleUsing/addEvent/step15.png'
import addEvent16 from '../../../../assets/images/ArticleUsing/addEvent/step16.png'

import editNews1 from '../../../../assets/images/ArticleUsing/editNews/step1.png'
import editNews2 from '../../../../assets/images/ArticleUsing/editNews/step2.png'
import editNews3 from '../../../../assets/images/ArticleUsing/editNews/step3.png'
import editNews4 from '../../../../assets/images/ArticleUsing/editNews/step4.png'

import editEvent1 from '../../../../assets/images/ArticleUsing/editEvent/step1.png'
import editEvent2 from '../../../../assets/images/ArticleUsing/editEvent/step2.png'
import editEvent3 from '../../../../assets/images/ArticleUsing/editEvent/step3.png'
import editEvent4 from '../../../../assets/images/ArticleUsing/editEvent/step4.png'
import editEvent5 from '../../../../assets/images/ArticleUsing/editEvent/step5.png'
import editEvent6 from '../../../../assets/images/ArticleUsing/editEvent/step6.gif'
import editEvent7 from '../../../../assets/images/ArticleUsing/editEvent/step7.png'
import editEvent8 from '../../../../assets/images/ArticleUsing/editEvent/step8.png'
import editEvent9 from '../../../../assets/images/ArticleUsing/editEvent/step9.png'
import editEvent10 from '../../../../assets/images/ArticleUsing/editEvent/step10.png'
import editEvent11 from '../../../../assets/images/ArticleUsing/editEvent/step11.png'

import delete1 from '../../../../assets/images/ArticleUsing/Delete/step1.png'
import delete2 from '../../../../assets/images/ArticleUsing/Delete/step2.png'
import delete3 from '../../../../assets/images/ArticleUsing/Delete/step3.png'
import delete4 from '../../../../assets/images/ArticleUsing/Delete/step4.png'

import { renderBackTitle } from 'configs/extensions'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'store/confirmRedirect'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const ArticleUsing: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      {renderBackTitle(history, t('news_management_using'))}
      <Card sx={{ p: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="THÊM TIN TỨC MỚI" {...a11yProps(0)} />
            <Tab label="THÊM SỰ KIỆN MỚI" {...a11yProps(1)} />
            <Tab label="Sửa tin tức " {...a11yProps(2)} />
            <Tab label="Sửa sự kiện" {...a11yProps(3)} />
            <Tab label="Xóa tin tức hoặc sự kiện" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box>
            <GuideUsingStep
              descreption="<b>Bước 1</b>: Nhấn vào nút <b>Tin tức mới</b> để thêm một tin tức."
              image={[addNew1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Tin tức mới</b>, hộp thoại Tin tức mới sẽ hiển thị như ảnh bên dưới:"
              image={[addNew2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Điền thông tin hợp lệ vào tất cả các trường trong hộp thoại Tin tức mới, sau đó nhấn nút <b>THÊM</b>."
              image={[addNew3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>THÊM</b>, tin tức sẽ được thêm thành công và hiển thị trong danh sách:"
              image={[addNew4]}
            />
            <GuideUsingStep
              descreption="Đồng thời, tin tức sẽ hiển thị ở đầu tiên tại trang <b>Tin tức & Sự kiện</b>:"
              image={[addNew5]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Bạn có thể tìm kiếm tin tức sự kiện theo <b>Tiêu đề</b>, lọc tin tức sự kiện theo <b>Ngày tạo/ Sự kiện quảng cáo</b>.',
                  image: [addNew6],
                },
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác thêm tin tức mới:',
                  image: [addNew7],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Sự kiện mới</b> để thêm một sự kiện."
              image={[addEvent1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Sự kiện mới</b>, màn hình thêm Sự kiện mới sẽ hiển thị như ảnh bên dưới:"
              image={[addEvent2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Điền thông tin hợp lệ vào các trường trong màn hình thêm Sự kiện mới, sau đó nhấn nút <b>THÊM SỰ KIỆN</b>."
              image={[addEvent3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>THÊM SỰ KIỆN</b>, sự kiện sẽ được thêm thành công và hiển thị trong danh sách:"
              image={[addEvent4]}
            />
            <GuideUsingStep
              descreption="Đồng thời, sự kiện sẽ hiển thị đầu tiên tại trang <b>Tin tức & Sự kiện:</b>"
              image={[addEvent5]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Nếu chọn Đăng bài ngay →  Sự kiện sẽ được đăng ngay trên trang <b>Tin tức & Sự kiện</b>.',
                },
                {
                  title:
                    'Nếu chọn thời gian đăng bài → Sự kiện được lên lịch sẽ được đăng tại trang <b>Tin tức & Sự kiện</b> vào đúng thời gian đã chọn.',
                  lastImage: [addEvent8],
                  subNote: [
                    {
                      title: 'Nhấn Chọn thời gian để chọn thời gian đăng sự kiện.',
                      image: [addEvent6],
                      subNote: [
                        {
                          title:
                            'Nhấn vào trường thời gian sẽ hiển thị hộp thoại cho phép chọn ngày và giờ đăng bài.',
                          image: [addEvent7],
                        },
                      ],
                    },
                    {
                      title: '<b>Lưu ý:</b>',
                      subNote: [
                        { title: 'Chỉ có thể chọn ngày và giờ trong tương lai.' },
                        {
                          title:
                            'Nhấn vào mũi tên hướng xuống bên cạnh tháng để chọn tháng khác tháng hiện tại.',
                        },
                        {
                          title:
                            'Nhấn vào mũi tên hướng xuống bên cạnh năm để chọn năm khác năm hiện tại.',
                        },
                        { title: 'Kéo xuống để thấy hết danh sách các khoảng thời gian.' },
                      ],
                    },
                  ],
                },
                {
                  title:
                    'Bạn có thể thêm định dạng cho nội dung của sự kiện. Định dạng được thêm sẽ được hiển thị tại chi tiết bài viết ở trang <b>Tin tức & Sự kiện.</b>',
                  image: [addEvent9],
                },
                {
                  title: 'Chọn Thêm sản phẩm để gắn thông tin sản phẩm vào nội dung bài viết.',
                  image: [addEvent10],
                  subNote: [
                    {
                      title:
                        'Sau khi chọn <b>Thêm sản phẩm</b>, cần phải chọn sản phẩm muốn gắn vào bài viết.',
                      image: [addEvent11Gif],
                    },
                    {
                      title:
                        'Sản phẩm được gắn vào bài viết sẽ hiển thị tại trang chi tiết bài viết ở <b>Tin tức & Sự kiện</b> như sau:',
                      image: [addEvent11],
                    },
                  ],
                },
                {
                  title:
                    'Chọn <b>Đặt làm sự kiện quảng cáo</b> nếu bạn muốn đặt sự kiện làm quảng cáo. ',
                  image: [addEvent12],
                  subNote: [
                    {
                      title: 'Sau khi chọn <b>Đặt làm sự kiện quảng cáo</b>, cần phải:',
                      lastImage: [addEvent13],
                      subNote: [
                        { title: 'Tải ảnh banner quảng cáo.' },
                        { title: 'Tải ảnh dialog quảng cáo.' },
                        { title: 'Chọn ngày cuối áp dụng quảng cáo.' },
                      ],
                    },
                    {
                      title:
                        'Nếu sự kiện được đặt làm quảng cáo → Hiển thị banner và dialog ở <b>Trang chủ</b>.',
                      image: [addEvent14],
                      subNote: [
                        {
                          title:
                            'Sau khi hết thời gian quảng cáo → Banner và dialog quảng cáo sẽ ẩn khỏi <b>Trang chủ.</b>',
                          image: [addEvent15],
                        },
                      ],
                    },
                  ],
                },
                {
                  title:
                    'Bạn có thể nhấn nút quay lại để hủy thao tác thêm sự kiện mới và quay về màn Quản lý tin tức sự kiện.',
                  image: [addEvent16],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box>
            <p style={{ fontSize: '18px', marginBottom: 20 }}>
              <b>Điều kiện:</b> Có ít nhất một tin tức đã được tạo trước đó.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Sửa</b> tại tin tức mà bạn muốn sửa thông tin."
              image={[editNews1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Sửa</b>, hộp thoại Sửa tin tức sẽ hiển thị như ảnh bên dưới:"
              image={[editNews2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Điền thông tin hợp lệ vào tất cả các trường trong hộp thoại Sửa tin tức, sau đó nhấn nút <b>LƯU</b>."
              image={[editNews3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>LƯU</b>, tin tức sẽ được cập nhật theo thông tin vừa sửa."
              noWrapper
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác sửa tin tức:',
                  image: [editNews4],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box>
            <p style={{ fontSize: '18px', marginBottom: 20 }}>
              <b>Điều kiện:</b> Có ít nhất một sự kiện đã được tạo trước đó.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Sửa</b> tại sự kiện mà bạn muốn sửa thông tin."
              image={[editEvent1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Sửa</b>, màn hình Sửa sự kiện sẽ hiển thị như ảnh bên dưới:"
              image={[editEvent2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Cập nhật thông tin vào các trường mà bạn muốn sửa trong màn hình Sửa sự kiện, sau đó nhấn nút <b>LƯU</b>."
              image={[editEvent3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>LƯU</b>, thông tin sự kiện sẽ được cập nhật theo thông tin vừa sửa."
              noWrapper
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Bạn có thể thêm định dạng cho nội dung của sự kiện. Định dạng được thêm sẽ được hiển thị tại trang chi tiết bài viết ở trang <b>Tin tức & Sự kiện</b>.',
                  image: [editEvent4],
                },
                {
                  title:
                    'Chọn <b>Thêm sản phẩm</b> để gắn thông tin sản phẩm vào nội dung bài viết.',
                  image: [editEvent5],
                  subNote: [
                    {
                      title:
                        'Sau khi chọn <b>Thêm sản phẩm</b>, cần phải chọn sản phẩm muốn gắn vào bài viết.',
                      image: [editEvent6],
                    },
                    {
                      title:
                        'Sản phẩm được gắn vào bài viết sẽ hiển thị tại trang chi tiết bài viết ở <b>Tin tức & Sự kiện</b> như sau:',
                      image: [editEvent7],
                    },
                  ],
                },
                {
                  title:
                    'Chọn <b>Đặt làm sự kiện quảng cáo</b> nếu bạn muốn đặt sự kiện làm quảng cáo. ',
                  image: [editEvent8],
                  subNote: [
                    {
                      title: 'Sau khi chọn <b>Đặt làm sự kiện quảng cáo</b>, cần phải:',
                      lastImage: [editEvent9],
                      subNote: [
                        { title: 'Tải ảnh banner quảng cáo.' },
                        { title: 'Tải ảnh dialog quảng cáo.' },
                        { title: 'Chọn ngày cuối áp dụng quảng cáo.' },
                      ],
                    },
                  ],
                },
                {
                  title:
                    'Nếu sự kiện được đặt làm quảng cáo → Hiển thị banner và dialog ở <b>Trang chủ.</b>',
                  image: [editEvent10],
                },
                {
                  title:
                    'Nếu trước đó sự kiện đã <b>Đặt làm quảng cáo</b> và đã quá thời gian quảng cáo → Không thể chỉnh sửa các thông tin banner quảng cáo, dialog quảng cáo, áp dụng quảng cáo đến ngày và không thể bỏ chọn Đặt làm sự kiện quảng cáo.',
                },
                {
                  title:
                    'Nếu trước đó sự kiện đã <b>Đặt làm quảng cáo</b> và chưa quá thời gian quảng cáo → Có thể chỉnh sửa các thông tin banner quảng cáo, dialog quảng cáo, áp dụng quảng cáo đến ngày hoặc bỏ chọn đặt làm sự kiện quảng cáo.',
                },
                {
                  title:
                    'Bạn có thể nhấn nút quay lại để hủy thao tác sửa sự kiện và quay về màn Quản lý tin tức sự kiện.',
                  image: [editEvent11],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <p style={{ fontSize: '18px', marginBottom: 20 }}>
            <b>Điều kiện:</b> Đã có tin tức hoặc sự kiện được tạo trước đó.
          </p>
          <GuideUsingStep
            descreption="<b>Bước 1:</b> Nhấn vào nút <b>Xoá</b> tại tin tức hoặc sự kiện mà bạn muốn xoá."
            image={[delete1]}
          />
          <GuideUsingStep
            descreption="Sau khi nhấn <b>Xoá</b>, hộp thoại Xoá bài viết sẽ hiển thị như ảnh bên dưới:"
            image={[delete2]}
          />
          <GuideUsingStep descreption="<b>Bước 2:</b> Nhấn nút <b>XÓA.</b>" image={[delete3]} />
          <GuideUsingStep
            descreption="Sau khi nhấn <b>XÓA</b>, bài viết sẽ bị xóa và không còn hiển thị trong danh sách. 
            Đồng thời bài viết sẽ không hiển thị ở màn <b>Tin tức & Sự kiện</b>."
            noWrapper
          />
          <GuideUsingStep
            descreption="<b>Lưu ý:</b>"
            note={[
              {
                title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác xoá tin tức sự kiện:',
                image: [delete4],
              },
            ]}
          />
        </TabPanel>
      </Card>
    </>
  )
}

export default ArticleUsing
