/*eslint-disable*/
import React, { useState } from 'react'
import { Box, Card, Tab, Tabs } from '@mui/material'
import GuideUsingStep from 'components/admin/guideUsingStep/GuideUsingStep'
import { renderBackTitle } from 'configs/extensions'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'store/confirmRedirect'

import add1 from '../../../../assets/images/adminsUsing/add1.png'
import add2 from '../../../../assets/images/adminsUsing/add2.png'
import add3 from '../../../../assets/images/adminsUsing/add3.png'
import add4 from '../../../../assets/images/adminsUsing/add4.png'
import add5 from '../../../../assets/images/adminsUsing/add5.png'
import add6 from '../../../../assets/images/adminsUsing/add6.png'
import add7 from '../../../../assets/images/adminsUsing/add7.png'
import add8 from '../../../../assets/images/adminsUsing/add8.png'
import add9 from '../../../../assets/images/adminsUsing/add9.png'
import add10 from '../../../../assets/images/adminsUsing/add10.png'
import add11 from '../../../../assets/images/adminsUsing/add11.png'
import add12 from '../../../../assets/images/adminsUsing/add12.png'

import reset1 from '../../../../assets/images/adminsUsing/reset1.png'
import reset2 from '../../../../assets/images/adminsUsing/reset2.png'
import reset3 from '../../../../assets/images/adminsUsing/reset3.png'
import reset4 from '../../../../assets/images/adminsUsing/reset4.png'
import reset5 from '../../../../assets/images/adminsUsing/reset5.png'
import reset6 from '../../../../assets/images/adminsUsing/reset6.png'
import reset7 from '../../../../assets/images/adminsUsing/reset7.png'
import reset8 from '../../../../assets/images/adminsUsing/reset8.png'

import edit1 from '../../../../assets/images/adminsUsing/edit1.png'
import edit2 from '../../../../assets/images/adminsUsing/edit2.png'
import edit3 from '../../../../assets/images/adminsUsing/edit3.png'
import edit4 from '../../../../assets/images/adminsUsing/edit4.png'
import edit5 from '../../../../assets/images/adminsUsing/edit5.png'
import edit6 from '../../../../assets/images/adminsUsing/edit6.png'
import edit7 from '../../../../assets/images/adminsUsing/edit7.png'

import delete1 from '../../../../assets/images/adminsUsing/delete1.png'
import delete2 from '../../../../assets/images/adminsUsing/delete2.png'
import delete3 from '../../../../assets/images/adminsUsing/delete3.png'
import delete4 from '../../../../assets/images/adminsUsing/delete4.png'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const AdminManagementUsing = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      {renderBackTitle(history, t('Quản lý quản trị viên - Hướng dẫn sử dụng'))}
      <Card sx={{ p: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="Thêm quản trị viên" {...a11yProps(0)} />
            <Tab label="Đặt lại mật khẩu" {...a11yProps(1)} />
            <Tab label="Sửa thông tin quản trị viên" {...a11yProps(2)} />
            <Tab label="Xóa quản trị viên" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Thêm quản trị viên</b> để thêm một quản trị viên mới."
              image={[add1]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Sau khi nhấn <b>Thêm quản trị viên</b>, hộp thoại Thêm quản trị viên sẽ hiển thị như ảnh bên dưới:"
              image={[add2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 3:</b> Điền thông tin hợp lệ vào các trường bắt buộc trong hộp thoại Thêm quản trị viên, sau đó nhấn nút <b>THÊM</b>."
              image={[add3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>THÊM</b>, quản trị viên sẽ được thêm thành công và hiển thị trong danh sách với trạng thái Kích hoạt:"
              image={[add4]}
            />
            <GuideUsingStep
              descreption="Đồng thời có email gửi đến cho quản trị viên như ảnh dưới đây:"
              image={[add5]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Hiển thị danh sách nhóm quyền theo thông tin đã tạo ở trang <b>Quản lý nhóm quyền</b>.',
                  image: [add6],
                },
                {
                  title:
                    'Chỉ có thể tạo thành công quản trị viên khi nhập địa chỉ email chưa từng tạo tài khoản quản trị viên trước đó.',
                },
                {
                  title:
                    'Quản trị viên được tạo tài khoản cần đăng nhập vào tài khoản email đã điền khi tạo tài khoản để lấy mật khẩu mặc định và đăng nhập vào hệ thống quản lý.',
                  subNote: [
                    {
                      title: 'Nhấn <b>Đăng nhập</b> trong email để chuyển sang màn hình Đăng nhập.',
                      image: [add7],
                    },
                    {
                      title:
                        'Sau khi nhập Email và Mật khẩu mặc định được gửi trong email, nhấn nút <b>ĐĂNG NHẬP</b> để chuyển sang màn hình Thiết lập mật khẩu.',
                      image: [add8],
                    },
                    {
                      title:
                        'Bạn cần nhập Mật khẩu mới và Xác nhận mật khẩu, sau đó nhấn <b>ĐẶT MẬT KHẨU</b> để hoàn thành thao tác đổi mật khẩu mặc định.',
                      image: [add9],
                    },
                    {
                      title:
                        'Sau khi đổi mật khẩu mặc định, quản trị viên có thể sử dụng email và mật khẩu vừa đổi để đăng nhập vào hệ thống quản lý.',
                    },
                  ],
                },
                {
                  title:
                    'Bạn có thể tìm kiếm quản trị viên theo <b>Họ tên, Email</b> hoặc <b>Số điện thoại</b>.',
                  image: [add10],
                },
                {
                  title:
                    'Đối với tài khoản quản trị viên thuộc nhóm quyền Quản trị viên cấp cao, khi truy cập vào màn Quản lý quản trị viên sẽ ẩn các thao tác: Đặt lại mật khẩu, chỉnh sửa, xóa ở chính tài khoản của quản trị viên đó.',
                  image: [add11],
                },
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác thêm quản trị viên mới:',
                  image: [add12],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có ít nhất một quản trị viên ở màn Quản lý quản trị viên (ngoại
              trừ tài khoản đang đăng nhập).
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Đặt lại mật khẩu</b> ở quản trị viên mà bạn cần đặt lại mật khẩu."
              image={[reset1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Đặt lại mật khẩu</b>, hộp thoại Đặt lại mật khẩu sẽ hiển thị như ảnh bên dưới:"
              image={[reset2]}
            />
            <GuideUsingStep descreption="<b>Bước 2:</b> Nhấn nút <b>ĐỒNG Ý</b>." image={[reset3]} />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>ĐỒNG Ý</b>, mật khẩu mới sẽ gửi về email của quản trị viên được đặt lại mật khẩu."
              noWrapper
            />
            <GuideUsingStep
              descreption="<b>Bước 3:</b> Đăng nhập vào email đã đăng ký tài khoản để lấy mật khẩu mới và nhấn <b>Đăng nhập</b>."
              image={[reset4]}
            />
            <GuideUsingStep
              descreption="<b>Bước 4:</b> Nhập email và mật khẩu mặc định, sau đó nhấn <b>ĐĂNG NHẬP</b>."
              image={[reset5]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>ĐĂNG NHẬP</b>, màn hình Thiết lập mật khẩu sẽ hiển thị như ảnh bên dưới:"
              image={[reset6]}
            />
            <GuideUsingStep
              descreption="<b>Bước 5:</b> Nhập thông tin Mật khẩu mới và Xác nhận mật khẩu, sau đó nhấn <b>ĐẶT MẬT KHẨU</b>."
              image={[reset7]}
            />
            <GuideUsingStep
              descreption="Sau khi đổi mật khẩu mặc định thành công, bạn có thể sửa dụng email và mật khẩu mới để đăng nhập vào hệ thống."
              noWrapper
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Chỉ có thể đăng nhập bằng mật khẩu mặc định và thiết lập mật khẩu nếu trạng thái của quản trị viên là <b>Kích hoạt</b>.',
                },
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác đặt lại mật khẩu:',
                  image: [reset8],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có ít nhất một quản trị viên ở màn Quản lý quản trị viên (ngoại
              trừ tài khoản đang đăng nhập).
            </p>

            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Sửa</b> ở quản trị viên mà bạn cần sửa thông tin."
              image={[edit1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Sửa</b>, hộp thoại Sửa quản trị viên sẽ hiển thị như ảnh bên dưới:"
              image={[edit2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Cập nhật thông tin vào các trường mà bạn muốn sửa trong hộp thoại Sửa quản trị viên, sau đó nhấn nút <b>LƯU</b>."
              image={[edit3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>LƯU</b>, thông tin quản trị viên sẽ được cập nhật theo thông tin vừa sửa:"
              image={[edit4]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Nếu tài khoản quản trị viên bị sửa trạng thái từ <b>Kích hoạt</b> sang <b>Chưa kích hoạt</b> → Gửi email thông báo tài khoản bị hủy kích hoạt đến quản trị viên.',
                  subNote: [
                    {
                      title:
                        'Quản trị viên khi đăng nhập bằng tài khoản bị hủy kích hoạt sẽ hiển thị thông báo lỗi và không thể truy cập vào hệ thống.',
                      image: [edit5],
                    },
                  ],
                },
                {
                  title:
                    'Nếu tài khoản quản trị viên được sửa trạng thái từ <b>Chưa kích hoạt</b> sang <b>Kích hoạt</b> → Gửi email Kích hoạt tài khoản cho quản trị viên và quản trị viên có thể đăng nhập vào hệ thống.',
                  image: [edit6],
                },
                {
                  title:
                    'Nếu chỉnh sửa nhóm quyền của quản trị viên → Quản trị viên sẽ được cập nhật các quyền mới trong nhóm quyền vừa chỉnh sửa sau khi quản trị viên đăng nhập trở lại vào hệ thống.',
                },
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác sửa quản trị viên:',
                  image: [edit7],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có ít nhất một quản trị viên ở màn Quản lý quản trị viên (ngoại
              trừ tài khoản đang đăng nhập).
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Xóa</b> ở quản trị viên mà bạn cần xóa thông tin."
              image={[delete1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Xóa</b>, hộp thoại xác nhận Xóa quản trị viên sẽ hiển thị như ảnh bên dưới:"
              image={[delete2]}
            />
            <GuideUsingStep descreption="<b>Bước 2:</b> Nhấn nút <b>XÓA</b>." image={[delete3]} />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>XÓA</b>, quản trị viên sẽ bị xóa và không còn hiển thị trong danh sách.
                        </br>
                        </br>
                        Quản trị viên đã bị xóa sẽ không thể đăng nhập vào hệ thống quản lý."
              noWrapper
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác xóa quản trị viên:',
                  image: [delete4],
                },
              ]}
            />
          </Box>
        </TabPanel>
      </Card>
    </>
  )
}

export default AdminManagementUsing
