/*eslint-disable*/
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material'

import { configureStore } from 'store'

import './i18n'
import App from './App'

import 'assets/scss/index.scss'
import { theme } from 'configs/theme'
import metadata from './metadata.json'
import { loading } from 'configs/extensions'
import { BrowserRouter as Router } from 'react-router-dom'

const { buildNumber } = metadata
const version = `1.${Number(buildNumber) + 286}`

ReactDOM.render(
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Suspense fallback={loading}>
        <Provider store={configureStore}>
          <Router>
            <App />
          </Router>
        </Provider>
      </Suspense>
    </ThemeProvider>
    {process.env.REACT_APP_ENV !== 'prd' && <div className="version">v{version}</div>}
  </React.Fragment>,
  document.getElementById('root')
)
