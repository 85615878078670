import './index.scss'

interface Props {
  title: string
  children: any
  required?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xl ws'
  marginBottom?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  margin?: any
  center?: boolean
  style?: any
}

const renderRequired = (
  <span>
    {' '}
    (<span style={{ color: 'var(--cl-primary)' }}>*</span>)
  </span>
)

const RenderField = ({
  title,
  children,
  required,
  size = 'md',
  center,
  marginBottom = 'xs',
  margin,
  style,
}: Props) => (
  <div
    className={`AdminRenderField-field d-f ai-c ${size}${
      center ? ' center' : ''
    } mb-${marginBottom}`}
    style={{ margin, ...style }}
  >
    <div className={`AdminRenderField-title ${size}`}>
      {title}
      {required && renderRequired}
    </div>
    {children}
  </div>
)

const RenderFieldSmall = ({ children, ...rest }: Props) => (
  <RenderField {...rest} size="sm">
    {children}
  </RenderField>
)

const RenderFieldLarge = ({ children, ...rest }: Props) => (
  <RenderField {...rest} size="xl">
    {children}
  </RenderField>
)

const RenderFieldExtraSmall = ({ children, ...rest }: Props) => (
  <RenderField {...rest} size="xs">
    {children}
  </RenderField>
)

export { RenderField, RenderFieldSmall, RenderFieldLarge, RenderFieldExtraSmall, renderRequired }
