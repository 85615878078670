/* eslint-disable */
import { Checkbox, InputAdornment, MenuItem, Popover, TextField } from '@mui/material'
import { isEqual } from 'lodash'
import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import { SelectType } from 'types'
import { formatCompare } from 'utils'
import Input from '../input/Input'

interface Props {
  selected?: any
  setSelected?: (selected: any) => void
  label?: string
  data?: SelectType[]
  style?: CSSProperties
  disabled?: boolean
  hideSelected?: boolean
  placeholder?: string
  isCheckBox?: boolean
  handleChangeCheckbox?: (id?: string) => void
  selectedItems?: any
  maxHeight?: any
  fullWidth?: boolean
  popupSearch?: {
    timeout?: number
    searchByLabel?: boolean
    labelNoItems?: string
    placeholder?: string
    onSearch?: (keyword: string) => void
    onChange?: (keyword: string) => void
    searchFrontend?: boolean
    margin?: boolean
    maxLengthSearch?: number
  }
  sort?: boolean
  noSortFirst?: boolean
  labelIsHtml?: boolean
  [key: string]: any
}

const Select: React.FC<Props> = ({
  data = [],
  selected,
  setSelected,
  style = {},
  disabled,
  hideSelected,
  isCheckBox,
  handleChangeCheckbox,
  selectedItems = [],
  maxHeight,
  fullWidth,
  popupSearch,
  placeholder,
  sort,
  noSortFirst,
  labelIsHtml,
  ...rest
}) => {
  const anchorRef = useRef<HTMLInputElement>(null)
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')

  const timeoutSearch = useRef<any>()

  const readOnly = true

  const {
    timeout,
    labelNoItems = 'Không có kết quả',
    placeholder: searchPlaceholder = 'Nhập từ khoá',
    onSearch,
    onChange,
    searchFrontend,
    margin,
    maxLengthSearch = 50,
  } = popupSearch || {}

  let newData = data
  const value = data.find((item) => isEqual(item.value, selected))?.label || ''
  let noItems = true

  if (searchFrontend && search) {
    newData = data.map((item) => {
      const value1 = formatCompare(item.label)
      const value2 = formatCompare(search)
      const hide = !value1.includes(value2) || item.hide
      if (!hide) noItems = false
      return { ...item, hide }
    })
  } else noItems = false

  if (sort) {
    let first: any[] = []
    if (noSortFirst) {
      first = newData.slice(0, 1)
      newData = newData.slice(1)
    }
    newData = newData.sort((a, b) => {
      const A = formatCompare(a.label)
      const B = formatCompare(b.label)
      return A < B ? -1 : 1
    })
    newData = [...first, ...newData]
  }

  // Open the menu if has data
  const handleOpen = () => {
    if (data.length) setOpen(true)
  }

  // Close the menu
  const handleClose = () => {
    setOpen(false)
  }

  // Search when click item, enter or after 1 second
  const handleSearch = (newValue?: string) => {
    if (!newValue) newValue = value || ''
    onSearch?.(newValue)
  }

  // When click item, set selected item, set value in textbox and close menu
  const handleClickItem = (item: SelectType) => {
    setSelected?.(item.value)
    handleSearch(item.value)
    handleClose()
  }

  useEffect(() => {
    if (open && search) setSearch('')
  }, [open])

  useEffect(() => {
    onChange?.(search)

    if (!popupSearch) return

    if (timeout === 0) {
      handleSearch?.(value)
      return
    }

    // If user not typing after 700ms => search
    timeoutSearch.current = setTimeout(handleSearch, timeout || 700)

    return () => clearTimeout(timeoutSearch.current)
  }, [search])

  const renderInputSearch = () => {
    if (!popupSearch) return null

    return (
      <div className="SelectPaper-search" style={{ padding: 12 }}>
        <Input
          autoFocus
          placeholder={searchPlaceholder}
          icon="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          maxLength={maxLengthSearch}
        />
      </div>
    )
  }

  return (
    <div
      className={readOnly ? 'TableSelect-readOnly' : ''}
      style={{ width: fullWidth ? '100%' : undefined, ...style }}
    >
      <TextField
        {...rest}
        ref={anchorRef}
        onClick={disabled ? undefined : handleOpen}
        value={value}
        disabled={disabled}
        size="small"
        fullWidth={fullWidth}
        placeholder={placeholder}
        InputProps={{
          readOnly,
          endAdornment: (
            <InputAdornment position="end" style={{ opacity: disabled ? 0.5 : 1 }}>
              <svg
                className={`TableSelect-arrow ${open && 'focus'}`}
                viewBox="0 0 24 24"
                width={24}
                height={24}
              >
                <path d="M7 10l5 5 5-5z"></path>
              </svg>
            </InputAdornment>
          ),
        }}
      />
      <Popover
        anchorOrigin={{
          vertical: popupSearch ? 'top' : 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            width: anchorRef.current?.getBoundingClientRect().width || 0,
            marginTop: margin ? 7 : undefined,
          },
        }}
        disableAutoFocus
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        {renderInputSearch()}
        {noItems && (
          <div
            className="TableSelect-item"
            style={{ padding: '0 16px', height: 28, color: 'var(--cl-gray)' }}
          >
            {labelNoItems}
          </div>
        )}
        <SimpleBar className="SelectPaper-paper" style={{ maxHeight }} autoHide={false}>
          {isCheckBox
            ? newData.map((item, index: number) => (
                <div
                  key={item.value + index}
                  style={{
                    display:
                      (hideSelected && item.value === selected) || item.hide
                        ? 'none'
                        : labelIsHtml
                        ? 'flex'
                        : undefined,
                  }}
                >
                  <Checkbox
                    onChange={() => handleChangeCheckbox?.(item.value)}
                    checked={selectedItems?.includes(item.value)}
                  />
                  {labelIsHtml ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: item.label }}
                      style={{
                        width: '100%',
                      }}
                    />
                  ) : (
                    item.label
                  )}
                </div>
              ))
            : newData.map((item, index) => (
                <MenuItem
                  className={`TableSelect-item${selected === item.value ? ' active' : ''}`}
                  key={item.value + index}
                  disabled={item.disabled}
                  onClick={() => handleClickItem(item)}
                  style={{
                    display:
                      (hideSelected && item.value === selected) || item.hide ? 'none' : undefined,
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {labelIsHtml ? (
                      <div dangerouslySetInnerHTML={{ __html: item.label }} />
                    ) : (
                      item.label
                    )}
                  </div>
                </MenuItem>
              ))}
        </SimpleBar>
      </Popover>
    </div>
  )
}

export default Select
