import React, { useContext } from 'react'
import { ButtonProps } from '@mui/material'
import Button from '../admin/button/Button'
import { ModalContext } from './constants'

interface Props extends ButtonProps {
  loading?: boolean
}

const ModalButtonCancel: React.FC<Props> = ({ children, onClick, ...rest }) => {
  const { dirty, setShowAttention } = useContext(ModalContext)

  return (
    <Button
      className="AdminButton small"
      {...rest}
      onClick={(e) => {
        if (dirty) setShowAttention(true)
        else onClick?.(e)
      }}
    >
      {children}
    </Button>
  )
}

export default ModalButtonCancel
