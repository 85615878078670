import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { ModalConfirmType } from 'types'

import { showModalConfirm, hideModalConfirm } from './actions'

export default function useModalConfirm() {
  const dispatch = useDispatch()

  const isAdmin = window.location.pathname.includes('/admin')

  const confirmRedirectContent = isAdmin
    ? 'Nếu nhấn <b>Hủy</b>, mọi thông tin bạn đã nhập sẽ không được lưu lại'
    : 'Những thay đổi bạn đã thực hiện có thể không được lưu. Bạn có chắn chắn muốn hủy thay đổi?'

  return useMemo(() => {
    const close = () => dispatch(hideModalConfirm())

    return {
      showModalConfirm: (payload: ModalConfirmType) => dispatch(showModalConfirm(payload)),
      hideModalConfirm: () => dispatch(hideModalConfirm()),
      showModalConfirmRedirect: (confirm: () => void) => {
        const excute = () => {
          dispatch(hideModalConfirm())
          confirm()
        }
        dispatch(
          showModalConfirm({
            title: 'Thông báo',
            content: confirmRedirectContent,
            confirm: {
              text: isAdmin ? 'Tiếp tục chỉnh sửa' : 'Hủy thay đổi',
              action: isAdmin ? close : excute,
            },
            cancel: {
              text: isAdmin ? 'Huỷ' : 'Thoát',
              action: isAdmin ? excute : close,
            },
            onBackdropClick: close,
            small: isAdmin,
            center: isAdmin,
          })
        )
      },
    }
  }, [dispatch])
}
