import { FETCH_CATEGORIES } from 'store/actionTypes'
import { DispatchType } from 'types'
import ApiRequest from 'services/api'
import { apiUrls } from 'configs/apis'
import { createLinkSeo, getProductsURL } from 'utils'
import { CATEGORY_TYPES } from 'configs/constants'

const { MANUFACTURER, MODEL, CATEGORY } = CATEGORY_TYPES

/**
 * Add categories to redux
 * @param categories
 * @returns
 */
export const addCategories = (callback?: any) => async (dispatch: DispatchType) => {
  return await ApiRequest.get(apiUrls.getMenu(), {}, ({ status, data }) => {
    if (status) {
      const arr = [
        ...data.filter((item: any) => item.type === CATEGORY),
        {
          name: 'Hãng sản xuất',
          sub: data.filter((item: any) => item.type === MANUFACTURER),
          type: MANUFACTURER,
        },
      ]

      const flattenArr: any[] = []

      // If item is not array or empty array => return null
      const getArray = (item: any) => {
        if (!item?.length) return null
        return item
      }

      // Update link seo for all items
      const updateLinkSeo = (arr: any[], parent?: any, level?: number) => {
        arr.forEach((item) => {
          const hasOtherProduct = item.other_product
          const linkSeo = item.link_seo || createLinkSeo(item.name || '')
          let linkFull
          if (parent) {
            // if ((level || 0) > 1) linkSeo = parent.linkSeo + '-' + linkSeo
            item.type = parent.type
            if (parent?.hasModel) item.type = MODEL
            linkFull = parent.linkFull + '/' + linkSeo
          } else {
            linkFull = item.type === MANUFACTURER ? '/' + linkSeo : '/danh-muc/' + linkSeo
          }
          item.linkSeo = linkSeo
          const { id, name, image_url: image, type, other_product_count } = item
          const sub = getArray(item.sub) || getArray(item.models) || getArray(item.children) || []
          item.sub = sub
          if (!item.sub.length) linkFull = getProductsURL(linkSeo)
          item.linkFull = linkFull
          const newItem = {
            id,
            linkSeo,
            linkFull,
            image,
            name,
            parent,
            sub,
            type,
            hasOtherProduct,
            other_product_count,
            hasModel: !!getArray(item.models),
          }
          if (sub.length) updateLinkSeo(sub, newItem, (level || 0) + 1)
          flattenArr.push(newItem)
        })
      }

      updateLinkSeo(arr)

      dispatch({
        type: FETCH_CATEGORIES,
        payload: { menu: arr, flattenMenu: flattenArr },
      })

      callback?.()
    }
  })
}
