/* eslint-disable */
import { Checkbox, CircularProgress, MenuItem, Popover } from '@mui/material'
import { Loading } from 'components/common'
import React, { useRef, useState } from 'react'
import SimpleBar from 'simplebar-react'
import InputSearch from '../input/InputSearch'

import { Waypoint } from 'react-waypoint'

interface Props {
  data?: any[]
  placeholder?: string
  onSearch?: (search: string) => void
  maxHeight?: number
  disabled?: boolean
  checkbox?: {
    name: string
    selected: any[]
    onChange: (value: any[]) => void
  }
  setSelected?: (value: string, data?: {}) => void
  selected?: string
  labelNoItems?: string
  maxLength?: number
  loadingSearch?: boolean

  onIncreasePage?: () => void
  showLoadmore?: boolean
}

const SearchSelect: React.FC<Props> = (Props) => {
  const anchorRef = useRef<HTMLInputElement>(null)

  const [open, setOpen] = useState(false)

  const {
    data,
    placeholder,
    onSearch,
    maxHeight = 360,
    disabled,
    checkbox,
    selected,
    setSelected,
    labelNoItems = 'Không có giá trị',
    maxLength = 50,
    loadingSearch = false,
    showLoadmore,

    onIncreasePage,
  } = Props

  const {
    name: checkboxName,
    selected: checkboxSelected,
    onChange: checkboxOnChange,
  } = checkbox || { name: '', onChange: () => {}, selected: [] }
  const checkboxSelectedIds = checkboxSelected?.map((item) => item.id)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleClickItem = (item: any) => {
    setSelected?.(item.value, item)
    setOpen(false)
  }

  return (
    <div ref={anchorRef} className="w-100">
      <InputSearch
        fullWidth
        placeholder={placeholder}
        onSearch={(search) => onSearch?.(search)}
        onClick={disabled ? undefined : handleOpen}
        style={{ maxWidth: '100% !important' }}
        maxLength={maxLength}
      />
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            width: anchorRef.current?.getBoundingClientRect().width || 0,
            marginTop: 7,
          },
        }}
        disableAutoFocus
        open={open}
        onClose={() => handleClose()}
        anchorEl={anchorRef.current}
      >
        {data?.length === 0 && (
          <>
            {loadingSearch ? null : (
              <div
                className="Select-item"
                style={{
                  padding: '10px 16px',
                  height: 38,
                }}
              >
                {labelNoItems}
              </div>
            )}
          </>
        )}
        <SimpleBar className="SelectPaper-paper" style={{ maxHeight }} autoHide={false}>
          {loadingSearch ? (
            <Loading style={{ minHeight: 300 }} />
          ) : (
            <>
              {!!checkbox
                ? data?.map(({ label, value, data }) => (
                    <div
                      className="Select-item d-f ai-c"
                      key={value}
                      //   style={{
                      //     display: value === selected || hide ? 'none' : undefined,
                      //   }}
                    >
                      <Checkbox
                        onChange={() =>
                          checkboxOnChange(
                            checkboxSelected.some((item1) => item1.id === value)
                              ? checkboxSelected.filter((i) => i.id !== value)
                              : [
                                  ...(checkboxSelected || []),
                                  { id: value, [checkboxName]: label, data },
                                ]
                          )
                        }
                        checked={checkboxSelectedIds.includes(value)}
                        style={{ height: 36, width: 36, marginLeft: 8 }}
                      />
                      <div dangerouslySetInnerHTML={{ __html: label }} style={{ width: '100%' }} />
                    </div>
                  ))
                : data?.map((item) => (
                    <MenuItem
                      //   className={`Select-item${selected === item.value ? ' active' : ''}`}
                      key={item.value}
                      disabled={item.disabled}
                      onClick={() => handleClickItem(item)}
                      style={{
                        display: item.value === selected || item.hide ? 'none' : undefined,
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        <div dangerouslySetInnerHTML={{ __html: item.label }} />
                      </div>
                    </MenuItem>
                  ))}
            </>
          )}
          {showLoadmore && !!data?.length && (
            <MenuItem>
              <div className="d-f jc-c w-100">
                <Waypoint
                  onEnter={() => {
                    onIncreasePage?.()
                  }}
                >
                  <CircularProgress size={20} color="inherit" />
                </Waypoint>
              </div>
            </MenuItem>
          )}
        </SimpleBar>
      </Popover>
    </div>
  )
}

export default SearchSelect
