import { useTranslation } from 'react-i18next'
import { useIsMobile } from 'store/mobile'
import './TabsItem.scss'

interface Props {
  label: string
  open?: boolean
  active?: any
  isPlus?: boolean
  onClick?: () => void
  disabled?: boolean
}

const TabsItem: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { label, open, active, isPlus, onClick, disabled } = props
  const isMobile = useIsMobile()

  return (
    <div
      className={`TabsItem-wrapper ${open ? 'open' : ''} ${disabled ? 'disabled' : ''} ${
        isPlus ? 'isPlus' : ''
      } ${active ? 'active' : ''}`}
      onClick={onClick}
    >
      {isPlus && <i className="mn-icon-plus" />}
      <div className="TabsItem-wrapper__label">{`${t(label)}`}</div>
      {!isPlus && isMobile && <i className="mn-icon-arrow" />}
    </div>
  )
}

export default TabsItem
