/*eslint-disable */
import { ClickAwayListener, Tooltip } from '@mui/material'
import copy from 'copy-to-clipboard'
import { useState } from 'react'
import QRCode from 'assets/images/QRCode.png'
import Lightbox from 'react-image-lightbox'
import { isMobile } from 'utils'

interface Props {
  dropdown?: boolean
  showDropDown?: any
  setShowDropDown?: (showDropDown: boolean) => any
  bankList?: any
  bankTranfer?: any
  handleChangeBank?: any
  hideQR?: boolean
  content?: string
  finalPrice?: string | number
  // anchorRef?: any
}

const BankingForm: React.FC<Props> = ({
  dropdown,
  showDropDown = false,
  setShowDropDown,
  bankList,
  bankTranfer,
  handleChangeBank,
  hideQR,
  content,
  finalPrice,
}: // anchorRef,
Props) => {
  const [copyBank, setCopyBank] = useState(false)
  const [imgPreview, setImgPreview] = useState({ open: false, src: '' })

  const {
    account_name: cardHolder,
    bank_name: nameBank,
    bank_number: bankNumber,
    momo_phone: phoneNumber,
    bank_key,
  } = bankTranfer || {}

  /**
   * handle Copy
   */
  const handleCopyBank = (e: any, content: string) => {
    e.stopPropagation()
    setCopyBank(true)
    copy(content)
    setTimeout(() => {
      setCopyBank(false)
    }, 1000)
  }

  if (dropdown && (bankList?.length || 0) <= 1) dropdown = false

  const handleClickDropdown = () => {
    if (dropdown) {
      return setShowDropDown?.(!showDropDown)
    }
    return null
  }

  const handleToggleImgPreview = (isOpen = false, image: any) => {
    setImgPreview({ open: isOpen, src: image })

    if (isOpen && isMobile()) {
      document.body.classList.add('mobile')
      return
    }
    document.body.classList.remove('mobile')
  }

  const bankClassName = `BankingForm-bankName label${dropdown ? ' mr' : ''}`

  const images = `https://img.vietqr.io/image/${
    bank_key || 'Techcombank'
  }-${bankNumber}-qr_only.png?amount=${finalPrice}&addInfo=${content}`

  return (
    <ClickAwayListener onClickAway={() => setShowDropDown?.(false)}>
      <div className="BankingForm">
        {bankList && <div className="BankingForm-title label gray">Ngân hàng</div>}
        <div className="d-f ai-c ">
          <div
            className={`BankingForm-bankingNumberWrapper ${showDropDown ? 'showDropDown' : ''}`}
            onClick={handleClickDropdown}
          >
            <div className={bankClassName}>{nameBank}</div>
            <div className={bankClassName}>Chủ tài khoản: {cardHolder}</div>
            {bankList && <div className={bankClassName}>Số tài khoản: {bankNumber}</div>}
            {phoneNumber && <div className={bankClassName}>Số điện thoại: {phoneNumber}</div>}

            {!!dropdown && <i className="mn-icon-dropdown icon " />}

            <div
              className="BankingForm-copy"
              onClick={(e: any) => handleCopyBank(e, bankNumber || phoneNumber || '')}
              // ref={anchorRef}
            >
              <ClickAwayListener onClickAway={() => setCopyBank(false)}>
                <div className="BankingForm-widthCopy">
                  <i className="mn-icon-copy" />
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setCopyBank(false)}
                    open={copyBank}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Sao chép"
                  >
                    {bankList ? (
                      <span className="label">Sao chép STK</span>
                    ) : (
                      <span className="label">Sao chép số điện thoại</span>
                    )}
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </div>
            {dropdown && (
              <ul className={`BankingForm-listBank ${showDropDown ? 'showDropDown' : ''}`}>
                {bankList?.map((bank: any) => (
                  <li
                    key={bank?.id}
                    className={`BankingForm-itemBank${bankTranfer === bank ? ' active' : ''}`}
                    onClick={() => handleChangeBank(bank?.id || '')}
                  >
                    {bank?.bank_name}
                  </li>
                ))}
              </ul>
            )}
          </div>
          {!hideQR && (
            <div className="BankingForm-QRCode">
              <img
                src={images}
                alt="QR code"
                onClick={() => handleToggleImgPreview(true, images)}
              />
            </div>
          )}
        </div>
        {imgPreview.open && (
          <Lightbox
            wrapperClassName={`ImageViewer-container`}
            reactModalStyle={{ overlay: { zIndex: 9999 } }}
            enableZoom={false}
            mainSrc={imgPreview.src}
            nextSrc={undefined}
            prevSrc={undefined}
            onMovePrevRequest={() => {}}
            onMoveNextRequest={() => {}}
            onCloseRequest={() => handleToggleImgPreview(false, imgPreview.src)}
            animationDuration={600}
          />
        )}
      </div>
    </ClickAwayListener>
  )
}
export default BankingForm
