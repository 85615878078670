import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import qs from 'qs'

import { useApis } from 'services/api'
import { useBreadcrumbs } from 'store/breadcrumbs'

import { RelativeProducts } from 'components/screens'

import { apiUrls } from 'configs/apis'
import { ProductType } from 'types'
import { BASIC_PAGE_SIZE } from 'configs/constants'
import { useHistory } from 'store/confirmRedirect'

export default function SimilarProducts() {
  const history = useHistory()
  const { showBreadcrumbs } = useBreadcrumbs()
  const { search } = useLocation()
  const { apiPost } = useApis()

  // states
  const [isLoading, setIsLoading] = useState(true)
  const [productsData, setProductsData] = useState<{
    products: ProductType[]
    total: number
    totalPages: number
  }>({
    products: [],
    total: 0,
    totalPages: 0,
  })

  const queryParams = qs.parse(search, { ignoreQueryPrefix: true })
  const { categoryId, brandId, page: queryPage } = queryParams
  const queryPageNum = Number(queryPage || 1)

  /**
   * Get similar products
   *
   * @param page Page number
   */
  const getSimilarProductsApi = useCallback(
    async (page: number) => {
      setIsLoading(true)

      try {
        const res = await apiPost(apiUrls.filterProducts(), {
          page,
          page_size: BASIC_PAGE_SIZE,
          category_ids: [categoryId],
          manufacturer_ids: [brandId],
        })
        setIsLoading(false)

        const { status, data = {} } = res
        const { items, total_pages: totalPages } = data || {}
        if (status) setProductsData({ ...data, products: items || [], totalPages })
      } catch (e) {
        setIsLoading(false)
      }
    },
    [categoryId, brandId]
  )

  useEffect(() => {
    showBreadcrumbs([{ name: 'similarProducts', path: '/' }])
  }, [])

  useEffect(() => {
    getSimilarProductsApi(queryPageNum)
  }, [queryPageNum])

  /**
   * Handle change page
   *
   * @param page Page
   */
  const handleChangePage = (page: number) => {
    const requestParams = { categoryId, brandId, page }

    history.push({
      pathname: '/san-pham-tuong-tu',
      search: qs.stringify(requestParams),
    })
  }

  return (
    <RelativeProducts
      {...productsData}
      title="similarProducts"
      initialPage={queryPageNum}
      isLoading={isLoading}
      onChangePage={handleChangePage}
    />
  )
}
