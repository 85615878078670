import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useShoppingCart } from 'store/shoppingCart'
import { useToasts } from 'store/toasts'

import { Button, ButtonOptions } from 'components'
import { InputQuantity } from 'components/common'
import { formatPhoneNumber, formatPrice, formatQuantity, useMobile } from 'utils'
import { ProductType } from 'types'
import { useModalConfirm } from 'store/modalConfirm'
import { useAuth } from 'store/auth'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import { PHONE_NUMBER } from 'configs/constants'

interface PropTypes {
  product: ProductType
  products: { current: number; items: ProductType[] }
  setProducts: any
}

export default function DetailIntro(props: PropTypes) {
  const { t } = useTranslation()
  const { isAuth } = useAuth()
  const { addItemIntoCart, buyNow, getCountProductInCart } = useShoppingCart()
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()
  const { apiGet } = useApis()
  const [loading, setLoading] = useState(false)
  const { createToast } = useToasts()
  const isMobile = useMobile()

  const { product, products, setProducts } = props
  const {
    id = '',
    name = '',
    inventory_number = 0,
    price = 0,
    final_price = 0,
    // discount_percent,
    // discount_value,
    short_description,
  } = product || {}

  // states
  const [quantity, setQuantity] = useState<number | string>(1)
  const [error, setError] = useState('')

  const countProductInCart = getCountProductInCart(id)

  // Get some infomation
  const { lbAvailable, isOutOfStock, oldPrice, newPrice, isPriceZero } = useMemo(
    () => ({
      isPriceZero: price === 0,
      isOutOfStock: inventory_number < 1,
      lbAvailable: t('lbAvailable', { quantity: formatQuantity(inventory_number) }),
      // ...getProductPrice(price, discount_percent, discount_value),
      oldPrice: final_price === price ? null : formatPrice(price),
      newPrice: formatPrice(final_price),
    }),
    [product]
  )

  const showToast = () =>
    createToast({
      type: 'success',
      message: { title: 'addToCart', content: 'addedIntoCart' },
      duration: 1500,
    })

  const checkProduct = async () => {
    if (isAuth) return true
    try {
      const { status, text } = await apiGet(apiUrls.getProductDetail(id))
      if (!status) createToast({ message: { content: text }, type: 'error' })
      return status
    } catch (e) {}
  }

  /**
   * Handle add to cart
   */
  const handleAddToCart = async () => {
    setError('')

    if (!(await checkProduct())) return

    addItemIntoCart({ ...product, quantity }, showToast)

    if (Number(quantity) + countProductInCart > inventory_number) {
      showModalConfirm({
        title: 'Thông báo',
        content:
          'Không thể thêm số lượng đã chọn vào giỏ hàng vì sẽ vượt quá giới hạn mua hàng của bạn',
        confirm: {
          text: 'Đã hiểu',
          action: hideModalConfirm,
        },
      })
    } else {
      if (!isAuth) showToast()
    }
  }

  const handleBuyNow = async () => {
    if (!(await checkProduct())) return

    setLoading(true)
    buyNow(product, Number(quantity), () => setLoading(false), showToast)
  }

  const renderButtonContac = (isPrice?: boolean) => (
    <div className="ProductDetailIntro-actions" style={{ marginBottom: isMobile ? 12 : 24 }}>
      {!isMobile ? (
        <div className="ProductDetailIntro-hotline-pc">
          <span>
            Liên hệ hotline để biết thêm thông tin {isPrice ? 'giá' : ''}
            <i className="mn-icon-phone-call" />
          </span>
          <span>{formatPhoneNumber(PHONE_NUMBER)}</span>
        </div>
      ) : (
        <Button
          borderRadius={0}
          icon="phone-call"
          small
          color="secondary"
          className="ProductDetailIntro-hotline"
          onClick={() => window.open(`tel:+84${PHONE_NUMBER}`, '_self')}
        >
          Liên hệ hotline để biết thêm thông tin
        </Button>
      )}
    </div>
  )

  return (
    <div className="ProductDetailIntro-info">
      <h5 className="ProductDetailIntro-title">{name}</h5>
      {!isMobile && <p className="ProductDetailIntro-short_description">{short_description}</p>}
      {isPriceZero ? (
        <>
          {renderButtonContac(true)}
          {isMobile && <p className="ProductDetailIntro-short_description">{short_description}</p>}
        </>
      ) : (
        <div className="ProductDetailIntro-price">
          {oldPrice && (
            <p>
              <del>{oldPrice}</del>
            </p>
          )}
          <p className="price">{newPrice}</p>
          {isOutOfStock && !isMobile && (
            <p className="ProductDetailIntro-availabelQuantity sm label gray big-height">
              {t('outOfStock')}
            </p>
          )}
          {isMobile && <p className="ProductDetailIntro-short_description">{short_description}</p>}
        </div>
      )}
      <div className="ProductDetailIntro-wrapperOptions">
        <div className="ProductDetailIntro-category">
          <span className="label gray">{t('productPortfolio')}: </span>
          <span className="categoryName">{product.category?.name}</span>
        </div>
        {products.items.filter((item) => item.manufacturer).length > 0 && (
          <ButtonOptions
            className="ProductDetailIntro-options"
            label="brand"
            value={products.current}
            options={products.items.map((item, index) => ({
              value: index,
              label: item.manufacturer?.name,
            }))}
            onClick={(value) => setProducts((old: any) => ({ ...old, current: value }))}
          />
        )}
      </div>
      {isOutOfStock ? (
        <div className="ProductDetailIntro-outOfStock">
          <span>{t('outOfStock')}</span>
          {renderButtonContac()}
        </div>
      ) : (
        <>
          <div
            className="ProductDetailIntro-quantityWrapper"
            style={{
              marginBottom: isPriceZero && isMobile ? 24 : 0,
            }}
          >
            <InputQuantity
              className="ProductDetailIntro-quantity"
              label="quantity"
              max={inventory_number - countProductInCart || inventory_number}
              value={quantity}
              onChange={setQuantity}
              error={error}
              setError={setError}
            />
            <span className="ProductDetailIntro-availabelQuantity label gray">{lbAvailable}</span>
          </div>
          {!!error && <div className="ProductDetailIntro-invalid form-invalid">{error}</div>}
          {isPriceZero ? null : (
            <div className="ProductDetailIntro-actions">
              <Button
                color="secondary"
                className="ProductDetailIntro-btnAdd"
                onClick={handleAddToCart}
                icon="shopping-cart"
              >
                {t('addToCart')}
              </Button>
              <Button
                className="ProductDetailIntro-btnBuyNow"
                loading={loading}
                onClick={handleBuyNow}
              >
                {t('buyNow')} <span className="Ic-next icon" />
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}
