import {
  Card,
  TableContainer,
  Table as TableWrapper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Select,
  MenuItem,
  Stack,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Row from './Row'
import './index.scss'
import { useState, useMemo, useEffect } from 'react'
import { RowType, TableFieldsType, TableSortType } from 'types'
import Pagination from './Pagination'
import { addNumber, formatCompare } from 'utils'
import { isNumber, isString } from 'lodash'
import { Loading } from 'components/common'
import ShowHideColumns from './ShowHideColumns'
import { apiUrls } from 'configs/apis'
import { LS_SHOWN_COLUMNS } from 'configs/constants'
import { useApis } from 'services/api'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Button from '../button/Button'

interface Props {
  items: RowType[]
  fields: TableFieldsType
  loading?: boolean
  header?: React.ReactChild
  headerRight?: React.ReactChild
  minWidth?: any
  sortBackend?: {
    field: string
    order: 'asc' | 'desc'
    onChange: (params: TableSortType) => void
  }
  sortFrontend?: {
    field: string
    order: 'asc' | 'desc'
  }
  pagination?: {
    total: number
    pageSize: number
    page: number
    onPageChange?: (page: number) => void
    onPageSizeChange?: (pageSize: number) => void
    itemsLabel: string
    extraLabel?: string
  }
  btnAdd?: any
  isFiltered?: boolean
  onClickItem?: (item: RowType) => void
  onMiddleClickItem?: (item: RowType) => void

  hiddenColumns?: string[]
  name?: string
  showHideColumnsWidth?: number
  showPopupShowHideColumns?: boolean

  importButton?: { action?: () => void; disabled?: boolean; title?: string } | any
  exportButton?: { action?: () => void; disabled?: boolean; title?: string } | any

  labelNoItem?: string
  onDragDrop?: (positionDrag: number, positionDrop: number) => void
}

const getAllTablesShownColumns = () => {
  const allTablesShownColumns = localStorage.getItem(LS_SHOWN_COLUMNS)
  if (!allTablesShownColumns) return

  try {
    const data = JSON.parse(allTablesShownColumns)
    return data
  } catch {}
}

const DataTable: React.FC<Props> = ({
  items,
  fields,
  loading,
  header,
  headerRight,
  sortBackend,
  sortFrontend,
  minWidth,
  pagination = {
    total: 0,
    pageSize: 0,
    page: 0,
    onPageChange: () => {},
    onPageSizeChange: () => {},
    itemsLabel: '',
    extraLabel: '',
  },
  btnAdd,
  isFiltered,
  onClickItem,
  onMiddleClickItem,
  hiddenColumns = [],
  name,
  showHideColumnsWidth,
  showPopupShowHideColumns,
  importButton,
  exportButton,
  labelNoItem,
  onDragDrop,
}) => {
  const { t } = useTranslation()
  const { apiPut } = useApis()
  const {
    total,
    page,
    onPageChange,
    pageSize,
    onPageSizeChange,
    itemsLabel,
    extraLabel = '',
  } = pagination
  const [sort, setSort] = useState({
    field: sortBackend?.field || sortFrontend?.field || '',
    order: sortBackend?.order || sortFrontend?.order || 'asc',
  })

  // const allFieldsObj = JSON.parse(JSON.stringify(fields))
  // delete allFieldsObj.actions
  const allFields = Object.keys(fields)
  const saveShownColumns: string[] = useMemo(() => {
    const data = getAllTablesShownColumns()
    return data?.[name || '']
  }, [])

  const [shownColumns, setShownColumns] = useState(
    saveShownColumns || allFields.filter((item) => !hiddenColumns.includes(item))
  )

  const colsLocal = getAllTablesShownColumns()?.[name || '']
  useEffect(() => {
    if (colsLocal?.length) setShownColumns(colsLocal)
  }, [JSON.stringify(colsLocal)])

  // Add data for column no.

  const hiddenFields = allFields.filter((item) => !shownColumns.includes(item))
  const shownFields: TableFieldsType = { ...fields }

  hiddenFields.forEach((item) => {
    delete shownFields[item]
  })

  const columns = Object.keys(shownFields).map((item) => ({
    key: item,
    label: fields[item].label,
    sort: fields[item].sort,
  }))

  const hasItems = items.length > 0

  const renderLoading = (
    <TableRow className={`DataTable-loading${hasItems ? ' has-items' : ' no-items'}`}>
      <TableCell colSpan={columns.length}>
        <Loading style={{ width: 50 }} />
      </TableCell>
    </TableRow>
  )

  const rows = [15, 25, 50, 100]

  const totalPages = Math.ceil(total / pageSize)

  // Get amount of records in this page
  const totalRows = page * pageSize <= total ? pageSize : total % pageSize

  let newItems = items
  if (!sortBackend && sort.field) {
    // Sort data
    newItems = newItems
      .sort((a, b) => {
        const key = sort.field
        let valueA = a[key]
        let valueB = b[key]
        if (isString(valueA)) valueA = formatCompare(valueA)
        if (isString(valueB)) valueB = formatCompare(valueB)
        const multiple = sort.order === 'asc' ? 1 : -1
        if (valueA === valueB) {
          valueA = a['number']
          valueB = b['number']
        }
        return (valueA > valueB ? 1 : -1) * multiple
      })
      .map((item: any, index: number) => addNumber(item, index, page, pageSize))
  }

  const shownColumnsLength = Object.keys(shownFields).length
  const extraMinWidth = shownColumnsLength > 12 ? (shownColumnsLength - 12) * 110 : 0

  if (isNumber(minWidth)) minWidth += extraMinWidth

  const dnd = !!onDragDrop // Check drag drop

  // Drag Drop table

  const handleDragEnd = (result: any) => {
    const { source, destination } = result
    if (!destination) return
    if (source.index === destination.index) return
    onDragDrop?.(source.index, destination.index)
  }

  const renderTable = dnd ? (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="table-body">
        {(dropProvided) => (
          <TableWrapper className={`DataTable-table${items.length ? '' : ' noItems'}`}>
            <TableBody {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
              {items.length > 0 ? (
                <>
                  <TableRow style={{ height: 0, padding: 0 }}>
                    <TableCell
                      style={{
                        width: '5%',
                        height: 0,
                        padding: 0,
                        border: 'none',
                      }}
                    ></TableCell>
                    {columns.map(({ key }) => (
                      <TableCell
                        key={key}
                        style={{
                          ...fields[key].style,
                          height: 0,
                          padding: 0,
                          border: 'none',
                        }}
                      />
                    ))}
                  </TableRow>
                  {newItems.map((item, index) => (
                    <Draggable draggableId={item.id} key={item.id} index={index}>
                      {(provided, snapshot) => {
                        let newDnd: any
                        if (dnd)
                          newDnd = {
                            innerRef: isFiltered ? undefined : provided.innerRef,
                            dragHandleProps: isFiltered ? undefined : provided.dragHandleProps,
                            draggableProps: isFiltered ? undefined : provided.draggableProps,
                            isDragging: isFiltered ? undefined : snapshot.isDragging,
                          }

                        return (
                          <Row
                            dnd={newDnd}
                            dndDisable={isFiltered}
                            key={item.id || index}
                            fields={shownFields}
                            row={item}
                            onClick={onClickItem}
                            onMiddleClick={onMiddleClickItem}
                          />
                        )
                      }}
                    </Draggable>
                  ))}
                  {dropProvided.placeholder}
                  {loading && renderLoading}
                </>
              ) : !loading ? (
                <TableRow>
                  <TableCell align="center" colSpan={columns.length} sx={{ py: 5 }}>
                    {isFiltered ? (
                      'Không có kết quả'
                    ) : (
                      <div className="DataTable-button">
                        <div style={{ fontSize: 17, marginBottom: 18 }}>
                          {labelNoItem ? labelNoItem : ` Chưa có ${itemsLabel} ${extraLabel}`}
                        </div>
                        <div>{btnAdd}</div>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ) : (
                renderLoading
              )}
            </TableBody>
          </TableWrapper>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <TableWrapper className={`DataTable-table${hasItems ? '' : ' noItems'}`}>
      <TableBody>
        {hasItems ? (
          <>
            {newItems.map((item, index) => (
              <Row
                key={item.id || index}
                fields={shownFields}
                row={item}
                onClick={onClickItem}
                onMiddleClick={onMiddleClickItem}
              />
            ))}
            {loading && renderLoading}
          </>
        ) : !loading ? (
          <TableRow>
            <TableCell align="center" colSpan={columns.length} sx={{ py: 5 }}>
              {isFiltered ? (
                'Không có kết quả'
              ) : (
                <div className="DataTable-button">
                  <div style={{ fontSize: 17, marginBottom: 18 }}>
                    {labelNoItem ? labelNoItem : ` Chưa có ${itemsLabel} ${extraLabel}`}
                  </div>
                  <div>{btnAdd}</div>
                </div>
              )}
            </TableCell>
          </TableRow>
        ) : (
          renderLoading
        )}
      </TableBody>
    </TableWrapper>
  )

  return (
    <Card className="DataTable" style={{ padding: '20px 0 24px' }}>
      {!!header && (isFiltered || hasItems) && (
        <div>
          <Stack className="DataTable-header" flexDirection="row" gap={3}>
            <div className="d-f jc-sb w-100">
              <div className="d-f ai-c space-2" style={{ flexWrap: 'wrap' }}>
                {header}
              </div>
              <div className="d-f ai-c jc-sb">
                {headerRight}
                {exportButton && (
                  <Button
                    variant="contained"
                    style={{ visibility: 'visible', marginRight: 10 }}
                    disabled={loading || exportButton.disable}
                    onClick={exportButton.action}
                    // title={exportButton.title}
                  >
                    <i className="fas fa-upload" style={{ marginRight: 8 }} />
                    {exportButton.title}
                  </Button>
                )}
                {importButton && (
                  <Button
                    variant="contained"
                    style={{ visibility: 'visible', marginRight: 10 }}
                    disabled={loading}
                    onClick={importButton.action}
                    // title={importButton.title}
                  >
                    <i className="fas fa-download" />
                    {importButton.title}
                  </Button>
                )}
                {btnAdd}
              </div>
            </div>
          </Stack>
          {showPopupShowHideColumns && (
            <div className="" style={{ textAlign: 'right' }}>
              <ShowHideColumns
                columns={allFields}
                defaultShownColumns={shownColumns}
                save={(shown) => {
                  const data = getAllTablesShownColumns() || {}
                  if (shown) data[name || ''] = shown
                  else delete data[name || '']
                  localStorage.setItem(LS_SHOWN_COLUMNS, JSON.stringify(data))
                  setShownColumns(
                    shown || allFields.filter((item) => !hiddenColumns.includes(item))
                  )
                  apiPut(apiUrls.adminSetting(), { display_column: data })
                }}
                width={showHideColumnsWidth}
                hiddenColumns={hiddenColumns}
              />
            </div>
          )}
        </div>
      )}

      <div className="Scrollbar bold">
        <TableContainer sx={{ minWidth: hasItems ? minWidth || 1000 : '100%' }}>
          {hasItems && (
            <TableWrapper className="DataTable-table">
              <TableHead>
                <TableRow>
                  {!!dnd && (
                    <TableCell style={{ width: '5%', textAlign: 'center' }}>Thứ tự</TableCell>
                  )}
                  {columns.map(({ key, label, sort: s }) => {
                    label = t(label || key)
                    const active = sort.field === key

                    if (!fields[key].style) fields[key].style = {}
                    if (fields[key].right)
                      fields[key].style = {
                        ...fields[key].style,
                        paddingRight: 8,
                        textAlign: 'right',
                      }

                    return (
                      <TableCell key={key} style={fields[key].style}>
                        {!s ? (
                          label
                        ) : (
                          <div
                            className={`DataTable-table__sort-cell ${active ? sort.order : s}${
                              active ? ' active' : ''
                            }`}
                            onClick={() => {
                              const newSort = {
                                field: key,
                                order: active ? (sort.order === 'asc' ? 'desc' : 'asc') : s,
                              }
                              setSort(newSort)
                              sortBackend?.onChange(newSort)
                            }}
                          >
                            {label}
                            <i className="fas fa-arrow-down" />
                          </div>
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
            </TableWrapper>
          )}
          {renderTable}
        </TableContainer>
      </div>

      {hasItems && (
        <Box className="Pagination" display="flex" justifyContent="center" pt={2}>
          {total > 0 && (
            <>
              <Pagination
                count={totalPages}
                page={page}
                onChange={(page) => onPageChange?.(page)}
                disabled={loading}
              />
              <Stack
                className={`Pagination-right${loading ? ' disabled' : ''}`}
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={2}
              >
                {!!onPageSizeChange && (
                  <Select
                    className="ChangePageSize"
                    size="small"
                    value={pageSize}
                    onChange={(p) => onPageSizeChange?.(Number(p.target.value))}
                    disabled={loading}
                  >
                    {rows.map((item) => (
                      <MenuItem
                        key={item}
                        className="ChangePageSize-item"
                        style={{ display: item === pageSize ? 'none' : undefined }}
                        value={item}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                <div>
                  {totalPages === 1 ? (
                    totalRows
                  ) : (
                    <>
                      {totalRows}/{total}
                    </>
                  )}{' '}
                  {itemsLabel}
                </div>
              </Stack>
            </>
          )}
        </Box>
      )}
    </Card>
  )
}

export default DataTable
