/* eslint-disable */
import { useTranslation } from 'react-i18next'
import { Button, Container } from '@mui/material'

import { AppLinks } from './modules'
import { INFO_MENU, PHONE_NUMBER } from 'configs/constants'
import { formatPhoneNumber } from 'utils'
import { useCategories } from 'store/categories'

import zalo from 'assets/images/zalo.png'
import zaloShare from 'assets/images/zalo_sharelogo.png'
import facebook from 'assets/images/facebook.png'
import youtube from 'assets/images/youtube.png'
import contact from 'assets/images/contact.png'
import { useApp } from 'store/app'
import { useMemo } from 'react'
import { isEmpty } from 'lodash'

const contactMenu = [
  {
    name: 'mayno.vn',
    path: 'https://zalo.me/3455131346017415940?gidzl=ukfn7IA3gbRcztCIAvIkLk_uTXGqbgXMyVebHZUGg57m-dKLFCAb0Q-eUq0_aV46_gr_4JRrMkXo9e6hNW',
    icon: 'zalo',
    image: zalo,
    isAHref: true,
  },
  {
    name: 'facebook.com/mayno.vn',
    path: 'https://www.facebook.com/profile.php?id=100086300058252',
    icon: 'facebook',
    isAHref: true,
    image: facebook,
  },
  {
    name: 'cskh@mayno.vn',
    path: 'mailto:cskh@mayno.vn',
    icon: 'contact',
    isAHref: true,
    image: contact,
  },
  {
    name: 'mayno.channel',
    path: 'https://www.youtube.com/@mayno-nomay',
    icon: 'youtobe',
    isAHref: true,
    image: youtube,
  },
]

export default function Footer() {
  const { t } = useTranslation()
  const { listCategories, listManufacturers } = useCategories()

  /**
   * Handle back to top
   */
  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  const thisPageLink = window.location.href

  const htmlChat = `<div
                      class="zalo-chat-widget" data-oaid="3455131346017415940"
                      data-welcome-message="Rất vui khi được hỗ trợ bạn!"  
                      data-autopopup="0" data-width="300" data-height="300">
                    </div>`
  const htmlZaloShare = `<div
                          class="zalo-share-button"
                          data-href="${thisPageLink}" data-oaid="3455131346017415940"
                          data-layout="2" data-color="blue" data-customize="true">
                            <img class="img-share" src="${zaloShare}" alt="" />
                          </div>`

  const htmlFbShare = `<div
                        class="fb-share-button "
                        data-layout="button"
                        data-size=""
                      >
                        <a
                          target="_blank"
                          href='https://www.facebook.com/sharer/sharer.php?u=${thisPageLink}'
                          class="fb-xfbml-parse-ignore"
                        >
                          <img src="${facebook}" alt="" class="img-share" />
                        </a>
                      </div>`

  const { aboutUs } = useApp()

  const { store_address, about_us, vision_mission, introduce } = aboutUs

  const listMenu = useMemo(() => {
    if (isEmpty(about_us) && isEmpty(store_address) && isEmpty(vision_mission)) {
      return INFO_MENU.filter((menuItem) => menuItem.path !== '/ve-chung-toi')
    }
    return INFO_MENU
  }, [aboutUs])

  return (
    <div className="Footer-area">
      <div dangerouslySetInnerHTML={{ __html: htmlChat }} />
      <Button className="Footer-goToTop" onClick={handleBackToTop}>
        <i className="mn-icon-arrow-to-top" />
      </Button>

      <div className="Footer-wrapperContainer">
        <div className="Footer-mainContainer">
          <Container className="Footer-container">
            <div className="Footer-company">
              <div className="AppHeader-logo" />
              {/* <p className="Footer-company_desc">
                <img src="/footer.png" alt="" style={{ width: '100%' }} />
              </p> */}
              <p
                className="introduce"
                dangerouslySetInnerHTML={{ __html: introduce?.content || '' }}
              ></p>
            </div>
            <div className="Footer-fork">
              <AppLinks name="product" items={listCategories.slice(0, 4)} />
              <AppLinks name="info" items={listMenu} />
              <AppLinks name="manufacturer" items={listManufacturers.slice(0, 6)} hideImage />
              <AppLinks name="contact" items={contactMenu} />
            </div>
            <AppLinks className="contact" items={contactMenu} />
          </Container>
        </div>
        <div className="Footer-copyright">
          <Container className="Footer-copyright_container">
            <span>{`© ${new Date().getFullYear()} - Bản quyền thuộc về mayno.vn`}</span>
          </Container>
        </div>
      </div>
      <a className="Footer-phone" href={`tel:+84${PHONE_NUMBER}`}>
        <span className="Ic-phone" />
        <span>{formatPhoneNumber(PHONE_NUMBER)}</span>
      </a>
      <div className="Footer-share">
        <span className="mn-icon-share icon"></span>
        <div className="d-f ai-c jc-c ml-1">
          <div dangerouslySetInnerHTML={{ __html: htmlFbShare }}></div>
          <div dangerouslySetInnerHTML={{ __html: htmlZaloShare }}></div>
        </div>
      </div>
    </div>
  )
}
