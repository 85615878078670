import React, { useEffect } from 'react'
import truck from 'assets/images/truck.png'
import { useTranslation } from 'react-i18next'
import { OrderDeliveryAddressType } from 'types'
import { Redirect, useLocation } from 'react-router-dom'
import { useBreadcrumbs } from 'store/breadcrumbs'
import { DELIVERY_METHODS, PAYMENT_METHODS } from 'configs/constants'
import { useMobile } from 'utils'

const { TRANSFER, PAYMENT_ON_DELIVERY } = PAYMENT_METHODS

const PaymentSuccess: React.FC = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const { showBreadcrumbs } = useBreadcrumbs()
  const isMobile = useMobile()

  if (!state) return <Redirect to="/" />

  const paymentInfo = state as any

  const {
    payment_method,
    order_payment_info = {},
    order_delivery_address = {} as OrderDeliveryAddressType,
    order_code,
    delivery_method,
  } = paymentInfo || {}

  const { city } = order_delivery_address

  const isHanoi = city === 'Hà Nội'

  useEffect(() => {
    !isMobile && showBreadcrumbs([{ name: 'payment', path: '/' }])
  }, [isMobile])

  return (
    <div className="PaymentLayout-wrapper">
      <div style={{ width: '100%' }}>
        <div className="PaymentLayout-title">{t('payment')}</div>
        <div className="PaymentSuccess">
          <div className="PaymentSuccess-container">
            <img src={truck} />
            <div className="PaymentSuccess-orderSuccess">Đặt hàng thành công</div>
            {delivery_method === DELIVERY_METHODS.GET_AT_ADDRESS && (
              <div className="label secondary bold" style={{ textAlign: 'left' }}>
                Chúng tôi sẽ giao hàng trong vòng {isHanoi ? '2-3' : '3-5'} ngày làm việc
              </div>
            )}
            {payment_method !== PAYMENT_ON_DELIVERY && (
              <div className="label gray text-left">
                Quý khách vui lòng chuyển khoản thanh toán theo thông tin:
                <br />
                {payment_method === TRANSFER ? (
                  <>
                    Ngân Hàng: <b>{order_payment_info.bank_name}</b>
                    <br />
                    Số Tài Khoản: <b>{order_payment_info.bank_number}</b>
                  </>
                ) : (
                  <>
                    Số Điện Thoại: <b>{order_payment_info.momo_phone}</b>
                  </>
                )}
                <br />
                Người Thụ Hưởng: <b>{order_payment_info.account_name}</b>
                <br />
                Nội dung: {order_delivery_address.phone} - Đơn hàng <b>#{order_code}</b>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentSuccess
