import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom'
import { RootState } from 'types'

interface PropTypes extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
}

export default function AdminRoute({ component: Component, ...rest }: PropTypes) {
  const isAdmin = useSelector(
    (state: RootState) => state.auth.isAdmin && !state.auth.force_change_password
  )

  return (
    <Route
      {...rest}
      render={(props) => (isAdmin ? <Component {...props} /> : <Redirect to="/admin/sign-in" />)}
    />
  )
}
