/*eslint-disable */
import { Chip, CircularProgress } from '@mui/material'
import { MAX_ATTACHMENT_SIZE } from 'configs/constants'
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useApis } from 'services/api'
import { useModalConfirm } from 'store/modalConfirm'
import { renderFileName, trimFileName } from 'utils'
import Button from '../button/Button'

interface Props {
  labelButton?: string
  style?: any
  file_url?: string
  onSuccess?: (url: string) => void
  setFileSize?: (fileSize: number) => void
  onClick?: (url: string) => void
  maxFileName?: number
  showConfirmDelete?: {
    title: string
    content: string
  }
  hideRemoveIcon?: boolean
  accept?: string
  maxSize?: number
  labelMaxsize?: string
  prefix?: string
  isUpdate?: boolean
}

const createFileName = (file: string) => {
  let newName: any = file.split('/')
  newName = newName[newName.length - 1]

  newName = trimFileName(newName)

  return newName
}

const UploadFile: React.FC<Props> = (Props) => {
  const inputRef = useRef<any>()
  const [file, setFile] = useState<any>({ url: '', fileSize: 0, name: '', loading: false })
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()

  const [fileName, setFileName] = useState('')

  const [loadingFilename, setLoadingFilename] = useState(true)

  const { apiPost, apiGet } = useApis()

  const {
    style,
    file_url,
    onSuccess,
    setFileSize,
    onClick,
    maxFileName,
    labelButton = 'Thêm file',
    showConfirmDelete,
    hideRemoveIcon,
    accept = 'application/pdf',
    maxSize,
    labelMaxsize = '50 MB',
    prefix = 'document',
    isUpdate,
  } = Props

  const showPopupCantUpload = () =>
    showModalConfirm({
      title: 'Thông báo',
      content: `Không thể upload file trên ${labelMaxsize}`,
      confirm: { action: hideModalConfirm, text: 'Đã hiểu' },
      small: true,
    })

  useEffect(() => {
    if (file.url !== file_url) setFile({ url: file_url, loading: false })
  }, [file_url])

  /**
   * Get upload link and Upload avatar to s3
   * @param fileUpload selected image
   */
  const uploadFile = (fileUpload: any) => {
    apiPost(
      '/api/v1/upload',
      {
        upload: { prefix, file: fileUpload, file_name: trimFileName(fileUpload.name, maxFileName) },
      },
      ({ status, data, text }) => {
        if (status) {
          onSuccess?.(data.file_url)
          setFile({ url: data.file_url, fileSize: file.size, loading: false })
          setFileSize?.(fileUpload.size / 1024 / 1024)
          setFile({ ...file, loading: false })
        } else {
          console.log(text)
        }
      },
      () => console.log('error')
    )
  }

  const handleUploadFile = useCallback((e: any) => {
    let file = e.target.files?.[0]
    if (!file) return

    // reader.onload = () => {
    if (file.size > (maxSize || MAX_ATTACHMENT_SIZE)) {
      showPopupCantUpload()
    } else {
      setFile({
        url: URL.createObjectURL(file),
        fileSize: file.size,
        loading: true,
      })
      setFileName(file.name)
      uploadFile(file)
    }

    e.target.value = null
  }, [])

  /**
   * remove file name
   */
  const removeFileName = (e: any) => {
    if (showConfirmDelete) {
      showModalConfirm({
        title: showConfirmDelete.title,
        content: showConfirmDelete.content,
        confirm: {
          action: () => {
            hideModalConfirm()
            setFile({ url: '', fileSize: 0, name: '', loading: false })
            onSuccess?.('')
          },
          text: 'Xóa',
        },
        cancel: { action: hideModalConfirm, text: 'Hủy bỏ' },
        small: true,
      })
    } else {
      setFile({ url: '', fileSize: 0, name: '', loading: false })
      onSuccess?.('')
    }
  }

  const { url, loading } = file

  const getFileName = () => {
    apiGet(
      '/api/v1/upload',
      {
        attached_files: [url],
      },
      ({ data, status }) => {
        if (status) {
          if (data.length) {
            setFileName(data[0].file_name)
          } else {
            setFileName(createFileName(url))
          }
        }

        setLoadingFilename(false)
      }
    )
  }

  useLayoutEffect(() => {
    if (isUpdate) {
      console.log('hehe')
      if (url?.length == 0) {
        setLoadingFilename(false)
      } else if (!url?.includes('blob')) {
        getFileName()
      }
    } else {
      setLoadingFilename(false)
    }
  }, [url?.length, isUpdate])

  return (
    <div style={style}>
      {loadingFilename ? (
        <CircularProgress size={16} color="inherit" style={{ marginLeft: 8 }} />
      ) : (
        <div>
          {!!file_url || (
            <Button
              onClick={() => inputRef.current?.click()}
              className=""
              variant="contained"
              color="primary"
              disabled={file.loading}
              loading={file.loading}
            >
              {labelButton}
            </Button>
          )}
          {!!file_url && (
            <Chip
              style={{ height: 36, maxWidth: '100%' }}
              label={renderFileName(fileName, maxFileName)}
              onDelete={hideRemoveIcon ? undefined : removeFileName}
              onClick={() => onClick?.(file_url)}
            />
          )}

          <input
            ref={inputRef}
            type="file"
            accept={accept}
            style={{ display: 'none' }}
            onChange={handleUploadFile}
          />
        </div>
      )}
    </div>
  )
}

export default UploadFile
