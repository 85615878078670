import * as Yup from 'yup'
import { Button, Input } from 'components'
import { validatePhone, validateTypingPhone, validatePassword } from 'utils'
import { Form, FormikProvider, useFormik } from 'formik'
import { Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import { useAuth } from 'store/auth'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import { LS_SIGN_IN_PASSWORD } from 'configs/constants'

interface Props {
  next: () => void

  setSendEmailSuccess: any
}

export default function SignIn({ next, setSendEmailSuccess }: Props) {
  const { t } = useTranslation()
  const [isOTP, setIsOTP] = useState(!localStorage.getItem(LS_SIGN_IN_PASSWORD))
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { signUp, forgotPassword, signInSuccess, updateAuth } = useAuth()
  const { apiPost } = useApis()

  let validatePasswordSchema: any = {}
  if (!isOTP)
    validatePasswordSchema = {
      password: Yup.string()
        .required()
        .min(8, 'password_format_is_incorrect')
        .matches(validatePassword(), 'password_format_is_incorrect'),
    }

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .required()
      .min(1, 'phone_format_is_incorrect')
      .matches(validatePhone(), 'phone_format_is_incorrect'),
    ...validatePasswordSchema,
  })

  const formik = useFormik({
    initialValues: {
      phone: '',
      password: '',
    },
    validationSchema,
    onSubmit: ({ phone, password }) => {
      setLoading(true)
      apiPost(
        // If user want to sign in by OTP, send OTP and go to enter OTP screen
        // If user want to sign in by password, call normal sign in
        isOTP ? apiUrls.sendOTP() : apiUrls.signIn(),
        { phone, password: isOTP ? undefined : password, action: 1 },
        ({ status, data, text }) => {
          if (status) {
            if (isOTP) {
              updateAuth({ phone, user_id: data?.user_id })
              setSendEmailSuccess(data?.verify_method === 2 ? true : false)
              next()
            } else {
              signInSuccess(data)
            }
          } else {
            setLoading(false)
            setError(text)
          }
        },
        () => setLoading(false)
      )
    },
  })

  const { errors, handleSubmit, getFieldProps, handleChange, isValid, validateForm } = formik

  const phoneRef = useRef<HTMLInputElement>()

  // Validate to disable button on startup
  useEffect(() => {
    setError('')
    validateForm()
    phoneRef.current?.focus()
  }, [isOTP])

  useEffect(() => {
    localStorage.removeItem(LS_SIGN_IN_PASSWORD)
  }, [])

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <Input
            autoFocus
            fullWidth
            type="tel"
            innerRef={phoneRef}
            {...getFieldProps('phone')}
            autoComplete="phone"
            error={(isOTP ? error : !!error) || (!!errors.phone && t(errors.phone?.toString()))}
            label={t('phone')}
            onChange={(e) => {
              if (error) setError('')
              if (e.target.value === '' || validateTypingPhone(e)) handleChange(e)
            }}
            disabled={loading}
            maxLength={20}
            required
            errorFocused={!!error}
          />
          {!isOTP && (
            <Input
              fullWidth
              type="password"
              autoComplete="password"
              {...getFieldProps('password')}
              error={error || (!!errors.password && t(errors.password?.toString()))}
              label={t('password')}
              onChange={(e) => {
                if (error) setError('')
                if (!e.target.value.includes(' ')) handleChange(e)
              }}
              disabled={loading}
              required
              errorFocused={!!error}
            />
          )}
          <Box>
            <Button
              loading={loading}
              type="submit"
              fullWidth
              icon="arrow"
              disabled={!!error || loading || !isValid}
            >
              {t(isOTP ? 'receive_otp' : 'sign_in')}
            </Button>
          </Box>
          <Box>
            <Button color="secondary" fullWidth icon="arrow" onClick={() => setIsOTP(!isOTP)}>
              {t(isOTP ? 'sign_in_by_password' : 'sign_in_by_otp')}
            </Button>
          </Box>
          <Box>
            <span className="label">Chưa có tài khoản? </span>
            <span className="label bold link" onClick={signUp}>
              {t('sign_up')}
            </span>
            <span className="label"> để nhận nhiều ưu đãi</span>
          </Box>
          {!isOTP && (
            <Box>
              <span className="label bold link" onClick={forgotPassword}>
                {t('forgot_password')}
              </span>
            </Box>
          )}
        </Stack>
      </Form>
    </FormikProvider>
  )
}
