import { Collapse, ListItemButton } from '@mui/material'
import React from 'react'
import Lightbox from 'react-image-lightbox'

interface Props {
  descreption: string
  image?: string[]
  orther?: JSX.Element
  lastImage?: string[]
  note?: {
    title?: string
    image?: string[]
    orther?: JSX.Element
    lastImage?: string[]
    subNote?: {
      title?: string
      image?: string[]
      orther?: JSX.Element
      lastImage?: string[]
      subNote?: {
        title?: string
        image?: string[]
        orther?: JSX.Element
        subNote?: {
          title?: string
          image?: string[]
          orther?: JSX.Element
        }[]
      }[]
    }[]
  }[]
  last_descreption?: string
  noWrapper?: boolean
}

const GuideUsingStep: React.FC<Props> = ({
  descreption,
  image,
  orther,
  note,
  last_descreption,
  noWrapper,
}) => {
  const [isActive, setIsActive] = React.useState<boolean>(true)

  const [imgPreview, setImgPreview] = React.useState({ open: false, src: '' })

  const handleToggleImgPreview = (isOpen = false, image: any) => {
    setImgPreview({ open: isOpen, src: image })
  }
  return (
    <div style={{ marginBottom: 30 }}>
      {noWrapper ? (
        <div
          style={{ fontSize: '18px', marginBottom: 20, marginLeft: 16 }}
          dangerouslySetInnerHTML={{ __html: descreption }}
        />
      ) : (
        <ListItemButton
          onClick={() => setIsActive((prev) => !prev)}
          sx={{
            padding: '12px 16px !important',
            display: 'flex !important',
            justifyContent: 'space-between !important',
            borderBottom: '2px solid var(--cl-light) !important',
          }}
        >
          <div
            style={{ fontSize: '18px', marginBottom: 20 }}
            dangerouslySetInnerHTML={{ __html: descreption }}
          />
          <span
            style={{ fontSize: '24px', marginLeft: 20 }}
            className={`mn-icon-${isActive ? 'minus' : 'plus'} icon`}
          />
        </ListItemButton>
      )}

      {!noWrapper ? (
        <Collapse in={isActive} timeout="auto" unmountOnExit>
          <>
            <div className="d-f ai-c jc-c" style={{ marginTop: '8px', flexDirection: 'column' }}>
              {image?.map((img, index) => (
                <img
                  style={{ marginTop: index > 0 ? 30 : 0 }}
                  key={img + index}
                  src={img}
                  alt=""
                  width={'60%'}
                  onClick={() => handleToggleImgPreview(true, img)}
                />
              ))}
            </div>
            {orther}
            <ul>
              {note?.map((item) => (
                <>
                  <li key={item?.title} style={{ fontSize: '18px', margin: '14px 40px 0' }}>
                    <div
                      style={{ fontSize: '18px', marginBottom: item?.image ? 20 : 0 }}
                      dangerouslySetInnerHTML={{ __html: item.title || '' }}
                    />
                    <div
                      className="d-f ai-c jc-c"
                      style={{
                        marginTop: item?.image ? 40 : 8,
                        flexDirection: 'column',
                        marginBottom: item?.image ? 40 : 0,
                      }}
                    >
                      {item?.image?.map((img, index) => (
                        <img
                          style={{ marginTop: index > 0 ? 30 : 0 }}
                          key={img + index}
                          src={img}
                          alt=""
                          width={'60%'}
                          onClick={() => handleToggleImgPreview(true, img)}
                        />
                      ))}
                    </div>

                    {item.orther}
                    <ul>
                      {item?.subNote?.map((item) => (
                        <>
                          <li key={item?.title} style={{ fontSize: '18px', margin: '14px 40px 0' }}>
                            <div
                              style={{ fontSize: '18px', marginBottom: item?.image ? 20 : 0 }}
                              dangerouslySetInnerHTML={{ __html: item.title || '' }}
                            />
                            <div
                              className="d-f ai-c jc-c"
                              style={{
                                marginTop: item?.image ? 40 : 8,
                                flexDirection: 'column',
                                marginBottom: item?.image ? 40 : 0,
                              }}
                            >
                              {item?.image?.map((img, index) => (
                                <img
                                  style={{ marginTop: index > 0 ? 30 : 0 }}
                                  key={img + index}
                                  src={img}
                                  alt=""
                                  width={'60%'}
                                  onClick={() => handleToggleImgPreview(true, img)}
                                />
                              ))}
                            </div>
                            {item.orther}
                            <ul>
                              {item?.subNote?.map((item) => (
                                <>
                                  <li
                                    key={item?.title}
                                    style={{ fontSize: '18px', margin: '14px 40px 0' }}
                                  >
                                    <div
                                      style={{
                                        fontSize: '18px',
                                        marginBottom: item?.image ? 40 : 0,
                                      }}
                                      dangerouslySetInnerHTML={{ __html: item.title || '' }}
                                    />
                                    <div
                                      className="d-f ai-c jc-c"
                                      style={{
                                        marginTop: item?.image ? 40 : 8,
                                        flexDirection: 'column',
                                        marginBottom: item?.image ? 40 : 0,
                                      }}
                                    >
                                      {item?.image?.map((img, index) => (
                                        <img
                                          style={{ marginTop: index > 0 ? 30 : 0 }}
                                          key={img + index}
                                          src={img}
                                          alt=""
                                          width={'60%'}
                                          onClick={() => handleToggleImgPreview(true, img)}
                                        />
                                      ))}
                                    </div>
                                    {item.orther}
                                    <ul>
                                      {item?.subNote?.map((item) => (
                                        <>
                                          <li
                                            key={item?.title}
                                            style={{ fontSize: '18px', margin: '14px 40px 0' }}
                                          >
                                            <div
                                              style={{
                                                fontSize: '18px',
                                                marginBottom: item?.image ? 40 : 0,
                                              }}
                                              dangerouslySetInnerHTML={{ __html: item.title || '' }}
                                            />
                                            <div
                                              className="d-f ai-c jc-c"
                                              style={{
                                                marginTop: item?.image ? 40 : 8,
                                                flexDirection: 'column',
                                                marginBottom: item?.image ? 40 : 0,
                                              }}
                                            >
                                              {item?.image?.map((img, index) => (
                                                <img
                                                  style={{ marginTop: index > 0 ? 30 : 0 }}
                                                  key={img + index}
                                                  src={img}
                                                  alt=""
                                                  width={'60%'}
                                                  onClick={() => handleToggleImgPreview(true, img)}
                                                />
                                              ))}
                                            </div>
                                            {item.orther}
                                          </li>
                                        </>
                                      ))}
                                    </ul>
                                  </li>
                                </>
                              ))}
                            </ul>
                            <div
                              className="d-f ai-c jc-c"
                              style={{ marginTop: '8px', flexDirection: 'column' }}
                            >
                              {item?.lastImage?.map((img, index) => (
                                <img
                                  key={img + index}
                                  src={img}
                                  alt=""
                                  width={'60%'}
                                  onClick={() => handleToggleImgPreview(true, img)}
                                />
                              ))}
                            </div>
                          </li>
                        </>
                      ))}
                    </ul>

                    <div
                      className="d-f ai-c jc-c"
                      style={{ marginTop: '8px', flexDirection: 'column' }}
                    >
                      {item?.lastImage?.map((img, index) => (
                        <img
                          key={img + index}
                          src={img}
                          alt=""
                          width={'60%'}
                          onClick={() => handleToggleImgPreview(true, img)}
                        />
                      ))}
                    </div>
                  </li>
                </>
              ))}
            </ul>
            <div
              style={{ fontSize: '18px', marginBottom: 20 }}
              dangerouslySetInnerHTML={{ __html: last_descreption || '' }}
            />
          </>
        </Collapse>
      ) : null}
      {imgPreview.open && (
        <Lightbox
          wrapperClassName={'ImageViewer-container'}
          reactModalStyle={{ overlay: { zIndex: 9999 } }}
          enableZoom={false}
          mainSrc={imgPreview.src}
          nextSrc={undefined}
          prevSrc={undefined}
          onMovePrevRequest={() => {}}
          onMoveNextRequest={() => {}}
          onCloseRequest={() => handleToggleImgPreview(false, imgPreview.src)}
          animationDuration={400}
        />
      )}
    </div>
  )
}

export default GuideUsingStep
