import AuthLayout from 'components/screens/app/auth/AuthLayout'
import { AUTH_TYPES } from 'configs/constants'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { forgotPassword as forgotPasswordAction } from 'store/auth/actions'

export default function ForgotPassword() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(forgotPasswordAction(false))
  }, [])

  return <AuthLayout authType={AUTH_TYPES.FORGOT_PASSWORD} />
}
