/*eslint-disable*/
import React from 'react'
import { Box } from '@mui/material'
import GuideUsingStep from 'components/admin/guideUsingStep/GuideUsingStep'

import group1_1 from '../../../../../assets/images/groupsUsing/group/1/group1-1.png'
import group1_2 from '../../../../../assets/images/groupsUsing/group/1/group1-2.png'
import group1_3 from '../../../../../assets/images/groupsUsing/group/1/group1-3.png'

import group2_1 from '../../../../../assets/images/groupsUsing/group/2/group2-1.png'
import group2_2 from '../../../../../assets/images/groupsUsing/group/2/group2-2.png'
import group2_3 from '../../../../../assets/images/groupsUsing/group/2/group2-3.png'
import group2_4 from '../../../../../assets/images/groupsUsing/group/2/group2-4.png'
import group2_5 from '../../../../../assets/images/groupsUsing/group/2/group2-5.png'
import group2_6 from '../../../../../assets/images/groupsUsing/group/2/group2-6.gif'
import group2_7 from '../../../../../assets/images/groupsUsing/group/2/group2-7.gif'
import group2_8 from '../../../../../assets/images/groupsUsing/group/2/group2-8.png'
import group2_9 from '../../../../../assets/images/groupsUsing/group/2/group2-9.png'
import group2_10 from '../../../../../assets/images/groupsUsing/group/2/group2-10.png'
import group2_11 from '../../../../../assets/images/groupsUsing/group/2/group2-11.png'
import group2_12 from '../../../../../assets/images/groupsUsing/group/2/group2-12.png'
import group2_13 from '../../../../../assets/images/groupsUsing/group/2/group2-13.png'
import group2_14 from '../../../../../assets/images/groupsUsing/group/2/group2-14.png'
import group2_15 from '../../../../../assets/images/groupsUsing/group/2/group2-15.png'
import group2_16 from '../../../../../assets/images/groupsUsing/group/2/group2-16.png'

import group3_1 from '../../../../../assets/images/groupsUsing/group/3/group3-1.png'
import group3_2 from '../../../../../assets/images/groupsUsing/group/3/group3-2.png'
import group3_3 from '../../../../../assets/images/groupsUsing/group/3/group3-3.png'
import group3_4 from '../../../../../assets/images/groupsUsing/group/3/group3-4.png'
import group3_5 from '../../../../../assets/images/groupsUsing/group/3/group3-5.png'
import group3_6 from '../../../../../assets/images/groupsUsing/group/3/group3-6.png'
import group3_7 from '../../../../../assets/images/groupsUsing/group/3/group3-7.png'
import group3_8 from '../../../../../assets/images/groupsUsing/group/3/group3-8.png'

const PermisstionOfGroup = () => {
  return (
    <Box>
      <GuideUsingStep
        descreption="<b>Xem danh sách quyền trong nhóm quyền Quản trị viên cấp cao</b>"
        orther={
          <Box margin={'0 30px'}>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào bất kỳ vị trí nào tại Nhóm quản trị viên cấp cao (ngoại trừ nút Sửa)."
              image={[group1_1]}
            />
            <GuideUsingStep
              descreption="Tại màn hình các quyền của Quản trị viên cấp cao, hiển thị danh sách các quyền mặc định của quản trị viên cấp cao."
              image={[group1_2]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Không cho phép <b>Thêm, Sửa, Xóa</b> các quyền của nhóm quyền Quản trị viên cấp cao.',
                },
                { title: 'Bạn có thể tìm quyền theo <b>Tên quyền</b>.', image: [group1_3] },
              ]}
            />
          </Box>
        }
      />
      <GuideUsingStep
        descreption="<b>Thêm quyền trong nhóm quyền</b>"
        orther={
          <Box margin={'0 30px'}>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Lưu ý:</b>{' '}
              <i>
                Chỉ quản trị viên cấp cao mới có quyền thêm quyền trong nhóm quyền. Các quản trị
                viên khác nếu được phân quyền chỉ có thể Xem nhóm quyền và Xem quyền trong nhóm
                quyền.
              </i>
            </p>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có nhóm quyền (không phải nhóm quyền Quản trị viên cấp cao) được
              tạo trước đó.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào bất kỳ vị trí nào tại nhóm quyền không phải nhóm Quản trị viên cấp cao (ngoại trừ nút Sửa và Xóa)."
              image={[group2_1]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Nhấn vào nút <b>Thêm quyền</b> để thêm quyền vào nhóm quyền."
              image={[group2_2]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Thêm quyền</b>, hộp thoại Thêm quyền cho nhóm sẽ hiển thị như ảnh bên dưới:"
              image={[group2_3]}
            />
            <GuideUsingStep
              descreption="<b>Bước 3:</b> Nhấn vào <b>Chọn quyền</b>."
              image={[group2_4]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Chọn quyền</b>, danh sách các quyền sẽ hiển thị như ảnh bên dưới:"
              image={[group2_5]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b> Bạn có thể kéo xuống để xem hết danh sách các quyền."
              image={[group2_6]}
            />
            <GuideUsingStep
              descreption="<b>Bước 4:</b> Nhấn chọn các quyền bạn muốn thêm vào nhóm quyền, sau đó nhấn ra ngoài để đóng danh sách quyền."
              image={[group2_7]}
            />
            <GuideUsingStep descreption="<b>Bước 5:</b> Nhấn nút <b>THÊM</b>." image={[group2_8]} />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>THÊM</b>, hộp thoại xác nhận Thêm quyền cho nhóm sẽ hiển thị như ảnh bên dưới:"
              image={[group2_9]}
            />
            <GuideUsingStep
              descreption="<b>Bước 6:</b> Nhấn nút <b>THÊM</b>."
              image={[group2_10]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>THÊM</b>, hộp thoại yêu cầu nhập mật khẩu xác nhận sẽ hiển thị như ảnh bên dưới:"
              image={[group2_11]}
            />
            <GuideUsingStep
              descreption="<b>Bước 7:</b> Nhập chính xác mật khẩu của tài khoản đang đăng nhập và nhấn <b>THÊM</b>."
              image={[group2_12]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>THÊM</b>, danh sách quyền đã chọn sẽ được hiển thị tại màn quyền trong nhóm quyền."
              image={[group2_13]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Bạn có thể nhấn <b>HỦY BỎ</b> tại hộp thoại <b>Thêm quyền cho nhóm - Chọn quyền</b> để hủy thao tác thêm quyền cho nhóm quyền.',
                  image: [group2_14],
                },
                {
                  title:
                    'Bạn có thể nhấn <b>HỦY BỎ</b> tại hộp thoại <b>Thêm quyền cho nhóm - Xác nhận</b> để hủy thao tác thêm quyền cho nhóm quyền.',
                  image: [group2_15],
                },
                {
                  title:
                    'Bạn có thể nhấn <b>HỦY BỎ</b> tại hộp thoại <b>Thêm quyền cho nhóm - Nhập mật khẩu</b> để hủy thao tác thêm quyền cho nhóm quyền.',
                  image: [group2_16],
                },
              ]}
            />
          </Box>
        }
      />
      <GuideUsingStep
        descreption="<b>Xóa quyền trong nhóm quyền</b>"
        orther={
          <Box margin={'0 30px'}>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Lưu ý:</b>{' '}
              <i>
                Chỉ quản trị viên cấp cao mới có quyền xóa quyền khỏi nhóm quyền. Các quản trị viên
                khác nếu được phân quyền chỉ có thể Xem nhóm quyền và Xem quyền trong nhóm quyền.
              </i>
            </p>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có quyền được thêm vào nhóm quyền (không phải nhóm quyền Quản trị
              viên cấp cao).
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào bất kỳ vị trí nào tại nhóm quyền không phải nhóm Quản trị viên cấp cao (ngoại trừ nút Sửa và Xóa)."
              image={[group3_1]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Nhấn nút <b>Xóa</b> tại quyền mà bạn cần xóa thông tin."
              image={[group3_2]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Xóa</b>, hộp thoại Xóa quyền khỏi nhóm hiển thị như ảnh bên dưới:"
              image={[group3_3]}
            />
            <GuideUsingStep descreption="<b>Bước 3:</b> Nhấn nút <b>XÓA</b>." image={[group3_4]} />
            <GuideUsingStep
              descreption="Sau khị nhấn <b>XÓA</b>, hộp thoại Xóa quyền khỏi nhóm - Nhập mật khẩu hiển thị như ảnh bên dưới:"
              image={[group3_5]}
            />
            <GuideUsingStep
              descreption="<b>Bước 4:</b> Nhập chính xác mật khẩu của tài khoản đang đăng nhập và nhấn <b>XÓA</b>."
              image={[group3_6]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>XÓA</b>, quyền sẽ bị xóa và không còn hiển thị trong danh sách."
              noWrapper
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Bạn có thể nhấn <b>HỦY BỎ</b> tại hộp thoại <b>Xóa quyền khỏi nhóm - Xác nhận</b> để hủy thao tác xóa quyền khỏi nhóm quyền.',
                  image: [group3_7],
                },
                {
                  title:
                    'Bạn có thể nhấn <b>HỦY BỎ</b> tại hộp thoại <b>Xóa quyền khỏi nhóm - Nhập mật khẩu</b> để hủy thao tác xóa quyền khỏi nhóm quyền.',
                  image: [group3_8],
                },
              ]}
            />
          </Box>
        }
      />
    </Box>
  )
}

export default PermisstionOfGroup
