/* eslint-disable */
import { DialogActions, DialogContent } from '@mui/material'
import { Button, Input } from 'components/admin'
import Modal from 'components/modal/Modal'
import ModalButtonCancel from 'components/modal/ModalButtonCancel'
import { apiUrls } from 'configs/apis'
import { Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useApis } from 'services/api'
import { useModalConfirm } from 'store/modalConfirm'
import { emailKeydownPreventSpace, validateEmail } from 'utils'
import * as Yup from 'yup'

interface Props {
  show: boolean
  id: string
  email: string
  onClose?: any
  onSuccess?: any
  attached_file: string
  type?: string
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required().matches(validateEmail(), 'email_is_invalid'),
})

const ModalSendEmail: React.FC<Props> = (props) => {
  const { show, id, onClose, email, attached_file, type } = props
  const [loading, setLoading] = useState(false)

  const { showModalConfirm, hideModalConfirm } = useModalConfirm()

  const isSendConfirm = type === 'recipient'

  const { apiPut, apiPost } = useApis()

  const [error, setError] = useState('')

  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: { email },
    enableReinitialize: true,
    validationSchema,
    onSubmit: ({ email }) => {
      setLoading(true)
      if (isSendConfirm) {
        apiPost(
          apiUrls.sendEmailRecipient(id),
          { email_recipient: email },
          ({ status, id: resId, text }) => {
            setLoading(false)
            if (status) {
              onClose?.()
            } else if (resId === '471') {
              showModalConfirm({
                title: 'Thông báo',
                content: text,
                confirm: {
                  text: 'Đã hiểu',
                  action: () => {
                    hideModalConfirm()
                  },
                },
              })
            }
            onClose?.()
          }
        )
      } else {
        apiPut(
          apiUrls.adminSendEmailVATInvoice(id),
          {
            email_user: email,
            attached_file,
          },
          ({ status }) => {
            setLoading(false)
            if (status) {
              onClose?.()
            }
            onClose?.()
          }
        )
      }
    },
  })

  const {
    getFieldProps,
    handleSubmit,
    handleChange,
    errors,
    isValid,
    dirty,
    resetForm,
    validateForm,
  } = formik

  useEffect(() => {
    if (!show) return
    resetForm()
    setTimeout(() => validateForm(), 10)
  }, [show])

  return (
    <Modal
      dirty={dirty}
      title={isSendConfirm ? 'Xác nhận đơn hàng qua email' : 'Gửi hóa đơn đỏ qua email'}
      show={show}
      size="xs"
      close={onClose}
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <div className="Admin-formTitle" style={{ marginBottom: 20, textAlign: 'center' }}>
              {isSendConfirm
                ? 'Vui lòng nhập địa chỉ email để xác nhận đơn hàng'
                : 'Vui lòng nhập địa chỉ email nhận hóa đơn đỏ'}
            </div>
            <Input
              fullWidth
              autoComplete="email"
              label={'Email'}
              {...getFieldProps('email')}
              onKeyDown={emailKeydownPreventSpace}
              onChange={(e) => {
                if (error) setError('')
                e.target.value = e.target.value.replace(/\s/g, '')
                handleChange(e)
              }}
              error={error || t(errors.email?.toString() || '')}
              //   disabled={loading}
              errorFocused={!!error}
              maxLength={50}
            />
          </DialogContent>

          <DialogActions>
            <ModalButtonCancel type="button" onClick={onClose} color="primary">
              Hủy bỏ
            </ModalButtonCancel>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid}
              loading={loading}
            >
              Gửi
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Modal>
  )
}

export default ModalSendEmail
