import { apiUrls } from 'configs/apis'
import { AdminContext } from 'configs/constants'
import { useCallback, useContext } from 'react'
import { useApis } from 'services/api'
import { useModalConfirm } from 'store/modalConfirm'
import { ObjectType } from 'types'
import { ADMIN_ORDER_STATUSES } from './constants'

export const useAdminOrders = () => {
  const { startLoading: start, stopLoading: stop } = useContext(AdminContext)
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()
  const { apiPut } = useApis()

  const updateOrder = useCallback(
    (
      id: string,
      params: ObjectType,
      onSuccess?: any,
      startLoading?: any,
      stopLoading?: any,
      closePopup?: any
    ) => {
      if (!startLoading) {
        startLoading = start
        stopLoading = stop
      }
      startLoading()
      apiPut(
        apiUrls.adminOrders(id),
        params,
        ({ status, text }) => {
          stopLoading()
          if (status) onSuccess?.()
          else {
            showModalConfirm({
              title: 'Thông báo',
              content: text,
              confirm: {
                text: 'Đã hiểu',
                action: () => {
                  hideModalConfirm()
                  closePopup?.()
                },
              },
              small: true,
            })
          }
        },
        stopLoading
      )
    },
    []
  )

  const cancelOrder = useCallback(
    (
      id: string,
      reason: string,
      onSuccess?: any,
      startLoading?: any,
      stopLoading?: any,
      closePopup?: any
    ) => {
      updateOrder(
        id,
        { status: ADMIN_ORDER_STATUSES.CANCELED, cancel_reason: reason },
        onSuccess,
        startLoading,
        stopLoading,
        closePopup
      )
    },
    [updateOrder]
  )

  return {
    updateOrder,
    cancelOrder,
  }
}
