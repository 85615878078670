import Button from './Button'
import React from 'react'
import './index.scss'

interface Props {
  onClick?: any
}

const ButtonDrawerConfirm: React.FC<Props> = ({ onClick }) => {
  return (
    <Button
      className="ButtonDrawerConfirm"
      color="secondary"
      onClick={onClick}
      fullWidth
      borderRadius={0}
    >
      Xác nhận
    </Button>
  )
}

export default ButtonDrawerConfirm
