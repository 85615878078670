import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function EmptyCart() {
  const { t } = useTranslation()

  return (
    <div className="EmptyCart-container">
      <div className="EmptyCart-content">
        <span className="EmptyCart-icon" />
        <h5>{t('emptyCart')}</h5>
        <p className="label gray">
          Bạn chưa có sản phẩm nào trong giỏ hàng. Hãy thử xem qua các&nbsp;
          <Link className="bold label link" to="/">
            sản phẩm nổi bật
          </Link>
          &nbsp;của chúng tôi.
        </p>
      </div>
    </div>
  )
}
