/*eslint-disable*/
import React, { useState } from 'react'
import { Box, Card, Tab, Tabs } from '@mui/material'
import GuideUsingStep from 'components/admin/guideUsingStep/GuideUsingStep'
import { renderBackTitle } from 'configs/extensions'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'store/confirmRedirect'

import add1 from '../../../../assets/images/ManufacturerUsing/add1.png'
import add2 from '../../../../assets/images/ManufacturerUsing/add2.png'
import add3 from '../../../../assets/images/ManufacturerUsing/add3.png'
import add4 from '../../../../assets/images/ManufacturerUsing/add4.png'
import add5 from '../../../../assets/images/ManufacturerUsing/add5.png'
import add6 from '../../../../assets/images/ManufacturerUsing/add6.png'
import add7 from '../../../../assets/images/ManufacturerUsing/add7.png'

import edit1 from '../../../../assets/images/ManufacturerUsing/edit1.png'
import edit2 from '../../../../assets/images/ManufacturerUsing/edit2.png'
import edit3 from '../../../../assets/images/ManufacturerUsing/edit3.png'
import edit4 from '../../../../assets/images/ManufacturerUsing/edit4.png'
import edit5 from '../../../../assets/images/ManufacturerUsing/edit5.png'

import delete1 from '../../../../assets/images/ManufacturerUsing/delete1.png'
import delete2 from '../../../../assets/images/ManufacturerUsing/delete2.png'
import delete3 from '../../../../assets/images/ManufacturerUsing/delete3.png'
import delete4 from '../../../../assets/images/ManufacturerUsing/delete4.png'
import delete5 from '../../../../assets/images/ManufacturerUsing/delete5.png'
import delete6 from '../../../../assets/images/ManufacturerUsing/delete6.png'
import delete7 from '../../../../assets/images/ManufacturerUsing/delete7.png'

import level1_1 from '../../../../assets/images/ManufacturerUsing/models/step1.mp4'
import level1_2 from '../../../../assets/images/ManufacturerUsing/models/step2.mp4'

import level2_1 from '../../../../assets/images/ManufacturerUsing/models/level2-1.mp4'

import level3_1 from '../../../../assets/images/ManufacturerUsing/models/level3-1.mp4'
import level3_2 from '../../../../assets/images/ManufacturerUsing/models/level3-2.mp4'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const ManufacturerUsing = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <>
      {renderBackTitle(history, t('Quản lý hãng sản xuất và model - Hướng dẫn sử dụng'))}
      <Card sx={{ p: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="Thêm hãng sản xuất mới" {...a11yProps(0)} />
            <Tab label="Sửa thông tin hãng sản xuất  " {...a11yProps(1)} />
            <Tab label="Xóa hãng sản xuất" {...a11yProps(2)} />
            <Tab label="Model cấp 1" {...a11yProps(3)} />
            <Tab label="Model cấp 2" {...a11yProps(4)} />
            <Tab label="Model cấp 3" {...a11yProps(5)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Thêm hãng sản xuất</b> để thêm một hãng sản xuất mới."
              image={[add1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Thêm hãng sản xuất</b>, hộp thoại Thêm hãng sản xuất sẽ hiển thị như ảnh bên dưới:"
              image={[add2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Điền tên hãng sản xuất hợp lệ và tải ảnh của hãng sản xuất trong hộp thoại Thêm hãng sản xuất, sau đó nhấn nút <b>THÊM</b>."
              image={[add3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>THÊM</b>, hãng sản xuất sẽ được thêm thành công và hiển thị trong danh sách với <b>Số lượng model cấp 1 = 0</b>:"
              image={[add4]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                {
                  title:
                    'Hãng sản xuất vừa thêm thành công sẽ được hiển thị ở màn <b>Trang chủ</b>:',
                  image: [add5],
                },
                {
                  title: 'Bạn có thể tìm kiếm hãng sản xuất theo <b>Tên hãng sản xuất</b>:',
                  image: [add6],
                },
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác thêm hãng sản xuất mới:',
                  image: [add7],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <p style={{ fontSize: '18px', marginBottom: 20 }}>
              <b>Điều kiện:</b> Đã có ít nhất một hãng sản xuất ở màn Quản lý hãng sản xuất và
              model.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Sửa</b> ở hãng sản xuất mà bạn cần sửa thông tin."
              image={[edit1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Sửa</b>, hộp thoại Sửa hãng sản xuất sẽ hiển thị như ảnh bên dưới:"
              image={[edit2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Cập nhật thông tin vào các trường mà bạn muốn sửa trong hộp thoại Sửa hãng sản xuất, sau đó nhấn nút <b>LƯU</b>. "
              image={[edit3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>LƯU</b>, thông tin hãng sản xuất sẽ được cập nhật theo thông tin vừa sửa:"
              image={[edit4]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                {
                  title:
                    'Hãng sản xuất vừa sửa thành công sẽ được cập nhật ở các màn hình của người dùng.',
                },
                {
                  title:
                    'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác sửa thông tin hãng sản xuất:',
                  image: [edit5],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box>
            <p style={{ fontSize: '18px', marginBottom: 20 }}>
              <b>Điều kiện:</b> Đã có ít nhất một hãng sản xuất ở màn Quản lý hãng sản xuất và
              model.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Xóa</b> ở hãng sản xuất mà bạn cần xóa thông tin."
              image={[delete1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Xóa</b>, hộp thoại xác nhận Xóa hãng sản xuất sẽ hiển thị như ảnh bên dưới:"
              image={[delete2]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title: 'Bạn có thể nhấn <b>HỦY</b> để hủy thao tác xóa hãng sản xuất:',
                  image: [delete3],
                },
              ]}
            />
            <GuideUsingStep descreption="<b>Bước 2:</b> Nhấn nút <b>XÓA</b>." image={[delete4]} />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>XÓA</b>, bạn cần nhập mật khẩu của tài khoản đang đăng nhập để xác nhận:"
              image={[delete5]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác xóa hãng sản xuất:',
                  image: [delete6],
                },
              ]}
            />
            <GuideUsingStep
              descreption="<b>Bước 3:</b> Nhập chính xác mật khẩu của tài khoản đang đăng nhập, sau đó nhấn nút <b>XÓA</b>."
              image={[delete7]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>XÓA</b>, hãng sản xuất vừa xóa sẽ không còn hiển thị ở màn <b>Quản lý sản phẩm</b> và <b>các màn của người dùng</b>, đồng thời xóa tất cả model cấp con (model cấp 1, cấp 2, cấp 3) và sản phẩm (nếu có) thuộc hãng sản xuất vừa xóa."
              noWrapper
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box>
            <p style={{ fontSize: '18px', marginBottom: 20 }}>
              <b>Điều kiện:</b> Đã có ít nhất một hãng sản xuất ở màn Quản lý hãng sản xuất và
              model.
            </p>
            <GuideUsingStep
              descreption="Nhấn vào một hãng sản xuất bạn có thể chuyển đến màn hình model cấp 1 tương ứng của hãng sản xuất đó (ngoại trừ nút Sửa và Xóa):"
              orther={
                <div
                  className="d-f ai-c jc-c"
                  style={{ marginTop: '8px', flexDirection: 'column' }}
                >
                  <video width={'60%'} controls>
                    <source src={level1_1} type="video/mp4" />
                  </video>
                </div>
              }
            />
            <GuideUsingStep
              descreption="Cách thêm, sửa, xóa model cấp 1 sẽ tương tự như thêm, sửa, xóa hãng sản xuất. Tuy nhiên, khi xóa model cấp 1, bạn không cần nhập mật khẩu của tài khoản đang đăng nhập để xác nhận xóa như xóa hãng sản xuất. Sau khi xóa model cấp 1 thành công:"
              note={[
                {
                  title:
                    'Đồng thời xóa tất cả model cấp con (model cấp 2, cấp 3) thuộc model cấp 1 vừa xóa.',
                },
                {
                  title:
                    'Tự động xóa model cấp 1 này khỏi các sản phẩm có gắn với model cấp 1 vừa xóa (nếu có).',
                  orther: (
                    <div
                      className="d-f ai-c jc-c"
                      style={{ marginTop: '8px', flexDirection: 'column' }}
                    >
                      <video width={'60%'} controls>
                        <source src={level1_2} type="video/mp4" />
                      </video>
                    </div>
                  ),
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Box>
            <p style={{ fontSize: '18px', marginBottom: 20 }}>
              <b>Điều kiện:</b> Đã có ít nhất một model cấp 1 ở màn model cấp 1.
            </p>
            <GuideUsingStep
              descreption="Nhấn vào một model cấp 1 bạn có thể chuyển đến màn hình model cấp 2 tương ứng của model cấp 1 đó (ngoại trừ nút Sửa và Xóa):"
              orther={
                <div
                  className="d-f ai-c jc-c"
                  style={{ marginTop: '8px', flexDirection: 'column' }}
                >
                  <video width={'60%'} controls>
                    <source src={level2_1} type="video/mp4" />
                  </video>
                </div>
              }
            />
            <GuideUsingStep
              descreption="Cách thêm, sửa, xóa model cấp 2 sẽ tương tự như thêm, sửa, xóa model cấp 1."
              noWrapper
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Box>
            <p style={{ fontSize: '18px', marginBottom: 20 }}>
              <b>Điều kiện:</b> Đã có ít nhất một model cấp 2 ở màn model cấp 2.
            </p>
            <GuideUsingStep
              descreption="Nhấn vào một model cấp 2 bạn có thể chuyển đến màn hình model cấp 3 tương ứng của model cấp 2 đó (ngoại trừ nút Sửa và Xóa):"
              orther={
                <div
                  className="d-f ai-c jc-c"
                  style={{ marginTop: '8px', flexDirection: 'column' }}
                >
                  <video width={'60%'} controls>
                    <source src={level3_1} type="video/mp4" />
                  </video>
                </div>
              }
            />
            <GuideUsingStep
              descreption="Cách thêm, sửa, xóa model cấp 3 sẽ tương tự như thêm, sửa, xóa model cấp 1. Tuy nhiên:"
              note={[
                {
                  title:
                    'Model cấp 3 là cấp cuối cùng nên không thể nhấn vào model cấp 3 để chuyển đến model cấp nhỏ hơn được.',
                },
                {
                  title:
                    'Sau khi xóa model cấp 3 thành công, tự động xóa model cấp 3 này khỏi các sản phẩm có gắn với model cấp 3 vừa xóa (nếu có).',
                  orther: (
                    <div
                      className="d-f ai-c jc-c"
                      style={{ marginTop: '8px', flexDirection: 'column' }}
                    >
                      <video width={'60%'} controls>
                        <source src={level3_2} type="video/mp4" />
                      </video>
                    </div>
                  ),
                },
              ]}
            />
          </Box>
        </TabPanel>
      </Card>
    </>
  )
}

export default ManufacturerUsing
