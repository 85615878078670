import moment from 'moment'

/**
 * Get current time
 */
export const getCurrentTime = (date: any) => {
  if (!date) return '-'
  return moment(date * 1000).format('DD/MM/YYYY')
}

/**
 * Format date
 */
export const formatDate = (date: any) => {
  return moment(date * 1000).format('DD/MM/YYYY')
}
