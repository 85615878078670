import { UPDATE_BREADCRUMBS, UPDATE_BREADCRUMBS_RIGHT_MENU } from 'store/actionTypes'
import { DispatchType, MenuItem } from 'types'

/**
 * Update breadcrumbs
 *
 * @param breadcrumbs Breadcrumbs
 */
export const updateBreadcrumbs = (breadcrumbs?: MenuItem[]) => (dispatch: DispatchType) => {
  dispatch({ type: UPDATE_BREADCRUMBS, payload: breadcrumbs })
}

/**
 * Update breadcrumbs with right menu
 *
 * @param breadcrumbs Breadcrumbs
 */
export const updateBreadcrumbsRightMenu =
  (breadcrumbs: MenuItem[], rightMenu: any) => (dispatch: DispatchType) => {
    dispatch({ type: UPDATE_BREADCRUMBS_RIGHT_MENU, payload: { breadcrumbs, rightMenu } })
  }
