import { useTranslation } from 'react-i18next'
import { Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery } from '@mui/material'

import CartItem from './CartItem'
import { Button } from 'components'

interface PropTypes {
  className?: string
  label?: string
  cartItems?: any[]
  cartType?: number
  addAllToCart?: any
}

export default function CartItems(props: PropTypes) {
  const { t } = useTranslation()
  const isMobileView = useMediaQuery('(max-width: 900px)')

  const { className = '', label = '', cartItems = [], cartType, addAllToCart } = props

  const canAddAllToCart = cartItems.some((item) => item.product?.inventory_number > 0)

  return (
    <div className={`CartItems-container ${className}`}>
      {label && (
        <div className="d-f jc-sb ai-c CartItems-labelWrapper">
          <h5 className="CartItems-label">
            {t(label)} <span className="counter">({cartItems.length})</span>
          </h5>
          {canAddAllToCart && (
            <Button
              className="CartItems-addAllToCart"
              color="stroke transparent"
              onClick={addAllToCart}
            >
              Thêm tất cả vào giỏ hàng
            </Button>
          )}
        </div>
      )}
      {isMobileView ? (
        <div className="CartItems-list">
          {cartItems.map((cartItem, index) => (
            <CartItem
              key={index}
              cartItem={cartItem}
              cartType={cartType}
              isMobileView={isMobileView}
            />
          ))}
        </div>
      ) : (
        <Table className="CartItems-table">
          <TableHead>
            <TableRow>
              <TableCell width={130}>{t('product')}</TableCell>
              <TableCell width={50}>{t('unitPrice')}</TableCell>
              <TableCell style={{ textAlign: 'center' }} width={50}>
                {t('quantity')}
              </TableCell>
              <TableCell width={50}>{t('moneyAmount')}</TableCell>
              <TableCell width={80}>{t('operations')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cartItems.map((cartItem, index) => (
              <CartItem
                key={index}
                cartItem={cartItem}
                cartType={cartType}
                isMobileView={isMobileView}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  )
}
