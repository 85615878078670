import { CartItemType } from './../../types/common'
/* eslint-disable */
import { apiUrls } from 'configs/apis'
import { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useApis } from 'services/api'
import { configureStore } from 'store'
import { useModalConfirm } from 'store/modalConfirm'
import { useToasts } from 'store/toasts'
import { CartType, CreateCartItemType, ObjectType, ProductType, RootState } from 'types'
import { getProductPrice, getTotalOfCartItems, storeShoppingCart } from 'utils'

import {
  addItemIntoCart,
  removeItemFromCart,
  fetchCart,
  updateCartItemId,
  updateCartBuyLater,
  updateCartItemQuantity,
  buyNow,
  updateCart,
  orderSuccess,
  updateCartItemsQuantity,
  addAllToCart,
  loadingCart,
  changeCart,
} from './actions'

const TIMEOUT = 300

let needCheckCart = false
setTimeout(() => (needCheckCart = true), 1000)

export default function useShoppingCart() {
  const dispatch = useDispatch()
  const { apiGet, apiPost, apiPut, apiDelete } = useApis()
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()
  const { createToast } = useToasts()
  const history = useHistory()
  const isAuth = !!configureStore.getState().auth.access_token

  const shoppingCartReducer: CartType = useSelector((state: RootState) => state.cart)

  const { allCartItems = [] } = shoppingCartReducer
  const cartItems = allCartItems.filter((item) => !item.is_buy_later)
  const buyLaterItems = allCartItems.filter((item) => item.is_buy_later)

  const timeouts = useRef<ObjectType>({})

  // Format some information
  const { totalItems, totalBuyLaterItems } = useMemo(() => {
    return {
      totalItems: getTotalOfCartItems(cartItems),
      totalBuyLaterItems: getTotalOfCartItems(buyLaterItems),
    }
  }, [JSON.stringify(allCartItems)])

  // When update cart, if user is not signed in => update cart
  // const { allCartItems: all } = getShoppingCartData()
  useEffect(() => {
    !isAuth && storeShoppingCart(allCartItems)
  }, [JSON.stringify(allCartItems)])

  const totalMoney = useMemo(
    () =>
      cartItems.reduce((sum, cartItem) => {
        const { product = {} as ProductType, quantity = 1 } = cartItem
        const { final_price = 0 } = product
        return sum + quantity * final_price
      }, 0),
    [cartItems]
  )

  let discount = (totalMoney * (shoppingCartReducer.discountPercent || 0)) / 100
  if (discount > 0) discount = -discount
  const totalMoneyAfterDiscount = totalMoney + discount

  return useMemo(() => {
    const syncCart = (data: CreateCartItemType[], onSuccess?: (data: any) => void) => {
      apiPost(apiUrls.syncCart(), data, ({ status, data }) => {
        if (status) onSuccess?.(data)
      })
    }

    const createCartItem = (product: any, onSuccess?: any, onError?: any) => {
      apiPost(
        apiUrls.cart(),
        { product_id: product.id, quantity: product.quantity || 1, is_buy_now: product.is_buy_now },
        ({ status, data, text }) => {
          if (status) {
            dispatch(updateCartItemId(product.id, data.id))
            onSuccess?.(data.id)
          } else {
            createToast({ message: { content: text }, type: 'error' })
            onError?.()
          }
        }
      )
    }

    const updateCartBuyLaterFunc = (
      cartItemId: string,
      productId: string,
      isBuyLater?: boolean
    ) => {
      isAuth && updateCartItem({ cartItemId, isBuyLater })
      dispatch(updateCartBuyLater(productId, isBuyLater))
    }

    const fetchCartFunc = () => {
      dispatch(loadingCart())
      apiGet(apiUrls.cart(), {}, ({ data, status }) => {
        if (status) {
          dispatch(fetchCart(data || []))
        }
      })
    }

    const checkCart = () => {
      if (!needCheckCart) return
      needCheckCart = false
      setTimeout(() => (needCheckCart = true), 1000)

      if (isAuth) fetchCartFunc()
      else if (allCartItems.length > 0)
        apiPost(
          apiUrls.checkCart(),
          allCartItems.map((item) => ({ product_id: item.product_id, quantity: item.quantity })),
          ({ status, data }) => {
            if (!data) data = []
            if (!status) dispatch(updateCartItemsQuantity(data))
          },
          () => {},
          true
        )
    }

    const updateCartItem = ({
      cartItemId,
      quantity,
      isBuyLater,
      productId,
    }: {
      cartItemId: string
      quantity?: string
      isBuyLater?: boolean
      productId?: string
    }) => {
      const needUpdate = (quantity: number) => {
        showModalConfirm({
          title: 'Thông tin',
          content: quantity ? `Chỉ còn ${quantity} sản phẩm có sẵn` : 'Không còn sản phẩm nào',
          confirm: {
            text: 'Đã hiểu',
            action: hideModalConfirm,
          },
          onBackdropClick: hideModalConfirm,
        })

        const id = productId || ''

        dispatch(updateCartItemQuantity(cartItemId || id, quantity, true))

        if (quantity === 0) updateCartBuyLaterFunc(cartItemId, id, true)
      }

      if (isAuth) {
        apiPut(
          apiUrls.cart(cartItemId),
          {
            quantity: quantity ? Number(quantity) : undefined,
            is_buy_later: isBuyLater ? true : false,
          },
          ({ status, data }) => {
            const { quantity } = data || {}
            if (!status && quantity !== undefined) needUpdate(quantity)
          }
        )
      } else {
        apiGet(apiUrls.getProductDetail(productId || ''), {}, ({ status, data }) => {
          if (status) {
            const { inventory_number } = data
            if (inventory_number < (quantity || 0)) {
              needUpdate(inventory_number)
            }
          }
        })
      }
    }

    const deleteItem = (cartItemId: string) => apiDelete(apiUrls.cart(cartItemId), {})

    return {
      ...shoppingCartReducer,
      cartItems,
      buyLaterItems,
      totalItems,
      totalBuyLaterItems,
      totalMoney,
      discount,
      totalMoneyAfterDiscount,
      syncCart,
      getCountProductInCart: (productId: string) => {
        const product = allCartItems.find((item) => item.product_id === productId)
        if (!product) return 0
        return product.quantity || 0
      },
      updateCart: (payload: ObjectType) => dispatch(updateCart(payload)),
      fetchCart: fetchCartFunc,
      addItemIntoCart: (product: ProductType, onSuccess?: any) => {
        isAuth && createCartItem(product, onSuccess)
        dispatch(addItemIntoCart(product))
      },
      buyNow: (product: ProductType, quantity: number, onError?: any, onSuccess?: any) => {
        const gotoCart = (cartItemId: string) => {
          onSuccess?.()
          dispatch(buyNow(cartItemId, product, quantity))
          history.push('/shopping-cart')
        }
        if (isAuth) createCartItem({ ...product, is_buy_now: true, quantity }, gotoCart, onError)
        else {
          gotoCart('')
        }
      },
      orderSuccess: () => dispatch(orderSuccess()),
      updateCartItem: (cartItemId: string, product: ProductType, force?: boolean) => {
        if (timeouts.current[cartItemId]) clearTimeout(timeouts.current[cartItemId])
        timeouts.current[cartItemId] = setTimeout(() => {
          delete timeouts.current[cartItemId]

          updateCartItem({ cartItemId, quantity: product.quantity, productId: product.id })
        }, TIMEOUT)

        dispatch(addItemIntoCart(product, true, force))
      },
      updateCartBuyLater: updateCartBuyLaterFunc,
      removeItemFromCart: (cartItemId: string, productId: string) => {
        isAuth && cartItemId && deleteItem(cartItemId)
        dispatch(removeItemFromCart(productId))
      },
      updateCartItemsQuantity: (data: any) => dispatch(updateCartItemsQuantity(data)),
      checkCart,
      addAllToCart: () => {
        if (isAuth) apiPut(apiUrls.moveToBuyNow())
        dispatch(addAllToCart())
      },
      buyAgain: (products: { product: ProductType; quantity: number }[]) => {
        const newProducts = products.map((item) => ({
          product_id: item.product.id,
          quantity: item.quantity,
        }))

        apiPost(apiUrls.buyAgain(), newProducts, ({ status, text }) => {
          if (!status)
            showModalConfirm({
              title: 'Thông tin',
              content: text,
              confirm: { text: 'Đã hiểu', action: hideModalConfirm },
            })
          else {
            fetchCartFunc()
            history.push('/shopping-cart')
          }
        })
      },
      changeCart: (data: CartItemType[] | any) => {
        dispatch(changeCart(data))
      },
    }
  }, [dispatch, shoppingCartReducer, isAuth])
}
