/*eslint-disable */
import { Box, Tab, Tabs } from '@mui/material'
import { apiUrls } from 'configs/apis'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Lightbox from 'react-image-lightbox'
import { useApis } from 'services/api'
import { useBreadcrumbs } from 'store/breadcrumbs'
import { AboutUSType } from 'types'
import { isMobile } from 'utils'
import AboutUsContent from './AboutUsContent'
import './index.scss'
import { isEmpty } from 'lodash'
import { Loading } from 'components/common'
import { useApp } from 'store/app'
import { useHistory } from 'react-router-dom'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  style?: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, style, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className="AboutUs-tabpanel"
      {...other}
      style={style}
    >
      {value === index && (
        <div className="AboutUs-tabpanel_item">
          <div className="AboutUs-tabpanels_wrapper">{children}</div>
        </div>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const AboutUs: React.FC = () => {
  const { showBreadcrumbs } = useBreadcrumbs()
  const { t } = useTranslation()
  const [value, setValue] = useState(0)
  const histoty = useHistory()

  const { aboutUs } = useApp()

  const [imgPreview, setImgPreview] = useState({ open: false, src: '' })

  /**
   *
   * @param event
   * @param newValue
   */
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  /**
   * Handle toggle img preview
   *
   * @param isOpen Open or not
   * @param imgIndex Image index
   */
  const handleToggleImgPreview = (isOpen = false, image: any) => {
    setImgPreview({ open: isOpen, src: image })

    if (isOpen && isMobile()) {
      document.body.classList.add('mobile')
      return
    }
    document.body.classList.remove('mobile')
  }

  useEffect(() => {
    if (isEmpty(about_us) && isEmpty(store_address) && isEmpty(vision_mission)) {
      histoty.push('/')
    } else {
      if (isEmpty(aboutUs.about_us)) {
        setValue(1)
        if (isEmpty(aboutUs.vision_mission)) {
          setValue(2)
        }
      }
    }
  }, [aboutUs])

  const { store_address, about_us, vision_mission } = aboutUs

  useEffect(() => {
    // getData()
    showBreadcrumbs([{ name: 'aboutUs', path: '/ve-chung-toi' }])
  }, [])

  return (
    <div className="AboutUs">
      <div className="AboutUs-label">{t('aboutUs')}</div>
      <div className="AboutUs-wrapper">
        <Box sx={{ flexGrow: 1, display: 'flex' }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs"
            className="AboutUs-Tabs"
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
          >
            <Tab
              className={`AboutUs-Tabs-button ${isEmpty(about_us) ? 'none' : ''}`}
              label={
                <span className="AboutUs-labelTab">
                  <i className="mn-icon-about" /> VỀ CHÚNG TÔI
                </span>
              }
              {...a11yProps(0)}
            />
            <Tab
              className={`AboutUs-Tabs-button ${isEmpty(vision_mission) ? 'none' : ''}`}
              label={
                <span className="AboutUs-labelTab">
                  <i className="mn-icon-vision" /> TẦM NHÌN &amp; SỨ MỆNH
                </span>
              }
              {...a11yProps(1)}
            />
            {store_address?.length > 0 && (
              <Tab
                label={
                  <span className="AboutUs-labelTab">
                    <i className="mn-icon-map" /> THÔNG TIN CHI NHÁNH
                  </span>
                }
                {...a11yProps(2)}
              />
            )}
          </Tabs>
          <TabPanel value={value} index={0}>
            <AboutUsContent {...about_us} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AboutUsContent {...vision_mission} />
          </TabPanel>
          <TabPanel value={value} index={2} style={{ background: 'none', padding: 0 }}>
            {store_address?.map((item: any) => {
              return (
                <div className="AboutUs-tabStore" key={item.id}>
                  <div className="AboutUs-TabAboutUS">{item?.store_name.toUpperCase()}</div>
                  <div className="infor">
                    <p className="label">Địa chỉ:</p>
                    <p className="text-main">{item.address}</p>
                  </div>
                  <div className="phone">
                    <p className="label">Điện thoại:</p>
                    <p className="text-main">{item.phone}</p>
                  </div>
                </div>
              )
            })}
          </TabPanel>
        </Box>
        {imgPreview.open && (
          <Lightbox
            wrapperClassName={`ImageViewer-container`}
            reactModalStyle={{ overlay: { zIndex: 9999 } }}
            enableZoom={false}
            mainSrc={imgPreview.src}
            nextSrc={undefined}
            prevSrc={undefined}
            onMovePrevRequest={() => {}}
            onMoveNextRequest={() => {}}
            onCloseRequest={() => handleToggleImgPreview(false, imgPreview.src)}
            animationDuration={600}
          />
        )}
      </div>
    </div>
  )
}

export default AboutUs
