import { Input } from 'components'
import { useTranslation } from 'react-i18next'
import {
  removeAccentsKeepSpecialEmail,
  validateTypingAddress,
  validateTypingCompany,
  validateTypingMST,
} from 'utils'

interface Props {
  formik: any
  setObjField?: any
  objField?: any
}

const FormVAT: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { formik, setObjField, objField } = props

  const { getFieldProps, values, handleChange, handleBlur, errors } = formik

  return (
    <div className="formStack">
      <Input
        fullWidth
        {...getFieldProps('company_name')}
        label="Tên công ty"
        value={values.company_name}
        onChange={(e) => {
          if (validateTypingCompany(e)) {
            setObjField?.({ ...objField, company_name: e.target.value })
            handleChange(e)
          }
        }}
        onBlur={handleBlur}
        maxLength={100}
        required
        className="formStack-input"
      />
      <Input
        fullWidth
        {...getFieldProps('tax_identification_number')}
        label="Mã số thuế"
        type="tel"
        value={values.tax_identification_number}
        onChange={(e) => {
          if (e.target.value === '' || validateTypingMST(e)) {
            setObjField?.({ ...objField, tax_identification_number: e.target.value })
            handleChange(e)
          }
        }}
        onBlur={handleBlur}
        maxLength={20}
        required
        className="formStack-input"
      />
      <Input
        fullWidth
        {...getFieldProps('company_address')}
        label="Địa chỉ công ty"
        value={values.company_address}
        maxLength={100}
        onChange={(e) => {
          if (validateTypingAddress(e)) {
            setObjField?.({ ...objField, company_address: e.target.value })
            handleChange(e)
          }
        }}
        onBlur={handleBlur}
        required
        className="formStack-input"
      />
      <Input
        fullWidth
        {...getFieldProps('email')}
        label="Email nhận hóa đơn đỏ"
        value={values.email}
        maxLength={50}
        error={!!errors.email && t(errors.email?.toString())}
        onChange={(e) => {
          const value = e.target.value
          e.target.value = removeAccentsKeepSpecialEmail(value)
          setObjField?.({ ...objField, email: e.target.value })
          handleChange(e)
        }}
        onBlur={handleBlur}
        className="formStack-input"
      />
    </div>
  )
}

export default FormVAT
