import AuthLayout from 'components/screens/app/auth/AuthLayout'
import { AUTH_TYPES } from 'configs/constants'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { signIn as signInAction } from 'store/auth/actions'

export default function SignIn() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(signInAction(false))
  }, [])

  return <AuthLayout authType={AUTH_TYPES.SIGN_IN} />
}
