import React from 'react'
import { Button } from '@mui/material'

import './index.scss'

const MAX_PAGE = 5

interface Props {
  count: number
  page: number
  onChange: (newPage: number) => void
  left?: boolean
  [key: string]: any
}

const Pagination: React.FC<Props> = ({ count, page, onChange, left }) => {
  if (count <= 1) return null

  const render: any[] = Array.from({ length: count > MAX_PAGE ? MAX_PAGE : count }).map(
    (_, index) => index + 1
  )
  if (count > MAX_PAGE) {
    if (page > 3) {
      if (page >= count - 2) {
        for (let i = 0; i < MAX_PAGE; i += 1) render[i] = count - (MAX_PAGE - 1) + i
      } else {
        for (let i = 0; i < MAX_PAGE; i += 1) render[i] = page - 2 + i
      }
    }
  }

  const showPrev = count > MAX_PAGE && page > 1
  const showNext = count > MAX_PAGE && page < count

  return (
    <div className={`Pagination-container${left ? ' left' : ''}`}>
      {showPrev && (
        <Button className="button" onClick={() => onChange(page - 1)}>
          <i className="mn-icon-arrow-left" />
        </Button>
      )}
      {render.map((item) => {
        return (
          <Button
            key={item}
            className={`button${page === item ? ' selected' : ''}`}
            onClick={() => page !== item && onChange(item)}
          >
            {('0' + item).substr(-2)}
          </Button>
        )
      })}
      {showNext && (
        <Button className="button" onClick={() => onChange(page + 1)}>
          <i className="mn-icon-arrow-right" />
        </Button>
      )}
    </div>
  )
}

export default Pagination
