import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'store/confirmRedirect'
import { validateTypingName, validateTypingPhone } from 'utils'
import Input from '../../../input/Input'

interface Props {
  disableAutoFocus?: boolean
  formik: any
}

const InputNamePhone: React.FC<Props> = ({ disableAutoFocus, formik }) => {
  const { t } = useTranslation()
  const { preventRedirect } = useHistory()

  const { errors, getFieldProps, handleChange, values } = formik

  return (
    <>
      <Input
        fullWidth
        autoFocus={!disableAutoFocus}
        {...getFieldProps('full_name')}
        value={values.full_name}
        error={!!errors.full_name && t(errors.full_name?.toString())}
        label={t('full_name')}
        onChange={(e) => {
          if (e.target.value === '' || validateTypingName(e)) {
            handleChange(e)
            preventRedirect()
          }
        }}
        maxLength={50}
        required
      />
      <Input
        fullWidth
        type="tel"
        {...getFieldProps('phone')}
        error={!!errors.phone && t(errors.phone?.toString())}
        label={t('phone')}
        onChange={(e) => {
          if (e.target.value === '' || validateTypingPhone(e)) {
            handleChange(e)
            preventRedirect()
          }
        }}
        maxLength={20}
        required
        blurWhenEnter
      />
    </>
  )
}

export default InputNamePhone
