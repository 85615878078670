/*eslint-disable*/
import React, { useState } from 'react'
import { Box, Card, Tab, Tabs } from '@mui/material'
import GuideUsingStep from 'components/admin/guideUsingStep/GuideUsingStep'
import Step1 from '../../../../assets/images/branchUsing/step1.png'
import popupAs from '../../../../assets/images/branchUsing/popup-as.png'
import Step2 from '../../../../assets/images/branchUsing/step2.png'
import Step3 from '../../../../assets/images/branchUsing/step3.png'
import Step4 from '../../../../assets/images/branchUsing/step4.png'
import Step5 from '../../../../assets/images/branchUsing/step5.png'
import Step6 from '../../../../assets/images/branchUsing/step6.gif'
import Step7 from '../../../../assets/images/branchUsing/step7.png'
import Step8 from '../../../../assets/images/branchUsing/step8.png'

import video from '../../../../assets/images/branchUsing/video.mp4'

import edit1 from '../../../../assets/images/branchUsing/edit/edit1.png'
import edit2 from '../../../../assets/images/branchUsing/edit/edit2.png'
import edit3 from '../../../../assets/images/branchUsing/edit/edit3.png'
import edit4 from '../../../../assets/images/branchUsing/edit/edit4.png'
import edit5 from '../../../../assets/images/branchUsing/edit/edit5.png'

import delete1 from '../../../../assets/images/branchUsing/delete/delete1.png'
import delete2 from '../../../../assets/images/branchUsing/delete/delete2.png'
import delete3 from '../../../../assets/images/branchUsing/delete/delete3.png'
import delete4 from '../../../../assets/images/branchUsing/delete/delete4.png'
import delete5 from '../../../../assets/images/branchUsing/delete/delete5.png'

import { renderBackTitle } from 'configs/extensions'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'store/confirmRedirect'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const BranchUsing: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      {renderBackTitle(history, t('branch_management_guide_using'))}
      <Card sx={{ p: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="Thêm chi nhánh" {...a11yProps(0)} />
            <Tab label="Sửa chi nhánh" {...a11yProps(1)} />
            <Tab label="Xóa chi nhánh" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box>
            <GuideUsingStep
              descreption="<b>Bước 1</b>: Nhấn vào nút <b>Thêm chi nhánh</b>
             để thêm một chi nhánh mới."
              image={[Step1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Thêm chi nhánh</b>, hộp thoại Thêm chi nhánh
                          sẽ hiển thị như ảnh bên dưới:"
              image={[popupAs]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2</b>: Điền thông tin hợp lệ vào tất cả các trường trong hộp 
                          thoại Thêm chi nhánh, sau đó nhấn nút <b>THÊM</b>."
              image={[Step2]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>THÊM</b>, chi nhánh sẽ được thêm
             thành công và hiển thị ở đầu tiên trong danh sách:"
              image={[Step3]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Nếu chi nhánh vừa thêm có trạng thái là <b>Hiển thị</b> → Chi nhánh sẽ được hiển thị ở màn <b>Thanh toán - Bước 1</b> và màn <b>Về chúng tôi - THÔNG TIN CHI NHÁNH:</b>',
                  image: [Step4, Step5],
                },
                {
                  title:
                    'Nếu chi nhánh vừa thêm có trạng thái là <b>Ẩn</b> → Chi nhánh sẽ không được hiển thị ở màn <b>Thanh toán - Bước 1</b> và màn <b>Về chúng tôi - THÔNG TIN CHI NHÁNH.</b>',
                },
                {
                  title:
                    'Khi có nhiều hơn một chi nhánh, bạn có thể thay đổi vị trí hiển thị của các chi nhánh bằng cách di chuyển chuột vào nút <b>Thứ tự</b> của chi nhánh tương ứng, nhấn giữ, sau đó kéo thả chi nhánh đó đến vị trí cần thay đổi:',
                  orther: (
                    <div
                      className="d-f ai-c jc-c"
                      style={{ marginTop: '8px', flexDirection: 'column' }}
                    >
                      <video width={'60%'} controls>
                        <source src={video} type="video/mp4" />
                      </video>
                    </div>
                  ),
                },
                {
                  title:
                    'Bạn có thể tìm kiếm chi nhánh theo <b>Tên chi nhánh, Địa chỉ chi nhánh</b> và lọc danh sách theo <b>Trạng thái chi nhánh</b>:',
                  image: [Step7],
                },
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác thêm chi nhánh mới:',
                  image: [Step8],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <p style={{ fontSize: '18px', marginBottom: 20 }}>
            <b>Điều kiện:</b> Đã có ít nhất một chi nhánh ở màn Quản lý chi nhánh.
          </p>
          <GuideUsingStep
            descreption="<b>Bước 1</b>: Nhấn vào nút <b>Sửa</b> ở chi nhánh mà bạn cần sửa thông tin."
            image={[edit1]}
          />
          <GuideUsingStep
            descreption="Sau khi nhấn nút <b>Sửa</b>, hộp thoại Sửa chi nhánh sẽ hiển 
          thị như ảnh bên dưới:"
            image={[edit2]}
          />
          <GuideUsingStep
            descreption="<b>Bước 2:</b> Cập nhật thông tin vào các trường mà bạn muốn
           sửa trong hộp thoại Sửa chi nhánh, sau đó nhấn nút <b>LƯU</b>. "
            image={[edit3]}
          />
          <GuideUsingStep
            descreption="Sau khi nhấn <b>LƯU</b>, thông tin chi nhánh sẽ được cập
           nhật theo thông tin vừa sửa:"
            image={[edit4]}
          />
          <GuideUsingStep
            descreption="<b>Lưu ý:</b>"
            note={[
              {
                title:
                  'Nếu chi nhánh vừa sửa có trạng thái là <b>Hiển thị</b> → Chi nhánh sẽ được hiển thị ở màn <b>Thanh toán - Bước 1</b> và màn <b>Về chúng tôi - THÔNG TIN CHI NHÁNH</b> với thông tin vừa cập nhật:',
                image: [Step4, Step5],
              },
              {
                title:
                  'Nếu chi nhánh vừa sửa có trạng thái là <b>Ẩn</b> → Chi nhánh sẽ không được hiển thị ở màn <b>Thanh toán - Bước 1</b> và màn <b>Về chúng tôi - THÔNG TIN CHI NHÁNH</b>.',
              },
              {
                title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác sửa chi nhánh:',
                image: [edit5],
              },
            ]}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <p style={{ fontSize: '18px', marginBottom: 20 }}>
            <b>Điều kiện:</b> Đã có ít nhất một chi nhánh ở màn Quản lý chi nhánh.
          </p>
          <GuideUsingStep
            descreption="<b>Bước 1</b>: Nhấn vào nút <b>Xóa</b> ở chi nhánh mà bạn cần xóa thông tin."
            image={[delete1]}
          />
          <GuideUsingStep
            descreption="Sau khi nhấn nút <b>Xóa</b>, hộp thoại xác nhận Xóa chi nhánh sẽ hiển thị như ảnh bên dưới:"
            image={[delete2]}
          />
          <GuideUsingStep descreption="<b>Bước 2</b>: Nhấn nút <b>XÓA</b>. " image={[delete3]} />
          <GuideUsingStep
            descreption="Sau khi nhấn <b>XÓA</b>, chi nhánh sẽ bị xóa và không còn hiển thị trong danh sách:"
            image={[delete4]}
          />
          <GuideUsingStep
            descreption="<b>Lưu ý:</b>"
            note={[
              {
                title:
                  'Nếu chi nhánh vừa xóa có trạng thái là <b>Hiển thị</b> → Chi nhánh không còn hiển thị ở màn <b>Thanh toán - Bước 1</b> và màn <b>Về chúng tôi - THÔNG TIN CHI NHÁNH</b> nữa.',
              },
              {
                title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác xóa chi nhánh:',
                image: [delete5],
              },
            ]}
          />
        </TabPanel>
      </Card>
    </>
  )
}

export default BranchUsing
