import { Button, Drawer, MenuItem } from '@mui/material'
import { Box } from '@mui/system'
import { ProductsContext, SORT_LIST } from 'configs/constants'
import { showProductsCount } from 'configs/extensions'
import React, { useContext, useState } from 'react'
import Filter from './Filter'
import Select from './Select'

interface Props {
  filterOtherProduct?: boolean
}

const ProductsHeader: React.FC<Props> = ({ filterOtherProduct }) => {
  const { products, onFilter, filter } = useContext(ProductsContext)
  const { total, items } = products

  const [showFilter, setShowFilter] = useState(false)
  const [sort, setSort] = useState(SORT_LIST[0])

  // Hide filter drawer
  const hideFilter = () => setShowFilter(false)

  const handleSort = (e: any) => {
    const newSort = e.target.value
    setSort(newSort)
    let newFilter = newSort.value || {}
    newFilter = { ...newFilter, page: 1 }
    onFilter(newFilter)
  }

  const hasFilter = !!(filter.categories.length || filter.manufacturers.length)

  if (items?.length === 0 && !hasFilter) return null

  return (
    <div className="ProductsHeader">
      <Drawer keepMounted anchor="right" open={showFilter} onClose={hideFilter}>
        <Filter hideFilter={hideFilter} filterOtherProduct={filterOtherProduct} />
      </Drawer>
      <span className="label gray count-product">{showProductsCount(items.length, total)}</span>
      {items?.length > 0 && (
        <Box display="flex" alignItems="center">
          <span className="label gray">Sắp xếp:</span>
          <Select value={sort} onChange={handleSort}>
            {SORT_LIST.map((item) => (
              <MenuItem
                style={{ display: sort === item ? 'none' : undefined }}
                key={item.label}
                value={item as any}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}
      {hasFilter && (
        <Button className="button-filter" onClick={() => setShowFilter(!showFilter)}>
          Bộ lọc <i className="mn-icon-filter" />
        </Button>
      )}
    </div>
  )
}

export default ProductsHeader
