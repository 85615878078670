/* eslint-disable */
import { TableCell, TableRow } from '@mui/material'
import { InputQuantity } from 'components/common'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/admin'
import { defaultProductThumbnail, MAX_QUANTITY } from 'configs/constants'
import { useHistory } from 'store/confirmRedirect'
import { CartItemType } from 'types'
import { formatPrice } from 'utils'
import { useModalConfirm } from 'store/modalConfirm'

interface PropTypes {
  cartItem: CartItemType
  isMobileView?: boolean
  updateQuantity: (id: string, product: any) => void
  removeProduct: (id: string) => void
}

export default function ProductItemRow(props: PropTypes) {
  const history = useHistory()
  const { t } = useTranslation()

  const { cartItem, updateQuantity, removeProduct } = props

  const { showModalConfirm, hideModalConfirm } = useModalConfirm()

  const {
    id = '',
    product_id: productId = '',
    quantity = 2,
    product = {},
  } = cartItem || ({} as CartItemType)

  const {
    price = 0,
    name,
    avatar_url,
    link_seo,
    inventory_number: inventoryNumber,
    code,
    final_price = 0,
  } = product

  const [newQuantity, setNewQuantity] = useState<any>(quantity)

  useEffect(() => {
    quantity !== newQuantity && setNewQuantity(quantity)
  }, [quantity])

  const maxQuantity = inventoryNumber || MAX_QUANTITY

  /**
   * Handle change quantity of product & update it in shopping cart
   * when user type quantity number into input
   *
   * @param value Quantity
   */
  const handleChangeInputQuantity = (value: number | string) => {
    setNewQuantity(value)
  }

  const handleUpdateCartQuantity = (value: number | string) => {
    if (Number(value) > maxQuantity) {
      showModalConfirm({
        title: 'Thông tin',
        content: `Chỉ còn ${inventoryNumber} sản phẩm có sẵn`,
        confirm: {
          text: 'Đã hiểu',
          action: () => {
            hideModalConfirm()
            setNewQuantity(inventoryNumber)
            updateQuantity(productId, { product, quantity: inventoryNumber })
          },
        },
        small: true,
      })
      return
    }
    const productCart = { product, quantity: value }
    // updateCartItem(id, productCart, true)
    updateQuantity(productId, productCart)
  }

  /**
   * Handle change quantity cart item when user click button increase or decrease
   *
   * @param value Quantity
   */
  const handleClickChangeQuantity = (isUp = true) => {
    if (isUp) {
      if (quantity >= maxQuantity) {
        showModalConfirm({
          title: 'Thông tin',
          content: `Chỉ còn ${inventoryNumber} sản phẩm có sẵn`,
          confirm: {
            text: 'Đã hiểu',
            action: hideModalConfirm,
          },
          small: true,
        })
        return
      }
    } else {
      if (quantity <= 1) return
    }
    const productCart = { product, quantity: quantity + (isUp ? 1 : -1) }
    updateQuantity(productId, productCart)

    // updateCartItem(id, productCart, true)
  }

  /**
   * Handle remove item from cart
   */
  const handleRemoveItem = () => {
    removeProduct(productId)
  }

  const manufacturerName = product.manufacturer?.name || 'Caterpillar'

  return (
    <>
      <TableRow>
        <TableCell>
          <div className="CartItem-product">
            <span
              className="CartItem-img"
              style={{ backgroundImage: `url("${avatar_url || defaultProductThumbnail}")` }}
            />
            <div className="CartItem-name">
              <h5>{name}</h5>
              <p>
                {'Mã sản phẩm'}: {code}
              </p>
              <p>
                {t('brand')}: {manufacturerName}
              </p>
            </div>
          </div>
        </TableCell>
        <TableCell>
          {price - final_price > 0 && (
            <p>
              <del>{formatPrice(price)}</del>
            </p>
          )}
          <p className="CartItem-price">{formatPrice(final_price)}</p>
        </TableCell>
        <TableCell>
          <InputQuantity
            value={newQuantity}
            count={inventoryNumber}
            onChangeInput={handleChangeInputQuantity}
            onIncrease={() => handleClickChangeQuantity()}
            onDecrease={() => handleClickChangeQuantity(false)}
            onBlur={handleUpdateCartQuantity}
          />
        </TableCell>
        <TableCell>
          <p className="CartItem-price note">{formatPrice(quantity * final_price)}</p>
        </TableCell>
        <TableCell>
          <Button variant="contained" onClick={handleRemoveItem}>
            Xóa
          </Button>
        </TableCell>
      </TableRow>
    </>
  )
}
