import { Button as MuiButton, CircularProgress } from '@mui/material'
import React, { CSSProperties } from 'react'
import './index.scss'

interface Props {
  className?: string
  color?: 'primary' | 'secondary' | 'stroke' | 'stroke transparent'
  onClick?: any
  disabled?: boolean
  type?: any
  borderRadius?: number
  loading?: boolean
  style?: CSSProperties
  fullWidth?: boolean
  icon?: string
  small?: boolean
}

const Button: React.FC<Props> = (props) => {
  const {
    className = '',
    color = 'primary',
    disabled,
    children,
    borderRadius = 3,
    loading,
    style = {},
    icon,
    small,
    ...rest
  } = props

  let classNames = `Button Button-${color}`
  if (className) classNames += ` ${className}`
  if (small) classNames += ' small'
  const variant = color.includes('stroke') ? 'outlined' : 'contained'

  return (
    <MuiButton
      {...rest}
      className={classNames}
      disabled={disabled || loading}
      style={{ ...style, borderRadius }}
      variant={variant}
    >
      {loading ? (
        <CircularProgress size={16} color="inherit" />
      ) : (
        <>
          {children}
          {!!icon && <i className={`mn-icon-${icon}`} />}
        </>
      )}
    </MuiButton>
  )
}

export default Button
