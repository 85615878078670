import React from 'react'
import Select from '../../../input/Select'
import { addressTypesList } from 'configs/constants'

interface Props {
  formik: any
}

const SelectAddressType: React.FC<Props> = ({ formik }) => {
  const { values, setFieldValue } = formik
  return (
    <Select
      selected={Number(values.type)}
      setSelected={(s) => setFieldValue('type', s)}
      data={addressTypesList}
      fullWidth
      label="Loại địa chỉ"
      required
      placeholder="Nhà riêng/Cơ quan/Khác"
    />
  )
}

export default SelectAddressType
