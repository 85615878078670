/*eslint-disable*/
import React, { useState } from 'react'
import { Box, Card, Tab, Tabs } from '@mui/material'
import GuideUsingStep from 'components/admin/guideUsingStep/GuideUsingStep'
import { renderBackTitle } from 'configs/extensions'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'store/confirmRedirect'

import list1 from '../../../../assets/images/CouponsUsing/list/list1.png'
import list2 from '../../../../assets/images/CouponsUsing/list/list2.png'
import list3 from '../../../../assets/images/CouponsUsing/list/list3.png'
import list4 from '../../../../assets/images/CouponsUsing/list/list4.png'
import list5 from '../../../../assets/images/CouponsUsing/list/list5.png'
import list6 from '../../../../assets/images/CouponsUsing/list/list6.png'
import list7 from '../../../../assets/images/CouponsUsing/list/list7.png'
import list8 from '../../../../assets/images/CouponsUsing/list/list8.png'

import add1 from '../../../../assets/images/CouponsUsing/add1.png'
import add2 from '../../../../assets/images/CouponsUsing/add2.png'
import add3 from '../../../../assets/images/CouponsUsing/add3.png'
import add4 from '../../../../assets/images/CouponsUsing/add4.png'
import add5 from '../../../../assets/images/CouponsUsing/add5.png'
import add6 from '../../../../assets/images/CouponsUsing/add6.png'
import add7 from '../../../../assets/images/CouponsUsing/add7.png'
import add8 from '../../../../assets/images/CouponsUsing/add8.png'
import add9 from '../../../../assets/images/CouponsUsing/add9.png'
import add10 from '../../../../assets/images/CouponsUsing/add10.png'
import add11 from '../../../../assets/images/CouponsUsing/add11.png'
import add12 from '../../../../assets/images/CouponsUsing/add12.png'

import edit1 from '../../../../assets/images/CouponsUsing/edit1.png'
import edit2 from '../../../../assets/images/CouponsUsing/edit2.png'
import edit3 from '../../../../assets/images/CouponsUsing/edit3.png'
import edit4 from '../../../../assets/images/CouponsUsing/edit4.png'
import edit5 from '../../../../assets/images/CouponsUsing/edit5.png'
import edit6 from '../../../../assets/images/CouponsUsing/edit6.png'
import edit7 from '../../../../assets/images/CouponsUsing/edit7.png'
import edit8 from '../../../../assets/images/CouponsUsing/edit8.png'
import edit9 from '../../../../assets/images/CouponsUsing/edit9.png'
import edit10 from '../../../../assets/images/CouponsUsing/edit10.png'

import delete1 from '../../../../assets/images/CouponsUsing/delete1.png'
import delete2 from '../../../../assets/images/CouponsUsing/delete2.png'
import delete3 from '../../../../assets/images/CouponsUsing/delete3.png'
import delete4 from '../../../../assets/images/CouponsUsing/delete4.png'
import delete5 from '../../../../assets/images/CouponsUsing/delete5.png'

import view1 from '../../../../assets/images/CouponsUsing/view1.png'
import view2 from '../../../../assets/images/CouponsUsing/view2.png'
import view3 from '../../../../assets/images/CouponsUsing/view3.png'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const CouponsUsing = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <>
      {renderBackTitle(history, t('Quản lý mã giảm giá -  Hướng dẫn sử dụng'))}
      <Card sx={{ p: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="Danh sách mã giảm giá" {...a11yProps(0)} />
            <Tab label="Thêm mã giảm giá mới " {...a11yProps(1)} />
            <Tab label="Sửa thông tin mã giảm giá " {...a11yProps(2)} />
            <Tab label="Xóa mã giảm giá " {...a11yProps(3)} />
            <Tab label="Chi tiết mã giảm giá " {...a11yProps(4)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box>
            <GuideUsingStep
              descreption="<b>Trạng thái mã giảm giá</b>"
              orther={
                <Box margin={'30px'}>
                  <GuideUsingStep
                    descreption="Mã giảm giá ở trạng thái <b>Đang áp dụng</b> khi:"
                    note={[
                      {
                        title:
                          'Mã giảm giá có ngày bắt đầu <= ngày hiện tại <= ngày kết thúc và số lượng mã giảm giá > số mã giảm giá đã sử dụng.',
                        image: [list1],
                      },
                    ]}
                  />
                  <GuideUsingStep
                    descreption="Mã giảm giá ở trạng thái <b>Chưa áp dụng</b> khi:"
                    note={[
                      {
                        title: 'Mã giảm giá có ngày bắt đầu áp dụng > ngày hiện tại.',
                        image: [list2],
                      },
                    ]}
                  />
                  <GuideUsingStep
                    descreption="Mã giảm giá ở trạng thái <b>Đã áp dụng</b> khi:"
                    note={[
                      { title: 'Mã giảm giá có ngày kết thúc < ngày hiện tại.', image: [list3] },
                      {
                        title: 'Mã giảm giá có số lượng mã giảm giá = số mã giảm giá đã sử dụng.',
                        image: [list4],
                      },
                    ]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Thời gian áp dụng mã giảm giá</b>"
              orther={
                <Box margin={'30px'}>
                  <GuideUsingStep
                    descreption="Thời gian áp dụng mã giảm giá sẽ từ 0:0:0 của ngày bắt đầu đến 23:59:59 của ngày kết thúc."
                    image={[list5]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Sản phẩm áp dụng mã giảm giá</b>"
              orther={
                <Box margin={'30px'}>
                  <GuideUsingStep
                    descreption="Nếu mã giảm giá thuộc loại <b>Giảm % đơn hàng</b> và chỉ áp dụng cho một số sản phẩm thì link button <b>Một số sản phẩm</b> sẽ được hiển thị như hình ảnh bên dưới:"
                    image={[list6]}
                  />
                  <GuideUsingStep
                    descreption="Sau khi nhấn link button <b>Một số sản phẩm</b>, hộp thoại Sản phẩm được áp dụng mã giảm giá sẽ hiển thị như hình ảnh bên dưới:"
                    image={[list7]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Tìm kiếm, lọc mã giảm giá</b>"
              orther={
                <Box margin={'30px'}>
                  <GuideUsingStep
                    descreption="Bạn có thể tìm kiếm mã giảm giá và lọc danh sách theo <b>Loại mã, Sản phẩm áp dụng mã, Trạng thái:"
                    image={[list8]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Số lần sử dụng mã giảm giá</b>"
              orther={
                <Box margin={'30px'}>
                  <GuideUsingStep
                    descreption="Nếu mã giảm giá có số lần sử dụng là <b>Một lần</b>:"
                    note={[
                      {
                        title:
                          'Với mỗi số điện thoại đặt hàng, chỉ có thể áp dụng mã giảm giá này 1 lần duy nhất và không thể tiếp tục áp dụng mã giảm giá này cho lần đặt hàng tiếp theo.',
                      },
                    ]}
                  />
                  <GuideUsingStep
                    descreption="Nếu mã giảm giá có số lần sử dụng là <b>Nhiều lần</b> (hiển thị số lần sử dụng quản trị viên đã nhập):
                                </br>
                                Ví dụ, số lần sử dụng = 3:"
                    note={[
                      {
                        title:
                          'Với mỗi số điện thoại đặt hàng, có thể áp dụng mã giảm giá tối đa 3 lần.',
                      },
                      {
                        title:
                          'Số lượt sử dụng sẽ tự động trừ đi 1 đối với số điện thoại đã đặt hàng thành công.',
                      },
                      {
                        title:
                          'Sau khi áp dụng hết 3 lần thì không thể sử dụng mã giảm giá này cho lần đặt hàng tiếp theo.',
                      },
                    ]}
                  />
                  <GuideUsingStep
                    descreption="Nếu mã giảm giá có số lần sử dụng là <b>Không giới hạn</b>: "
                    note={[
                      {
                        title:
                          'Với mỗi số điện thoại đặt hàng, có thể áp dụng mã giảm giá không giới hạn số lần.',
                      },
                    ]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Thao tác với mã giảm giá</b>"
              note={[
                {
                  title:
                    'Nếu mã giảm giá có trạng thái “Chưa áp dụng” → Cho phép <b>Xem, Sửa</b> và <b>Xóa</b> mã giảm giá.',
                },
                {
                  title:
                    'Nếu mã giảm giá có trạng thái “Đang áp dụng” → Chỉ cho phép <b>Xem</b> và <b>Xóa</b> mã giảm giá.',
                },
                {
                  title:
                    'Nếu mã giảm giá có trạng thái “Đã áp dụng” → Chỉ cho phép <b>Xem</b> và <b>Xóa</b> mã giảm giá.',
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Thêm mã giảm giá</b> để thêm một mã giảm giá mới. "
              image={[add1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Thêm mã giảm giá</b>, hộp thoại Thêm mã giảm giá sẽ hiển thị như ảnh bên dưới:"
              image={[add2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Điền thông tin hợp lệ vào hộp thoại Thêm mã giảm giá, sau đó nhấn nút <b>THÊM</b>."
              image={[add3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>THÊM</b>, mã giảm giá sẽ được thêm thành công vào danh sách:"
              image={[add4]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                {
                  title:
                    'Nếu thêm mã giảm giá có số lần sử dụng là <b>Nhiều lần</b> thì bạn cần phải điền thông tin về <b>Số lần sử dụng</b>:',
                  image: [add5],
                },
                {
                  title:
                    'Nếu thêm mã giảm giá có loại mã là <b>Miễn phí ship</b> với điều kiện <b>Giao hàng toàn quốc</b> thì bạn cần phải điền thông tin về <b>Giá trị đơn hàng tối thiểu</b>:',
                  image: [add6],
                },
                {
                  title:
                    'Nếu thêm mã giảm giá có loại mã là <b>Giảm % đơn hàng</b> thì bạn cần phải điền thông tin về <b>% giảm giá</b> và <b>Giá trị đơn hàng tối thiểu</b>: ',
                  image: [add7],
                },
                {
                  title:
                    'Nếu thêm mã giảm giá có loại mã là <b>Giảm % đơn hàng</b> và sản phẩm áp dụng mã giảm giá là <b>Một số sản phẩm</b> thì bạn cần phải chọn sản phẩm ở dropdown <b>Chọn sản phẩm</b>:',
                  image: [add8],
                },
                {
                  title:
                    'Mã giảm giá chỉ được thêm thành công khi nhập mã giảm giá không trùng với mã giảm giá đã tồn tại.',
                },
                {
                  title:
                    'Sau khi mã giảm giá được thêm thành công và có trạng thái là Đang áp dụng, người dùng/quản trị viên có thể nhập mã giảm giá cho đơn hàng của mình trên trang <b>Đặt hàng/Thêm đơn hàng/Sửa đơn hàng</b>:',
                  image: [add9, add10, add11],
                },
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác thêm mã giảm giá mới:',
                  image: [add12],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có ít nhất một mã giảm giá có trạng thái Chưa áp dụng ở màn Quản
              lý mã giảm giá.
            </p>

            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Sửa</b> ở mã giảm giá cần chỉnh sửa thông tin."
              image={[edit1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút Sửa, hộp thoại Sửa mã giảm giá sẽ hiển thị như ảnh bên dưới:"
              note={[
                { title: 'Mã giảm giá <b>Miễn phí ship</b>:', image: [edit2] },
                { title: 'Mã giảm giá <b>Giảm % đơn hàng</b>:', image: [edit3] },
              ]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Cập nhật thông tin vào các trường mà bạn muốn sửa trong hộp thoại Sửa mã giảm giá, sau đó nhấn nút <b>LƯU</b>. "
              image={[edit4]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>LƯU</b>, thông tin mã giảm giá sẽ được cập nhật theo thông tin vừa sửa:"
              image={[edit5]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                {
                  title:
                    'Nếu sửa mã giảm giá thành mã giảm giá có số lần sử dụng là <b>Nhiều lần</b> thì bạn cần phải điền thông tin về <b>Số lần sử dụng</b>: ',
                  image: [edit6],
                },
                {
                  title:
                    'Nếu sửa mã giảm giá thành mã giảm giá <b>Miễn phí ship</b> với điều kiện <b>Giao hàng toàn quốc</b> thì bạn cần phải điền thông tin về <b>Giá trị đơn hàng tối thiểu</b>:',
                  image: [edit7],
                },
                {
                  title:
                    'Nếu sửa mã giảm giá thành mã giảm giá <b>Giảm % đơn hàng</b> thì bạn cần phải điền thông tin về <b>% giảm giá</b> và <b>Giá trị đơn hàng tối thiểu</b>:',
                  image: [edit8],
                },
                {
                  title:
                    'Nếu sửa mã giảm giá thành mã giảm giá <b>Giảm % đơn hàng</b> và sản phẩm áp dụng mã giảm giá là <b>Một số sản phẩm</b> thì bạn cần phải chọn sản phẩm ở dropdown <b>Chọn sản phẩm</b>:',
                  image: [edit9],
                },
                {
                  title:
                    'Mã giảm giá chỉ được sửa thành công khi sửa mã giảm giá không trùng với mã giảm giá đã tồn tại.',
                },
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác sửa mã giảm giá:',
                  image: [edit10],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có ít nhất một mã giảm giá ở màn Quản lý mã giảm giá.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Xóa</b> ở mã giảm giá mà bạn cần xóa thông tin."
              image={[delete1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Xóa</b>, hộp thoại xác nhận Xóa mã giảm giá sẽ hiển thị như ảnh bên dưới:"
              image={[delete2]}
            />
            <GuideUsingStep descreption="<b>Bước 2:</b> Nhấn nút <b>XÓA</b>." image={[delete3]} />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>XÓA</b>, mã giảm giá sẽ bị xóa và không còn hiển thị trong danh sách:"
              image={[delete4]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Các đơn hàng đã áp dụng mã giảm giá đã bị xóa vẫn được lưu thông tin và không bị ảnh hưởng.',
                },
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác xóa mã giảm giá:',
                  image: [delete5],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có ít nhất một mã giảm giá ở màn Quản lý mã giảm giá.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Xem</b> ở mã giảm giá mà bạn cần xem thông tin. "
              image={[view1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Xem</b>, hộp thoại Xem mã giảm giá sẽ hiển thị như ảnh bên dưới:"
              image={[view2]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Bạn có thể nhấn nút <b>X</b> để đóng hộp thoại Xem mã giảm giá và quay về màn Quản lý mã giảm giá.',
                  image: [view3],
                },
              ]}
            />
          </Box>
        </TabPanel>
      </Card>
    </>
  )
}

export default CouponsUsing
