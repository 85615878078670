// import { Button } from 'components'
import React from 'react'
import { Link } from 'react-router-dom'
import { DocumentType } from 'types'

const DocumentItem: React.FC<DocumentType> = ({ name, file_size = 0, file_url }) => {
  const size = file_size < 1 ? Math.ceil(file_size * 1024) + 'KB' : file_size.toFixed(2) + 'MB'

  return (
    <div className="DocumentItem-wrapper">
      <Link className="DocumentItem" to={{ pathname: `${file_url}#toolbar=0` }} target="_blank">
        <div className="DocumentItem-left">
          <div className="DocumentItem-name">{name}</div>
          <div className="DocumentItem-size">{size} - PDF</div>
        </div>
      </Link>
      {/* <Button
        className="DocumentItem-download"
        icon="download"
        small
        color="secondary"
        onClick={async (e: any) => {
          e.preventDefault()
          const fileName = file_url?.split('/').pop() || 'noname.pdf'

          try {
            let blob = await fetch(file_url).then((r) => r.blob())
            blob = blob.slice(0, blob.size, 'octet/stream')
            const fileURL = URL.createObjectURL(blob)
            const a = document.createElement('a')
            document.body.appendChild(a)
            a.href = fileURL
            a.download = fileName
            a.click()
            window.URL.revokeObjectURL(fileURL)
            document.body.removeChild(a)
          } catch (e) {}
        }}
      /> */}
    </div>
  )
}

export default DocumentItem
