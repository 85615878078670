/* eslint-disable */
import { Card } from '@mui/material'
import { apiUrls } from 'configs/apis'
import { AdminContext, minDate1900, today } from 'configs/constants'
import { renderBackTitle } from 'configs/extensions'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AdminUserType } from 'types'
import { Button, DatePicker, Input, RenderField } from 'components/admin'
import { Form, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { defaultCustomerlValues, listGenders } from './constants'
import Select from 'components/admin/dataTable/Select'
import {
  removeAccentsKeepSpecialEmail,
  isString,
  validateEmail,
  validateTypingName,
  validateTypingCode,
  emailKeydownPreventSpace,
} from 'utils'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'store/confirmRedirect'
import moment from 'moment'

import { useHistory as useHistoryBase } from 'react-router'
import { toUpper } from 'lodash'

const validationSchema = (duplicate: any) =>
  Yup.object().shape({
    full_name: isString,
    customer_code: Yup.string().notOneOf([duplicate.value], duplicate.text),
    email: Yup.string().when('verify_method', {
      is: (verify_method: any) => {
        return verify_method === null || verify_method === 1
      },
      then: Yup.string().matches(validateEmail(), 'email_is_invalid'),
    }),
  })

const EditUser: React.FC = () => {
  const { id } = useParams() || ({} as any)
  const history = useHistory()
  const { allowRedirect, preventRedirect } = history
  const history1 = useHistoryBase()
  const { callApi } = useContext(AdminContext)
  const [initialValues, setInitialValues] = useState<AdminUserType>(defaultCustomerlValues)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const [duplicate, setDuplicate] = useState({ value: '', text: '' })

  // Get user detail from server
  const getUserDetail = () => {
    setLoading(true)
    callApi({
      method: 'get',
      url: apiUrls.adminUsers(id),
      successCallback: ({ status, data }) => {
        if (status) {
          setInitialValues({
            ...data,
            email: data.email || '',
            customer_code: data.customer_code || '',
            date_of_birth: data.date_of_birth
              ? moment(data.date_of_birth, 'DD/MM/YYYY').toDate()
              : null,
          })
          setLoading(false)
        } else setLoading(false)
      },
    })
  }

  useEffect(() => {
    getUserDetail()
  }, [])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema(duplicate),
    onSubmit: (values) => {
      const { full_name, customer_code, gender, email, date_of_birth } = values
      const data = {
        full_name,
        customer_code,
        gender,
        email,
        date_of_birth: date_of_birth ? moment(date_of_birth).format('DD/MM/YYYY') : '',
      }
      if (gender === null) delete data.gender
      callApi({
        method: 'put',
        url: apiUrls.adminNormalUsers(id),
        data,
        successCallback: ({ id, status, text }) => {
          if (status) {
            allowRedirect()
            history1.push('/admin/users')
          } else if (id === '461') {
            setDuplicate({ value: customer_code || '', text })
            validateForm()
          }
        },
        loading: true,
        //   popupIds: [{ id: '430', confirm: onClose }],
      })
    },
  })

  const {
    getFieldProps,
    handleChange,
    values,
    isValid,
    validateForm,
    setFieldValue,
    dirty,
    handleSubmit,
    errors,
  } = formik

  useEffect(() => {
    if (dirty) preventRedirect()
    else allowRedirect()
  }, [dirty])
  return (
    <div>
      {renderBackTitle(history, 'Sửa người dùng')}
      <Card sx={{ p: 3 }} style={{ maxWidth: 540 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <RenderField title="Họ và tên" size="xl ws" marginBottom="md" required>
              <Input
                autoFocus
                {...getFieldProps('full_name')}
                value={values.full_name}
                onChange={(e) => {
                  if (e.target.value === '' || validateTypingName(e)) handleChange(e)
                }}
                maxLength={50}
              />
            </RenderField>
            <RenderField title="Số điện thoại" size="xl ws" marginBottom="md" required>
              <Input {...getFieldProps('phone')} disabled />
            </RenderField>
            <RenderField title="Mã số khách hàng" size="xl ws" marginBottom="md">
              <Input
                {...getFieldProps('customer_code')}
                maxLength={50}
                value={(values.customer_code || '').toUpperCase()}
                onChange={(e) => {
                  if (validateTypingCode(e)) {
                    const value = e.target.value
                    e.target.value = removeAccentsKeepSpecialEmail(value).toUpperCase()
                    handleChange(e)
                  }
                }}
                error={!!errors.customer_code && t(errors.customer_code?.toString())}
              />
            </RenderField>
            <RenderField title="Giới tính" size="xl ws" marginBottom="md">
              <Select
                fullWidth
                data={listGenders}
                hideSelected
                selected={values.gender}
                setSelected={(value) => setFieldValue('gender', value)}
              />
            </RenderField>
            <RenderField title="Ngày sinh" size="xl ws" marginBottom="md">
              <DatePicker
                rootPortal
                popperPlacement="bottom"
                placeholder="DD/MM/YYYY"
                date={values.date_of_birth}
                setDate={(date) => setFieldValue('date_of_birth', date)}
                minDate={minDate1900}
                maxDate={today}
              />
            </RenderField>
            <RenderField title="Email" size="xl ws" marginBottom="md">
              {values.verify_method === 1 || values.verify_method === null ? (
                <Input
                  {...getFieldProps('email')}
                  maxLength={50}
                  onKeyDown={emailKeydownPreventSpace}
                  onChange={(e) => {
                    const value = e.target.value
                    e.target.value = removeAccentsKeepSpecialEmail(value)
                    handleChange(e)
                  }}
                  error={!!errors.email && t(errors.email?.toString())}
                />
              ) : (
                `${values.email}`
              )}
            </RenderField>
            <div style={{ textAlign: 'right' }}>
              <Button
                style={{ marginRight: 24 }}
                onClick={() => history.push('/admin/users')}
                type="button"
                small={false}
              >
                Hủy bỏ
              </Button>
              <Button
                type="submit"
                variant="contained"
                small={false}
                loading={loading}
                style={{ width: 70 }}
                disabled={!dirty || !isValid}
              >
                Lưu
              </Button>
            </div>
          </Form>
        </FormikProvider>
      </Card>
    </div>
  )
}

export default EditUser
