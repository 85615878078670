import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from '@mui/material'
import { MAX_QUANTITY, REGEX_NUMBER } from 'configs/constants'
import { formatQuantity } from 'utils'

interface PropTypes {
  className?: string
  label?: string
  value?: number | string
  max?: number
  count?: number
  onChange?: (value: number | string) => void
  onChangeInput?: (value: number | string) => void
  onIncrease?: (value: number | string) => void
  onDecrease?: (value: number | string) => void
  onBlur?: (value: number | string) => void
  error?: any
  setError?: any
}

export default function InputQuantity(props: PropTypes) {
  const { t } = useTranslation()
  const {
    className = '',
    label = '',
    value = 1,
    count,
    max = MAX_QUANTITY,
    onChange = () => {},
    onChangeInput = () => {},
    onIncrease = () => {},
    onDecrease = () => {},
    onBlur = () => {},
    error,
    setError,
  } = props

  // Get max value
  const maxValue = useMemo(() => (max >= 1 ? max : MAX_QUANTITY), [max])

  useEffect(() => {
    if (!setError) return

    if (value >= maxValue) {
      if (value > maxValue) onChange(maxValue)

      setError('Đã đạt đến số lượng mua tối đa cho phép của sản phẩm này')
    } else {
      if (error) setError('')
    }
  }, [value])

  /**
   * Handle change quantity
   *
   * @param e DOM Event
   */
  const handleChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    // if invalid quanity => do nothing
    if ((value && !REGEX_NUMBER.test(value)) || Number(value) > Number.MAX_SAFE_INTEGER) return

    onChange(value)
    onChangeInput(value)
  }

  /**
   * Handle blur
   */
  const handleBlur = () => {
    let numValue = Number(value)

    if (numValue < 1) numValue = 1
    if (numValue > maxValue) numValue = maxValue

    onChange(numValue)
    onChangeInput(numValue)
    onBlur(numValue)
  }

  /**
   * Handle click change quantity
   *
   * @param isUp Click button increase
   */
  const handleClickChangeQuantity = (isUp = true) => {
    const numValue = Number(value)

    const newQuantity = isUp ? Math.min(numValue + 1, maxValue) : Math.max(numValue - 1, 1)
    onChange(newQuantity)
    isUp ? onIncrease(newQuantity) : onDecrease(newQuantity)
  }

  const outOfStock = count === 0

  return (
    <div className={`InputQuantity-container ${className}`}>
      {outOfStock ? (
        <div className="InputQuantity-outOfStock">{t('outOfStock')}</div>
      ) : (
        <>
          {label && <label className="InputQuantity-lb label gray">{t(label)}</label>}
          <div className="InputQuantity-inputWrapper">
            <button type="button" onClick={() => handleClickChangeQuantity(false)}>
              -
            </button>
            <Input
              value={formatQuantity(value, true)}
              onChange={handleChangeQuantity}
              onBlur={handleBlur}
              inputProps={{ maxLength: 4 }}
            />
            <button type="button" onClick={() => handleClickChangeQuantity()}>
              +
            </button>
          </div>
        </>
      )}
    </div>
  )
}
