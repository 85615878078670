/* eslint-disable */
import { Container } from '@mui/material'
import { Loading } from 'components/common'
import Page from 'components/page/Page'
import ProductsWrapper from 'components/screens/app/products'
import { isArray } from 'lodash'
import qs from 'qs'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useBreadcrumbs } from 'store/breadcrumbs'
import { useCategories } from 'store/categories'
import { useMobile } from 'utils'

export default function Products() {
  const { showBreadcrumbs } = useBreadcrumbs()
  const { getBreadcrumbs, getCategory, hasCategories } = useCategories()
  const isMobile = useMobile()

  const { search: requestParams, state } = useLocation() as any

  // const

  const parsedRequestParams = qs.parse(requestParams, { ignoreQueryPrefix: true })

  const { page, keyword, category }: any = parsedRequestParams
  let { selected = [] }: any = parsedRequestParams
  const categories: any[] = []
  if (category) categories.push(category)
  if (selected && !isArray(selected)) selected = [selected]
  categories.push(...selected)

  const fullCategories = categories?.map((item: any) => getCategory(item)).filter((item) => item)

  const thisCategory = category
    ? category === 'san-pham-khac'
      ? {
          id: state?.manufacturerId,
          type: 2,
          name: 'Sản phẩm khác',
          other_product: true,
          ...category,
        }
      : getCategory(category)
    : null

  const breadcrumb = `Kết quả tìm kiếm cho "${keyword}"`

  const title = keyword ? breadcrumb : thisCategory?.name || 'Danh Sách Sản Phẩm'

  const otherProductBreadcumbs = [
    {
      name: 'Hãng sản xuất',
      path: '/hang-san-xuat',
    },
    {
      name: state?.manufacturerName,
      path: state?.manufacturerLinkFull,
    },
    {
      name: 'Sản phẩm khác',
      path: '/danh-sach-san-pham?category=san-pham-khac',
    },
  ]

  const categoryBreadcrumbs = category
    ? category === 'san-pham-khac'
      ? otherProductBreadcumbs
      : getBreadcrumbs(category)
    : []

  useEffect(() => {
    showBreadcrumbs(
      keyword ? [{ name: isMobile ? breadcrumb : keyword, path: '/' }] : categoryBreadcrumbs
    )
  }, [getBreadcrumbs, isMobile, keyword])

  if (!hasCategories) return <Loading fullScreen />

  let newPage = Number(page)
  if (!newPage || isNaN(newPage)) newPage = 1

  return (
    <Page title="Danh Sách Sản phẩm">
      <Container>
        <div className="Page-title">{title}</div>
      </Container>
      <ProductsWrapper
        category={thisCategory}
        categories={fullCategories}
        selected={selected}
        parsedRequestParams={parsedRequestParams}
        page={newPage}
        keyword={keyword}
      />
    </Page>
  )
}
