import { useTranslation } from 'react-i18next'
import { MenuItem } from 'types'
import { Link, MaxLine } from 'components'
import { useCallback } from 'react'

interface PropTypes {
  className?: string
  name?: string
  items?: MenuItem[]
  showIconNext?: boolean
  onClose?: any
  hideImage?: boolean
}

export default function AppLinks(props: PropTypes) {
  const { t } = useTranslation()

  const { className = '', name = '', items = [], showIconNext, onClose, hideImage } = props

  const Wrapper = useCallback(({ to, onClick, children, isAHref, isPreview }: any) => {
    if (isAHref)
      return (
        <a href={to} target="_blank" rel="noreferrer" onClick={onClick}>
          {children}
        </a>
      )
    if (isPreview) {
      return <a>{children}</a>
    }
    return (
      <Link to={to} onClick={onClick}>
        {children}
      </Link>
    )
  }, [])

  return (
    <div className={`AppLinks-container ${className}`}>
      {name && <h5 className="AppLinks-name">{t(name)}</h5>}
      {!!items?.length && (
        <ul className="AppLinks-list">
          {items.map((item: any, index) => {
            const hasImage = !!item.image && !hideImage

            return (
              <li key={index}>
                <Wrapper
                  to={item.path}
                  onClick={() => {
                    onClose?.()
                  }}
                  isAHref={item.isAHref}
                  isPreview={item.isPreview}
                >
                  <span className="main">
                    {hasImage && <img alt="" className="AppLinks-img" src={item.image} />}
                    <span className="text" style={{ marginLeft: hasImage ? 8 : 0 }}>
                      <MaxLine numberOfLines={1} text={t(item.name)} />
                      {/* {t(item.name)} */}
                    </span>
                  </span>
                  {showIconNext && <span className="Ic-next" />}
                </Wrapper>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}
