import { Pagination } from 'components/common'
import React from 'react'
import { DocumentType } from 'types'
import DocumentItem from './DocumentItem'

interface Props {
  items: DocumentType[]
  page: number
  totalPages: number
  onChangePage: any
  isMobile: boolean
  loading: boolean
}

const Documents: React.FC<Props> = ({ items, totalPages, page, onChangePage, loading }) => {
  return (
    <div className="ArticleItemsWrapper">
      {!loading && items.length === 0 && (
        <div className="card-noItems">
          <div className="ProductsMain-no-items__wrapper" style={{ paddingTop: 50 }}>
            <h3 className="card-title">Không có tài liệu phù hợp</h3>
            <p className="label gray ProductsMain-no-items__label">
              Không tìm thấy tài liệu kỹ thuật nào
            </p>
          </div>
        </div>
      )}
      {items.map((item) => (
        <DocumentItem key={item.id} {...item} />
      ))}
      <div style={{ marginBottom: 32 }}>
        <Pagination count={totalPages} page={page} onChange={onChangePage} left />
      </div>
    </div>
  )
}

export default Documents
