import MaxLine from 'components/maxLine/MaxLine'
import { defaultProductThumbnail } from 'configs/constants'
import { useMemo } from 'react'
import { formatPrice } from 'utils'
import './index.scss'

interface Props {
  price?: number
  name?: string
  amount?: number
  manufacturer?: string
  discount_percent?: number
  discount_value?: number
  finalPrice?: number
  image?: string
  [key: string]: any
}

const ProductItem: React.FC<Props> = (props: Props) => {
  const { price = 0, image = '', name, amount = 1, manufacturer, finalPrice = 0 } = props

  const { oldPrice, newPrice } = useMemo(() => {
    return {
      oldPrice: finalPrice === price ? null : formatPrice(price * amount),
      newPrice: formatPrice(finalPrice * amount),
    }
  }, [price, finalPrice])

  return (
    <div className="ProductItem">
      <img
        className="ProductItem-img"
        alt=""
        width={48}
        height={48}
        src={image || defaultProductThumbnail}
      />
      <div className="ProductItem-information">
        <div className="ProductItem-name">
          <MaxLine text={name || ''} numberOfLines={1} title={name || ''} />
        </div>
        <div className="ProductItem-amount">{`Số lượng: ${amount}`}</div>
        <div className="ProductItem-manufacturer">
          <MaxLine text={`Hãng sản xuất: ${manufacturer || ''}`} numberOfLines={1} />
        </div>
      </div>
      <div className="ProductItem-price">
        {oldPrice && <del className="ProductItem-oldPrice">{oldPrice}</del>}
        <div className="ProductItem-newPrice">{newPrice}</div>
      </div>
    </div>
  )
}
export default ProductItem
