/*eslint-disable*/
import React from 'react'
import { ArticleType, ProductType } from 'types'
import { formatDateVI, formatPrice, getProductTag } from 'utils'

import imgProductTemp from 'assets/images/imgProductTemp.png'
import { renderToString } from 'react-dom/server'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'store/confirmRedirect'

interface Props extends ArticleType {
  [key: string]: any
  getArticleDetail?: () => void
}

const ArticleDetail: React.FC<Props> = ({
  content,
  start_date,
  title,
  block_products,
  getArticleDetail,
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleClickProduct = (e: any, link_seo: string) => {
    history.push(`/san-pham/${link_seo}`)
    e.stopPropagation()
  }

  const renderBlockProduct = (product: ProductType = {}, indexBlock: number) => {
    const {
      name = 'Bộ công cụ sửa chữa cầm tay của Makita',
      link_seo = '',
      avatar_url,
      price = 5000000,
      final_price = 0,
      id = '',
    } = product || {}

    const oldPrice = final_price === price ? null : formatPrice(price)
    const newPrice = formatPrice(final_price)
    const tag = getProductTag(product)
    setTimeout(() => {
      Array.from(
        document.getElementsByClassName(`ProductItemArticle-content${id}${indexBlock}`)
      )?.forEach((item: any) =>
        item.addEventListener('click', (e: any) => handleClickProduct(e, link_seo))
      )
      Array.from(
        document.getElementsByClassName(`ProductItemArticle-btn${id}${indexBlock}`)
      )?.forEach((item: any) =>
        item.addEventListener('click', (e: any) => handleClickProduct(e, link_seo))
      )
    }, 200)

    return renderToString(
      <div className={`ProductItemArticle-item ProductItemArticle-content${id}${indexBlock}`}>
        <div className="ProductItemArticle-content">
          <span
            className="ProductItemArticle-img"
            style={{ backgroundImage: `url("${avatar_url || imgProductTemp}")` }}
          ></span>
          <h6 className="ProductItemArticle-name">{name}</h6>
          <div className="ProductItemArticle-prices">
            {<span className="oldPrice">{oldPrice}</span>}
            <span className="currentPrice">{newPrice}</span>
          </div>
        </div>
        <button className={`ProductItemArticle-btn ProductItemArticle-btn${id}${indexBlock}`}>
          <span>Mua ngay</span>
          <span className="Ic-next icon" />
        </button>
        {tag && <span className={`ProductItemArticle-tag ${tag}`}>{t(tag)}</span>}
      </div>
    )
  }

  let contentReplace = content

  block_products?.forEach(
    ({ block_id, products }: { block_id: string; products: any }, index: number) => {
      contentReplace = contentReplace.replaceAll(
        `{${block_id}}`,
        products.length
          ? `<div class='ProductItemArticle-container'>${products
              .map((i: any) => renderBlockProduct(i, index))
              .join('')}</div>`
          : `{${block_id}}`
      )
    }
  )

  const summary = (
    <div
      className="label"
      dangerouslySetInnerHTML={{
        __html: contentReplace,
      }}
    />
  )

  return (
    <div className="ArticleDetail">
      <div className="ArticleDetail-date">{start_date ? formatDateVI(start_date) : null}</div>
      <h3 className="ArticleDetail-title">{title}</h3>
      <div className="ArticleDetail-content">{summary}</div>
    </div>
  )
}

export default ArticleDetail
