import { Checkbox, MenuItem, Popover } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Button from '../button/Button'
import Scrollbar from '../../scrollbar/Scrollbar'
import { useTranslation } from 'react-i18next'

interface Props {
  columns: string[]
  defaultShownColumns: string[]
  save: (newShownColumns?: string[]) => void
  width?: number
  hiddenColumns?: string[]
  [key: string]: any
}

const ShowHideColumns: React.FC<Props> = ({
  columns,
  defaultShownColumns,
  save,
  width,
  hiddenColumns = [],
}) => {
  const { t } = useTranslation()
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [checked, setChecked] = useState(defaultShownColumns)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const apply = (isReset?: boolean) => {
    handleClose()
    save(isReset ? columns.filter((item) => !hiddenColumns.includes(item)) : checked)
  }

  useEffect(() => {
    if (!open) return

    setChecked(defaultShownColumns)
  }, [open])

  return (
    <div style={{ paddingRight: 20 }}>
      <Button innerRef={anchorRef} style={{ padding: '0 10px', height: 32 }} onClick={handleOpen}>
        Ẩn/hiện cột
      </Button>
      <Popover
        className="MenuPopover"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1,
            width: width || 250,
            borderRadius: '4px',
          },
        }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <Scrollbar className="mt-1" style={{ maxHeight: 300 }}>
          {columns.map((item) => (
            <MenuItem
              key={item}
              style={{ padding: '0px 12px' }}
              onClick={() => {
                setChecked((oldChecked) => {
                  // If checked => un check, otherwise
                  if (oldChecked.includes(item)) return oldChecked.filter((c) => c !== item)
                  return [...checked, item]
                })
              }}
            >
              <Checkbox checked={checked.includes(item)} />
              {!!item && <span>{t(item)}</span>}
            </MenuItem>
          ))}
        </Scrollbar>
        <div className="w-100 d-f jc-e" style={{ padding: '0 28px 6px' }}>
          <span
            className="a"
            style={{ textDecoration: 'underline', fontSize: 14, cursor: 'pointer' }}
            onClick={() => apply(true)}
          >
            Mặc định
          </span>
        </div>
        <div className="d-f jc-e w-100" style={{ padding: '8px 12px 12px' }}>
          <Button variant="outlined" style={{ marginRight: 8, height: 30 }} onClick={handleClose}>
            Hủy
          </Button>
          <Button variant="contained" style={{ height: 30 }} onClick={() => apply()}>
            Lưu
          </Button>
        </div>
      </Popover>
    </div>
  )
}

export default ShowHideColumns
