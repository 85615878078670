import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TableCell, TableRow } from '@mui/material'

import { useShoppingCart } from 'store/shoppingCart'

import { InputQuantity } from 'components/common'
import CartItemActions from './CartItemActions'

import { formatPrice } from 'utils'
import { CART_TYPES, defaultProductThumbnail, MAX_QUANTITY } from 'configs/constants'
import { CartItemType } from 'types'
import { useHistory } from 'store/confirmRedirect'

interface PropTypes {
  cartItem: CartItemType
  cartType?: number
  isMobileView?: boolean
}

const { ON_BOARD } = CART_TYPES

export default function CartItem(props: PropTypes) {
  const history = useHistory()
  const { t } = useTranslation()
  const { removeItemFromCart, updateCartItem, updateCartBuyLater } = useShoppingCart()

  const { cartItem, cartType = ON_BOARD, isMobileView } = props

  const {
    id = '',
    product_id: productId = '',
    quantity = 1,
    product = {},
  } = cartItem || ({} as CartItemType)

  const {
    price = 0,
    name,
    avatar_url,
    link_seo,
    inventory_number: inventoryNumber,
    final_price = 0,
  } = product

  const isCartOnBoard = cartType === ON_BOARD

  const outOfStock = inventoryNumber === 0

  useEffect(() => {
    quantity !== newQuantity && setNewQuantity(quantity)
  }, [quantity])

  const [newQuantity, setNewQuantity] = useState<any>(quantity)

  // Get some info
  const { btnExecuteLb, numberPrice, newPrice } = useMemo(() => {
    return {
      btnExecuteLb: isCartOnBoard ? 'buyLater' : 'addToCart',
      numberPrice: final_price,
      newPrice: price,
    }
  }, [isCartOnBoard, price])

  /**
   * Handle change quantity of product & update it in shopping cart
   * when user type quantity number into input
   *
   * @param value Quantity
   */
  const handleChangeInputQuantity = (value: number | string) => {
    setNewQuantity(value)
  }

  const handleUpdateCartQuantity = (value: number | string) => {
    const productCart = { ...product, quantity: value }
    updateCartItem(id, productCart, true)
  }

  /**
   * Handle change quantity cart item when user click button increase or decrease
   *
   * @param value Quantity
   */
  const handleClickChangeQuantity = (isUp = true) => {
    if (isUp) {
      if (quantity >= MAX_QUANTITY) return
    } else {
      if (quantity <= 1) return
    }
    const productCart = { ...product, quantity: quantity + (isUp ? 1 : -1) }

    updateCartItem(id, productCart, true)
  }

  /**
   * Handle remove item from cart
   */
  const handleRemoveItem = () => {
    removeItemFromCart(id, productId)
  }

  /**
   * Handle execute product item
   */
  const handleExecuteItem = () => {
    updateCartBuyLater(id, productId, isCartOnBoard)
  }

  const handleClickProduct = () => {
    history.push(`/san-pham/${link_seo}`, { cart: productId })
  }

  const manufacturerName = product.manufacturer?.name || 'Caterpillar'

  return (
    <>
      {isMobileView ? (
        <div className="CartItemSm-container">
          <span
            className="CartItem-img"
            style={{ backgroundImage: `url("${avatar_url || defaultProductThumbnail}")` }}
          />
          <div className="CartItemSm-content">
            <div className="CartItemSm-top">
              <span
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: 'calc(100% - 90px)',
                }}
                onClick={handleClickProduct}
              >
                {name}
              </span>
              <span>{newPrice}</span>
            </div>
            <p className="CartItemSm-brand">
              {t('brand')}: {manufacturerName}
            </p>
            <div className={outOfStock ? 'CartItemSm-inputActions' : ''}>
              <InputQuantity
                className="CartItemSm-quantity"
                value={newQuantity}
                count={inventoryNumber}
                onChangeInput={handleChangeInputQuantity}
                onIncrease={() => handleClickChangeQuantity()}
                onDecrease={() => handleClickChangeQuantity(false)}
                onBlur={handleUpdateCartQuantity}
              />
              <CartItemActions
                className="CartItemSm-actions"
                btnExecute={btnExecuteLb}
                onExecute={quantity ? handleExecuteItem : undefined}
                btnDelete="delete"
                onDelete={handleRemoveItem}
              />
            </div>
          </div>
        </div>
      ) : (
        <TableRow>
          <TableCell>
            <div className="CartItem-product">
              <span
                className="CartItem-img"
                style={{ backgroundImage: `url("${avatar_url || defaultProductThumbnail}")` }}
              />
              <div className="CartItem-name">
                <h5 onClick={handleClickProduct}>{name}</h5>
                <p>
                  {t('brand')}: {manufacturerName}
                </p>
              </div>
            </div>
          </TableCell>
          <TableCell>
            <p className="CartItem-price">{formatPrice(newPrice)}</p>
          </TableCell>
          <TableCell>
            <InputQuantity
              value={newQuantity}
              count={inventoryNumber}
              onChangeInput={handleChangeInputQuantity}
              onIncrease={() => handleClickChangeQuantity()}
              onDecrease={() => handleClickChangeQuantity(false)}
              onBlur={handleUpdateCartQuantity}
            />
          </TableCell>
          <TableCell>
            <p className="CartItem-price note">
              {outOfStock ? '-' : formatPrice(quantity * numberPrice)}
            </p>
          </TableCell>
          <TableCell>
            <CartItemActions
              btnExecute={btnExecuteLb}
              onExecute={quantity ? handleExecuteItem : undefined}
              btnDelete="delete"
              onDelete={handleRemoveItem}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
