/*eslint-disable*/
import { Box, Card } from '@mui/material'
import { Button, Editor, RenderField } from 'components/admin'
import UploadFile from 'components/admin/uploadFile/UploadFile'
import { Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { isString } from 'utils'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import { Loading } from 'components/common'
import { TYPR_OF_ABOT_US } from './constant'
import { useHistory } from 'store/confirmRedirect'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'types'

interface Props {
  type: number
}

const validationSchema = Yup.object().shape({
  video_url: isString,
  content: isString,
})

const { ABOUT_US } = TYPR_OF_ABOT_US

const AboutUsWrapper: React.FC<Props> = ({ type }) => {
  const history = useHistory()
  const { allowRedirect, preventRedirect } = history
  const { apiGet, apiPut } = useApis()
  const [initialValues, setInitialValues] = useState({
    video_url: '',
    content: '',
  })

  const [ckValidator, setCKValidator] = useState<{ show: boolean; content: string }>({
    show: false,
    content: '',
  })

  const [loading, setLoading] = useState(true)

  const allow = useSelector((state: RootState) => state.confirmRedirect, shallowEqual)

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: ({ video_url, content }) => {
      apiPut(apiUrls.adminAboutUs(), { video_url, content, type }, ({ status }) => {
        if (status) getAboutUs()
      })
    },
  })

  const { setFieldValue, values, handleSubmit, isValid, dirty, isSubmitting, resetForm } = formik

  const getAboutUs = () => {
    apiGet(apiUrls.adminAboutUs(), { type }, ({ data, status }) => {
      if (status) {
        setInitialValues({
          content: data?.content || '',
          video_url: data?.video_url || '',
        })
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    if (allow) {
      getAboutUs()
      resetForm()
      setCKValidator({
        show: false,
        content: '',
      })
    }
  }, [allow])

  useEffect(() => {
    return () => {
      allowRedirect()
    }
  }, [])

  useEffect(() => {
    if (dirty) preventRedirect()
    else allowRedirect()
  }, [dirty])

  return (
    <Card sx={{ p: 3 }}>
      {loading ? (
        <Box
          sx={{
            height: '816px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Loading />
        </Box>
      ) : (
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <RenderField title="Tải video" required>
              <div style={{ maxWidth: 'calc(100% - 192px)', overflow: 'hidden' }}>
                <UploadFile
                  labelButton="Chọn file"
                  accept="video/mp4"
                  file_url={values.video_url || ''}
                  onSuccess={(url) => {
                    setFieldValue('video_url', url)
                  }}
                  onClick={(url) => window.open(url)}
                  labelMaxsize="100MB"
                  maxFileName={200}
                  maxSize={104857600}
                  isUpdate={true}
                  prefix={type === ABOUT_US ? 'video-about-us' : 'video-vision-mission'}
                />
              </div>
            </RenderField>
            <div style={{ margin: '20px 0' }}>
              Nội dung
              <span>
                {' '}
                (<span style={{ color: 'var(--cl-primary)' }}>*</span>)
              </span>
            </div>
            <Editor
              value={values.content}
              onChange={(value) => {
                setFieldValue('content', value)
              }}
              error={ckValidator}
              setError={setCKValidator}
              minLength={200}
              maxLength={10000}
            />
            <div style={{ textAlign: 'right', marginTop: 20 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isValid || !dirty || ckValidator.show}
                loading={isSubmitting}
              >
                Lưu
              </Button>
            </div>
          </Form>
        </FormikProvider>
      )}
    </Card>
  )
}

export default AboutUsWrapper
