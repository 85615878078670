import { useTranslation } from 'react-i18next'
import { formatQuantity } from 'utils'

interface Props {
  name: string
  amount: number
  image: string
  onClick: () => void
}

const EnginePartItem: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { name = '', amount = 0, image = '', onClick } = props

  return (
    <div className="EnginePartItem-wrapper" onClick={onClick}>
      <div className="EnginePartItem-container">
        <img className="EnginePartItem-container__img" src={image} alt="" />
        <i className="mn-icon-arrow" />
        <div className="EnginePartItem-background" />
        <div className="EnginePartItem-triangle"></div>
        <div className="EnginePartItem-information">
          <div className="EnginePartItem-name">{name}</div>
          <div className="EnginePartItem-amount">
            <span> {`${formatQuantity(amount)} ${t('product')}`}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnginePartItem
