import { Drawer, MenuItem, Popover } from '@mui/material'
import Input from './Input'
import Checkbox from '../checkbox/Checkbox'
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react'
import { ObjectType, SelectType } from 'types'
import { isEqual } from 'lodash'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import { useIsMobile } from 'store/mobile'
import ButtonOption from '../button/ButtonOption'
import { ButtonDrawerConfirm } from 'components'

interface Props {
  selected?: any
  setSelected?: (selected: any) => void
  label?: string
  data?: SelectType[]
  style?: CSSProperties
  inputStyle?: CSSProperties
  placeholder?: string
  required?: boolean
  disabled?: boolean
  maxHeight?: number
  checkboxes?: { checked: string[]; onChange: (id: string) => void }
  hideArrow?: boolean
  popupSearch?: {
    timeout?: number
    searchByLabel?: boolean
    labelNoItems?: string
    placeholder?: string
    onSearch?: (keyword: string) => void
    onChange?: (keyword: string) => void
  }
  onClick?: any
  drawer?: {
    label?: string
    buttonOptions?: boolean
    minHeight?: number
    btnConfirm?: boolean
  }
  itemStyle?: ObjectType
  [key: string]: any
}

const Select: React.FC<Props> = ({
  data = [],
  selected,
  setSelected,
  style,
  disabled,
  maxHeight,
  popupSearch,
  inputStyle,
  hideArrow,
  checkboxes,
  onClick,
  label,
  drawer: drawerParams,
  itemStyle = {},
  ...rest
}) => {
  const drawer = useIsMobile()

  const anchorRef = useRef<HTMLInputElement>(null)
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const timeoutSearch = useRef<any>()

  const showCheckbox = !!checkboxes
  const { checked = [], onChange: onChangeChecked = () => {} } = checkboxes || {}
  const {
    label: drawerLabel,
    buttonOptions,
    minHeight: drawerMinHeight,
    btnConfirm,
  } = drawerParams || {}

  const {
    timeout,
    searchByLabel,
    labelNoItems,
    placeholder: searchPlaceholder = 'Nhập từ khóa',
    onSearch,
    onChange,
  } = popupSearch || {}

  const value = data.find((item) => isEqual(item.value, selected))?.label || ''

  // Open the menu if has data
  const handleOpen = () => {
    if (disabled) return
    if (onClick) {
      onClick?.()
      return
    }
    if (data.length) setOpen(true)
  }

  // Close the menu
  const handleClose = () => {
    setOpen(false)
  }

  // Search when click item, enter or after 1 second
  const handleSearch = (newValue?: string) => {
    if (!newValue) newValue = value || ''
    onSearch?.(newValue)
  }

  // When click item, set selected item, set value in textbox and close menu
  const handleClickItem = (item: SelectType) => {
    if (showCheckbox) {
      onChangeChecked(item.value)
      return
    }

    setSelected?.(item.value)
    handleSearch(searchByLabel ? item.label : item.value)
    handleClose()
  }

  useEffect(() => {
    if (open && search) setSearch('')
  }, [open])

  useEffect(() => {
    onChange?.(search)

    if (!popupSearch) return

    if (timeout === 0) {
      handleSearch?.(value)
      return
    }

    // If user not typing after 700ms => search
    timeoutSearch.current = setTimeout(handleSearch, timeout || 700)

    return () => clearTimeout(timeoutSearch.current)
  }, [search])

  const renderInputSearch = () => {
    if (!popupSearch) return null

    return (
      <div className="SelectPaper-search">
        <Input
          autoFocus
          placeholder={searchPlaceholder}
          icon="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          maxLength={50}
        />
      </div>
    )
  }

  const Wrapper = useCallback(
    ({ children, open, onClose, popupSearch }) => {
      if (drawer)
        return (
          <Drawer
            className="SelectDrawer"
            open={open}
            anchor="bottom"
            onClose={onClose}
            onBackdropClick={onClose}
          >
            <div style={{ minHeight: drawerMinHeight }}>
              <div className="SelectDrawer-header">
                <span>{drawerLabel || label}</span>
                <i className="mn-icon-close" onClick={handleClose} />
              </div>
              <div style={{ paddingBottom: 12 }}>{children}</div>
              {btnConfirm && <ButtonDrawerConfirm onClick={handleClose} />}
            </div>
          </Drawer>
        )

      return (
        <Popover
          anchorOrigin={{
            vertical: popupSearch ? 'top' : 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{
            style: {
              width: anchorRef.current?.getBoundingClientRect().width || 0,
            },
            className: 'SelectPaper',
          }}
          disableAutoFocus
          open={open}
          onClose={handleClose}
          anchorEl={anchorRef.current}
        >
          {children}
        </Popover>
      )
    },
    [drawer]
  )

  return (
    <div style={style}>
      <Input
        {...rest}
        style={inputStyle}
        disabled={disabled}
        innerRef={anchorRef}
        onClick={handleOpen}
        value={value}
        readOnly={true}
        label={label}
        icon={!hideArrow ? `dropdown rotate${open ? ' rotate-180' : ''}` : undefined}
        cursorPointer={true}
      />
      <Wrapper open={open} onClose={handleClose} popupSearch={popupSearch}>
        {renderInputSearch()}
        {data.length === 0 && labelNoItems && (
          <div className="SelectPaper-noItems">{labelNoItems}</div>
        )}
        <SimpleBar className="SelectPaper-paper" style={{ maxHeight }} autoHide={false}>
          {buttonOptions && drawer ? (
            <div className="SelectPaper-buttonOptions">
              <div className="SelectPaper-buttonOptions__title label dark">
                Có thể chọn nhiều kết quả
              </div>
              <div className="SelectPaper-buttonOptions__content">
                {data.map((item, index) => (
                  <ButtonOption
                    key={index}
                    active={checked.includes(item.value)}
                    onClick={() => onChangeChecked(item.value)}
                    label={item.value}
                  />
                ))}
              </div>
            </div>
          ) : (
            data.map((item, index) => (
              <div key={index}>
                <MenuItem
                  className={`SelectPaper-item${selected === item.value ? ' active' : ''}`}
                  onClick={() => handleClickItem(item)}
                  style={{ display: item.hide ? 'none' : undefined, ...itemStyle }}
                >
                  {showCheckbox && (
                    <Checkbox
                      checked={checked.includes(item.value)}
                      color="secondary"
                      onChange={() => onChangeChecked(item.value)}
                    />
                  )}
                  {item.label}
                </MenuItem>
              </div>
            ))
          )}
        </SimpleBar>
      </Wrapper>
    </div>
  )
}

export default Select
