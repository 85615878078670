import { ClickAwayListener, Tooltip } from '@mui/material'
import copy from 'copy-to-clipboard'
import { useState } from 'react'

import './DropdownBank.scss'

interface Props {
  dropdown?: boolean
  showDropDown?: any
  setShowDropDown?: (showDropDown: boolean) => any
  bankList?: any
  bankTranfer?: any
  handleChangeBank?: any
  // anchorRef?: any
}

const DropdownBank: React.FC<Props> = ({
  dropdown,
  showDropDown = false,
  setShowDropDown,
  bankList,
  bankTranfer,
  handleChangeBank,
}: // anchorRef,
Props) => {
  const [copyBank, setCopyBank] = useState(false)

  const {
    account_name: cardHolder,
    bank_name: nameBank,
    bank_number: bankNumber,
    momo_phone: phoneNumber,
  } = bankTranfer || {}

  /**
   * handle Copy
   */
  const handleCopyBank = (e: any, content: string) => {
    e.stopPropagation()
    setCopyBank(true)
    copy(content)
    setTimeout(() => {
      setCopyBank(false)
    }, 1000)
  }

  if (dropdown && (bankList?.length || 0) <= 1) dropdown = false

  const handleClickDropdown = () => {
    if (dropdown) {
      return setShowDropDown?.(!showDropDown)
    }
    return null
  }

  const bankClassName = `DropdownBank-bankName label${dropdown ? ' mr' : ''}`

  return (
    <ClickAwayListener onClickAway={() => setShowDropDown?.(false)}>
      <div className="DropdownBank" onClick={handleClickDropdown}>
        <div className="d-f ai-c ">
          <div
            className={`DropdownBank-bankingNumberWrapper ${showDropDown ? 'showDropDown' : ''}`}
          >
            <div className={bankClassName}>{nameBank}</div>
            <div className={bankClassName}>Chủ tài khoản: {cardHolder}</div>
            {bankList && <div className={bankClassName}>Số tài khoản: {bankNumber}</div>}
            {phoneNumber && <div className={bankClassName}>Số điện thoại: {phoneNumber}</div>}

            {!!dropdown && <i className="mn-icon-dropdown icon " />}

            <div
              className="DropdownBank-copy"
              onClick={(e: any) => handleCopyBank(e, bankNumber || phoneNumber || '')}
              // ref={anchorRef}
            >
              <ClickAwayListener onClickAway={() => setCopyBank(false)}>
                <div className="DropdownBank-widthCopy">
                  <i className="mn-icon-copy" />
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setCopyBank(false)}
                    open={copyBank}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Sao chép"
                  >
                    <span className="label">Sao chép </span>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </div>
            {dropdown && (
              <ul className={`DropdownBank-listBank ${showDropDown ? 'showDropDown' : ''}`}>
                {bankList?.map((bank: any) => (
                  <li
                    key={bank?.id}
                    className={`DropdownBank-itemBank${bankTranfer === bank ? ' active' : ''}`}
                    onClick={() => handleChangeBank(bank?.id || '')}
                  >
                    {bank?.bank_name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </ClickAwayListener>
  )
}
export default DropdownBank
