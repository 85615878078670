import React, { MutableRefObject } from 'react'
import { Button as MuiButton, ButtonProps, CircularProgress } from '@mui/material'
import './index.scss'

interface Props extends ButtonProps {
  loading?: boolean
  small?: boolean
  innerRef?: MutableRefObject<any>
}

const Button: React.FC<Props> = ({
  loading,
  innerRef,
  children,
  disabled,
  small = true,
  className = '',
  ...rest
}) => {
  return (
    <MuiButton
      ref={innerRef}
      className={`AdminButton${small ? ' small' : ''} ${className}`}
      disabled={loading || disabled}
      {...rest}
    >
      {loading ? <CircularProgress size={small ? 20 : 24} color="inherit" /> : children}
    </MuiButton>
  )
}

export default Button
