import React, { useEffect } from 'react'

interface Props {
  title?: string
  className?: string
}

const Page: React.FC<Props> = ({
  title = 'Cửa hàng phụ tùng máy nổ uy tín',
  children,
  className = '',
}) => {
  useEffect(() => {
    document.title = `Mayno.vn - ${title}`
  }, [])

  return <div className={className}>{children}</div>
}

export default Page
