/*eslint-disable*/
import React, { useState } from 'react'
import { Box, Card, Tab, Tabs } from '@mui/material'
import GuideUsingStep from 'components/admin/guideUsingStep/GuideUsingStep'
import { renderBackTitle } from 'configs/extensions'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'store/confirmRedirect'

import import1 from '../../../../assets/images/ProductsUsing/import/import1.png'
import import2 from '../../../../assets/images/ProductsUsing/import/import2.png'
import import3 from '../../../../assets/images/ProductsUsing/import/import3.png'
import import4 from '../../../../assets/images/ProductsUsing/import/import4.png'
import import5 from '../../../../assets/images/ProductsUsing/import/import5.png'
import import6 from '../../../../assets/images/ProductsUsing/import/import6.png'
import import7 from '../../../../assets/images/ProductsUsing/import/import7.png'

import exportVideo from '../../../../assets/images/ProductsUsing/import/export.mp4'

import add1 from '../../../../assets/images/ProductsUsing/add/add1.png'
import add2 from '../../../../assets/images/ProductsUsing/add/add2.png'
import add3 from '../../../../assets/images/ProductsUsing/add/add3.png'
import add4 from '../../../../assets/images/ProductsUsing/add/add4.png'
import add5 from '../../../../assets/images/ProductsUsing/add/add5.mp4'
import add6 from '../../../../assets/images/ProductsUsing/add/add6.png'
import add7 from '../../../../assets/images/ProductsUsing/add/add7.png'

import list1 from '../../../../assets/images/ProductsUsing/list/list1.png'
import list2 from '../../../../assets/images/ProductsUsing/list/list2.png'
import list3 from '../../../../assets/images/ProductsUsing/list/list3.png'
import list4 from '../../../../assets/images/ProductsUsing/list/list4.png'
import list5 from '../../../../assets/images/ProductsUsing/list/list5.png'
import list6 from '../../../../assets/images/ProductsUsing/list/list6.png'
import list7 from '../../../../assets/images/ProductsUsing/list/list7.png'
import list8 from '../../../../assets/images/ProductsUsing/list/list8.png'
import list9 from '../../../../assets/images/ProductsUsing/list/list9.png'
import list10 from '../../../../assets/images/ProductsUsing/list/list10.png'
import list11 from '../../../../assets/images/ProductsUsing/list/list11.mp4'
import list12 from '../../../../assets/images/ProductsUsing/list/list12.png'
import list13 from '../../../../assets/images/ProductsUsing/list/list13.png'

import listNote1 from '../../../../assets/images/ProductsUsing/list/listNote1.png'
import listNote2 from '../../../../assets/images/ProductsUsing/list/listNote2.png'
import listNote3 from '../../../../assets/images/ProductsUsing/list/listNote3.png'
import listNote4 from '../../../../assets/images/ProductsUsing/list/listNote4.png'

import view1 from '../../../../assets/images/ProductsUsing/edit/edit1.png'
import view2 from '../../../../assets/images/ProductsUsing/edit/edit2.png'
import view3 from '../../../../assets/images/ProductsUsing/edit/edit3.png'
import edit4 from '../../../../assets/images/ProductsUsing/edit/edit4.png'
import edit5 from '../../../../assets/images/ProductsUsing/edit/edit5.png'
import edit6 from '../../../../assets/images/ProductsUsing/edit/edit6.png'
import edit7 from '../../../../assets/images/ProductsUsing/edit/edit7.png'
import edit8 from '../../../../assets/images/ProductsUsing/edit/edit8.png'

import delete1 from '../../../../assets/images/ProductsUsing/delete/delete1.png'
import delete2 from '../../../../assets/images/ProductsUsing/delete/delete2.png'
import delete3 from '../../../../assets/images/ProductsUsing/delete/delete3.png'
import delete4 from '../../../../assets/images/ProductsUsing/delete/delete4.png'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const ProductsUsing = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <>
      {renderBackTitle(history, t('Quản lý sản phẩm - Hướng dẫn sử dụng'))}
      <Card sx={{ p: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
            value={value}
            onChange={handleChange}
          >
            <Tab label="Import sản phẩm" {...a11yProps(0)} />
            <Tab label="Danh sách sản phẩm" {...a11yProps(1)} />
            <Tab label="Export sản phẩm" {...a11yProps(2)} />
            <Tab label="Thêm sản phẩm mới" {...a11yProps(3)} />
            <Tab label="Xem sản phẩm" {...a11yProps(4)} />
            <Tab label="Sửa sản phẩm" {...a11yProps(5)} />
            <Tab label="Xóa sản phẩm" {...a11yProps(6)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có dữ liệu được tạo ở màn Quản lý danh mục sản phẩm và màn Quản
              lý hãng sản xuất và model.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Import</b> để import danh sách sản phẩm."
              image={[import1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Import</b>, hộp thoại Import sản phẩm sẽ hiển thị như ảnh bên dưới:"
              image={[import2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Nhấn vào nút <b>CHỌN FILE</b> để chọn file import từ máy tính. Sau khi chọn xong file import, file vừa chọn sẽ hiển thị ở hộp thoại như ảnh bên dưới:"
              image={[import3]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                {
                  title:
                    'Chỉ có thể chọn file import thuộc một trong các định dạng sau: <b>xlsx, xls, xlsb</b>.',
                },
                {
                  title: 'Mẫu file import sản phẩm:',
                  orther: (
                    <a
                      target="_blank"
                      href="https://mayno-uploader-bucket-stg.s3.ap-southeast-1.amazonaws.com/document/1684143655/maynoVIEForm%20import%20san%20pham.xlsx"
                    >
                      mayno_VIE_Form import san pham.xlsx
                    </a>
                  ),
                },
                {
                  title: 'Một số lưu ý khi điền dữ liệu vào file import:',
                  subNote: [
                    { title: 'Các cột có dấu (*) là các cột bắt buộc phải điền dữ liệu.' },
                    {
                      title: 'Các cột không có dấu (*) là các cột có thể điền dữ liệu hoặc không.',
                    },
                    {
                      title: 'Yêu cầu dữ liệu của một số cột:',
                      subNote: [
                        {
                          title: '<b>Từ khóa tìm kiếm</b>: Các từ được ngăn cách nhau bởi dấu “,”.',
                        },
                        {
                          title:
                            '<b>Danh mục sản phẩm</b>: Điền chính xác danh mục sản phẩm cấp cuối cùng có trong hệ thống.',
                        },
                        {
                          title:
                            '<b>Hãng sản xuất gốc</b>: Điền chính xác hãng sản xuất đã có trong hệ thống.',
                        },
                        {
                          title:
                            '<b>List model</b>: Các model ngăn cách nhau bởi dấu ",", và cần điền chính xác model cấp cuối cùng của hãng sản xuất gốc.',
                        },
                        {
                          title:
                            '<b>Hãng sản xuất</b>: Điền chính xác hãng sản xuất đã có trong hệ thống.',
                        },
                        {
                          title:
                            '<b>Thông số kỹ thuật (danh mục)</b>: Ví dụ về giá trị có thể điền như sau: <b>A:30, B:40, C:50.23</b>. Trong đó: ',
                          subNote: [
                            {
                              title:
                                'A, B, C là kí hiệu của thông số kỹ thuật trong danh mục sản phẩm (cần điền đúng danh sách thông số kỹ thuật của danh mục sản phẩm).',
                            },
                            {
                              title: '30, 40, 50.23 là giá trị của thông số tương ứng (đơn vị mm).',
                            },
                          ],
                        },
                        {
                          title:
                            '<b>Thuế</b>: Chỉ được điền các giá trị nguyên và 0 < giá trị < 100.',
                        },
                        {
                          title:
                            '<b>Số lượng tồn kho</b>: Chỉ được điền các giá trị nguyên và 0 < giá trị < 10.000.',
                        },
                        {
                          title:
                            '<b>Đơn vị tính</b>: Chỉ được chọn 1 trong 2 giá trị: Cái hoặc Bộ.',
                        },
                        {
                          title:
                            '<b>% giảm giá (%)</b>: Nếu sản phẩm có giảm giá theo %, chỉ được điền % giảm giá ở đây (% giảm giá > 0), không được điền thêm giảm giá theo giá trị (VNĐ).',
                        },
                        {
                          title:
                            '<b>Giá trị giảm giá (VNĐ)</b>: Nếu sản phẩm có giảm giá theo giá trị, chỉ được điền giá trị giảm giá ở đây (giá trị giảm giá >=0), không được điền thêm giảm giá theo %.',
                        },
                        {
                          title:
                            '<b>Thời gian bắt đầu giảm giá</b>: Định dạng ngày tháng hợp lệ là dd.mm.yyyy hoặc dd/mm/yyyy hoặc dd-mm-yyyy.',
                        },
                        {
                          title:
                            '<b>Thời gian kết thúc giảm giá</b>: Định dạng ngày tháng hợp lệ là dd.mm.yyyy hoặc dd/mm/yyyy hoặc dd-mm-yyyy.',
                        },
                        {
                          title: '<b>Sản phẩm bán chạy</b>: Chỉ có thể điền 0 hoặc 1.',
                          subNote: [
                            { title: '0: Không hiển thị sản phẩm trong top sản phẩm bán chạy.' },
                            { title: '1: Hiển thị sản phẩm trong top bán chạy.' },
                          ],
                        },
                        {
                          title: '<b>Trạng thái sản phẩm</b>: Chỉ có thể điền 0 hoặc 1.',
                          subNote: [{ title: '0: Chưa kích hoạt.' }, { title: '1: Kích hoạt.' }],
                        },
                      ],
                    },
                  ],
                },
              ]}
            />
            <GuideUsingStep
              descreption="<b>Bước 3:</b> Nhấn vào nút <b>IMPORT</b> để import dữ liệu sản phẩm trong file đã chọn."
              image={[import4]}
            />
            <GuideUsingStep
              descreption="Sau khi import thành công:"
              note={[
                {
                  title:
                    'Những sản phẩm trong file import chưa có trong màn Quản lý sản phẩm trước đó sẽ được thêm thành công vào màn Quản lý sản phẩm với đúng các thông tin đã điền trong file.',
                },
                {
                  title:
                    'Những sản phẩm trong file import đã có trong màn Quản lý sản phẩm trước đó (trùng mã sản phẩm chính với sản phẩm đã tồn tại) sẽ chỉ cập nhật lại số lượng tồn theo file import, các thông tin khác không thay đổi theo dữ liệu trong file.',
                },
              ]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                {
                  title:
                    'Nếu dữ liệu trong file import không chính xác, sẽ hiển thị thông báo chứa thông tin những giá trị lỗi ở hàng đầu tiên có dữ liệu sai trong file, bạn vui lòng kiểm tra lại dữ liệu của các giá trị được nhắc đến trong thông báo lỗi:',
                  image: [import5],
                },
                {
                  title:
                    'Sau khi import thành công, cần nhấn vào nút Sửa ở mỗi sản phẩm vừa import để:',
                  subNote: [
                    {
                      title:
                        '<b>Tải ảnh của sản phẩm</b> (cần tải 1 ảnh đại diện của sản phẩm, và có thể tải thêm tối đa 10 hình ảnh khác của sản phẩm):',
                      image: [import6],
                    },
                    {
                      title:
                        'Cập nhật giá trị <b>Thông số kỹ thuật (danh mục)</b> nếu Danh mục sản phẩm có thông số kỹ thuật nhưng bạn chưa điền dữ liệu thông số kỹ thuật trong file import:',
                      image: [import7],
                    },
                  ],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <GuideUsingStep
              descreption="<b>Trạng thái sản phẩm</b>"
              orther={
                <Box margin={'30px'}>
                  <GuideUsingStep
                    descreption='Chỉ những sản phẩm có trạng thái <b>Kích hoạt</b> mới được hiển thị ở trên trang <a target="__blank" href="https://mayno.vn/.">https://mayno.vn/</a>.'
                    image={[list1]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Những sản phẩm được gắn với model cấp cuối sẽ được hiển thị ở danh sách sản phẩm thuộc model tương ứng của hãng sản xuất khi xem sản phẩm theo hãng sản xuất gốc của sản phẩm đó trên trang <a target="__blank" href="https://mayno.vn/.">https://mayno.vn/</a>.',
                  image: [listNote1, listNote2],
                },
                {
                  title:
                    'Những sản phẩm không được gắn với model cấp cuối sẽ được hiển thị ở danh sách sản phẩm thuộc “Sản phẩm khác” khi xem sản phẩm theo hãng sản xuất gốc của sản phẩm đó trên trang <a target="__blank" href="https://mayno.vn/.">https://mayno.vn/</a>.',
                  image: [listNote3, listNote4],
                },
              ]}
            />
            <GuideUsingStep
              descreption="<b>Áp dụng giảm giá sản phẩm theo thời gian giảm giá</b>"
              orther={
                <Box margin={'30px'}>
                  <GuideUsingStep
                    descreption="Nếu sản phẩm được giảm giá, và có giới hạn thời gian giảm giá như ảnh bên dưới thì sản phẩm sẽ được áp dụng giảm giá từ 0:0:0 của ngày bắt đầu đến 23:59:59 của ngày kết thúc."
                    image={[list2]}
                  />
                  <GuideUsingStep
                    descreption="Nếu sản phẩm được giảm giá, và có giới hạn thời gian giảm giá như ảnh bên dưới thì sản phẩm sẽ được áp dụng giảm giá từ 0:0:0 của ngày bắt đầu, và không có ngày kết thúc giảm giá."
                    image={[list3]}
                  />
                  <GuideUsingStep
                    descreption="Nếu sản phẩm được giảm giá, và không bị giới hạn thời gian giảm giá như ảnh bên dưới thì sản phẩm sẽ được áp dụng giảm giá từ 0:0:0 của ngày mà sản phẩm được thêm/import thành công, và không có ngày kết thúc giảm giá."
                    image={[list4]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Tìm kiếm, lọc sản phẩm</b>"
              orther={
                <Box margin={'30px'}>
                  <GuideUsingStep
                    descreption="Bạn có thể tìm kiếm sản phẩm theo mã sản phẩm chính hoặc tên sản phẩm, lọc sản phẩm theo Hãng sản xuất/ Danh mục sản phẩm/Loại giảm giá/Trạng thái sản phẩm/Sản phẩm bán chạy. Khi lọc theo Hãng sản xuất, sẽ lọc toàn bộ các sản phẩm có hãng sản xuất hoặc hãng sản xuất gốc là hãng sản xuất đã chọn:"
                    image={[list5]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Chỉnh sửa số lượng tồn của sản phẩm</b>"
              orther={
                <Box margin={'30px'}>
                  <GuideUsingStep
                    descreption="Nếu muốn cập nhật số lượng tồn của một sản phẩm, bạn có thể nhấn vào giá trị ở cột Số lượng tồn của sản phẩm đó, chỉnh sửa số lượng tồn sản phẩm, sau đó nhấn phím Enter ở bàn phím để lưu lại số lượng tồn vừa cập nhật:"
                    image={[list6]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Chỉnh sửa trạng thái sản phẩm bán chạy</b>"
              orther={
                <Box margin={'30px'}>
                  <GuideUsingStep
                    descreption="Bạn có thể nhấn vào ô trong cột Sản phẩm bán chạy để cài đặt sản phẩm là bán chạy hay không (chỉ được cài đặt tối đa 32 sản phẩm làm sản phẩm bán chạy):"
                    image={[list7]}
                  />
                  <GuideUsingStep
                    descreption="Những sản phẩm bán chạy có trạng thái Kích hoạt (ô được chọn) sẽ được hiển thị ở danh sách <b>SẢN PHẨM BÁN CHẠY</b> ở Trang chủ:"
                    image={[list8]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Cài đặt ẩn/hiện cột</b>"
              orther={
                <Box margin={'30px'}>
                  <GuideUsingStep
                    descreption="<b>Bước 1:</b> Nhấn vào nút <b>ẨN/HIỆN CỘT</b> để cài đặt hiển thị hoặc ẩn đi các cột trong bảng danh sách."
                    image={[list9]}
                  />
                  <GuideUsingStep
                    descreption="Sau khi nhấn <b>ẨN/HIỆN CỘT</b>, danh sách cột của màn Quản lý sản phẩm sẽ hiển thị như ảnh bên dưới:"
                    image={[list10]}
                  />
                  <GuideUsingStep
                    descreption="<b>Bước 2:</b> Chọn/bỏ chọn vào các ô có tên cột mà bạn muốn hiển thị/ẩn đi, sau đó nhấn <b>LƯU</b>. Sau khi nhấn <b>LƯU</b>, các cột tương ứng ở bảng quản lý sẽ hiển thị theo cài đặt của bạn."
                    noWrapper
                  />
                  <GuideUsingStep
                    descreption="<b>Lưu ý:</b>"
                    note={[
                      {
                        title:
                          'Nếu số lượng cột hiển thị vượt quá kích thước của màn hình, bạn cần di chuyển xuống cuối trang, kéo trượt thanh ngang để xem hết các cột không hiển thị đủ trên màn hình:',
                        orther: (
                          <div
                            className="d-f ai-c jc-c"
                            style={{ marginTop: '8px', flexDirection: 'column' }}
                          >
                            <video width={'60%'} controls>
                              <source src={list11} type="video/mp4" />
                            </video>
                          </div>
                        ),
                      },
                      {
                        title:
                          'Bạn có thể nhấn vào nút <b>Mặc định</b> ở trong danh sách cột ở cài đặt ẩn/hiện cột theo mặc định của hệ thống:',
                        image: [list12],
                      },
                      {
                        title:
                          'Sau khi nhấn <b>Mặc định</b>, các cột sau sẽ được hiển thị (các cột còn lại sẽ bị ẩn):',
                        subNote: [
                          { title: 'STT' },
                          { title: 'Mã sản phẩm chính' },
                          { title: 'Mã sản phẩm gốc' },
                          { title: 'Tên sản phẩm' },
                          { title: 'Hãng sản xuất' },
                          { title: 'Hãng sản xuất gốc' },
                          { title: 'Danh mục sản phẩm' },
                          { title: 'Đơn giá' },
                          { title: 'Giảm giá' },
                          { title: 'Đơn giá sau giảm' },
                          { title: 'Thời gian giảm giá' },
                          { title: 'Số lượng tồn' },
                          { title: 'Trạng thái sản phẩm' },
                          { title: 'Sản phẩm bán chạy' },
                          { title: 'Thao tác' },
                        ],
                      },
                      {
                        title: 'Bạn có thể nhấn <b>HỦY</b> để hủy cài đặt ẩn/hiện cột:',
                        image: [list13],
                      },
                    ]}
                  />
                </Box>
              }
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box>
            <GuideUsingStep
              descreption="Bạn có thể nhấn vào nút <b>Export</b> để xuất danh sách các sản phẩm đang hiển thị ở màn Quản lý sản phẩm:"
              orther={
                <div
                  className="d-f ai-c jc-c"
                  style={{ marginTop: '8px', flexDirection: 'column' }}
                >
                  <video width={'60%'} controls>
                    <source src={exportVideo} type="video/mp4" />
                  </video>
                </div>
              }
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Danh sách sản phẩm trong file export sẽ giống các sản phẩm đang hiển thị ở màn Quản lý sản phẩm.',
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Thêm sản phẩm</b> để thêm một sản phẩm mới."
              image={[add1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Thêm sản phẩm</b>, màn hình Thêm sản phẩm sẽ hiển thị như ảnh bên dưới:"
              image={[add2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Điền thông tin hợp lệ vào các trường bắt buộc và các trường mong muốn khác trong màn hình Thêm sản phẩm, tải ảnh của sản phẩm, sau đó nhấn nút <b>THÊM SẢN PHẨM</b>."
              image={[add3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>THÊM SẢN PHẨM</b>, sản phẩm sẽ được thêm thành công vào danh sách:"
              image={[add4]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Sau khi nhập từ khóa tìm kiếm, bạn có thể nhấn ra ngoài trường Từ khóa tìm kiếm hoặc nhấn phím Enter từ bàn phím để có thể tạo từ khóa tìm kiếm thành công. Người dùng có thể tìm kiếm sản phẩm theo tên sản phẩm chính, mã sản phẩm gốc, mã sản phẩm chính và từ khóa tìm kiếm.',
                  orther: (
                    <div
                      className="d-f ai-c jc-c"
                      style={{ marginTop: '8px', flexDirection: 'column' }}
                    >
                      <video width={'60%'} controls>
                        <source src={add5} type="video/mp4" />
                      </video>
                    </div>
                  ),
                },
                {
                  title: 'Bạn có thể nhấn <b>HỦY</b> để hủy thao tác thêm sản phẩm mới:',
                  image: [add6],
                },
                {
                  title:
                    'Bạn có thể nhấn nút quay lại để hủy thao tác thêm sản phẩm mới và quay về màn Quản lý sản phẩm:',
                  image: [add7],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có ít nhất một sản phẩm ở màn Quản lý sản phẩm.
            </p>
            <GuideUsingStep
              descreption="Nhấn vào nút <b>Xem</b> ở sản phẩm mà bạn cần xem thông tin."
              image={[view1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Xem</b>, màn hình Xem sản phẩm sẽ hiển thị như ảnh bên dưới:"
              image={[view2]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title: 'Bạn có thể nhấn nút quay lại để quay về màn Quản lý sản phẩm:',
                  image: [view3],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có ít nhất một sản phẩm ở màn Quản lý sản phẩm.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Sửa</b> ở sản phẩm mà bạn cần sửa thông tin."
              image={[edit4]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Sửa</b>, màn hình Sửa sản phẩm sẽ hiển thị như ảnh bên dưới:"
              image={[edit5]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Cập nhật thông tin vào các trường mà bạn muốn sửa trong màn hình Sửa sản phẩm, sau đó nhấn nút <b>LƯU</b>."
              image={[edit6]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>LƯU</b>, thông tin sản phẩm sẽ được cập nhật theo thông tin vừa sửa."
              noWrapper
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title: 'Bạn có thể nhấn <b>HỦY</b> để hủy thao tác sửa thông tin sản phẩm:',
                  image: [edit7],
                },
                {
                  title:
                    'Bạn có thể nhấn nút quay lại để hủy thao tác sửa thông tin sản phẩm và quay về màn Quản lý sản phẩm:',
                  image: [edit8],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có ít nhất một sản phẩm ở màn Quản lý sản phẩm.
            </p>

            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Xóa</b> ở sản phẩm mà bạn cần xóa thông tin."
              image={[delete1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Xóa</b>, hộp thoại xác nhận Xóa sản phẩm sẽ hiển thị như ảnh bên dưới:"
              image={[delete2]}
            />
            <GuideUsingStep descreption="<b>Bước 2:</b> Nhấn nút <b>XÓA</b>." image={[delete3]} />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>XÓA</b>, sản phẩm sẽ bị xóa, không còn hiển thị trong màn Quản lý sản phẩm và trang người dùng (nếu sản phẩm ở trạng thái Kích hoạt)."
              noWrapper
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title: 'Bạn có thể nhấn <b>HỦY</b> để hủy thao tác xóa sản phẩm:',
                  image: [delete4],
                },
              ]}
            />
          </Box>
        </TabPanel>
      </Card>
    </>
  )
}

export default ProductsUsing
