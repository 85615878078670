/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react'
import { Button, Input } from 'components'
import { useTranslation } from 'react-i18next'
import {
  formatCode,
  formatPrice,
  getPaymentLS,
  getShoppingCartData,
  storeShoppingCart,
  updatePaymentLS,
} from 'utils'
import { DELIVERY_METHODS, mappingDeliveryMethod, mappingPaymentMethod } from 'configs/constants'
import { OrderDeliveryAddressType, OrderVatInvoice, ProductType } from 'types'
import { useShoppingCart } from 'store/shoppingCart'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import { useModalConfirm } from 'store/modalConfirm'
import { useHistory } from 'react-router'
import OrderInfo, { OrderProducts } from './OrderInfo'
import { useIsMobile } from 'store/mobile'
import { useAuth } from 'store/auth'

const ContainerItem = ({ title, children, onClick }: any) => (
  <div className="FormSummaryContainer rounded">
    <div className="FormSummaryContainer-header">
      <div className="form-label">{title}</div>
      <i className="mn-icon-edit" onClick={onClick} />
    </div>
    <div className="FormSummaryContainer-inside label dark-gray big-height">{children}</div>
  </div>
)

interface Props {
  setStep: any
  handleDone: () => void
  createAddress: any
  createVAT: any
  code: any
  setCode: any
  codeError: any
  handleGetStores?: any
  // setCodeError: any
  childrenSummary?: JSX.Element
}

const backToCartIds = ['317', '318']

const FormSummary: React.FC<Props> = ({
  setStep,
  handleDone,
  createAddress,
  createVAT,
  code,
  codeError,
  handleGetStores,
  childrenSummary,
}) => {
  const { t } = useTranslation()
  const {
    delivery_method = '',
    order_delivery_address = {} as OrderDeliveryAddressType,
    order_vat_invoice,
    payment_method,
    order_store_address,
    note,
  } = getPaymentLS()
  const { totalMoney, discount, totalMoneyAfterDiscount, updateCart, changeCart } =
    useShoppingCart()
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()
  const isMobile = useIsMobile()
  const history = useHistory()

  let name, phone, address

  const isGetAtStore = delivery_method === DELIVERY_METHODS.GET_AT_STORE

  if (isGetAtStore) {
    address = order_store_address?.address
  } else {
    name = order_delivery_address.full_name
    phone = order_delivery_address.phone
    const { address: newAddress, town, district, city } = order_delivery_address
    address = `${newAddress}, ${town}, ${district}, ${city}`
  }

  // const { full_name = '', phone, address, town, district, city } = order_delivery_address
  const { company_name, tax_identification_number, company_address, email } =
    order_vat_invoice || ({} as OrderVatInvoice)
  const { apiPut, apiPost } = useApis()
  const [loading, setLoading] = useState(false)
  const { isAuth } = useAuth()

  const codeRef = useRef(code)

  const done = (isSubmit?: boolean) => {
    const params = updatePaymentLS({
      create_status: isSubmit ? 3 : 2,
    })
    const { id } = params
    delete params.id
    delete params.order_code
    if (!params.discount_code) delete params.discount_code
    isSubmit && setLoading(true)

    apiPut(
      apiUrls.orders(id),
      params,
      ({ id: responseId, status, data, text }) => {
        setLoading(false)

        if (status) {
          apiPut(
            apiUrls.adminSendEmailOrder(id),
            {
              link: `${window.location.origin}/admin/orders/${id}`,
            },
            () => {}
          )
          const { discount_value, total_price } = data || {}
          updateCart({ discountPercent: (discount_value * 100) / total_price })
          if (isSubmit) {
            createAddress.current &&
              apiPost(
                apiUrls.addresses(),
                createAddress.current,
                () => {},
                () => {},
                true
              )

            createVAT.current &&
              apiPost(
                apiUrls.vat(),
                createVAT.current,
                () => {},
                () => {},
                true
              )

            handleDone()
          }
        } else {
          isSubmit &&
            showModalConfirm({
              title: 'Thông báo',
              content: text,
              confirm: {
                text: 'Đã hiểu',
                action: async () => {
                  hideModalConfirm()
                  if (backToCartIds.includes(responseId)) {
                    history.push('/shopping-cart')
                  } else if (responseId === '465') {
                    handleGetStores?.()
                    setStep(1)
                  } else if (responseId === '467') {
                    if (!isAuth) {
                      updateCartChangePrice(data, () => {
                        history.push('/shopping-cart')
                      })
                    } else {
                      history.push('/shopping-cart')
                    }
                  }
                },
              },
              onBackdropClick: () => {
                hideModalConfirm()
              },
            })
          updateCart({ discountPercent: 0 })
        }
      },
      () => setLoading(false)
    )
  }

  const updateCartChangePrice = (productsChanged: ProductType[], callBack?: () => void) => {
    const { allCartItems } = getShoppingCartData()
    const newProducts = allCartItems?.map((item: any) => {
      const productChanged = productsChanged.find((i: any) => i.id === item.product_id)
      return productChanged ? { ...item, product: productChanged } : item
    })
    changeCart(newProducts)

    callBack?.()
  }

  useEffect(() => {
    codeRef.current = code
  }, [code])

  return (
    <div>
      <ContainerItem title={t('delivery_address')} onClick={() => setStep(1)}>
        {t(mappingDeliveryMethod[delivery_method])} <br />
        {!isGetAtStore && (
          <>
            {name} <br />
            {phone} <br />
          </>
        )}
        {address}
      </ContainerItem>
      <ContainerItem title={t('paymentMethod')} onClick={() => setStep(2)}>
        {mappingPaymentMethod[payment_method || 1]}
      </ContainerItem>
      {!!order_vat_invoice?.company_name && (
        <ContainerItem title={t('vatInfo')} onClick={() => setStep(2)}>
          <b>{company_name || 'Tên công ty'}</b>
          <br />
          <b>Mã số thuế: </b>
          {tax_identification_number || '001201255486'}
          <br />
          <b>Địa chỉ: </b>
          {company_address || '185 Giảng Võ, Cát Linh, Đống Đa, Hà Nội'}
          <br />
          {email && (
            <>
              <b>Email: </b>
              {email}
              <br />
            </>
          )}
        </ContainerItem>
      )}
      {isMobile && childrenSummary}
      {!!note && (
        <div className="FormSummaryContainer mx">
          <div>
            <div className="label gray" style={{ marginBottom: 4 }}>
              Ghi chú
            </div>
            <div className="label big-height secondary" style={{ marginBottom: 8 }}>
              {note}
            </div>
          </div>
        </div>
      )}
      <div className="FormSummaryContainer margin-small padding-big">
        <span className="label">
          Chúng tôi sẽ liên hệ và trao đổi về phí vận chuyển trong vòng 2 giờ sau khi nhận được đơn
          đặt hàng
        </span>
      </div>
      <div className="PaymentLayout-next">
        <Button
          fullWidth
          icon="arrow"
          small
          onClick={() => done(true)}
          loading={loading}
          disabled={!!codeError}
        >
          Hoàn tất
        </Button>
      </div>
    </div>
  )
}

export default FormSummary
