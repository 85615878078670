import { Box } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'

interface Props {
  show: any
  handleResend: () => void
}

export default function CountDownTimer(props: Props) {
  const { show, handleResend } = props
  const [time, setTime] = useState(0)
  const timerRef = useRef<any>()

  useEffect(() => {
    if (!show) {
      // When timer expired, set time to 0 and clear timer
      setTime(0)
      clearInterval(timerRef.current)
      return
    }
    // Reset timer to 5 minutes
    setTime(300)
    // Create a timer countdown -1 second
    timerRef.current = setInterval(() => {
      setTime((time) => {
        if (time > 0) {
          const newTime = time - 1
          if (newTime === 0) {
            clearInterval(timerRef.current)
          }
          return newTime
        }
        return time
      })
    }, 1000)

    return () => clearInterval(timerRef.current)
  }, [show])

  const timeLabel = Math.floor(time / 60) + ':' + ('0' + (time % 60)).substr(-2)

  const disableResend = time !== 0

  return (
    <Box display="flex" justifyContent="space-between">
      <span className="label gray">{timeLabel} còn lại</span>
      <span
        className={`label bold link${disableResend ? ' disabled' : ''}`}
        onClick={() => {
          if (!disableResend) handleResend()
        }}
      >
        Gửi lại mã
      </span>
    </Box>
  )
}
