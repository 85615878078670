import React from 'react'
import { useHistory } from 'react-router'
import { useTranslation, Trans } from 'react-i18next'

import img404 from 'assets/images/img404.png'

export default function PageNotFound() {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <div className="PageNotFound-area">
      <img className="PageNotFound-img" src={img404} alt="" />
      <h4 className="PageNotFound-title">{t('wrongPath')}</h4>
      <p className="PageNotFound-msg">
        <Trans i18nKey="lbGoToHome">
          <span />
          <strong className="PageNotFound-homePath" onClick={() => history.push('/')} />
        </Trans>
      </p>
    </div>
  )
}
