import { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { MenuCategoryItem, ObjectType, RootState } from 'types'
import { addCategories } from './action'

let needGetMenu = true

const useCategories = () => {
  const dispatch = useDispatch()
  const categories: {
    menu: MenuCategoryItem[]
    flattenMenu: MenuCategoryItem[]
  } = useSelector((state: RootState) => state.categories, shallowEqual)

  return useMemo(() => {
    const mappingCategories: ObjectType = {}
    const mappingIdCategories: ObjectType = {}
    categories.flattenMenu.forEach((item) => {
      mappingCategories[item.linkSeo] = item
      mappingIdCategories[item.id] = item
    })

    // const getCategory = (linkSeo: string) =>
    //   categories.flattenMenu.find((item) => item.linkSeo === linkSeo)

    const getCategory = (linkSeo: string) => {
      return mappingCategories[linkSeo]
    }

    const getCategoryById = (id: string) => {
      return mappingIdCategories[id]
    }

    const convertToPath = (item: any, image?: boolean) => ({
      name: item.name,
      path: item.linkFull,
      image: image ? item.image_url : undefined,
    })

    const listCategories = categories.menu.slice(0, -1).map((item) => convertToPath(item))
    const listManufacturers =
      categories.menu[categories.menu.length - 1]?.sub?.map((item) => convertToPath(item, true)) ||
      []

    return {
      ...categories,
      listCategories,
      listManufacturers,
      hasCategories: categories.menu.length > 0,
      getCategories: async (callback?: any) => {
        if (!needGetMenu) return
        needGetMenu = false
        setTimeout(() => {
          needGetMenu = true
        }, 700)
        dispatch(addCategories(callback))
      },
      getCategory,
      getCategoryById,
      // Create breadcrumbs from link seo last child category
      getBreadcrumbs: (linkSeo: string) => {
        const arr = []
        let current = getCategory(linkSeo) || getCategoryById(linkSeo)
        while (current) {
          // Add parent to breadcrumbs
          arr.push({ name: current.name, path: current.linkFull })
          current = current.parent
        }
        // Reverse to move parent to the top
        return arr.reverse()
      },
    }
  }, [dispatch, categories])
}

export default useCategories
