import { Breakpoint, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import './index.scss'
import { ModalContext } from './constants'
import Button from '../admin/button/Button'
import MaxLine from 'components/maxLine/MaxLine'

interface Props {
  className?: string
  show: boolean
  title: string
  close?: any
  size?: Breakpoint
  onBackdropClick?: any
  dirty?: boolean
  titleMaxline?: boolean
}

const Modal: React.FC<Props> = ({
  show,
  title,
  close,
  children,
  size = 'xs',
  onBackdropClick,
  dirty,
  className = '',
  titleMaxline = false,
}) => {
  const [showAttention, setShowAttention] = useState(false)
  if (window.location.pathname.includes('/admin')) className = 'Modal-admin'

  const closeAttention = () => setShowAttention(false)

  return (
    <ModalContext.Provider value={{ dirty: dirty || false, setShowAttention }}>
      <Dialog
        className={`Modal ${className}`}
        open={show}
        fullWidth
        maxWidth={size}
        onBackdropClick={onBackdropClick}
      >
        {dirty && (
          <Dialog
            className={`Modal ${className}`}
            open={showAttention}
            fullWidth
            maxWidth="xs"
            onBackdropClick={closeAttention}
          >
            <DialogTitle>Thông báo</DialogTitle>
            <DialogContent style={{ fontWeight: 500, textAlign: 'center' }}>
              Nếu nhấn <b>Hủy</b>, mọi thông tin bạn đã nhập sẽ không được lưu lại
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowAttention(false)
                  close?.()
                }}
              >
                Hủy
              </Button>
              <Button variant="contained" onClick={closeAttention}>
                Tiếp tục chỉnh sửa
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <DialogTitle style={{ margin: '0 15px' }}>
          {titleMaxline ? <MaxLine text={title} numberOfLines={2} /> : title}
          {!!close && (
            <i
              className="mn-icon-close"
              onClick={() => {
                if (dirty) setShowAttention(true)
                else close?.()
              }}
            />
          )}
        </DialogTitle>
        <div className="Modal-content">{children}</div>
      </Dialog>
    </ModalContext.Provider>
  )
}

export default Modal
