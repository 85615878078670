/*eslint-disable*/
import { DataTable } from 'components/admin'
import { apiUrls } from 'configs/apis'
import { DELIVERY_METHODS, MAX_PAGE_SIZE } from 'configs/constants'
import { renderBackTitle } from 'configs/extensions'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { useReactToPrint } from 'react-to-print'
import { useApis } from 'services/api'
import { useHistory } from 'store/confirmRedirect'
import { TableFieldsType } from 'types'
import TemplateQuotation from '../orders/TemplateQuotation'

const { GET_AT_STORE } = DELIVERY_METHODS

const OrderHistoryQuotes: React.FC = () => {
  const { id } = (useParams() || {}) as any
  const history = useHistory()
  const orderCode = useLocation().state
  const { apiGet } = useApis()
  const [data, setData] = useState({ items: [], loading: true, total: 0 })
  const [listDataExport, setListDataExport] = useState([])
  const [dataExport, setDataExport] = useState({})
  const componentToPrint = useRef<any>()
  const [hidePrice, setHidePrice] = useState<boolean>(false)

  const getHistoryQuotes = () => {
    apiGet(apiUrls.historyQuotes(id), {}, ({ data, status }) => {
      if (status) {
        const newData: any = []
        const listDataExport: any = []
        data.forEach((item: any, index: number) => {
          const { order, products, created_user } = item
          newData.push({
            id: item.id,
            number: index + 1,
            number_of_quotes: item.number_of_quotes,
            created_date: item.created_date,
            full_name: created_user?.full_name,
            hidePrice: item.hide_price,
          })
          const delivery_address = `${order?.order_delivery_address.address}, ${order?.order_delivery_address.town}, ${order?.order_delivery_address.district}, ${order?.order_delivery_address.city}`
          const address =
            order?.delivery_method === GET_AT_STORE
              ? order?.order_store_address.address
              : delivery_address

          const dataExport = {
            id: item.id,
            created_date: new Date(item.created_date * 1000),
            customerName: order?.order_delivery_address?.full_name,
            customerPhone: order?.order_delivery_address?.phone,
            customerAddress: address,
            customer_code: item.customer_code,
            number_of_quotes: item.number_of_quotes,
            full_name: item.full_name,
            adminPhone: item.phone,
            adminName: created_user?.full_name,
            email: created_user?.email,
            model: item.model,
            initPrice: order?.total_price,
            finalPrice: order?.final_total_price,
            products_json: products,
          }
          listDataExport.push(dataExport)
        })
        setData({
          items: newData,
          loading: false,
          total: data?.length,
        })
        setListDataExport(listDataExport)
      }
    })
  }

  const handleExport = useReactToPrint({
    content: () => componentToPrint.current,
    pageStyle: `@page {
        size: auto; 
        margin: 0mm; 
      }
  
      html {
        background-color: #ffffff;
        margin: 0px; 
      }
  
      body {
        margin: 10mm 15mm 10mm 15mm;
      }`,
  })

  useEffect(() => {
    if (JSON.stringify(dataExport) !== '{}') {
      handleExport()
    }
  }, [JSON.stringify(dataExport)])

  const fields: TableFieldsType = {
    number: { style: { width: '10%', textAlign: 'center' } },
    number_of_quotes: { label: 'Số báo giá', style: { width: '20%', paddingLeft: '40px' } },
    created_date: {
      label: 'Ngày in báo giá',
      style: { width: '20%', paddingLeft: '40px' },
      type: 'date',
    },
    full_name: { label: 'Người in báo giá', style: { width: '20%' } },
    action: {
      style: { width: '12%' },
      type: 'actions',
      actions: [
        {
          icon: 'eye',
          action: ({ id, hidePrice }) => {
            setHidePrice(hidePrice)
            setDataExport(listDataExport?.find((item: any) => item.id === id) || {})
          },
          title: 'Xem file báo giá',
        },
      ],
    },
  }

  useEffect(() => {
    getHistoryQuotes()
  }, [])

  const { items, loading, total } = data

  return (
    <div>
      {renderBackTitle(history, `Lịch sử in báo giá - Đơn hàng ${orderCode || ''}`)}
      <DataTable
        items={items}
        fields={fields}
        loading={loading}
        pagination={{
          total,
          pageSize: MAX_PAGE_SIZE,
          page: 1,
          itemsLabel: 'bản ghi',
        }}
      />
      <TemplateQuotation
        data={dataExport}
        hidePrice={hidePrice}
        componentToPrintRef={componentToPrint}
      />
    </div>
  )
}

export default OrderHistoryQuotes
