import { CartItemType } from './../../types/common'
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_FETCH_ITEMS,
  CART_CLEAR_ITEMS,
  CART_UPDATE_ITEM_ID,
  CART_UPDATE_BUY_LATER,
  CART_UPDATE_ITEM_QUANTITY,
  CART_BUY_NOW,
  CART_UPDATE,
  CART_ORDER_SUCCESS,
  CART_UPDATE_ITEMS_QUANTITY,
  CART_ADD_ALL_ITEMS,
  CART_LOADING,
  CHANGE_CART_DATA,
} from 'store/actionTypes'
import { DispatchType, CartType, ProductType, ObjectType } from 'types'
import { clearShoppingCartData } from 'utils'

export const loadingCart = () => (dispatch: DispatchType) => {
  dispatch({ type: CART_LOADING })
}

/**
 * Fetch items into shopping cart from server when user is signed in
 *
 * @param data Product items, includes current and buy later
 */
export const fetchCart = (data: CartType) => (dispatch: DispatchType) => {
  dispatch({ type: CART_FETCH_ITEMS, payload: data })
}

/**
 * Clear cart when sign out
 * @returns
 */
export const clearCart = () => (dispatch: DispatchType) => {
  clearShoppingCartData()
  dispatch({ type: CART_CLEAR_ITEMS })
}

/**
 * Add item into shopping cart
 *
 * @param product Product item
 * @param isRefresh Allow reset quantity or update quantity with new quantity
 */
export const addItemIntoCart =
  (product: ProductType, isRefresh?: boolean, force?: boolean) => (dispatch: DispatchType) => {
    dispatch({ type: CART_ADD_ITEM, payload: { isRefresh, product, force } })
  }

/**
 * Remove item from shopping cart
 *
 * @param productId Product id
 */
export const removeItemFromCart = (productId: string) => (dispatch: DispatchType) => {
  dispatch({ type: CART_REMOVE_ITEM, payload: productId })
}

/**
 * Update cart item id after call api
 * @param productId
 * @param cartItemId
 * @returns
 */
export const updateCartItemId =
  (productId: string, cartItemId: string) => (dispatch: DispatchType) => {
    dispatch({ type: CART_UPDATE_ITEM_ID, payload: { productId, cartItemId } })
  }

/**
 * Update type cart item to buy now or buy later
 * @param productId
 * @param isByLater
 * @returns
 */
export const updateCartBuyLater =
  (productId: string, isBuyLater?: boolean) => (dispatch: DispatchType) => {
    dispatch({ type: CART_UPDATE_BUY_LATER, payload: { productId, isBuyLater } })
  }

/**
 * Update cart item quantity when over stock
 * @param cartItemId
 * @param quantity
 * @returns
 */
export const updateCartItemQuantity =
  (cartItemId: string, quantity: number, updateInventory?: boolean) => (dispatch: DispatchType) => {
    dispatch({
      type: CART_UPDATE_ITEM_QUANTITY,
      payload: { cartItemId, quantity, updateInventory },
    })
  }

/**
 * Buy now
 * @param product
 * @returns
 */
export const buyNow =
  (cartItemId: string, product: ProductType, quantity: number) => (dispatch: DispatchType) => {
    dispatch({ type: CART_BUY_NOW, payload: { cartItemId, product, quantity } })
  }

/**
 * Buy now
 * @param product
 * @returns
 */
export const updateCart = (payload: ObjectType) => (dispatch: DispatchType) => {
  dispatch({ type: CART_UPDATE, payload })
}

export const orderSuccess = () => (dispatch: DispatchType) => {
  dispatch({ type: CART_ORDER_SUCCESS })
}

/**
 * Buy now
 * @param product
 * @returns
 */
export const updateCartItemsQuantity =
  (payload: { product_id: string; quantity: number }[]) => (dispatch: DispatchType) => {
    dispatch({ type: CART_UPDATE_ITEMS_QUANTITY, payload })
  }

export const addAllToCart = () => (dispatch: DispatchType) => {
  dispatch({ type: CART_ADD_ALL_ITEMS })
}
export const changeCart = (payload: CartItemType[]) => (dispatch: DispatchType) => {
  dispatch({ type: CHANGE_CART_DATA, payload })
}
