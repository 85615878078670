import { BaseTextFieldProps, IconButton, InputAdornment, TextField } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { removeAccentsKeepSpecialEmail } from 'utils'
import './index.scss'

interface Props extends BaseTextFieldProps {
  label?: string
  error?: any
  variant?: 'outlined' | 'standard' | 'filled'
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  errorFocused?: boolean
  blurWhenEnter?: boolean
  size?: 'small' | 'medium'
  resetShowPassword?: any
  isRunOnBlurWhenEnter?: boolean
  [key: string]: any
}

const Input: React.FC<Props> = (props) => {
  const inputRef = useRef<any>(null)
  const [isFocusing, setIsFocusing] = useState(false)

  const {
    fullWidth = true,
    type = 'text',
    name = '',
    label = '',
    error,
    value,
    onFocus,
    onBlur,
    variant = 'outlined',
    errorFocused,
    onKeyDown,
    blurWhenEnter,
    maxLength,
    InputProps,
    size = 'small',
    resetShowPassword,
    onChange,
    isRunOnBlurWhenEnter,
    ...rest
  } = props as any
  const enterWithoutBlur = useRef(false)
  const isPassword = type === 'password'

  const [passwordShown, setPasswordShown] = useState(!isPassword)

  /**
   * When input is focus, save status focus to this field name
   */
  const handleFocus = useCallback((e: any) => {
    setIsFocusing(true)
    onFocus?.(e)
  }, [])

  /**
   * Reset status focus when blur field
   */
  const handleBlur = useCallback((e: any) => {
    setIsFocusing(false)
    onBlur?.(e)
  }, [])

  useEffect(() => {
    setPasswordShown(!isPassword)
  }, [resetShowPassword])

  const invalid = !!value && (!isFocusing || errorFocused) && !!error

  const adornmentPassword = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={() => (isPassword ? setPasswordShown(!passwordShown) : {})} edge="end">
          <i className={`icon-password fas fa-eye${passwordShown ? '-slash' : ''}`} />
        </IconButton>
      </InputAdornment>
    ),
  }

  const handleChangePassword = (e: any) => {
    const value = e.target.value
    e.target.value = removeAccentsKeepSpecialEmail(value)
    onChange?.(e)
  }

  const helperText = invalid && error !== true ? error : undefined

  return (
    <TextField
      ref={inputRef}
      {...rest}
      inputProps={{ maxLength: isPassword ? 16 : maxLength }}
      fullWidth={fullWidth}
      label={label || undefined}
      name={name}
      onFocus={handleFocus}
      onBlur={(e) => {
        if (blurWhenEnter && enterWithoutBlur.current) {
          enterWithoutBlur.current = false
          return
        }
        handleBlur(e)
      }}
      onKeyDown={(e) => {
        if (blurWhenEnter && e.key === 'Enter') {
          if (isRunOnBlurWhenEnter) {
            enterWithoutBlur.current = true
          }
          setIsFocusing(false)
          inputRef.current?.children[0]?.children[0].blur()
        }

        onKeyDown?.(e)
      }}
      onChange={isPassword ? handleChangePassword : onChange}
      type={isPassword ? (passwordShown ? 'text' : 'password') : type}
      value={value}
      InputProps={isPassword ? adornmentPassword : InputProps}
      error={invalid}
      helperText={helperText}
      variant={variant}
      size={size}
    />
  )
}

export default Input
