import * as Yup from 'yup'
import { Button, Input } from 'components'
import { validatePassword } from 'utils'
import { Form, FormikProvider, useFormik } from 'formik'
import { Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import { AUTH_TYPES } from 'configs/constants'
import { useAuth } from 'store/auth'

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required()
    .min(8, 'password_format_is_incorrect')
    .matches(validatePassword(), 'password_format_is_incorrect'),
  confirm_password: Yup.string()
    .required()
    .oneOf([Yup.ref('password'), null], 'confirm_password_not_match'),
})

interface Props {
  next: () => void
}

export default function UpdatePassword({ next }: Props) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { requestApi } = useApis()
  const { user_id, modal, updateAuth } = useAuth()
  const authType = modal.type
  const isSignUp = authType === AUTH_TYPES.SIGN_UP

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
    },
    validationSchema,
    onSubmit: ({ password }) => {
      setLoading(true)
      requestApi(
        isSignUp ? 'put' : 'post',
        isSignUp ? apiUrls.initPassword() : apiUrls.resetPassword(),
        { user_id, password },
        {
          successCallback: ({ status, data }) => {
            if (status) {
              updateAuth({ saveInfo: data })
              next()
            } else setLoading(false)
          },
          errorCallback: () => setLoading(false),
        }
      )
    },
  })

  const { errors, handleSubmit, getFieldProps, isValid, validateForm } = formik

  const passwordRef = useRef<HTMLInputElement>()

  // Validate to disable button on startup
  useEffect(() => {
    validateForm()
    passwordRef.current?.focus()
  }, [])

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <span className="label">
            {isSignUp
              ? 'Vui lòng thiết lập mật khẩu để hoàn tất việc đăng ký.'
              : 'Vui lòng nhập mật khẩu mới'}
          </span>
          <Input
            fullWidth
            autoFocus
            innerRef={passwordRef}
            autoComplete="new-password"
            type="password"
            {...getFieldProps('password')}
            error={!!errors.password && t(errors.password?.toString())}
            label={t('new_password')}
            disabled={loading}
            required
          />
          <Input
            fullWidth
            autoComplete="new-password"
            type="password"
            {...getFieldProps('confirm_password')}
            error={!!errors.confirm_password && t(errors.confirm_password?.toString())}
            label={t('confirm_password')}
            disabled={loading}
            required
          />
          <Box>
            <Button
              type="submit"
              loading={loading}
              disabled={loading || !isValid}
              fullWidth
              icon="arrow"
            >
              {t('confirm')}
            </Button>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  )
}
