import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNil } from 'lodash'

import { Container } from '@mui/material'
import { useBreadcrumbs } from 'store/breadcrumbs'
import { MenuItem } from 'types'

import './index.scss'
import { NavLink } from 'components'
import { useIsMobile } from 'store/mobile'

interface PropTypes {
  className?: string
}

export default function Breadcrumbs(props: PropTypes) {
  const { t } = useTranslation()
  const { breadcrumbs, rightMenu } = useBreadcrumbs()
  const isMobile = useIsMobile()

  const { className = '' } = props

  /**
   *
   * render status order by data form backend
   * @param status
   * @returns
   */
  const renderStatusOrder = (status: number) => {
    let content = ''
    if (status === 1 || status === 2) {
      content = 'Chờ thanh toán'
    } else if (status === 3 || status === 4) {
      content = 'Chờ lấy hàng'
    } else if (status === 5) {
      content = 'Đang giao'
    } else if (status === 6) {
      content = 'Đã giao'
    } else if (status === 7) {
      content = 'Đã hủy'
    }
    return content
  }

  if (isNil(breadcrumbs)) return null

  return (
    <div className={`Breadcrumbs-area ${className}`}>
      <Container className="Breadcrumbs-container">
        <ul className="Breadcrumbs-list">
          <li>
            <NavLink className={isEmpty(breadcrumbs) ? 'Breadcrumbs-defaultName' : ''} to="/">
              {t('hostname')}
            </NavLink>
            {!isEmpty(breadcrumbs) && (isMobile ? breadcrumbs?.length > 1 : true) && (
              <span className="mn-icon-arrow-right" />
            )}
          </li>
          {breadcrumbs.map((item: MenuItem, index: number) => (
            <li key={index}>
              <NavLink to={item.path}>{t(item.name)}</NavLink>
              {index < breadcrumbs.length - (isMobile ? 2 : 1) && (
                <span className="mn-icon-arrow-right" />
              )}
              {item.status && (
                <div className="Breadcrumbs-status">{renderStatusOrder(item.status)}</div>
              )}
            </li>
          ))}
        </ul>
      </Container>
      {isMobile && rightMenu}
    </div>
  )
}
