import { Box, CircularProgress } from '@mui/material'

export const loading = (
  <div className="modal-loading">
    <CircularProgress size={60} color="inherit" />
  </div>
)

export const showProductsCount = (count: number, total: number) => (
  <>
    Hiển thị{' '}
    <b>
      {count}
      {total > 24 && <>/{total}</>}
    </b>{' '}
    sản phẩm
  </>
)

export const renderBackTitle = (history: any, title: string) => (
  <Box mb={2.5} mt={2} className="d-f ai-c">
    {!!history && (
      <i className="mn-icon-arrow-left ic-b mr-2 admin-link" onClick={() => history.goBack()} />
    )}
    <h2>{title}</h2>
  </Box>
)
