import React from 'react'
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom'
import { AppLayout } from 'components/common'

interface PropTypes extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
  [key: string]: any
}

const isAuth = true

export default function AppRoute({ component: Component, ...rest }: PropTypes) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? (
          <AppLayout {...rest}>
            <Component {...props} />
          </AppLayout>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  )
}
