import { BASIC_PAGE_SIZE } from 'configs/constants'

// Formatter price
const formatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
})

/**
 * Convert price to VND
 *
 * @param price Price value
 */
export const formatPrice = (price: number, hideUnit?: boolean) =>
  formatter
    .format(price)
    .replace(/\./g, window.location.pathname.includes('/admin') ? '.' : ',')
    .replace(/₫/g, hideUnit ? '' : 'đ')
    .replace(/\s/g, '')

/**
 * Convert price to VND
 *
 * @param price Price value
 */
export const formatPriceAdmin = (price: number, hideZero?: boolean) => {
  if (!price && hideZero) return ''
  return formatter.format(price).replace(/,/g, '.').replace(/₫/g, '').replace(/\s/g, '')
}
export const formatPriceQuotes = (price: number, hideZero?: boolean) => {
  if (!price && hideZero) return ''
  return formatter.format(price).replaceAll('.', ',').replace(/₫/g, '').replace(/\s/g, '')
}

/**
 * Format quantity
 *
 * @param quantity Quantity
 * @param isAllowEmpty Allow show empty string
 */
export const formatQuantity = (quantity: number | string, isAllowEmpty = false) => {
  if (!quantity) {
    return isAllowEmpty ? '' : 0
  }
  const quantityTemp = Number(quantity)

  return quantityTemp && quantityTemp < 10 ? `0${quantityTemp}` : quantityTemp
}

/**
 * Format phone number
 *
 * @param phoneNumber Phone number
 */
export const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/(\d{4})(\d{3})(\d{3})/, '$1.$2.$3')
}

/**
 * Highlight keyword
 *
 * @param text Text wanna check
 * @param keyword Keyword
 */
export const highlightKeyword = (text = '', keyword = '') => {
  return text
  return text.replace(new RegExp(keyword, 'g'), '<strong>' + keyword + '</strong>')
}

/**
 * Calculate total of cart items with each product and its quantity
 *
 * @param cartItems Items in cart
 */
export const getTotalOfCartItems = (cartItems: any[]) => {
  return cartItems.reduce((sum: number, item: any) => sum + (item.quantity || 1), 0)
}

/**
 * Get product price to VND with discount (discount percent is high priority)
 *
 * @param price Price value
 * @param discountPercent Discount by percent
 * @param discountValue Discount by value
 */
export const getProductPrice = (price: number, discountPercent = 0, discountValue = 0) => {
  if ((!discountPercent && !discountValue) || !price) {
    return { oldPrice: null, newPrice: formatPrice(price), numberPrice: price }
  }

  let checkingPrice = price

  // discount by percent
  if (discountPercent) {
    checkingPrice = checkingPrice - (price * discountPercent) / 100
  } else if (discountValue) {
    checkingPrice = checkingPrice - discountValue
  }

  return {
    oldPrice: formatPrice(price),
    newPrice: formatPrice(checkingPrice),
    numberPrice: checkingPrice,
  }
}

/**
 * Get counter label
 *
 * @param dataLength Length of data
 * @param total Total of data
 * @param page Current page
 * @param pageSize Page size
 */
export const getCounterLabel = (
  dataLength = 0,
  total = 0,
  page = 1,
  pageSize = BASIC_PAGE_SIZE
) => {
  return total > pageSize ? `${Math.max(page - 1, 0) * pageSize + dataLength}/${total}` : total
}

export const formatDateVI = (time: number) => {
  const date = new Date(time * 1000)
  return date.getDate() + ' tháng ' + (date.getMonth() + 1) + ', ' + date.getFullYear()
}
