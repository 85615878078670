import { Drawer, DrawerProps } from '@mui/material'
import AppLinks from './AppLinks'

import { INFO_MENU } from 'configs/constants'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useApp } from 'store/app'
import { useCategories } from 'store/categories'

interface PropTypes extends DrawerProps {
  className?: string
  onClose?: () => void
}

export default function AppSidebar(props: PropTypes) {
  const { className = '', onClose = () => {}, ...rest } = props
  const { listCategories, listManufacturers } = useCategories()

  const { aboutUs } = useApp()

  const { store_address, about_us, vision_mission } = aboutUs

  const listMenu = useMemo(() => {
    if (isEmpty(about_us) && isEmpty(store_address) && isEmpty(vision_mission)) {
      return INFO_MENU.filter((menuItem) => menuItem.path !== '/ve-chung-toi')
    }
    return INFO_MENU
  }, [aboutUs])

  return (
    <Drawer {...rest} anchor="right" onClose={onClose}>
      <div className={`AppSidebar-container ${className}`} style={{ maxWidth: 280 }}>
        <div className="AppSidebar-header">
          <span className="AppSidebar-btn" onClick={onClose}>
            <span className="Ic-close" />
          </span>
        </div>
        <div className="AppSidebar-content">
          <AppLinks
            className="AppSidebar-menu"
            name="productPortfolio"
            items={listCategories}
            onClose={onClose}
            showIconNext
          />
          <AppLinks
            className="AppSidebar-menu"
            name="manufacturer"
            items={listManufacturers}
            onClose={onClose}
            showIconNext
          />
          <AppLinks className="AppSidebar-menu" name="info" items={listMenu} onClose={onClose} />
        </div>
      </div>
    </Drawer>
  )
}
