import { PaymentLayout } from 'components/screens/app/payment'
import { useEffect } from 'react'
import { useHistory } from 'store/confirmRedirect'

const Payment: React.FC = () => {
  const history = useHistory()

  // F5 will redirect to cart
  useEffect(() => {
    if (!localStorage.getItem('payment')) history.push('/shopping-cart')
    localStorage.removeItem('payment')
  }, [])

  return <PaymentLayout />
}

export default Payment
