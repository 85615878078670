/*eslint-disable*/
import { Box, Stack } from '@mui/material'
import { Button } from 'components'
import InputOTP from 'components/input/InputOTP'
import { apiUrls } from 'configs/apis'
import { AUTH_TYPES, OTP_NUMBER_FIELDS } from 'configs/constants'
import { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useApis } from 'services/api'
import { useAuth } from 'store/auth'
import { useToasts } from 'store/toasts'
import CountDownTimer from './CountDownTimer'

const { FORGOT_PASSWORD, SIGN_UP, SIGN_IN } = AUTH_TYPES

interface Props {
  next: () => void
  toEmail: () => void
  sendEmailSuccess: boolean
}

export default function EnterOTP({ next, toEmail, sendEmailSuccess }: Props) {
  const { t } = useTranslation()
  const [error, setError] = useState<any>('')
  const [value, setValue] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [showTimer, setShowTimer] = useState(1)
  const { signInSuccess, modal, phone, user_id } = useAuth()
  const authType = modal.type
  const isSignIn = authType === SIGN_IN
  const isSignUp = authType === SIGN_UP

  const { apiPost, requestApi } = useApis()

  
  const { createToast } = useToasts()

  let params: {
    title: string
    button: string
    api: string
    method: any
  }

  switch (authType) {
    case SIGN_UP:
      params = {
        title: sendEmailSuccess
          ? 'Nhập mã xác nhận được gửi tới email của bạn để kích hoạt tài khoản'
          : 'Nhập mã xác nhận được gửi tới tài khoản Zalo của số điện thoại bạn đã nhập để kích hoạt tài khoản',
        button: t('confirm'),
        api: apiUrls.checkOTPSignUp(),
        method: 'put',
      }
      break

    case FORGOT_PASSWORD:
      params = {
        title: sendEmailSuccess
          ? 'Nhập mã xác nhận được gửi tới email của bạn'
          : 'Nhập mã xác nhận được gửi tới tài khoản Zalo của số điện thoại bạn đã nhập',
        button: t('confirm'),
        api: apiUrls.checkOTP(),
        method: 'post',
      }
      break

    default:
      params = {
        title: sendEmailSuccess
          ? 'Nhập mã xác nhận được gửi tới email của bạn để đăng nhập'
          : 'Nhập mã xác nhận được gửi tới tài khoản Zalo của số điện thoại bạn đã nhập',
        button: t('sign_in'),
        api: apiUrls.signIn(),
        method: 'post',
      }
  }

  // Reset error when change value
  useEffect(() => {
    error && setError('')
  }, [value])

  const valid = value.length === OTP_NUMBER_FIELDS && value.every((item) => item)

  // Call api check OTP when click button
  const handleSubmit = () => {
    if (!valid) return

    setLoading(true)
    const otp = value.join('')
    requestApi(params.method, params.api, isSignIn ? { otp, phone } : { otp, user_id }, {
      successCallback: ({ id, status, text, data }) => {
        if (status) {
          if (isSignIn) signInSuccess(data)
          else next()
        } else {
          setLoading(false)
          setError(
            id !== '306' ? (
              text
            ) : (
              <>
                Mã xác nhận hết hiệu lực. Vui lòng nhấn <b>Gửi lại mã</b> để nhận mã xác nhận mới
              </>
            )
          )
        }
      },
      errorCallback: () => setLoading(false),
    })
  }

  // Resend OTP, disable button resend in 5 minutes
  const handleResend = () => {
    setLoading(true)
    apiPost(
      apiUrls.sendOTP(),
      { phone, re_send: true },
      ({ status,data,text }) => {
        if (status) {
          setShowTimer((showTimer) => showTimer + 1)
          createToast({
            type: 'success',
            message: { title: 'Thông báo', content:text },
            duration: 1500,
          })
        }
        setLoading(false)
      },
      () => setLoading(false)
    )
  }

  return (
    <Stack spacing={2}>
      <span className="label">{params.title}</span>
      <InputOTP
        error={valid && error}
        onChange={(value) => setValue(value)}
        disabled={loading}
        onSubmit={handleSubmit}
      />
      <Box>
        <Button
          loading={loading}
          disabled={!valid || loading}
          fullWidth
          icon="arrow"
          onClick={handleSubmit}
        >
          {params.button}
        </Button>
        {!sendEmailSuccess && isSignUp && (
          <Button
            style={{ marginTop: 16 }}
            loading={loading}
            disabled={loading}
            fullWidth
            icon="arrow"
            onClick={toEmail}
            color="secondary"
          >
            {'Gửi mã xác nhận qua email'}
          </Button>
        )}
      </Box>
      <CountDownTimer show={showTimer} handleResend={handleResend} />
    </Stack>
  )
}
