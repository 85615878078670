import { Box } from '@mui/material'
import { isFunction } from 'lodash'
import React, { useCallback, useRef, useState } from 'react'
import { removeAccentsKeepSpecialEmail } from 'utils'
// import { useHistory } from 'store/confirmRedirect'
// import { randomId } from 'utils'
import './index.scss'

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  color?: 'gray' | 'white'
  borderRadius?: number
  width?: number | string
  height?: number | string
  ml?: number
  mr?: number
  mt?: number
  mb?: number
  icon?: string
  fullWidth?: boolean
  error?: any
  label?: string
  border?: boolean
  innerRef?: any
  errorFocused?: boolean
  blurWhenEnter?: boolean
  cursorPointer?: boolean
  rows?: number
  isPreventRedirect?: boolean
  [key: string]: any
}

const Element = ({ rows = 1, innerRef, ...rest }: any) => {
  if (rows > 1) return <textarea rows={rows} ref={innerRef} {...rest} />
  return <input ref={innerRef} {...rest} />
}

const Input: React.FC<Props> = (props) => {
  // const id = useRef(randomId())
  // const { allowRedirect, preventRedirect } = useHistory()

  const {
    className = '',
    color = 'gray',
    borderRadius = 4,
    width,
    height = 45,
    ml,
    mr,
    mt,
    mb,
    style = {},
    icon = '',
    fullWidth,
    error,
    type,
    value,
    onFocus,
    onBlur,
    label,
    required,
    border,
    innerRef,
    errorFocused,
    onKeyDown,
    blurWhenEnter,
    onClick,
    cursorPointer,
    rows = 1,
    onChange,
    // isPreventRedirect,
    ...rest
  } = props

  const [isFocusing, setIsFocusing] = useState(false)
  const isPassword = type === 'password'
  const [passwordShown, setPasswordShown] = useState(!isPassword)
  const ref = useRef<any>()

  /**
   * When input is focus, save status focus to this field name
   */
  const handleFocus = useCallback((e: any) => {
    setIsFocusing(true)
    onFocus?.(e)
  }, [])

  /**
   * Reset status focus when blur field
   */
  const handleBlur = useCallback((e: any) => {
    setIsFocusing(false)
    onBlur?.(e)
  }, [])

  const invalid = value && (!isFocusing || errorFocused) && !!error

  const iconPassword = isPassword
    ? `cursor-pointer ${passwordShown ? 'mn-icon-eye-slash' : 'mn-icon-eye'}`
    : ''
  const iconClassName = icon ? `mn-icon-${icon}` : iconPassword

  // useEffect(() => {
  //   if (!isPreventRedirect) return
  //   if (value) preventRedirect(id.current)
  //   else allowRedirect(id.current)
  // }, [value])

  // useEffect(() => {
  //   if (!isPreventRedirect) return
  //   return () => {
  //     allowRedirect(id.current)
  //   }
  // }, [])

  const handleChangePassword = (e: any) => {
    const value = e.target.value
    e.target.value = removeAccentsKeepSpecialEmail(value)
    onChange?.(e)
  }

  return (
    <div
      className={`Input Input-${color} ${border ? ' Input-border' : ''} ${className}${
        fullWidth ? ' full-width' : ''
      }`}
      style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}
    >
      {label && (
        <div className="Input-label">
          <span>{label}</span>
          {required && (
            <Box ml={0.5}>
              <span>(</span>
              <span className="Input-label__asterisk">*</span>
              <span>)</span>
            </Box>
          )}
        </div>
      )}
      <div className="Input-wrapper">
        <Element
          maxLength={isPassword ? 16 : undefined}
          {...rest}
          innerRef={(ref_: any) => {
            if (innerRef) {
              isFunction(innerRef) ? innerRef?.(ref_) : (innerRef.current = ref_)
            }
            ref.current = ref_
          }}
          rows={rows}
          required={required}
          className={`${invalid ? 'invalid' : ''}${cursorPointer ? ' cursor-pointer' : ''}`}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={(e: any) => {
            if (blurWhenEnter && e.key === 'Enter') setIsFocusing(false)
            onKeyDown?.(e)
          }}
          onChange={isPassword ? handleChangePassword : onChange}
          onClick={onClick}
          type={isPassword ? (passwordShown ? 'text' : 'password') : type}
          value={value}
          style={{
            width,
            height: rows > 1 ? undefined : height,
            borderRadius,
            paddingRight: iconClassName ? 58 : undefined,
            ...style,
          }}
        />
        {!!iconClassName && (
          <i
            className={`${iconClassName} Input-icon${cursorPointer ? ' cursor-pointer' : ''}`}
            onClick={(e: any) => {
              if (isPassword) setPasswordShown(!passwordShown)
              else {
                onClick?.(e)
                ref.current.focus()
              }
            }}
          />
        )}
      </div>
      {invalid && error !== true && <div className="Input-invalid">{error || ''}</div>}
    </div>
  )
}

export default Input
