import { Container } from '@mui/material'
import { useBreadcrumbs } from 'store/breadcrumbs'
import { useEffect, useMemo } from 'react'
import EnginePartItem from './EnginePartItem'
import { useLocation } from 'react-router'
import { getLinkSeo } from 'utils'
import { useCategories } from 'store/categories'
import { useHistory } from 'store/confirmRedirect'
import { useModalConfirm } from 'store/modalConfirm'

const EnginePartsLevel1: React.FC = () => {
  const { pathname } = useLocation()
  const { showBreadcrumbs } = useBreadcrumbs()
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()
  const { getCategory, getBreadcrumbs, menu } = useCategories()
  const history = useHistory()

  const category = getCategory(getLinkSeo(pathname))

  useEffect(() => {
    if (menu.length !== 0) {
      if (!category) {
        showModalConfirm({
          title: 'Thông báo',
          content: 'Danh mục không tồn tại',
          confirm: {
            action: () => {
              hideModalConfirm()
              history.goBack()
            },
            text: 'Đã hiểu',
          },
        })
        return
      } else {
        if (!category?.sub?.length) {
          history.push(category.linkFull)
          return
        }
      }
    }
    showBreadcrumbs(getBreadcrumbs(category?.linkSeo || '') || [])
  }, [pathname, getBreadcrumbs])

  const engines = useMemo(() => {
    return category?.sub || []
  }, [category])

  return (
    <div className="EnginePartLevel1-wrapper">
      <div className="EnginePartLevel1-body">
        <Container>
          <div className="EnginePartLevel1-title">{category?.name}</div>
          <div className="EnginePartLevel1-container">
            {engines.map((engine: any) => {
              const {
                image_url: image = '',
                name = '',
                product_count: amount = 0,
                linkSeo = '',
                linkFull = '',
              } = engine || {}
              return (
                <EnginePartItem
                  key={linkSeo}
                  name={name}
                  amount={amount}
                  image={image}
                  onClick={() => {
                    if (linkSeo !== 'san-pham-khac') {
                      history.push(linkFull)
                    } else {
                      history.push(linkFull, {
                        manufacturerName: category?.name,
                        manufacturerId: category?.id,
                        manufacturerLinkFull: category?.linkFull,
                      })
                    }
                  }}
                />
              )
            })}
          </div>
        </Container>
      </div>
    </div>
  )
}

export default EnginePartsLevel1
