import React, { useCallback, useContext } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { Box, List, ListItemButton, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import './index.scss'
import { useHistory } from 'store/confirmRedirect'
import { AdminContext, ADMIN_ALL_ROLES } from 'configs/constants'

interface Props {
  navConfig: any[]
  [key: string]: any
  open: boolean
}

const NavSection: React.FC<Props> = ({ navConfig, open, ...rest }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const history = useHistory()
  const { myRoles } = useContext(AdminContext)

  const match = (path: any) =>
    open && path ? !!matchPath(pathname, { path, exact: false }) : false

  /**
   * Check sidebar item has permission to show
   */
  const checkHavePermission = useCallback(
    (role?: any) =>
      !role ||
      myRoles.find((item) => item.key === ADMIN_ALL_ROLES.SUPER_ADMIN || role.includes?.(item.key)),
    [myRoles]
  )

  return (
    <Box className="NavSection" {...rest}>
      <List disablePadding>
        {navConfig.map(({ title, path, icon, onClick, sub = [], permission }) => {
          const active = !sub.length && match(path)
          const disabled = !!sub.length

          const handleClick = () => {
            path && history.push(path)
            onClick?.()
            // Close sidebar if clicked at current path
          }

          if (!permission && sub.length) {
            const subItem = sub.find((item: any) => checkHavePermission(item.permission))
            if (!subItem) return null
          } else {
            if (!checkHavePermission(permission)) return null
          }

          return (
            <React.Fragment key={title}>
              <ListItemButton
                disabled={!open}
                className={`NavSection-item${active ? ' active' : ''}${
                  disabled ? ' disabled' : ''
                }`}
                onClick={handleClick}
              >
                <i className={icon} />
                <ListItemText>{t(title)}</ListItemText>
                <div className="NavSection-item__border-right"></div>
              </ListItemButton>
              {sub.map((z: any, index: number) => {
                const { path, title, permission: per } = z
                const active = match(path)

                const handleClick = () => {
                  history.push(path)
                }

                if (!checkHavePermission(per)) return null

                return (
                  <ListItemButton
                    key={index}
                    onClick={handleClick}
                    className={`NavSection-item small${active ? ' active' : ''}${
                      index === 0 ? ' first' : ''
                    }`}
                  >
                    <div className="NavSection-sub">
                      <i className="fas fa-plus" />
                      {t(title)}
                    </div>
                  </ListItemButton>
                )
              })}
            </React.Fragment>
          )
        })}
      </List>
    </Box>
  )
}

export default NavSection
