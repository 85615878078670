/* eslint-disable */
import { useEffect, useMemo, useRef, useState } from 'react'
import Slider from 'react-slick'

import { ImageViewer } from 'components/common'
import { isMobile } from 'utils'
import { ProductType } from 'types'

import imgProduct from 'assets/images/imgProductTemp.png'

interface ArrowPropTypes {
  isNext?: boolean
  onClick?: () => void
}

interface PropTypes {
  className?: string
  product?: ProductType
}

/**
 * Custom slider arrow button
 *
 * @param props Component props
 */
function CustomArrow(props: ArrowPropTypes) {
  const { isNext, onClick } = props

  return (
    <div className={`ProductDetailSlider-arrow ${isNext ? 'next' : 'prev'}`} onClick={onClick}>
      <span className="mn-icon-arrow-right" />
    </div>
  )
}

const navImgSettings = {
  slidesToShow: 6,
  swipeToSlide: true,
  focusOnSelect: true,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
}

export default function DetailSlider(props: PropTypes) {
  const { className = '', product } = props

  const [imgPreview, setImgPreview] = useState({ open: false, index: 0 })
  const [currentSlide, setCurrentSlide] = useState(0)

  const navImgRef = useRef<any>(null)
  const navDotRef = useRef<any>(null)

  const { avatar_url = '', images: thumnails = [] } = product || ({} as ProductType)

  // Get images from product
  const images = useMemo(
    () =>
      [avatar_url || imgProduct, ...thumnails.map((item) => item.trim().replace(/\n/g, ''))].filter(
        (item) => item
      ),
    [product]
  )

  useEffect(() => {
    currentSlide !== 0 && setCurrentSlide(0)
  }, [product])

  /**
   * Handle toggle img preview
   *
   * @param isOpen Open or not
   * @param imgIndex Image index
   */
  const handleToggleImgPreview = (isOpen = false, imgIndex = 0) => {
    setImgPreview({ open: isOpen, index: imgIndex })

    if (isOpen && isMobile()) {
      document.body.classList.add('mobile')
      return
    }
    document.body.classList.remove('mobile')
  }

  useEffect(() => {
    navImgRef.current?.slickGoTo?.(currentSlide)
    navDotRef.current?.slickGoTo?.(currentSlide)
  }, [currentSlide])

  return (
    <div className={`ProductDetailSlider-container ${className}`}>
      <div className="ProductDetailSlider-container__order">
        {currentSlide + 1}/{images.length}
      </div>
      <Slider
        className="ProductDetailSlider-img"
        infinite={true}
        ref={navImgRef}
        prevArrow={<CustomArrow />}
        nextArrow={<CustomArrow isNext />}
        beforeChange={(_, next) => setCurrentSlide(next)}
      >
        {images.map((imgItem, index) => (
          <div key={index} onClick={() => handleToggleImgPreview(true, index)}>
            <span
              className="ProductDetailSlider-item intro"
              style={{ backgroundImage: `url("${imgItem}")` }}
            />
          </div>
        ))}
      </Slider>
      <Slider
        className="ProductDetailSlider-dots"
        ref={navDotRef}
        {...navImgSettings}
        infinite={true}
        prevArrow={<CustomArrow />}
        nextArrow={<CustomArrow isNext />}
        beforeChange={(_, next) => setCurrentSlide(next)}
      >
        {images.map((imgItem, index) => (
          <div key={index} className="slide-item">
            <div className={`slide-item-inside ${currentSlide === index ? 'selected' : ''}`} />
            <span
              className="ProductDetailSlider-item"
              style={{ backgroundImage: `url("${imgItem}")` }}
            />
          </div>
        ))}
      </Slider>
      <ImageViewer
        className="ProductDetailSlider-imgViewer"
        {...imgPreview}
        images={images}
        currentImgIndex={imgPreview.index}
        onClose={() => handleToggleImgPreview(false)}
      />
    </div>
  )
}
