/* eslint-disable */
import React, { useCallback, useContext, useEffect, useRef, useState, useMemo } from 'react'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import { Button, RenderField } from 'components/admin'
import { Box, Container, Step, StepButton, StepLabel, Stepper, Card, Chip } from '@mui/material'
import { AdminOrderDetailType } from 'types'
import { formatDate, renderFileName, trimFileName } from 'utils'
import { useParams } from 'react-router'
import { renderBackTitle } from 'configs/extensions'
import { useHistory } from 'store/confirmRedirect'
import {
  ADMIN_ORDER_STATUSES,
  defaultModalCancel,
  defaultModalDeliveryNote,
  defaultModalSendEmail,
  defaultModalUpdate,
  mappingAdminOrderChangeTransfer,
  mappingAdminOrderChangePaymentDelivery,
  mappingAdminOrderStatus,
  mappingAdminOrderStatusColor,
  mappingAdminOrderChangeTransfer2,
  mappingAdminOrderChangePaymentDelivery2,
  defaultModalChangStatusSucsessfull,
} from './constants'
import ProductItem from 'components/screens/app/payment/ProductItem'
import UnitPrice from 'components/screens/app/payment/UnitPrice'
import { useAdminOrders } from './hook'
import ModalCancelOrder from './ModalCancelOrder'
import ModalUpdateOrder from './ModalUpdateOrder'
import {
  AdminContext,
  ADMIN_ALL_ROLES,
  DELIVERY_METHODS,
  mappingPaymentMethod,
  PAYMENT_METHODS,
  today,
} from 'configs/constants'
import UploadFile from 'components/admin/uploadFile/UploadFile'
import ModalSendEmail from './ModalSendEmail'
import ModalDeliveryNote from './ModalDeliveryNote'
import ModalChangStatusSucsessfull from './ModalChangStatusSucsessfull'
import { useAuth } from 'store/auth'

const { RECEIVED_MONEY, PACKING, DELIVERING, SUCCESSFUL_DELIVERY, CANCELED, UNPAID } =
  ADMIN_ORDER_STATUSES

const { PAYMENT_ON_DELIVERY } = PAYMENT_METHODS

const { GET_AT_STORE } = DELIVERY_METHODS

const OrderDetail: React.FC = () => {
  const { id } = useParams() || ({} as any)
  const { updateOrder } = useAdminOrders()
  const history = useHistory()
  const { apiGet } = useApis()
  const { hasPermission } = useContext(AdminContext)
  const permission = hasPermission(ADMIN_ALL_ROLES.MANAGE_ORDERS)
  const { full_name: adminName, phone: adminPhone } = useAuth()

  const [modalCancel, setModalCancel] = useState(defaultModalCancel)
  const [modalUpdate, setModalUpdate] = useState(defaultModalUpdate)
  const [modalChangStatusSucsessfull, setModalChangStatusSucsessfull] = useState(
    defaultModalChangStatusSucsessfull
  )
  const [modalSendEmail, setModalSendEmail] = useState(defaultModalSendEmail)
  const [modalDeliveryNote, setModalDeliveryNote] = useState(defaultModalDeliveryNote)
  const [data, setData] = useState<AdminOrderDetailType>({ loading: true })

  const setLoading = (loading: boolean) => setData((data) => ({ ...data, loading }))

  // Get order from server
  const getOrder = useCallback(() => {
    setLoading(true)
    apiGet(
      apiUrls.adminOrders(id),
      {},
      ({ status, data }) => {
        if (status) setData(data)
        else setLoading(false)
      },
      () => setLoading(false)
    )
  }, [])

  const handleUpdate = (param: any) => {
    if (getAtStore && param.status === SUCCESSFUL_DELIVERY) {
      if (param.status === SUCCESSFUL_DELIVERY) {
        setModalChangStatusSucsessfull({
          show: true,
          id,
          type: 'create',
          initialValues: {
            time_delivery: today,
            delivery_address: addressInfo[3],
            admin_name: adminName,
            admin_phone: adminPhone,
            customer_name: full_name,
            customer_phone: phone,
          },
        })
      }
    } else {
      if (status === PACKING) {
        setModalUpdate({
          show: true,
          id,
          type: 'create',
          updateShipper: true,
        })
      } else if (status === DELIVERING) {
        setModalUpdate({
          show: true,
          id,
          type: 'create',
          updateShipper: false,
          initialValues: {
            handover_shipping,
          },
        })
      } else
        updateOrder(
          id,
          { ...param, old_status: status },
          getOrder,
          () => setLoading(true),
          () => setLoading(false)
        )
    }
  }

  useEffect(() => {
    getOrder()
  }, [])

  const {
    loading,
    order_code,
    order_products = [],
    status = 1,
    order_delivery_address,
    payment_method,
    order_store_address,
    order_vat_invoice,
    discount_code,
    total_price = 0,
    final_total_price = 0,
    created_date,
    cancel_by,
    cancel_reason,
    handover_shipping,
    shipping_unit,
    expected_delivery,
    shipper_name,
    shipper_phone,
    user_id,
    user,
    delivered_date,
    file_url,
    created_user,
    delivery_method,
    phone_receiver,
    full_name_receiver,
    delivery_address_store,
    is_freeship,
  } = data

  const { totalInit } = useMemo(() => {
    let totalInit = 0

    order_products?.forEach((item: any) => {
      const initPrice = item?.original_price * item.quantity
      totalInit += initPrice
    })
    return {
      totalInit,
    }
  }, [order_products])

  const isPaymentOnDelivery = payment_method === PAYMENT_ON_DELIVERY

  const getAtStore = delivery_method === GET_AT_STORE

  let { full_name: fullName1, phone: phone1 } = user || {}

  const { full_name, phone, address, town, district, city, note } = order_delivery_address || {}

  if (!fullName1) fullName1 = full_name
  if (!phone1) phone1 = phone

  const { address: store_address } = order_store_address || {}

  const addressInfo = getAtStore
    ? ['Lấy tại cửa hàng', full_name, phone, store_address]
    : ['Giao hàng tận nơi', full_name, phone, `${address}, ${town}, ${district}, ${city}`]

  const { company_name, tax_identification_number, company_address, email } =
    order_vat_invoice || {}

  const listSteps = [
    { value: RECEIVED_MONEY, icon: 'money-bill-alt' },
    { value: PACKING, icon: 'box' },
    { value: DELIVERING, icon: 'truck' },
    { value: SUCCESSFUL_DELIVERY, icon: 'star' },
  ]

  const steps = useMemo(() => {
    if (isPaymentOnDelivery) {
      if (getAtStore) {
        return [listSteps[1], listSteps[3]]
      } else return listSteps.splice(1)
    } else {
      if (getAtStore) {
        listSteps.splice(2, 1)
        return listSteps
      } else return listSteps
    }
  }, [isPaymentOnDelivery, getAtStore])

  const activeStep = status > 2 && status < 7 ? (isPaymentOnDelivery ? status - 4 : status - 3) : -1

  const renderField = (title: string, children: (string | undefined)[], header?: any) => (
    <div className="AdminOrderDetail-block">
      <div className="AdminOrderDetail-blockTitle d-f ai-c">
        <div>{title}</div>
        <div>{header}</div>
      </div>
      {children.map((item, index) => (
        <div className="AdminOrderDetail-blockContent" key={index}>
          {item}
        </div>
      ))}
    </div>
  )

  let shipperInfo: string[] = []
  if (shipper_name) shipperInfo = [`Họ tên người vận chuyển: ${shipper_name}`]
  if (shipper_phone) shipperInfo = [...shipperInfo, `Số điện thoại: ${shipper_phone}`]
  if (delivered_date)
    shipperInfo = [...shipperInfo, `Thời gian giao hàng thực tế: ${formatDate(delivered_date)}`]

  const shippingInfo =
    delivery_method === GET_AT_STORE && delivered_date
      ? renderField('Thông tin giao hàng ', [
          `Thời gian giao hàng trực tiếp cho khách hàng: ${formatDate(delivered_date)}`,
          `Địa điểm giao hàng: ${delivery_address_store}`,
          `Họ tên người giao hàng: ${shipper_name}`,
          `Số điện thoại: ${shipper_phone}`,
          `Họ tên người nhận hàng: ${full_name_receiver}`,
          `Số điện thoại: ${phone_receiver}`,
        ])
      : handover_shipping
      ? renderField(
          'Thông tin vận chuyển',
          [
            `Thời gian giao hàng cho đơn vị vận chuyển: ${formatDate(handover_shipping)}`,
            `Đơn vị vận chuyển: ${shipping_unit}`,
            `Thời gian giao hàng dự kiến: ${formatDate(expected_delivery)}`,
            ...shipperInfo,
          ],
          permission && status < 6 ? (
            <Button
              variant="outlined"
              style={{ textTransform: 'none', height: 26, fontSize: 13, marginLeft: 12 }}
              onClick={() =>
                setModalUpdate({
                  show: true,
                  id,
                  updateShipper: true,
                  type: 'update',
                  initialValues: {
                    handover_shipping,
                    shipping_unit,
                    expected_delivery,
                    shipper_name,
                    shipper_phone,
                  },
                })
              }
            >
              Chỉnh sửa
            </Button>
          ) : undefined
        )
      : null

  const renderStatus = () => {
    const { background, text, border } = mappingAdminOrderStatusColor[status]

    const style = {
      backgroundColor: background,
      color: text,
      border: `1px solid ${border}`,
    }

    return (
      <div className="AdminOrderStatus" style={style}>
        {mappingAdminOrderStatus[status]}
      </div>
    )
  }

  const listChangeStatuses = isPaymentOnDelivery
    ? getAtStore
      ? mappingAdminOrderChangePaymentDelivery2[status]
      : mappingAdminOrderChangePaymentDelivery[status]
    : getAtStore
    ? mappingAdminOrderChangeTransfer2[status]
    : mappingAdminOrderChangeTransfer[status]

  if (loading && !order_code) return null

  return (
    <Container>
      <ModalDeliveryNote
        {...modalDeliveryNote}
        onClose={() => setModalDeliveryNote((prev) => ({ ...prev, show: false }))}
      />
      <ModalSendEmail
        {...modalSendEmail}
        onClose={() => setModalSendEmail((prev) => ({ ...prev, show: false }))}
      />
      <ModalCancelOrder
        {...modalCancel}
        onClose={() => setModalCancel((prev) => ({ ...prev, show: false }))}
        onSuccess={getOrder}
      />
      <ModalUpdateOrder
        {...modalUpdate}
        onClose={() => setModalUpdate((prev) => ({ ...prev, show: false }))}
        onSuccess={getOrder}
        oldStatus={status}
        paymentMethod={payment_method}
      />
      <ModalChangStatusSucsessfull
        {...modalChangStatusSucsessfull}
        onClose={() => setModalChangStatusSucsessfull((prev) => ({ ...prev, show: false }))}
        onSuccess={getOrder}
        oldStatus={status}
      />
      <div className="d-f jc-sb ai-c">
        {renderBackTitle(history, `Chi tiết đơn hàng ${order_code || ''}`)}
        {renderStatus()}
      </div>
      <div>
        {status === CANCELED ? (
          <div className="AdminOrderDetail-cancel">
            <div>Bên hủy: {cancel_by !== 1 ? 'Người mua' : 'Người bán'}</div>
            {!!cancel_reason && <div>Lý do: {cancel_reason}</div>}
          </div>
        ) : (
          <Box sx={{ maxWidth: 800, margin: '30px auto 20px' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((step, index) => {
                const { value, icon } = step
                const label = mappingAdminOrderStatus[value]

                return (
                  <Step key={value}>
                    <StepButton
                      icon={
                        <div className="step-icon">
                          <i
                            className={`fas fa-${icon}`}
                            style={{
                              color: activeStep >= index ? 'var(--cl-admin-success)' : undefined,
                            }}
                          />
                        </div>
                      }
                      disabled
                    />
                    <StepLabel StepIconComponent={() => null}>{label}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          </Box>
        )}
        <div className="AdminOrderDetail-wrapper">
          <div className="left">
            <Card sx={{ p: 3 }}>
              {!!user_id &&
                renderField('Thông tin khách hàng', [
                  `Họ và tên: ${fullName1}`,
                  `Số điện thoại: ${phone1}`,
                ])}
              {renderField('Địa chỉ nhận hàng', addressInfo)}
              {(status === RECEIVED_MONEY || status === UNPAID || status === PACKING) &&
                permission && (
                  <div style={{ margin: '-10px 0 20px 25px' }}>
                    <Button
                      // style={{ width: 180 }}
                      variant="contained"
                      onClick={() => {
                        const isAdmin = created_user?.type === 2 || created_user?.type === 3
                        setModalDeliveryNote({
                          show: true,
                          id,
                          fullName: isAdmin ? created_user?.id : '',
                          phone: isAdmin ? created_user?.phone : '',
                        })
                      }}
                    >
                      In phiếu giao nhận
                    </Button>
                  </div>
                )}
              {renderField('Phương thức thanh toán', [mappingPaymentMethod[payment_method || 1]])}

              {status === SUCCESSFUL_DELIVERY && (
                <div className="AdminOrderDetail-block">
                  <div className="AdminOrderDetail-blockTitle">
                    <div>Hóa đơn đỏ</div>
                    <div style={{ margin: '8px 0 0 24px' }} className="d-f ai-c jc-sb">
                      {(permission || file_url) && (
                        <UploadFile
                          labelButton="Thêm hóa đơn đỏ"
                          file_url={file_url || ''}
                          onSuccess={(url) => {
                            handleUpdate({ file_url: url })
                          }}
                          showConfirmDelete={{
                            title: 'Xóa hóa đơn đỏ',
                            content: 'Bạn có chắc muốn xóa hóa đơn đỏ này?',
                          }}
                          onClick={(url) => window.open(url)}
                          hideRemoveIcon={!permission}
                          isUpdate
                        />
                      )}
                      {file_url && permission && (
                        <Button
                          variant="contained"
                          onClick={() => {
                            // const email = email ? email : ''
                            setModalSendEmail({
                              show: true,
                              id,
                              email: email ? email : '',
                              attached_file: file_url,
                              type: '',
                            })
                          }}
                        >
                          Gửi hóa đơn đỏ
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {!!order_vat_invoice?.company_name &&
                renderField('Thông tin hóa đơn đỏ', [
                  `Tên công ty: ${company_name}`,
                  `Mã số thuế: ${tax_identification_number}`,
                  `Địa chỉ công ty: ${company_address}`,
                  email ? `Email: ${email}` : undefined,
                ])}
              {!!note && renderField('Ghi chú', [note])}
              {shippingInfo}
            </Card>
          </div>
          <div className="right">
            <Card sx={{ p: 3 }}>
              {order_products.map((item: any) => {
                const { quantity: amount, product = {}, price, original_price } = item
                const { avatar_url: image, name, manufacturer } = product
                return (
                  <ProductItem
                    key={item.id}
                    {...item}
                    image={image}
                    price={original_price}
                    amount={amount}
                    finalPrice={price}
                    name={name}
                    manufacturer={manufacturer?.name}
                  />
                )
              })}
              <div className="d-f jc-sb" style={{ marginTop: 18 }}>
                <div className="AdminOrderDetail-discountCode">
                  {!!discount_code && (
                    <div style={{ marginBottom: 15 }}>Mã giảm giá: {discount_code}</div>
                  )}
                  {is_freeship && <div style={{ marginBottom: 15 }}>Loại mã: Miễn phí ship</div>}
                  <div>Ngày đặt hàng: {formatDate(created_date)}</div>
                </div>

                <div style={{ minWidth: 280 }}>
                  <UnitPrice label="Tổng tiền trước giảm:" price={totalInit} />
                  {final_total_price - totalInit !== 0 && (
                    <UnitPrice label="Giảm giá:" price={final_total_price - totalInit} />
                  )}
                  <UnitPrice label="Tổng cộng:" price={final_total_price} large redColor />
                </div>
              </div>

              {permission && !!listChangeStatuses?.length && (
                <div className="AdminOrderDetail-changeStatus d-f jc-sb">
                  Chuyển trạng thái
                  <div>
                    {listChangeStatuses.map((item: number) => (
                      <div key={item}>
                        <Button
                          style={{ width: 180, marginBottom: 12 }}
                          variant="contained"
                          color={item === UNPAID ? 'warning' : 'primary'}
                          size="small"
                          onClick={() => handleUpdate({ status: item })}
                        >
                          {mappingAdminOrderStatus[item]}
                        </Button>
                      </div>
                    ))}
                    <div>
                      <Button
                        style={{ width: 180 }}
                        variant="outlined"
                        color="error"
                        onClick={() => setModalCancel({ show: true, id })}
                        size="small"
                      >
                        Hủy đơn hàng
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default OrderDetail
