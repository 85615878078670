import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import Button from '../button/Button'
import MuiButton from '../admin/button/Button'
import React from 'react'
import Modal from './Modal'
import { formatContent } from './extensions'

interface Props {
  show: boolean
  title: string
  content: string
  confirm?: { text: string; action: any }
  cancel?: { text: string; action: any }
  onBackdropClick?: any
  small?: boolean
  center?: boolean
}

const ModalConfirm: React.FC<Props> = (props) => {
  const { show, title, confirm, cancel, onBackdropClick, small, center } = props
  let { content }: any = props

  if (content.includes('<b>'))
    content = (
      <span
        className={center ? '' : 'label'}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    )
  else content = formatContent(content)

  if (window.location.pathname.includes('/admin'))
    return (
      <Dialog
        className="Modal Modal-admin Modal-left"
        open={show}
        fullWidth
        maxWidth={small ? 'xs' : 'sm'}
        onBackdropClick={onBackdropClick}
      >
        <DialogTitle style={{ textAlign: center ? 'center' : undefined }}>{title}</DialogTitle>
        <DialogContent style={{ fontWeight: 500, textAlign: center ? 'center' : undefined }}>
          {content}
        </DialogContent>
        <DialogActions>
          {cancel && (
            <MuiButton onClick={cancel.action} color="primary">
              {cancel.text}
            </MuiButton>
          )}
          {confirm && (
            <MuiButton onClick={confirm.action} variant="contained" color="primary">
              {confirm.text}
            </MuiButton>
          )}
        </DialogActions>
      </Dialog>
    )

  return (
    <Modal className="Modal-user" show={show} title={title} onBackdropClick={onBackdropClick}>
      <div className="label gray">
        {content}
        {confirm && (
          <Box className="Modal-user__button">
            <Button fullWidth small onClick={confirm.action} color="primary">
              {confirm.text}
            </Button>
          </Box>
        )}
        {cancel && (
          <Box className="Modal-user__button">
            <Button fullWidth small onClick={cancel.action} color="stroke">
              {cancel.text}
            </Button>
          </Box>
        )}
      </div>
    </Modal>
  )
}

export default ModalConfirm
