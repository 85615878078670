/*eslint-disable*/
import React, { useState } from 'react'
import { Box, Card, Tab, Tabs } from '@mui/material'
import GuideUsingStep from 'components/admin/guideUsingStep/GuideUsingStep'
import { renderBackTitle } from 'configs/extensions'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'store/confirmRedirect'

import add1 from '../../../../assets/images/groupsUsing/addEdit/add1.png'
import add2 from '../../../../assets/images/groupsUsing/addEdit/add2.png'
import add3 from '../../../../assets/images/groupsUsing/addEdit/add3.png'
import add4 from '../../../../assets/images/groupsUsing/addEdit/add4.png'

import list1 from '../../../../assets/images/groupsUsing/addEdit/list1.png'
import list2 from '../../../../assets/images/groupsUsing/addEdit/list2.png'

import edit1 from '../../../../assets/images/groupsUsing/addEdit/edit1.png'
import edit2 from '../../../../assets/images/groupsUsing/addEdit/edit2.png'
import edit3 from '../../../../assets/images/groupsUsing/addEdit/edit3.png'
import edit4 from '../../../../assets/images/groupsUsing/addEdit/edit4.png'

import delete1 from '../../../../assets/images/groupsUsing/delete/delete1.png'
import delete2 from '../../../../assets/images/groupsUsing/delete/delete2.png'
import delete3 from '../../../../assets/images/groupsUsing/delete/delete3.png'
import delete4 from '../../../../assets/images/groupsUsing/delete/delete4.png'
import delete5 from '../../../../assets/images/groupsUsing/delete/delete5.png'
import delete6 from '../../../../assets/images/groupsUsing/delete/delete6.png'
import delete7 from '../../../../assets/images/groupsUsing/delete/delete7.png'

import view1 from '../../../../assets/images/groupsUsing/view1.png'
import view2 from '../../../../assets/images/groupsUsing/view2.png'
import PermisstionOfGroup from './groupUsingComponent/PermisstionOfGroup'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const GroupUsing = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      {renderBackTitle(history, t('group_management_using'))}
      <Card sx={{ p: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="Thêm nhóm quyền mới" {...a11yProps(0)} />
            <Tab label="Danh sách nhóm quyền" {...a11yProps(1)} />
            <Tab label="Sửa nhóm quyền" {...a11yProps(2)} />
            <Tab label="Xóa nhóm quyền" {...a11yProps(3)} />
            <Tab label="Quyền trong nhóm quyền" {...a11yProps(4)} />
            <Tab label="Danh sách quyền" {...a11yProps(5)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <i>
                <b>Lưu ý:</b> Chỉ quản trị viên cấp cao mới có quyền thêm nhóm quyền. Các quản trị
                viên khác nếu được phân quyền chỉ có thể Xem nhóm quyền.
              </i>
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Thêm nhóm quyền</b> để thêm một nhóm quyền mới."
              image={[add1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Thêm nhóm quyền</b>, hộp thoại Thêm nhóm quyền sẽ hiển thị như ảnh bên dưới:"
              image={[add2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Điền Tên nhóm quyền hợp lệ và Mô tả (nếu có), sau đó nhấn nút <b>THÊM</b>."
              image={[add3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>THÊM</b>, nhóm quyền sẽ được thêm thành công và hiển thị trong danh sách:"
              image={[add4]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <GuideUsingStep
              descreption="<b>Nhóm quyền mặc định</b>"
              orther={
                <Box margin={'30px'}>
                  <GuideUsingStep
                    descreption="Mặc định luôn hiển thị nhóm quyền <b>Quản trị viên cấp cao</b>. Nhóm quyền này có tất cả các quyền trong hệ thống và là nhóm quyền cấp cao nhất."
                    noWrapper
                  />
                  <GuideUsingStep
                    descreption="Không thể xóa nhóm quyền Quản trị viên cấp cao."
                    image={[list1]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Tìm kiếm nhóm quyền</b>"
              orther={
                <Box margin={'0 30px'}>
                  <GuideUsingStep
                    descreption="Bạn có thể tìm kiếm nhóm quyền bằng <b>tên nhóm quyền.</b>"
                    image={[list2]}
                  />
                </Box>
              }
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <i>
                <b>Lưu ý:</b> Chỉ quản trị viên cấp cao mới có quyền sửa nhóm quyền. Các quản trị
                viên khác nếu được phân quyền chỉ có thể Xem nhóm quyền.
              </i>
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Sửa</b> ở nhóm quyền mà bạn cần sửa thông tin."
              image={[edit1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Sửa</b>, hộp thoại Sửa nhóm quyền sẽ hiển thị như ảnh dưới đây:"
              image={[edit2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Cập nhật thông tin vào các trường mà bạn muốn sửa trong hộp thoại Sửa nhóm quyền, sau đó nhấn nút <b>LƯU</b>."
              image={[edit3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>LƯU</b>, thông tin nhóm quyền sẽ được cập nhật theo thông tin vừa sửa."
              noWrapper
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác sửa nhóm quyền:',
                  image: [edit4],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 18 }}>
              <i>
                <b>Lưu ý:</b> Chỉ quản trị viên cấp cao mới có quyền xóa nhóm quyền. Các quản trị
                viên khác nếu được phân quyền chỉ có thể Xem nhóm quyền.
              </i>
            </p>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có nhóm quyền (không phải nhóm quyền Quản trị viên cấp cao) được
              tạo trước đó.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Xóa</b> tại nhóm quyền mà bạn cần xóa thông tin."
              image={[delete1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Xóa</b>, hộp thoại xác nhận Xóa nhóm quyền sẽ hiển thị như ảnh bên dưới:"
              image={[delete2]}
            />
            <GuideUsingStep descreption="<b>Bước 2:</b> Nhấn nút <b>XÓA</b>." image={[delete3]} />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>XÓA</b> sẽ hiển thị hộp thoại nhập mật khẩu xác nhận."
              image={[delete4]}
            />
            <GuideUsingStep
              descreption="<b>Bước 3:</b> Nhập chính xác mật khẩu của tài khoản đang đăng nhập và nhấn <b>XÓA</b>."
              image={[delete5]}
              note={[
                {
                  title:
                    'Nếu nhóm quyền không gán với quản trị viên nào → Nhóm quyền sẽ bị xóa và không còn hiển thị trong danh sách.',
                },
                {
                  title:
                    'Nếu nhóm quyền đang được gán với quản trị viên → Hiển thị hộp thoại thông báo lỗi, bạn vui lòng bỏ gán người quản trị viên khỏi nhóm quyền và xóa nhóm quyền một lần nữa.',
                  image: [delete6],
                  subNote: [
                    {
                      title:
                        'Nhấn <b>ĐÃ HIỂU</b> → Đóng hộp thoại Thông báo và quay về màn Quản lý nhóm quyền.',
                    },
                  ],
                },
              ]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác xóa nhóm quyền:',
                  image: [delete7],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <PermisstionOfGroup />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Box>
            <GuideUsingStep
              descreption="Bạn có thể xem danh sách các quyền mặc định của hệ thống tại màn hình Danh sách quyền."
              image={[view1]}
              note={[
                {
                  title:
                    'Nếu quyền A được đánh dấu ở cột <b>Xem</b> → Quản trị viên được gán vào quyền A có thể xem đối tượng được nhắc đến trong tên quyền.',
                },
                {
                  title:
                    'Nếu quyền A được đánh dấu ở cột <b>Thêm</b> → Quản trị viên được gán vào quyền A có thể thêm mới đối tượng được nhắc đến trong tên quyền.',
                },
                {
                  title:
                    'Nếu quyền A được đánh dấu ở cột <b>Sửa</b> → Quản trị viên được gán vào quyền A có thể chỉnh sửa thông tin đối tượng được nhắc đến trong tên quyền.',
                },
                {
                  title:
                    'Nếu quyền A được đánh dấu ở cột <b>Xóa</b> → Quản trị viên được gán vào quyền A có thể xóa đối tượng được nhắc đến trong tên quyền.',
                },
              ]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title: 'Bạn có thể tìm kiếm quyền theo <b>Tên quyền</b>.',
                  image: [view2],
                },
              ]}
            />
          </Box>
        </TabPanel>
      </Card>
    </>
  )
}

export default GroupUsing
