import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { Container } from '@mui/material'

import { useApp } from 'store/app'

import { Loading, ProductItem } from 'components/common'
import { Button } from 'components'
import { useLocation } from 'react-router'

export default function HomeBestSeller() {
  const { t } = useTranslation()
  const { bestSellerData, getBestSellerApi } = useApp()
  const location = useLocation()

  const currentPage = useRef(1)
  const [isLoading, setIsLoading] = useState(false)

  const { items = [], total } = bestSellerData || {}
  const isMoreItems = items.length < total

  /**
   * Handle get best seller data
   */
  const getBestSellerData = useCallback(async () => {
    setIsLoading(true)
    try {
      await getBestSellerApi({ page: currentPage.current, page_size: 8 })
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    currentPage.current = 1
    getBestSellerData()
  }, [location])

  /**
   * Handle load more
   */
  const handleLoadmore = () => {
    currentPage.current = currentPage.current + 1
    getBestSellerData()
  }

  if (isEmpty(items) && !isLoading) return null

  return (
    <div className={`HomeBestSeller-container ${!isMoreItems && !isLoading && 'noMore'}`}>
      {isEmpty(items) && isLoading ? (
        <Loading />
      ) : (
        <Container>
          <p className="Home-smallTitle">{t('product')}</p>
          <h5 className="Home-title">{t('bestSell')}</h5>
          <div className="HomeBestSeller-list">
            {items.map((productItem: any, index: number) => (
              <ProductItem key={index} className="HomeBestSeller-item" product={productItem} />
            ))}
          </div>
          {isMoreItems && (
            <div className="HomeBestSeller-actions">
              <Button
                className="HomeBestSeller-btn"
                color="stroke"
                loading={isLoading}
                onClick={handleLoadmore}
              >
                {t('loadMore')}
              </Button>
            </div>
          )}
        </Container>
      )}
    </div>
  )
}
