import { Button, Radio } from 'components'
import { mappingAddressType } from 'configs/constants'
import React from 'react'
import './index.scss'

interface Props {
  checked?: boolean
  onClick?: any
  type?: number
  is_default: boolean
  children: any
  companyName?: string
  edit?: any
  remove?: any
  hideRadio?: boolean
  marginBig?: boolean
}

const AddressItem: React.FC<Props> = (props: Props) => {
  const {
    type = 0,
    is_default,
    children,
    checked,
    onClick,
    companyName,
    edit,
    remove,
    hideRadio,
    marginBig,
  } = props

  return (
    <div className={`AddressItem${marginBig ? ' marginBig' : ''}`}>
      <div className="AddressItem-radio">
        {!hideRadio && <Radio checked={checked} onClick={onClick} />}
      </div>

      <div className="AddressItem-wrapper">
        <div className="AddressItem-type">
          <span className="AddressItem-type__type">
            {companyName ? companyName : mappingAddressType[type]}
          </span>
          {!!is_default && <div className="AddressItem-type__default">Mặc định</div>}
        </div>
        <p className="label gray big">{children}</p>
        {!!edit && (
          <div className="AddressItem-buttons">
            <Button color="stroke" onClick={edit}>
              Sửa
            </Button>
            {!!remove && (
              <Button color="secondary" onClick={remove}>
                Xóa
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default AddressItem
