/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@mui/material'
import { isEmpty } from 'lodash'
import { isMobile } from 'utils'
import { MenuItem } from 'types'
import { CATEGORY_TYPES } from 'configs/constants'
import { useBreadcrumbs } from 'store/breadcrumbs'
import { NavLink } from 'components'
import { useCategories } from 'store/categories'

export default function AppMenu() {
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<any>(null)
  const [selectedSubMenu, setSelectedSubMenu] = useState<MenuItem[]>([])
  const isMatch = useMediaQuery('(max-width: 860px)')
  const { getCategories, menu } = useCategories()
  const { breadcrumbs } = useBreadcrumbs()

  let engines = menu || []

  useEffect(() => {
    if (!isMatch) setSelectedSubMenu([])
  }, [isMatch])

  /**
   * Handle open user menu
   *
   * @param subMenu Sub menu
   * @param e DOM Event
   */
  const handleOpenUserMenu = (subMenu: MenuItem[]) => (e: React.MouseEvent<HTMLLIElement>) => {
    if (isMatch) setSelectedSubMenu(subMenu)
    setAnchorEl(e?.currentTarget)
  }

  /**
   * Handle close user menu
   */
  const handleCloseUserMenu = () => {
    setAnchorEl(null)
  }

  /**
   * Handle render sub menu item
   *
   * @param subMenuItem Sub menu item
   */
  const renderSubMenu = (subMenuItem: any, isMenufacturer: boolean) => {
    const { linkFull: path } = subMenuItem || {}

    return (
      <NavLink to={path} onClick={getCategories}>
        <span className="group">
          {isMenufacturer && (
            <img
              className="icon"
              src={subMenuItem.image_url}
              width={37}
              height={10}
              style={{ minWidth: 37 }}
            />
          )}
          <span className="AppMenu-subList__item">{t(subMenuItem.name)}</span>
        </span>
        <span className="Ic-next next" />
      </NavLink>
    )
  }

  if (engines.length > 0)
    engines = [
      ...engines.filter((i) => i.type === CATEGORY_TYPES.CATEGORY).slice(0, 4),
      engines[engines.length - 1],
    ]

  return (
    <div className="AppMenu-container">
      <ul className={`AppMenu-list ${isMobile() && 'mobile'}`}>
        {engines.map((menuItem: any, index: number) => {
          const { name = '', sub: subMenu = [], type, linkFull: path = '', linkSeo } = menuItem
          const isMenufacturer = type === CATEGORY_TYPES.MANUFACTURER

          const active = breadcrumbs?.some((item: any) => {
            const lastPathArr = item.path?.split('/')
            const lastPath = lastPathArr?.[lastPathArr.length - 1]
            return lastPath === linkSeo
          })

          return (
            <li key={index} onClick={handleOpenUserMenu(subMenu)}>
              <NavLink
                to={path}
                exact
                activeClassName="active"
                className={active ? 'isActive' : ''}
                onClick={getCategories}
              >
                {t(name)}
              </NavLink>
              {!isEmpty(subMenu) && (
                <ul className="AppMenu-subList">
                  {subMenu?.map((subMenuItem: any, subIndex: number) => (
                    <li key={subIndex}>{renderSubMenu(subMenuItem, isMenufacturer)}</li>
                  ))}
                </ul>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}
