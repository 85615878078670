import { Button } from 'components'
import { Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AUTH_TYPES } from 'configs/constants'
import { useAuth } from 'store/auth'

export default function DonePassword() {
  const { t } = useTranslation()
  const { signIn, modal, saveInfo, signInSuccess, updateAuth } = useAuth()
  const authType = modal.type
  const isSignUp = authType === AUTH_TYPES.SIGN_UP

  const handleClick = () => {
    if (isSignUp) {
      // Auto sign in with data get from previous step
      signInSuccess(saveInfo)
    } else {
      // Go to form sign in with password
      signIn(true)
    }
    // Clear all saved auth info from previous step
    updateAuth({ saveInfo: null })
  }

  return (
    <Stack spacing={2}>
      <span className="label">
        {authType === AUTH_TYPES.SIGN_UP
          ? 'Bạn đã thiết lập mật khẩu thành công'
          : 'Bạn đã đổi mật khẩu thành công '}
      </span>
      <Box>
        <Button onClick={handleClick} fullWidth>
          {t('sign_in_now')}
        </Button>
      </Box>
    </Stack>
  )
}
