import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AppSearchSuggestions from './AppSearchSuggestions'
import { addHistorySearch, fetchHistorySearch, getHistorySearch, removeHistorySearch } from 'utils'
import qs from 'qs'
import { SuggestionsType } from 'types'
import { DEFAULT_SUGGESTIONS } from 'configs/constants'
import { useAuth } from 'store/auth'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import { useHistory } from 'store/confirmRedirect'

interface PropTypes {
  className?: string
}

export default function AppSearch(props: PropTypes) {
  const { t } = useTranslation()
  const { className = '' } = props

  const [open, setOpen] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [historySearch, setHistorySearch] = useState(getHistorySearch())
  const [suggestions, setSuggestions] = useState<SuggestionsType>(DEFAULT_SUGGESTIONS)
  const { isAuth } = useAuth()
  const { apiGet, apiDelete, apiPost } = useApis()

  const formRef = useRef<any>(null)
  const inputRef = useRef<any>(null)
  const suggestionTimeOut = useRef<any>()
  const history = useHistory()

  const isOpen = useMemo(
    () =>
      open &&
      (keyword
        ? suggestions.groups_recommend.length > 0 || suggestions.products_recommend.length > 0
        : historySearch.length > 0),
    [open, suggestions, historySearch, keyword]
  )

  /**
   * Handle change keyword
   *
   * @param e DOM Event
   */
  const handleChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value = '' } = e.target

    setKeyword(value)
    clearTimeout(suggestionTimeOut.current)
    const trimmedValue = value.trim()

    if (!trimmedValue) {
      setSuggestions(DEFAULT_SUGGESTIONS)
      return
    }
    suggestionTimeOut.current = setTimeout(() => {
      apiGet(apiUrls.getRecommendProducts(), { keyword: trimmedValue }, ({ status, data }) => {
        if (status) {
          if (!open) setOpen(true)
          setSuggestions(data)
        }
      })
    }, 300)
  }

  /**
   * Close suggestion menu search
   */
  const handleCloseMenu = () => {
    setOpen(false)
  }

  /**
   * Handle search
   *
   * @param keyword
   */
  const handleSearch = (newKeyword?: string, category?: string) => {
    const k = newKeyword || keyword
    setHistorySearch(addHistorySearch(k))
    if (isAuth) apiPost(apiUrls.history(), { keyword: k })
    handleCloseMenu()
    inputRef.current?.blur?.()

    // Use qs to handle special character
    const queryString = qs.stringify({
      keyword: k,
      category,
    })
    history.push(`/danh-sach-san-pham?${queryString}`)
  }

  const handleSubmitSearch = (e: React.FormEvent) => {
    e.preventDefault()
    if (!keyword.trim()) return
    handleSearch(keyword)
  }

  /**
   * Remove a keyword from list
   * @param e
   * @param keyword
   */
  const handleRemoveHistory = (e: React.MouseEvent<HTMLElement>, keyword: string) => {
    e.stopPropagation()
    setHistorySearch(removeHistorySearch(keyword))
    if (isAuth) apiDelete(apiUrls.history(), { keyword })
  }

  // Sync history search when signed in
  useEffect(() => {
    if (!isAuth) return

    apiGet(apiUrls.history(), {}, ({ status, data }) => {
      if (status) {
        setHistorySearch(fetchHistorySearch(data.keywords))
      }
    })
  }, [isAuth])

  return (
    <div className={`AppSearch-container ${className} ${open && 'open'}`}>
      <form className="AppSearch-form" onSubmit={handleSubmitSearch} ref={formRef}>
        <input
          ref={inputRef}
          className="AppSearch-keyword"
          value={keyword}
          maxLength={100}
          onChange={handleChangeKeyword}
          placeholder={t('searchKeyword')}
          onClick={() => setOpen(true)}
        />
        {!!keyword && (
          <button type="button" className="AppSearch-btnSearch" onClick={() => setKeyword('')}>
            <span className="mn-icon-close" />
          </button>
        )}
        <button type="submit" className="AppSearch-btnSearch mr-16">
          <span className="mn-icon-search" />
        </button>
        <AppSearchSuggestions
          open={isOpen}
          keyword={keyword}
          suggestions={suggestions}
          historySearch={historySearch}
          anchorEl={formRef.current}
          onClose={handleCloseMenu}
          onRemoveHistory={handleRemoveHistory}
          onSearch={handleSearch}
        />
      </form>
    </div>
  )
}
