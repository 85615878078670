/* eslint-disable */
import React, { Suspense, useEffect, useMemo, useState } from 'react'
import './index.scss'
import AdminSidebar from './AdminSidebar'
import { Redirect, Switch } from 'react-router'
import AdminPageRoute from '../pageRoutes/AdminPageRoute'
import {
  AdminContext,
  ADMIN_ALL_ROLES,
  LS_SHOWN_COLUMNS,
  MAX_PAGE_SIZE,
  priorityRoutes,
  SHOW_COLUMNS_DEFAULT,
} from 'configs/constants'
import { Loading } from '..'
import { Method } from 'axios'
import { useApis } from 'services/api'
import {
  AdminGroupType,
  AdminPermissionType,
  ErrorCallbackType,
  ObjectType,
  SuccessCallbackType,
} from 'types'
import { useModalConfirm } from 'store/modalConfirm'
import { isFunction } from 'lodash'
import './index.scss'
import { useCategories } from 'store/categories'
import { apiUrls } from 'configs/apis'
import { ROUTE_PERMISSIONS } from 'configs/constants'
import { AddEditOrders } from 'components/screens/admin/orders'
import OrderHistoryQuotes from 'components/screens/admin/orderHistoryQuotes/OrderHistoryQuotes'
import EditUser from 'components/screens/admin/users/EditUser'
import BranchUsing from 'components/screens/admin/branch/BranchUsing'
import ArticleUsing from 'components/screens/admin/articles/ArticleUsing'
import GroupUsing from 'components/screens/admin/groups/GroupUsing'
import QuestionsUsing from 'components/screens/admin/questions/QuestionsUsing'
import DocumentTechnicalUsing from 'components/screens/admin/technical/documentTechnical/DocumentTechnicalUsing'
import ManufacturerUsing from 'components/screens/admin/categories/ManufacturerUsing'
import CategoriesUsing from 'components/screens/admin/categories/CategoriesUsing'
import AdminManagementUsing from 'components/screens/admin/users/AdminManagementUsing'
import DocumentCategoriesUsing from 'components/screens/admin/technical/documentCategories/DocumentCategoriesUsing'
import UserUsing from 'components/screens/admin/users/UserUsing'
import ProductsUsing from 'components/screens/admin/products/ProductsUsing'
import CouponsUsing from 'components/screens/admin/coupons/CouponsUsing'
import ProfileUsing from 'components/screens/admin/profiles/ProfileUsing'
import OrderUsing from 'components/screens/admin/orders/orderUsing/OrderUsing'
import DashboardUsing from 'components/screens/admin/dashboard/DashboardUsing'
import AboutUsManagement from 'components/screens/admin/aboutUs/AboutUsManagement'
import VisionManagement from 'components/screens/admin/aboutUs/VisionManagement'
import IntroduceManagemnt from 'components/screens/admin/aboutUs/IntroduceManagemnt'

const Dashboard = React.lazy(() => import('pages/admin/dashboard'))
const Orders = React.lazy(() => import('pages/admin/orders'))
const EditOrder = React.lazy(() => import('pages/admin/editOrder'))
const AddOrderThisUser = React.lazy(() => import('pages/admin/addOrderThisUser'))
const EditOrderThisUser = React.lazy(() => import('pages/admin/editOrderThisUser'))
const Admins = React.lazy(() => import('pages/admin/admins'))
const Users = React.lazy(() => import('pages/admin/users'))
const UserDetail = React.lazy(() => import('pages/admin/userDetail'))
const UserOrders = React.lazy(() => import('pages/admin/userOrders'))
const Profile = React.lazy(() => import('pages/admin/profile'))
const ChangePassword = React.lazy(() => import('pages/admin/changePassword'))
const Products = React.lazy(() => import('pages/admin/product'))
const OrderDetail = React.lazy(() => import('pages/admin/orderDetail'))
const Quotation = React.lazy(() => import('pages/admin/orderQuotation'))
const ProductDetail = React.lazy(() => import('pages/admin/productDetail'))
const Coupons = React.lazy(() => import('pages/admin/coupons'))
const Branchs = React.lazy(() => import('pages/admin/branch'))
const AddProduct = React.lazy(() => import('pages/admin/addProduct'))
const EditProduct = React.lazy(() => import('pages/admin/editProduct'))
const Categories = React.lazy(() => import('pages/admin/categories'))
const Manufacturers = React.lazy(() => import('pages/admin/manufacturers'))
const Groups = React.lazy(() => import('pages/admin/groups'))
const GroupDetail = React.lazy(() => import('pages/admin/groupDetail'))
const Permissions = React.lazy(() => import('pages/admin/permissions'))
const DocumentCategories = React.lazy(() => import('pages/admin/categoriesDocument'))
const DocumentTechnical = React.lazy(() => import('pages/admin/documentTechnical'))
const Articles = React.lazy(() => import('pages/admin/articles'))
const EventDetail = React.lazy(() => import('pages/admin/eventDetail'))
const Questions = React.lazy(() => import('pages/admin/questions'))
const QuestionsDetail = React.lazy(() => import('pages/admin/questionDetail'))

const routeCategory = {
  component: Categories,
  title: 'Quản lý danh mục sản phẩm',
  hideTitle: true,
}

const routeManufacturer = {
  component: Manufacturers,
  title: 'Quản lý hãng sản xuất và model',
  hideTitle: true,
}

const {
  DASHBOARD,
  ORDERS,
  CUSTOMERS,
  PRODUCTS,
  CATEGORIES,
  MANUFACTURERS,
  ARTICLES,
  DOCUMENTS_CATEGORIES,
  DOCUMENTS,
  QUESTIONS,
  ADMINS,
  GROUPS,
  ROLES,
  COUPONS,
  BRANCHS,
  ABOUT_US,
  VISION,
} = ROUTE_PERMISSIONS

const routes = [
  { path: 'profile', component: Profile, hideTitle: true, title: 'admin_profile' },
  { path: 'profile/using', component: ProfileUsing, hideTitle: true, title: 'admin_profile' },
  {
    path: 'dashboard',
    component: Dashboard,
    title: 'dashboard',
    hideTitle: true,
    permission: DASHBOARD,
  },
  {
    path: 'dashboard/using',
    component: DashboardUsing,
    hideTitle: true,
    title: 'dashboard',
    permission: DASHBOARD,
  },
  { path: 'orders', component: Orders, title: 'order_management', permission: ORDERS },
  {
    path: 'orders/using',
    component: OrderUsing,
    title: 'order_management',
    hideTitle: true,
    permission: ORDERS,
  },
  { path: 'users', component: Users, title: 'user_management', permission: CUSTOMERS },
  {
    path: 'users/using',
    component: UserUsing,
    title: 'user_management',
    permission: CUSTOMERS,
    hideTitle: true,
  },
  {
    path: 'users/:id',
    component: UserDetail,
    title: 'Thông tin Người dùng',
    hideTitle: true,
    permission: CUSTOMERS,
  },
  {
    path: 'users/edit/:id',
    component: EditUser,
    title: 'Sửa Người dùng',
    hideTitle: true,
    permission: CUSTOMERS,
  },
  {
    path: 'users/:id/orders',
    component: UserOrders,
    title: 'Đơn hàng của Người dùng',
    hideTitle: true,
    permission: CUSTOMERS,
  },
  {
    path: 'users/orders/:id',
    component: OrderDetail,
    title: 'order_detail',
    hideTitle: true,
    permission: CUSTOMERS,
  },
  {
    path: 'users/order/add/:userId',
    component: AddOrderThisUser,
    title: 'add_orders',
    hideTitle: true,
    permission: ORDERS,
  },
  {
    path: 'users/order/edit/:userId/:id',
    component: EditOrderThisUser,
    title: 'Sửa đơn hàng',
    hideTitle: true,
    permission: ORDERS,
  },
  { path: 'products', component: Products, title: 'product_management', permission: PRODUCTS },
  { path: 'news', component: Articles, title: 'news_management', permission: ARTICLES },
  {
    path: 'news/news-using',
    component: ArticleUsing,
    title: 'news_management_using',
    permission: ARTICLES,
    hideTitle: true,
  },
  {
    path: 'news/:id',
    component: EventDetail,
    title: 'news_management',
    hideTitle: true,
    permission: ARTICLES,
  },
  { path: 'questions', component: Questions, title: 'question_management', permission: QUESTIONS },
  {
    path: 'questions/questions-using',
    component: QuestionsUsing,
    title: 'question_management_using',
    permission: QUESTIONS,
    hideTitle: true,
  },
  {
    path: 'questions/:id',
    component: QuestionsDetail,
    title: 'question_management',
    permission: QUESTIONS,
    hideTitle: true,
  },
  { path: 'admins', component: Admins, title: 'admin_management', permission: ADMINS },
  {
    path: 'admins/using',
    component: AdminManagementUsing,
    title: 'admin_management',
    hideTitle: true,
    permission: ADMINS,
  },
  { path: 'coupons', component: Coupons, title: 'coupon_management', permission: COUPONS },
  {
    path: 'coupons/using',
    component: CouponsUsing,
    title: 'coupon_management',
    hideTitle: true,
    permission: COUPONS,
  },
  { path: 'branchs', component: Branchs, title: 'branch_management', permission: BRANCHS },
  {
    path: 'branchs/branch-using',
    component: BranchUsing,
    title: 'branch_management_guide_using',
    permission: BRANCHS,
    hideTitle: true,
  },
  { path: 'change-password', component: ChangePassword, title: 'change_password' },
  {
    path: 'groups',
    component: Groups,
    title: 'group_management',
    permission: GROUPS,
  },
  {
    path: 'groups/groups-using',
    component: GroupUsing,
    title: 'group_management_using',
    permission: GROUPS,
    hideTitle: true,
  },
  {
    path: 'groups/:id',
    component: GroupDetail,
    title: 'permission_management',
    hideTitle: true,
    permission: GROUPS,
  },
  {
    path: 'permissions',
    component: Permissions,
    title: 'permission_management',
    hideTitle: true,
    permission: ROLES,
  },
  {
    path: 'document-categories',
    component: DocumentCategories,
    title: 'document_category_management',
    permission: DOCUMENTS_CATEGORIES,
  },
  {
    path: 'document-categories/using',
    component: DocumentCategoriesUsing,
    title: 'document_category_management',
    hideTitle: true,
    permission: DOCUMENTS_CATEGORIES,
  },
  {
    path: 'technical-documents',
    component: DocumentTechnical,
    title: 'technical_document_management',
    permission: DOCUMENTS,
  },
  {
    path: 'technical-documents/using',
    component: DocumentTechnicalUsing,
    title: 'technical_document_management_using',
    permission: DOCUMENTS,
    hideTitle: true,
  },
  {
    path: 'orders/add',
    component: AddEditOrders,
    title: 'add_orders',
    hideTitle: true,
    permission: ORDERS,
  },
  {
    path: 'orders/edit/:id',
    component: EditOrder,
    title: 'Sửa đơn hàng',
    hideTitle: true,
    permission: ORDERS,
  },
  {
    path: 'orders/:id',
    component: OrderDetail,
    title: 'order_detail',
    hideTitle: true,
    permission: ORDERS,
  },
  {
    path: 'orders/quotation/:id',
    component: Quotation,
    title: 'order_detail',
    hideTitle: true,
    permission: ORDERS,
  },
  {
    path: 'orders/:id/history-quotes',
    component: OrderHistoryQuotes,
    title: 'Lịch sử in báo giá - Đơn hàng',
    hideTitle: true,
    permission: ORDERS,
  },
  {
    path: 'products/add',
    component: AddProduct,
    title: 'Thêm sản phẩm',
    hideTitle: true,
    permission: PRODUCTS,
  },
  {
    path: 'products/edit/:id',
    component: EditProduct,
    title: 'Sửa sản phẩm',
    hideTitle: true,
    permission: PRODUCTS,
  },
  {
    path: 'products/using',
    component: ProductsUsing,
    title: 'Xem sản phẩm',
    hideTitle: true,
    permission: PRODUCTS,
  },
  {
    path: 'products/:id',
    component: ProductDetail,
    title: 'Xem sản phẩm',
    hideTitle: true,
    permission: PRODUCTS,
  },
  { path: 'categories', ...routeCategory, permission: CATEGORIES },
  {
    path: 'categories/using',
    component: CategoriesUsing,
    title: 'Quản lý hãng sản xuất và model',
    hideTitle: true,
    permission: CATEGORIES,
  },
  { path: 'categories/:id', ...routeCategory, permission: CATEGORIES },
  {
    path: 'manufacturers/using',
    component: ManufacturerUsing,
    title: 'Quản lý hãng sản xuất và model',
    hideTitle: true,
    permission: MANUFACTURERS,
  },
  { path: 'manufacturers', ...routeManufacturer, permission: MANUFACTURERS },
  { path: 'manufacturers/:id', ...routeManufacturer, permission: MANUFACTURERS },
  { path: 'manufacturers/:id/:modelId', ...routeManufacturer, permission: MANUFACTURERS },
  {
    path: 'about-us',
    component: AboutUsManagement,
    title: 'about_us_management',
    permission: ABOUT_US,
  },
  {
    path: 'vision',
    component: VisionManagement,
    title: 'vision_management',
    permission: VISION,
  },
  {
    path: 'introduce',
    component: IntroduceManagemnt,
    title: 'introduce_management',
    permission: VISION,
  },
]

export default function AdminLayout() {
  const { apiGet, apiPost, requestApi, apiPut } = useApis()
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()
  const { getCategories } = useCategories()

  const [loading, setLoading] = useState(false)
  const [groups, setGroups] = useState<AdminGroupType[]>([])
  const [roles, setRoles] = useState<AdminPermissionType[]>([])
  const [myRoles, setMyRoles] = useState<{ items: AdminPermissionType[]; loading: boolean }>({
    items: [],
    loading: true,
  })

  const getGroups = () => {
    apiPost(
      apiUrls.adminGroups('search'),
      {
        page: 1,
        page_size: MAX_PAGE_SIZE,
        sort_by: 'name',
        order: 'asc',
      },
      ({ status, data }) => {
        if (status) setGroups(data.items)
      }
    )
  }

  useEffect(() => {
    apiGet(apiUrls.adminSetting(), {}, ({ status, data }) => {
      if (status) {
        localStorage.setItem(LS_SHOWN_COLUMNS, data.display_column?.replace(/'/g, '"'))
      } else if (!status && !data?.display_column) {
        apiPut(
          apiUrls.adminSetting(),
          { display_column: SHOW_COLUMNS_DEFAULT },
          () => {},
          () => {},
          true
        )
        localStorage.setItem(
          LS_SHOWN_COLUMNS,
          JSON.stringify(SHOW_COLUMNS_DEFAULT).replace(/'/g, '"')
        )
      }
    })
  }, [])

  const getRoles = () => {
    apiGet(
      apiUrls.adminPermissions(),
      {
        page: 1,
        page_size: MAX_PAGE_SIZE,
        // is_all: true,
      },
      ({ status, data }) => {
        if (status) setRoles(data.items)
      }
    )

    apiGet(
      apiUrls.adminMyPermissions(),
      {
        page: 1,
        page_size: MAX_PAGE_SIZE,
      },
      ({ status, data }) => {
        if (status) setMyRoles({ items: data.items, loading: false })
        else setMyRoles({ items: [], loading: false })
      },
      () => setMyRoles({ items: [], loading: false })
    )
  }

  useEffect(() => {
    getCategories()
    getGroups()
    getRoles()
  }, [])

  const mappingGroup = useMemo(() => {
    const mapping: ObjectType = {}
    groups.forEach((item) => {
      mapping[item.id || ''] = item
    })
    return mapping
  }, [groups])

  const mappingRole = useMemo(() => {
    const mapping: ObjectType = {}
    roles.forEach((item) => {
      mapping[item.id || ''] = item
    })
    return mapping
  }, [roles])

  const callApi = ({
    method,
    url,
    data,
    successCallback,
    errorCallback,
    loading,
    popup,
    popupIds,
    forceShowToast,
    sure,
    contentType,
  }: {
    method: Method
    url: string
    data?: any
    successCallback?: SuccessCallbackType
    errorCallback?: ErrorCallbackType
    loading?: boolean
    popup?: (() => void) | boolean
    popupIds?: { id: string; confirm: () => void; small?: boolean }[]
    forceShowToast?: boolean
    sure?: {
      cancel?: {
        text?: string
        action?: any
      }
      confirm?: {
        text?: string
        action?: any
      }
    }
    contentType?: any
  }) => {
    if (loading) setLoading(true)
    requestApi(
      method,
      url,
      data || {},
      {
        successCallback: (res: any) => {
          if (loading) setLoading(false)
          const { id, status, text } = res
          if (id === 'is_sure') {
            const { cancel, confirm } = sure || {}
            showModalConfirm({
              title: 'Thông báo',
              content: text,
              cancel: {
                text: cancel?.text || 'Hủy',
                action: () => {
                  hideModalConfirm()
                  cancel?.action?.()
                },
              },
              confirm: {
                text: confirm?.text || 'Tạo',
                action: () => {
                  hideModalConfirm()
                  callApi({
                    method,
                    url,
                    data: { ...data, is_sure: true },
                    successCallback,
                    errorCallback,
                    loading,
                    popup,
                    popupIds,
                    forceShowToast,
                  })
                  confirm?.action?.()
                },
              },
              small: true,
              center: true,
            })
          }
          if (popupIds) {
            const foundPopup = popupIds.find((item) => item.id === id)
            if (foundPopup) {
              showModalConfirm({
                title: 'Thông báo',
                content: text,
                confirm: {
                  text: 'Đã hiểu',
                  action: () => {
                    hideModalConfirm()
                    foundPopup.confirm()
                  },
                },
                small: foundPopup.small,
              })
            }
          } else if (popup && !status) {
            showModalConfirm({
              title: 'Thông báo',
              content: text,
              confirm: {
                text: 'Đã hiểu',
                action: () => {
                  hideModalConfirm()
                  if (isFunction(popup)) popup()
                },
              },
            })
          }
          successCallback?.(res)
        },
        errorCallback: (res: any) => {
          if (loading) setLoading(false)
          errorCallback?.(res)
        },
        hideToast: false,
        hideToastError: !popup,
        forceShowToast,
      },
      false,
      contentType
    )
  }

  // If permissions list has item permission name
  // And coressponding type => show button create/update/delete
  const hasPermission = (permission: string) => {
    return (
      !!myRoles.items.find(
        (item) => item.key === ADMIN_ALL_ROLES.SUPER_ADMIN || item.key === permission
      ) || false
    )
  }

  const filteredRoutes = useMemo(() => {
    const newMyRoles = myRoles.items.map((j) => j.key)

    return routes.filter(
      (item) =>
        !item.permission ||
        newMyRoles.includes(ADMIN_ALL_ROLES.SUPER_ADMIN) ||
        item.permission.some((i) => newMyRoles.includes(i))
    )
  }, [routes, myRoles])

  if (myRoles.loading) return <Loading fullScreen />

  const defaultRoute = priorityRoutes.find((route) =>
    myRoles.items.some((x) => x.key === route.permission)
  )?.path

  return (
    <AdminContext.Provider
      value={{
        loading,
        startLoading: () => setLoading(true),
        stopLoading: () => setLoading(false),
        callApi,
        groups,
        getGroups,
        mappingGroup,
        roles,
        mappingRole,
        myRoles: myRoles.items,
        hasPermission,
      }}
    >
      <div className="Admin-layout">
        <AdminSidebar />
        <div className="Admin-main">
          <div className="Admin-container">
            <Suspense fallback={loading}>
              <Switch>
                {filteredRoutes.map(({ path, component, title, hideTitle }) => (
                  <AdminPageRoute
                    exact
                    key={path}
                    path={`/admin/${path}`}
                    component={component}
                    title={title}
                    hideTitle={hideTitle}
                  />
                ))}
                <Redirect to={`/admin/${defaultRoute}`} />
              </Switch>
            </Suspense>
          </div>
        </div>
      </div>
      {loading && <Loading fullScreen overlay />}
    </AdminContext.Provider>
  )
}
