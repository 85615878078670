// MODAL CONFIRM
export const SHOW_MODAL_CONFIRM = 'SHOW_MODAL_CONFIRM'
export const HIDE_MODAL_CONFIRM = 'HIDE_MODAL_CONFIRM'

// AUTH
export const UPDATE_AUTH = 'UPDATE_AUTH'
export const CLEAR_AUTH = 'CLEAR_AUTH'
export const UPDATE_AUTH_MODAL = 'UPDATE_AUTH_MODAL'

// TOASTS
export const CREATE_TOAST = 'CREATE_TOAST'
export const REMOVE_TOAST = 'REMOVE_TOAST'

// BREADCRUMBS
export const UPDATE_BREADCRUMBS = 'UPDATE_BREADCRUMBS'
export const UPDATE_BREADCRUMBS_RIGHT_MENU = 'UPDATE_BREADCRUMBS_RIGHT_MENU'

// SHOPPING CART
export const CART_ADD_ITEM = 'CART_ADD_ITEM'
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM'
export const CART_LOADING = 'CART_LOADING'
export const CART_FETCH_ITEMS = 'CART_FETCH_ITEMS'
export const CART_CLEAR_ITEMS = 'CART_CLEAR_ITEMS'
export const CART_UPDATE_ITEM_ID = 'CART_UPDATE_ITEM_ID'
export const CART_BUY_NOW = 'CART_BUY_NOW'
export const CART_UPDATE_BUY_LATER = 'CART_UPDATE_BUY_LATER'
export const CART_UPDATE_ITEM_QUANTITY = 'CART_UPDATE_ITEM_QUANTITY'
export const CART_UPDATE = 'CART_UPDATE'
export const CART_ORDER_SUCCESS = 'CART_ORDER_SUCCESS'
export const CART_UPDATE_ITEMS_QUANTITY = 'CART_UPDATE_ITEMS_QUANTITY'
export const CART_ADD_ALL_ITEMS = 'CART_ADD_ALL_ITEMS'
export const CHANGE_CART_DATA = 'CHANGE_CART_DATA'

// APPLICATION
export const APP_UPDATE_BANNERS = 'APP_UPDATE_BANNERS'
export const APP_UPDATE_BEST_SELLER = 'APP_UPDATE_BEST_SELLER'
export const APP_RESET_ADVERTISEMENT = 'APP_RESET_ADVERTISEMENT'
export const APP_FETCH_AREA = 'APP_FETCH_AREA'
export const APP_GET_ABOUT_US = 'APP_GET_ABOUT_US'

// CATEGORIES
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'
export const UPDATE_MAIN_MENU = 'UPDATE_MAIN_MENU'
export const UPDATE_SUB_MENU = 'UPDATE_SUB_MENU'

// CONFIRM REDIRECT
export const PREVENT_REDIRECT = 'PREVENT_REDIRECT'
export const ALLOW_REDIRECT = 'ALLOW_REDIRECT'

// MOBILE
export const RESOLUTION_MOBILE = 'RESOLUTION_MOBILE'
