import { MAX_QUANTITY, PRODUCT_TAGS } from 'configs/constants'
import { AddProductType, CartItemType, ObjectType } from 'types'

/**
 * Add item to cart
 *
 * @param cartItems Cart items
 * @param cardItemData Cart item data
 */
export const addItemToCart = (cartItems: CartItemType[] = [], cartItemAdd: AddProductType) => {
  const { product, isRefresh, force } = cartItemAdd
  const { inventory_number = MAX_QUANTITY, id = '', quantity = 1 } = product
  let isExist = false

  // Update cart item if exist in cart
  const newCartItems = cartItems.map((item: CartItemType) => {
    if (item?.product_id === id) {
      isExist = true
      // if user not refresh quantity
      let mergeQuantity = !isRefresh ? item.quantity + quantity : quantity
      mergeQuantity =
        mergeQuantity < 1 ? 1 : mergeQuantity > inventory_number ? inventory_number : mergeQuantity

      if (force) mergeQuantity = quantity
      return {
        ...item,
        quantity: mergeQuantity,
        product,
      }
    }

    return item
  })

  // Add new item into shopping cart
  if (!isExist) newCartItems.push({ product_id: id, product, quantity })

  return newCartItems
}

/**
 * Get product tag
 *
 * @param productData Product data
 */
export const getProductTag = (productData?: any) => {
  const { is_best_selling, is_sale, is_new } = productData || {}
  const tags = Object.keys(PRODUCT_TAGS)

  const priorityTagIndex = [is_best_selling, is_sale, is_new].findIndex((tagValue) => tagValue)

  return tags[priorityTagIndex] || ''
}

/**
 * Convert viewed products data
 *
 * @param viewedProducts Viewed products
 */
export const convertViewedProductsData = (viewedProducts: any = []) => {
  return viewedProducts.map((viewdProductItem: ObjectType) => ({ ...viewdProductItem?.product }))
}
