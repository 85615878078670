/*eslint-disable*/
import { CREATE_ADDRESS_TYPES, DELIVERY_METHODS, PAYMENT_METHODS } from 'configs/constants'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'store/auth'
import { useHistory as useHistoryCustom } from 'store/confirmRedirect'
import { useShoppingCart } from 'store/shoppingCart'
import { getPaymentLS, removePaymentLS, validateEmail } from 'utils'
import FormAddress from './FormAddress'
import FormPayment from './FormPayment'
import FormSummary from './FormSummary'
import { updatePaymentLS, validatePhone } from 'utils'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import { orderBy } from 'lodash'
import { PaymentType } from 'types'

interface Props {
  step: number
  paymentInfor?: any
  handleNext: any
  setStep: any
  vat?: any
  showDropDown?: any
  setShowDropDown?: any
  vatDefault?: any
  chooseVat?: any
  setChooseVat?: any
  functionFormik?: any
  setFormikState?: any
  codeError?: string
  childrenSummary?: JSX.Element
  setDeliveryMethod: any
  deliveryMethod: any
  finalPrice?: string | number
}

const { GET_AT_ADDRESS, GET_AT_STORE } = DELIVERY_METHODS
const { NEW_ADDRESS, AVAILABLE_ADDRESS } = CREATE_ADDRESS_TYPES
const { TRANSFER, PAYMENT_ON_DELIVERY, USE_MOMO } = PAYMENT_METHODS

const isString = Yup.string().required()
const isObject = Yup.object().defined()

const validateName = {
  full_name: isString,
  phone: Yup.string()
    .required()
    .min(1, 'phone_format_is_incorrect')
    .matches(validatePhone(), 'phone_format_is_incorrect'),
}

/***
 * validate input
 */
const validationSchema = () =>
  Yup.object().shape({
    company_name: Yup.string().required(),
    tax_identification_number: Yup.string().required(),
    company_address: Yup.string().required(),
    email: Yup.string().matches(validateEmail(), 'email_is_invalid'),
  })

const FormWrapper: React.FC<Props> = (props: Props) => {
  const { isAuth, full_name, phone } = useAuth()
  const { allowRedirect, preventRedirect } = useHistoryCustom()
  const {
    step,
    setStep,
    paymentInfor,
    handleNext,
    vat,
    showDropDown,
    setShowDropDown,
    chooseVat = {},
    setChooseVat,
    setFormikState,
    codeError = '',
    childrenSummary,
    setDeliveryMethod,
    deliveryMethod,
    finalPrice,
  } = props
  const history = useHistory()
  const { orderSuccess, cartItems, discountCode } = useShoppingCart()
  const { apiGet, requestApi, apiPut } = useApis()

  const [loading, setLoading] = useState(false)

  let form: any = ''

  // Step 1
  const [stores, setStores] = useState([])
  const [addressType, setAddressType] = useState(NEW_ADDRESS)
  const [addresses, setAddresses] = useState([])
  const [selectedAddress, setSelectedAddress] = useState('')
  const { order_code: orderCode, id, order_delivery_address: orderDeliveryAddress } = getPaymentLS()
  const { phone: phoneUser } = orderDeliveryAddress || {}
  const content: any = `${phoneUser} - ${orderCode}`

  const createAddress = useRef<any>(null)
  const createVAT = useRef<any>(null)

  const savedData = {
    deliveryMethod,
    setDeliveryMethod,
    stores,
    setStores,
    addressType,
    setAddressType,
    addresses,
    setAddresses,
    selectedAddress,
    setSelectedAddress,
    loading,
    codeError,
  }

  // Step 2
  const [value, setValue] = useState('banking')
  const [checked, setChecked] = useState(false)
  const [copyContent, setCopyContent] = useState(false)
  const [copyCode, setCode] = useState(false)
  const [bankTranfer, setBankTranfer] = useState<any>(0)
  const [valueVat, setValueVat] = useState(isAuth ? '1' : '0')
  const [loadingPayment, setLoadingPayment] = useState(false)
  const [objField, setObjField] = useState({
    company_name: '',
    tax_identification_number: '',
    company_address: '',
    email: '',
  })

  const {
    bank_name: bankName,
    account_name: account,
    bank_number: bankNumber,
    momo_phone: momoPhone,
  } = bankTranfer || {}

  let schema

  // Set schema follow by delivery method, create new address or select available address
  if (deliveryMethod === GET_AT_STORE) {
    schema = Yup.object().shape({
      ...validateName,
      selected_store: Yup.object().defined(),
    })
  } else {
    if (addressType === NEW_ADDRESS) {
      schema = Yup.object().shape({
        ...validateName,
        type: isString,
        address: isString,
        city: isObject,
        district: isObject,
        town: isObject,
      })
    } else {
      schema = Yup.object().shape({
        ...validateName,
      })
    }
  }

  const formik = useFormik({
    initialValues: {
      full_name: full_name || '',
      phone: phone || '',
      type: '',
      address: '',
      city: null,
      district: null,
      town: null,
      selected_store: null,
      note: '',
      discount_code: '',
    } as any,
    validationSchema: schema,
    onSubmit: (values) => {
      let params: any
      let newAddress
      const newNote = (values.note || '').trim()

      if (deliveryMethod === GET_AT_STORE) {
        // If get at store, need store address and name, phone of user

        const { address, phone, store_name, id } = values.selected_store || {}
        params = {
          order_store_address: {
            address,
            phone,
            store_name,
            store_address_id: id,
          },
          order_delivery_address: {
            type: 4,
            full_name: values.full_name?.trim(),
            phone: values.phone?.trim(),
            note: (values.note || '').trim(),
            address: '',
            city: '',
            district: '',
            town: '',
          },
        }
      } else {
        const newSelectedAddress =
          addressType === AVAILABLE_ADDRESS
            ? addresses.find((item: any) => item.id === selectedAddress)
            : null
        // If select available address => get address in selected address
        let { full_name, phone, type, city, district, town, address, note } =
          newSelectedAddress || ({} as any)

        if (!note) note = ''

        // If create a new address => address in form
        if (!newSelectedAddress) {
          full_name = values.full_name?.trim()
          phone = values.phone?.trim()
          type = values.type
          address = values.address?.trim()
          city = values.city.name?.trim()
          district = values.district.name?.trim()
          town = values.town.name?.trim()
          note = (values.note || '').trim()
        }

        newAddress = {
          full_name,
          phone,
          address,
          city,
          district,
          town,
          type,
          note,
        }

        params = {
          order_delivery_address: newAddress,
        }
      }

      setLoading(true)

      params = {
        ...params,
        create_status: 1,
        delivery_method: deliveryMethod,
        order_products: cartItems.map((item) => ({
          product_id: item.product_id,
          quantity: item.quantity,
          name: item.product?.name || '',
          price: item?.product?.price,
          final_price: item?.product?.final_price,
        })),
        note: newNote,
      }

      // Save info to local storage
      params = updatePaymentLS(params)

      // If user is auth and want to create a new address
      if (isAuth && deliveryMethod === GET_AT_ADDRESS && addressType === NEW_ADDRESS) {
        createAddress.current = newAddress
      } else createAddress.current = null

      const body = { ...params }
      delete body.id
      delete body.order_code
      delete body.discount_code

      // Create order step 1
      requestApi(params.id ? 'put' : 'post', apiUrls.orders(params.id), body, {
        successCallback: ({ status, data }) => {
          setLoading(false)
          if (status) {
            updatePaymentLS({ order_code: data.order_code, id: data.id })
            preventRedirect()
            handleNext()
          }
        },
        errorCallback: () => setLoading(false),
      })
    },
  })

  useEffect(() => {
    if (formik) setFormikState(formik)
  }, [...Object.values(formik.values)])

  // Update addressType when change delivery method
  useEffect(() => {
    if (deliveryMethod === GET_AT_ADDRESS) {
      if (addresses.length !== 0) setAddressType(AVAILABLE_ADDRESS)
      else setAddressType(NEW_ADDRESS)
    }
  }, [deliveryMethod, addresses.length])

  /**
   * formik
   */
  const formikPayment = useFormik({
    initialValues: {
      company_name: '',
      tax_identification_number: '',
      company_address: '',
      email: '',
    } as any,
    validationSchema: checked && valueVat === '0' ? validationSchema() : undefined,
    enableReinitialize: true,
    onSubmit: (values) => {
      let params: PaymentType = {
        create_status: 2,
        order_payment_info: {
          bank_name: bankName || '',
          account_name: account || '',
          bank_number: bankNumber || '',
          payment_content: content || '',
          momo_phone: momoPhone || '',
        },
        order_vat_invoice: {
          company_address: values.company_address,
          company_name: values.company_name,
          tax_identification_number: values.tax_identification_number,
          email: values.email,
        },
      }

      if (value === 'momo') params.payment_method = USE_MOMO
      else if (value === 'banking') params.payment_method = TRANSFER
      else if (value === 'onDelivery') params.payment_method = PAYMENT_ON_DELIVERY

      params = updatePaymentLS(params, !checked ? ['order_vat_invoice'] : [])

      delete params.id
      delete params.order_code
      delete params.discount_code

      apiPut(apiUrls.orders(id), params, ({ status }) => {
        setLoadingPayment(false)

        if (status) {
          if (checked && isAuth && valueVat === '0') {
            /**
             * create vat
             */
            createVAT.current = {
              company_name: values.company_name.trim(),
              tax_identification_number: values.tax_identification_number.trim(),
              company_address: values.company_address.trim(),
              email: values.email.trim(),
            }
          } else createVAT.current = null
          handleNext()
        }
      })
    },
  })

  const { validateForm: validateForm2, setFieldValue: setFieldValue2 } = formikPayment

  /**
   *
   * handle click vat
   * @param item
   */
  const handeClickVAT = (item: any) => {
    setFieldValue2('company_name', item?.company_name)
    setFieldValue2('company_address', item?.company_address)
    setFieldValue2('tax_identification_number', item?.tax_identification_number)
    setFieldValue2('email', item?.email)
    setChooseVat({ id: item?.id })
  }

  const savedDataStep2 = {
    value,
    setValue,
    checked,
    setChecked,
    copyCode,
    setCode,
    copyContent,
    setCopyContent,
    bankTranfer,
    setBankTranfer,
    valueVat,
    setValueVat,
    setChooseVat,
    chooseVat,
    loadingPayment,
    content,
    setLoadingPayment,
    orderCode,
    isAuth,
    initialValues: {
      company_name: '',
      tax_identification_number: '',
      company_address: '',
      email: '',
    },
    handeClickVAT,
    setObjField,
    objField,
    finalPrice,
  }

  useEffect(() => {
    // When vatn is null => create new vat (0)
    if (isAuth && checked) {
      setValueVat('1')
    }

    if (!vat?.length) setValueVat('0')
  }, [checked, isAuth, vat])

  useEffect(() => {
    if (valueVat === '0' || !checked) {
      setFieldValue2('company_name', '')
      setFieldValue2('company_address', '')
      setFieldValue2('tax_identification_number', '')
      setFieldValue2('email', '')
    }

    if (
      valueVat === '0' &&
      (objField.company_address ||
        objField.company_name ||
        objField.tax_identification_number ||
        objField.email) &&
      checked
    ) {
      setFieldValue2('company_name', objField.company_name)
      setFieldValue2('company_address', objField.company_address)
      setFieldValue2('tax_identification_number', objField.tax_identification_number)
      setFieldValue2('email', objField.email)
    }

    if (checked && chooseVat?.id && valueVat === '1') {
      const thisVat = vat.find((item: any) => item.id === chooseVat?.id)

      if (thisVat) {
        setFieldValue2('company_name', thisVat.company_name)
        setFieldValue2('company_address', thisVat.company_address)
        setFieldValue2('tax_identification_number', thisVat.tax_identification_number)
        setFieldValue2('email', thisVat.email)
      }
    }

    setTimeout(() => validateForm2(), 10)
  }, [checked, valueVat, chooseVat])

  const { validateForm, setFieldValue } = formik

  // Get data stores

  const getStores = () => {
    apiGet(apiUrls.getStores(), { status: 1 }, ({ status, data }) => {
      if (status) {
        setStores(
          data.items.map(({ store_name, address, phone, id }: any) => ({
            id,
            store_name,
            address,
            phone,
          }))
        )
        setFieldValue('selected_store', data.items?.[0])
      }
    })
  }

  // Validate to disable button on startup
  useEffect(() => {
    validateForm()

    // Get all stores
    if (!stores.length) getStores()

    // If is auth => get all address of current user
    if (isAuth && !addresses.length) {
      apiGet(apiUrls.addresses(), {}, ({ status, data }) => {
        if (status) {
          let { items } = data
          items = orderBy(items, ['is_default'], ['desc'])
          if (items?.length > 0) {
            const index = items.findIndex((i: any) => i.is_default)
            if (index !== -1) setSelectedAddress(items[index].id)
            setAddresses(items)
          }
        }
      })
    }
  }, [step])
  useEffect(() => {
    if (stores.length === 0) {
      setDeliveryMethod(GET_AT_ADDRESS)
    } else setDeliveryMethod(GET_AT_STORE)
  }, [stores])

  const [dCode, setDCode] = useState(discountCode || '')
  // const [codeError, setCodeError] = useState('')

  switch (step) {
    case 1:
      form = <FormAddress formik={formik} savedData={savedData} />
      break
    case 2:
      form = (
        <FormPayment
          paymentInfor={paymentInfor}
          vat={vat}
          handleNext={handleNext}
          savedDataStep2={savedDataStep2}
          createVAT={createVAT}
          setShowDropDown={setShowDropDown}
          showDropDown={showDropDown}
          formik={formikPayment}
        />
      )
      break
    case 3:
      form = (
        <FormSummary
          handleDone={() => {
            const data = getPaymentLS()
            allowRedirect()
            orderSuccess()
            removePaymentLS()
            history.push('/payment-success', data)
          }}
          handleGetStores={getStores}
          setStep={setStep}
          createAddress={createAddress}
          createVAT={createVAT}
          code={dCode}
          setCode={setDCode}
          codeError={codeError}
          childrenSummary={childrenSummary}
          // setCodeError={setCodeError}
        />
      )
      break
  }

  return form
}

export default FormWrapper
