import React, { useState } from 'react'
import { Box, Avatar, Stack, Button } from '@mui/material'
import './AdminSidebar.scss'
import { useModalConfirm } from 'store/modalConfirm'
import NavSection from '../../navSection/NavSection'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'store/auth'
import { useHistory } from 'store/confirmRedirect'
import { ROUTE_PERMISSIONS } from 'configs/constants'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

const {
  DASHBOARD,
  ORDERS,
  CUSTOMERS,
  PRODUCTS,
  CATEGORIES,
  MANUFACTURERS,
  ARTICLES,
  DOCUMENTS_CATEGORIES,
  DOCUMENTS,
  QUESTIONS,
  ADMINS,
  GROUPS,
  ROLES,
  COUPONS,
  BRANCHS,
  ABOUT_US,
  VISION,
  INTRODUCE,
} = ROUTE_PERMISSIONS

const AdminSidebar: React.FC = () => {
  const [open, setOpen] = useState(true)
  const { t } = useTranslation()
  const history = useHistory()
  const { signOut, full_name, avatar_url } = useAuth()

  const { showModalConfirm, hideModalConfirm } = useModalConfirm()

  const toggleSidebar = () => setOpen(!open)

  /**
   * Handle sign out
   */
  const handleSignOut = () => {
    showModalConfirm({
      title: 'Đăng xuất',
      content: t('sure_sign_out'),
      cancel: { text: 'Hủy', action: hideModalConfirm },
      confirm: {
        text: 'Đăng xuất',
        action: () => {
          hideModalConfirm()
          signOut(true)
        },
      },
    })
  }

  const sidebarConfig = [
    {
      title: 'dashboard',
      path: '/admin/dashboard',
      icon: 'fas fa-chart-bar',
      permission: DASHBOARD,
    },
    {
      title: 'order_management',
      path: '/admin/orders',
      icon: 'fas fa-shopping-cart',
      permission: ORDERS,
    },
    {
      title: 'user_management',
      path: '/admin/users',
      icon: 'fas fa-user',
      permission: CUSTOMERS,
    },
    {
      title: 'product_management',
      icon: 'mn-icon-engine-motor',
      sub: [
        {
          title: 'product_management',
          path: '/admin/products',
          permission: PRODUCTS,
        },
        {
          title: 'category_management',
          path: '/admin/categories',
          permission: CATEGORIES,
        },
        {
          title: 'manufacturer_management',
          path: '/admin/manufacturers',
          permission: MANUFACTURERS,
        },
      ],
    },
    {
      title: 'news_management',
      path: '/admin/news',
      icon: 'fas fa-newspaper',
      permission: ARTICLES,
    },
    {
      title: 'technical_management',
      icon: 'fas fa-wrench',
      sub: [
        {
          title: 'document_category_management',
          path: '/admin/document-categories',
          permission: DOCUMENTS_CATEGORIES,
        },
        {
          title: 'technical_document_management',
          path: '/admin/technical-documents',
          permission: DOCUMENTS,
        },
      ],
    },
    {
      title: 'question_management',
      path: '/admin/questions',
      icon: 'fas fa-question',
      permission: QUESTIONS,
    },
    {
      title: 'admin_management',
      icon: 'fas fa-lock',
      sub: [
        {
          title: 'admin_management',
          path: '/admin/admins',
          permission: ADMINS,
        },
        {
          title: 'group_management',
          path: '/admin/groups',
          permission: GROUPS,
        },
        {
          title: 'permission_management',
          path: '/admin/permissions',
          permission: ROLES,
        },
      ],
    },
    {
      title: 'branch_management',
      path: '/admin/branchs',
      icon: 'fas fa-tags',
      permission: BRANCHS,
    },
    {
      title: 'coupon_management',
      path: '/admin/coupons',
      icon: 'fas fa-percent',
      permission: COUPONS,
    },
    {
      title: 'about_us_management',
      icon: 'fas fa-database',
      sub: [
        {
          title: 'about_us_management',
          path: '/admin/about-us',
          permission: ABOUT_US,
        },
        {
          title: 'vision_management',
          path: '/admin/vision',
          permission: VISION,
        },
        {
          title: 'introduce_management',
          path: '/admin/introduce',
          permission: INTRODUCE,
        },
      ],
    },
    {
      title: 'change_password',
      path: '/admin/change-password',
      icon: 'fas fa-key',
    },
    {
      title: 'sign_out',
      icon: 'fas fa-sign-out-alt',
      onClick: () => handleSignOut(),
    },
  ]

  const renderContent = (
    <SimpleBar className="AdminSidebar-scroll">
      <Box sx={{ px: 2.5, py: 3 }}></Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Stack alignItems="center" sx={{ mb: 3 }}>
          <Avatar
            src={avatar_url}
            className="AdminSidebar-avatar"
            onClick={() => history.push('/admin/profile')}
          />
        </Stack>

        <Stack alignItems="center">
          <h3>{full_name}</h3>
        </Stack>
      </Box>

      <NavSection open={open} navConfig={sidebarConfig} />
    </SimpleBar>
  )

  return (
    <div className={`AdminSidebar${open ? ' open' : ' close'}`}>
      <div className="AdminSidebar-wrapper">
        <div className="AdminSidebar-collapse">
          <Button color="primary" variant="contained" onClick={toggleSidebar}>
            <i className={`fas fa-chevron-${open ? 'left' : 'right'}`} />
          </Button>
        </div>
        {renderContent}
      </div>
    </div>
  )
}

export default AdminSidebar
