import { useMemo } from 'react'
import { isEmpty } from 'lodash'

import { useShoppingCart } from 'store/shoppingCart'

import CartItems from './CartItems'
import CartMoney from './CartMoney'

import { formatQuantity } from 'utils'
import { CART_TYPES } from 'configs/constants'
import { useAuth } from 'store/auth'

const { ON_BOARD, BUY_LATER } = CART_TYPES

export default function Cart() {
  const { cartItems, buyLaterItems, totalMoneyAfterDiscount, addAllToCart } = useShoppingCart()
  const { isAuth } = useAuth()

  // Get description
  const description = useMemo(() => {
    const numOfOrder = formatQuantity(cartItems.length)
    const numOfOrderLater = formatQuantity(buyLaterItems.length)

    let description = 'Bạn có '
    if (numOfOrder) {
      description += `<strong>${numOfOrder}</strong> sản phẩm trong giỏ hàng`
    }

    if (numOfOrderLater) {
      description += `${
        numOfOrder ? ' và ' : ''
      }<strong>${numOfOrderLater}</strong> sản phẩm để mua sau`
    }

    return description
  }, [cartItems, buyLaterItems])

  return (
    <div className="Cart-container">
      <p className="Cart-desc" dangerouslySetInnerHTML={{ __html: description }} />
      {!isEmpty(cartItems) && (
        <>
          <CartItems className="Cart-order" cartItems={cartItems} cartType={ON_BOARD} />
          <CartMoney totalMoney={totalMoneyAfterDiscount} />
        </>
      )}
      {!isEmpty(buyLaterItems) && (
        <CartItems
          className="Cart-orderLater"
          label="orderListLater"
          cartItems={isAuth ? buyLaterItems : buyLaterItems.reverse()}
          cartType={BUY_LATER}
          addAllToCart={addAllToCart}
        />
      )}
    </div>
  )
}
