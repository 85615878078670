import { Pagination } from 'components/common'
import React from 'react'
import { ArticleType } from 'types'
import ArticleItem from './ArticleItem'

interface Props {
  items: ArticleType[]
  page: number
  totalPages: number
  onChangePage: any
  isMobile: boolean
  loading: boolean
}

const Articles: React.FC<Props> = ({
  items,
  totalPages,
  page,
  onChangePage,
  isMobile,
  loading,
}) => {
  return (
    <div className="ArticleItemsWrapper">
      {!loading && items.length === 0 && (
        <div className="card-noItems">
          <h3 className="card-title hideMobile mb-3">Kết quả tìm kiếm</h3>
          <div className="ProductsMain-no-items__wrapper">
            <h3 className="card-title">Không có bài viết phù hợp</h3>
            <p className="label gray ProductsMain-no-items__label">
              Không có bài viết nào phù hợp với từ khóa mà bạn đã tìm.
            </p>
          </div>
        </div>
      )}
      {items.map((item) => (
        <ArticleItem key={item.id} {...item} isSmall={isMobile} />
      ))}
      <div style={{ marginBottom: 32 }}>
        <Pagination count={totalPages} page={page} onChange={onChangePage} left />
      </div>
    </div>
  )
}

export default Articles
