import { Box } from '@mui/material'
import Link from '../../links/Link'
import React from 'react'
import './index.scss'

interface Props {
  originTitle: string
  basePathname: string
  extraPathname?: string
  breadcrumbs?: { name: string; id: string }[]
}

const Breadcrumbs: React.FC<Props> = ({
  originTitle,
  basePathname,
  extraPathname,
  breadcrumbs = [],
}) => {
  const iconLeft = (
    <h2>
      <i className="mn-icon-arrow-right" />
    </h2>
  )

  const level = breadcrumbs.length + 1
  const breadcrumbsLeft = breadcrumbs.slice(0, -1)
  const breadcrumbLast = breadcrumbs[breadcrumbs.length - 1]

  return (
    <div className="d-f jc-sb ai-c AdminBreadcrumbs">
      <Box mb={2.5} mt={2} className="d-f ai-c AdminBreadcrumbs-wrapper">
        {level === 1 ? (
          <h2>{originTitle}</h2>
        ) : (
          <>
            <Link to={`/admin/${basePathname}`}>
              <h2>{originTitle}</h2>
            </Link>
            {breadcrumbsLeft?.map(({ id, name }, index) => (
              <React.Fragment key={id}>
                {iconLeft}
                <Link
                  to={`/admin/${basePathname}${
                    extraPathname && index > 0 ? `/${extraPathname}` : ''
                  }/${id}`}
                >
                  <h2>{name}</h2>
                </Link>
              </React.Fragment>
            ))}
            <>
              {iconLeft}
              <h2>{breadcrumbLast?.name}</h2>
            </>
          </>
        )}
      </Box>
    </div>
  )
}

export default Breadcrumbs
