import { formatPrice } from 'utils'

interface Props {
  label: string
  large?: boolean
  price: number
  redColor?: boolean
}

const UnitPrice: React.FC<Props> = (props: Props) => {
  const { label, large, price, redColor } = props

  return (
    <div className="UnitPrice" style={{ padding: large ? '20px 16px 20px' : '8px 16px 16px' }}>
      <div className="UnitPrice-label label gray">{label}</div>
      <div className={`UnitPrice-price ${redColor ? 'redColor' : ''}`}>{formatPrice(price)}</div>
    </div>
  )
}

export default UnitPrice
