import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { isNil } from 'lodash'
import { Tabs, Tab, useMediaQuery } from '@mui/material'

import DetailInfoSm from './DetailInfoSm'
import DetailInfoTabContent from './DetailInfoTabContent'
import { ProductType } from 'types'

interface PropTypes {
  product: ProductType
}

export default function DetailInfo(props: PropTypes) {
  const { t } = useTranslation()
  const isMobileView = useMediaQuery('(max-width: 860px)')

  const [tabActive, setTabActive] = useState<number | undefined>(0)
  const { product } = props
  const { description, specification, user_manual, warranty } = product || {}

  const tabs = useMemo(
    () => [
      { name: 'productDescription', content: description },
      { name: 'specifications', content: specification },
      { name: 'userManual', content: user_manual },
      { name: 'warranty', content: warranty },
    ],
    [product]
  )

  useEffect(() => {
    if (!isMobileView) {
      setTabActive(0)
    } else {
      setTabActive(-1)
    }
  }, [isMobileView])

  /**
   * Handle change tab
   *
   * @param event DOM Event
   * @param newValue New tab active
   */
  const handleChangeTab = (event: any, newValue: number) => {
    setTabActive(newValue)
  }

  return (
    <>
      {!isMobileView ? (
        <div className="ProductDetailInfo-container">
          <Tabs
            className="ProductDetailInfo-tabs"
            variant="scrollable"
            scrollButtons="auto"
            value={tabActive || 0}
            onChange={handleChangeTab}
          >
            {tabs.map((tabItem, index) => (
              <Tab key={index} label={t(tabItem.name)} value={index} />
            ))}
          </Tabs>
          {tabs.map((tabItem, index) => (
            <div key={index} role="tabpanel" hidden={tabActive !== index}>
              {tabActive === index && <DetailInfoTabContent data={tabItem.content} />}
            </div>
          ))}
        </div>
      ) : (
        <DetailInfoSm tabs={tabs} value={tabActive} onChangeTab={setTabActive} />
      )}
    </>
  )
}
