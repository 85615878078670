/* eslint-disable */
import React, { CSSProperties, forwardRef } from 'react'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './index.scss'
import moment from 'moment'
import { TextField } from '@mui/material'
import bg from 'date-fns/locale/bg'

function buildFormatLongFn(args: any) {
  return (options = {} as any) => {
    // TODO: Remove String()
    const width = options.width ? (String(options.width) as any) : args.defaultWidth
    const format = args.formats[width] || args.formats[args.defaultWidth]
    return format
  }
}

const daysBG = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7']

const monthValues = {
  narrow: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'] as const,
  abbreviated: [
    'Tháng 1',
    'Tháng 2',
    'Tháng 3',
    'Tháng 4',
    'Tháng 5',
    'Tháng 6',
    'Tháng 7',
    'Tháng 8',
    'Tháng 9',
    'Tháng 10',
    'Tháng 11',
    'Tháng 12',
  ] as const,
  wide: [
    'Tháng 1,',
    'Tháng 2,',
    'Tháng 3,',
    'Tháng 4,',
    'Tháng 5,',
    'Tháng 6,',
    'Tháng 7,',
    'Tháng 8,',
    'Tháng 9,',
    'Tháng 10,',
    'Tháng 11,',
    'Tháng 12,',
  ] as const,
}

var dateFormats = {
  // thứ Sáu, ngày 25 tháng 08 năm 2017
  full: "EEEE, 'ngày' d MMMM 'năm' y",
  // ngày 25 tháng 08 năm 2017
  long: "'ngày' d MMMM 'năm' y",
  // 25 thg 08 năm 2017
  medium: "d MMM 'năm' y",
  // 25/08/2017
  short: 'dd/MM/y',
}

function buildLocalizeFn(args: any) {
  return (dirtyIndex: any, dirtyOptions: any) => {
    const options = dirtyOptions || {}

    const context = options.context ? String(options.context) : 'standalone'

    let valuesArray: any
    if (context === 'formatting' && args.formattingValues) {
      const defaultWidth = args.defaultFormattingWidth || args.defaultWidth
      const width = (options.width ? String(options.width) : defaultWidth) as any
      valuesArray = (args.formattingValues[width] || args.formattingValues[defaultWidth]) as any
    } else {
      const defaultWidth = args.defaultWidth
      const width = (options.width ? String(options.width) : args.defaultWidth) as any
      valuesArray = (args.values[width] || args.values[defaultWidth]) as any
    }
    const index = (
      args.argumentCallback ? args.argumentCallback(dirtyIndex as any) : (dirtyIndex as any as any)
    ) as any
    // @ts-ignore: For some reason TypeScript just don't want to match it, no matter how hard we try. I challenge you to try to remove it!
    return valuesArray[index]
  }
}

registerLocale('bg', {
  ...bg,
  localize: {
    // month: (n: any) => monthsBG[n],
    day: (n: any) => daysBG[n],
    month: buildLocalizeFn({
      values: monthValues,
      defaultWidth: 'wide',
    }),
  },
} as any)

setDefaultLocale('vi')

/* eslint-disable-next-line */
const CustomInput = forwardRef(
  (
    {
      onClick,
      fullWidth,
      date,
      format,
      label,
      handleClear,
      disabled,
      placeholder,
      disableClear,
    }: {
      onClick?: any
      fullWidth?: boolean
      date?: any
      format?: any
      label?: string
      handleClear?: any
      disabled?: boolean
      placeholder?: string
      disableClear?: boolean
    },
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    ref: any
  ) => (
    <div
      style={{ position: 'relative', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      onClick={onClick}
    >
      <TextField
        size="small"
        style={{ width: fullWidth ? undefined : 150 }}
        fullWidth={fullWidth}
        value={date ? moment(date).format(format || 'DD/MM/YYYY') : ''}
        label={placeholder ? undefined : label || 'dd/mm/yyyy'}
        InputProps={{
          readOnly: true,
        }}
        placeholder={placeholder}
        disabled={disabled}
      />
      {fullWidth ? (
        <i className="far fa-calendar" style={{ position: 'absolute', right: 16, fontSize: 16 }} />
      ) : (
        !disableClear &&
        !disabled && (
          <i
            className="fas fa-times-circle"
            style={{ position: 'absolute', right: 12, fontSize: 14, color: '#aaa' }}
            onClick={handleClear}
          />
        )
      )}
    </div>
  )
)

interface Props {
  fullWidth?: boolean
  minDate?: any
  maxDate?: any
  date?: Date | null
  setDate?: (date: Date | null) => void
  yearDropdownItemNumber?: number
  format?: string
  label?: string
  disabled?: boolean
  rootPortal?: boolean
  popperPlacement?: any
  placeholder?: string
  style?: CSSProperties
  type?: 'day' | 'month' | 'year'
  disableClear?: boolean
  showTimeSelect?: boolean
  [key: string]: any
}

const CustomDatePicker: React.FC<Props> = ({
  fullWidth,
  minDate,
  maxDate,
  date,
  setDate,
  yearDropdownItemNumber,
  format,
  label,
  disabled,
  rootPortal,
  popperPlacement,
  placeholder,
  style,
  type = 'day',
  disableClear,
  showTimeSelect,
  ...rest
}) => {
  const params = {
    minDate,
    maxDate,
    yearDropdownItemNumber,
    calendarStartDay: 1,
    disabled,
  }

  const handleClear = (e: any) => {
    e.stopPropagation()
    setDate?.(null)
  }

  const customInputParams = {
    fullWidth,
    date,
    label,
    handleClear,
    format: type === 'month' ? 'MM/YYYY' : type === 'year' ? 'YYYY' : format,
    disableClear,
  }

  const smallYear = minDate && maxDate && maxDate?.getFullYear() - minDate?.getFullYear() + 1
  const yearItem = smallYear < 8 ? undefined : 150

  return (
    <div className={`DatePicker ${type}`} style={style}>
      <DatePicker
        showYearDropdown
        showMonthDropdown
        scrollableYearDropdown
        selected={date}
        portalId={rootPortal ? 'root-portal' : undefined}
        popperPlacement={popperPlacement || 'bottom'}
        {...params}
        onChange={(date: any) => setDate?.(date)}
        customInput={<CustomInput {...customInputParams} />}
        placeholderText={placeholder}
        {...rest}
        locale={'bg'}
        useShortMonthInDropdown
        showMonthYearPicker={type === 'month'}
        showYearPicker={type === 'year'}
        yearItemNumber={12}
        yearDropdownItemNumber={yearItem}
        calendarClassName={`${smallYear ? `small-year-${smallYear}` : undefined} ${
          showTimeSelect ? 'show-time' : ''
        }`}
        showTimeSelect={showTimeSelect}
      />
    </div>
  )
}

export default CustomDatePicker
