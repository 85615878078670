import { ProductItem, Pagination, Loading } from 'components/common'
import React, { useContext } from 'react'
import Header from './Header'
import { Button } from 'components'
import { ProductsContext } from 'configs/constants'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'store/confirmRedirect'

interface Props {
  isMobile: boolean
}

const ListProducts: React.FC<Props> = ({ isMobile }) => {
  const { products, onFilter, category, keyword, filter } = useContext(ProductsContext)
  const { items, loading, total_pages, page } = products
  const { filtered } = filter
  const history = useHistory()
  const location = useLocation()

  const noHasFilter = filter.categories.length === 0 && filter.manufacturers.length === 0

  const showKeyword = !filtered && !!keyword

  let textNoItems = ''
  if (showKeyword) textNoItems = 'Không tìm thấy sản phẩm phù hợp với từ khóa bạn đã nhập'
  else if (!noHasFilter) textNoItems = 'Không có sản phẩm nào phù hợp với bộ lọc mà bạn đã tìm.'

  return (
    <div className="ProductsMain">
      {loading && <Loading fullScreen />}
      {!items.length && !loading ? (
        <div className={`ProductsMain-no-items${showKeyword || noHasFilter ? ' has-keyword' : ''}`}>
          {!(showKeyword || noHasFilter) && !isMobile && (
            <h3 className="ProductsMain-title mb-3">Kết quả lọc</h3>
          )}
          <div className="ProductsMain-no-items__wrapper">
            {!showKeyword && <h3 className="ProductsMain-title">Không có sản phẩm phù hợp</h3>}
            <p className="label gray ProductsMain-no-items__label">{textNoItems}</p>
            <Button color="stroke transparent" onClick={() => history.goBack()}>
              Quay lại trang trước
            </Button>
          </div>
        </div>
      ) : (
        <>
          {!isMobile && <Header />}
          <div className="ProductsMain-body">
            {items.map((item, index) => (
              <div key={index} className="ProductsMain-body__item">
                <ProductItem
                  product={item}
                  fromCategory={category?.id}
                  fromSearch={keyword ? { ...location, keyword } : undefined}
                />
              </div>
            ))}
          </div>
          <div className="ProductsMain-footer">
            <Pagination
              count={total_pages}
              page={page}
              onChange={(newPage) => onFilter({ page: newPage })}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default ListProducts
