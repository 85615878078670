/* eslint-disable */
import React, { useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor'
import ApiRequest from 'services/api'

import './index.scss'
import { FormHelperText } from '@mui/material'
import { trimFileName } from 'utils'

class UploadAdapter {
  loader: any

  constructor(loader: any) {
    this.loader = loader
  }

  upload() {
    return new Promise((resolve, reject) => {
      const loader = this.loader
      loader.file.then((file: any) => {
        ApiRequest.post(
          '/api/v1/upload',
          { upload: { prefix: 'article', file, file_name: trimFileName(file.name) } },
          ({ status, data }) => {
            if (status) {
              loader.uploadTotal = 1
              loader.uploaded = true
              resolve({
                default: data.file_url,
              })
            }
          }
        )
      })
    })
  }
}

const uploadAdapterPlugin = (editor: any) => {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    return new UploadAdapter(loader)
  }
}

interface Props {
  value?: string
  error?: { show: boolean; content: string }
  setError?: any
  onChange?: (value: string) => void
  minLength?: number
  maxLength?: number
  small?: boolean
}

const Editor: React.FC<Props> = ({
  value,
  onChange,
  minLength = 0,
  maxLength = 10000,
  error,
  setError,
  small,
}) => {
  const [focus, setFocus] = useState(false)
  const showError = (show: boolean, isMax?: boolean) =>
    setError({
      show,
      content: isMax ? 'Quá giới hạn ký tự cho phép' : `Vui lòng nhập tối thiểu ${minLength} ký tự`,
    })

  const check = (value: any) => {
    const length = value.length
    const isMax = maxLength && length > maxLength
    const isMin = minLength && length < minLength
    if (isMax || isMin) {
      if (!error?.show) showError(true, !!isMax)
    } else {
      if (error?.show) showError(false)
    }
  }

  const isShowError = !focus && !!error?.show

  return (
    <div className={`AdminEditor${isShowError ? ' error' : ''}${small ? ' small' : ''}`}>
      <CKEditor
        onReady={(editor: any) => {
          // editor.ui.view.editable.element.style.height = '200px';
          uploadAdapterPlugin(editor)
        }}
        data={value || ''}
        editor={ClassicEditor}
        onBlur={(_: any, editor: any) => {
          setFocus(false)
          const data = editor.getData()
          check(data)
          onChange?.(data)
        }}
        onChange={(_: any, editor: any) => {
          // if (!editor.editing.view.document.isFocused) editor.editing.view.focus()
          const data = editor.getData()
          // check(data)
          onChange?.(data)
        }}
        onFocus={() => {
          setError({ show: false, content: '' })
          setFocus(true)
        }}
      />
      {isShowError && (
        <FormHelperText error style={{ marginTop: 8, marginLeft: 0, marginRight: 0 }}>
          {error?.content}
        </FormHelperText>
      )}
    </div>
  )
}

export default Editor
