export const validateEmail = () => /^[A-Z0-9a-z.…_%+-]+@[A-Za-z0-9.…-]+\.[A-Za-z]{2,}$/
export const validatePassword = () =>
  /(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9!@#$%^&*()\\-_=+{}|?>.<,:;]{8,16}/
export const validateName = () => /^([A-Za-z ']+\s?)*$/
// Start with +1 -> +9 or 0-9, end by a number
export const validatePhone = () => /^([+1-9]{2}|[0-9])[0-9]{0,19}$/

export const validateTypingAlphabet = (e: any) => /^[a-zA-Z]*$/.test(e.target.value)
export const validateTypingPhone = (e: any) => /^[+0-9][0-9]*$/.test(e.target.value)
export const validateTypingNumber = (e: any) => /^[0-9]*$/.test(e.target.value)
export const validateTypingNumberD = (e: any) => /^[0-9,]*$/.test(e.target.value)
export const validateTypingMST = (e: any) => /^[0-9-]*$/.test(e.target.value)
export const validateTypingCode = (e: any) => /^[0-9a-zA-Z]*$/.test(e.target.value)

export const validateTypingNumberFull = (e: any) => {
  const value = e.target.value

  if (value === '') return true
  if (value?.[0] === '0') return false
  return validateTypingNumber(e)
}

export const validateTypingName = (e: any) => {
  const value = e.target.value
  if (value === '') return true
  if (value.trim() === '') return false
  if (value.includes('  ')) return false
  if (
    // eslint-disable-next-line no-useless-escape
    /[0-9`~!@#$%^&*(),.?'":;{}+=|<>_\-\\\/\[\]]/.test(value)
  )
    return false
  return true
}

export const validateTypingNumberDecimal = (e: any) => {
  const value = e.target.value

  if (value === '') return true
  if (value === ',') return false
  const splits = value.split(',')
  if (splits.length > 2) return false

  if (value.includes(',') && splits[splits.length - 1]?.length >= 3) return false
  return validateTypingNumberD(e)
}

export const validateTypingCompany = (e: any) => {
  const value = e.target.value
  if (value === '') return true
  if (value.trim() === '') return false
  if (value.includes('  ')) return false
  if (
    // eslint-disable-next-line no-useless-escape
    /[`~!@#$%^&*(),?'":;{}+=|<>_\\\\/\[\]¬£]/.test(value)
  )
    return false
  return true
}

export const validateTypingAddress = (e: any) => {
  const value = e.target.value
  if (value === '') return true
  if (value.trim() === '') return false
  if (value.includes('  ')) return false
  if (
    // eslint-disable-next-line no-useless-escape
    /[`~!@#$%^&*().?'":;{}+=|<>_\-\\\[\]¬£]/.test(value)
  )
    return false
  return true
}

export const validateTypingSpecification = (e: any) => {
  const value = e.target.value
  if (value === '') return true
  if (value.trim() === '') return false
  if (value.includes('  ')) return false
  return true
}
