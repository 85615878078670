import AuthLayout from 'components/screens/app/auth/AuthLayout'
import { AUTH_TYPES } from 'configs/constants'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { signUp as signUpAction } from 'store/auth/actions'

export default function SignUp() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(signUpAction(false))
  }, [])

  return <AuthLayout authType={AUTH_TYPES.SIGN_UP} />
}
