import { useState } from 'react'
import Slider from 'react-slick'
import { isEmpty } from 'lodash'

import { useApp } from 'store/app'
import { useHistory } from 'store/confirmRedirect'

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
}
export default function HomeSlider() {
  const { banners = [] } = useApp()
  const { push } = useHistory()

  const [isDragging, setIsDragging] = useState(false)

  /**
   * Handle view banner detail
   *
   * @param bannerUrl Banner url
   */
  const handleViewDetail = (e: any, id: string) => {
    if (isDragging) {
      e.stopPropagation()
      return
    }
    push(`/tin-tuc-su-kien/${id}`)
  }

  if (isEmpty(banners)) return null

  return (
    <Slider
      beforeChange={() => setIsDragging(true)}
      afterChange={() => setIsDragging(false)}
      className="HomeSlider-container"
      {...settings}
    >
      {banners.map((bannerItem: any, index: number) => (
        <div key={index} className="HomeSliderItem-container">
          <div
            className="HomeSliderItem-mainContainer"
            style={{
              // eslint-disable-next-line max-len
              // linear-gradient(179.53deg, #292732 0.41%, rgba(41, 39, 50, 0) 25.54%),
              backgroundImage: `url("${bannerItem.banner_image_url}")`,
            }}
            onClick={(e) => handleViewDetail(e, bannerItem.id)}
          />
        </div>
      ))}
    </Slider>
  )
}
