/*eslint-disable*/
import { Box } from '@mui/system'
import GuideUsingStep from 'components/admin/guideUsingStep/GuideUsingStep'
import React from 'react'

import view1 from '../../../../../assets/images/OrderUsing/view/view1.png'
import view2 from '../../../../../assets/images/OrderUsing/view/view2.png'
import view3 from '../../../../../assets/images/OrderUsing/view/view3.png'
import view4 from '../../../../../assets/images/OrderUsing/view/view4.png'
import view5 from '../../../../../assets/images/OrderUsing/view/view5.png'
import view6 from '../../../../../assets/images/OrderUsing/view/view6.png'
import view7 from '../../../../../assets/images/OrderUsing/view/view7.png'
import view8 from '../../../../../assets/images/OrderUsing/view/view8.png'
import view9 from '../../../../../assets/images/OrderUsing/view/view9.png'
import view10 from '../../../../../assets/images/OrderUsing/view/view10.png'
import view11 from '../../../../../assets/images/OrderUsing/view/view11.png'
import view12 from '../../../../../assets/images/OrderUsing/view/view12.png'
import view13 from '../../../../../assets/images/OrderUsing/view/view13.png'
import view14 from '../../../../../assets/images/OrderUsing/view/view14.png'
import view15 from '../../../../../assets/images/OrderUsing/view/view15.png'
import view16 from '../../../../../assets/images/OrderUsing/view/view16.png'
import view17 from '../../../../../assets/images/OrderUsing/view/view17.png'
import view18 from '../../../../../assets/images/OrderUsing/view/view18.png'
import view19 from '../../../../../assets/images/OrderUsing/view/view19.png'
import view20 from '../../../../../assets/images/OrderUsing/view/view20.png'
import view21 from '../../../../../assets/images/OrderUsing/view/view21.png'
import view22 from '../../../../../assets/images/OrderUsing/view/view22.png'
import view23 from '../../../../../assets/images/OrderUsing/view/view23.png'
import view24 from '../../../../../assets/images/OrderUsing/view/view24.png'
import view25 from '../../../../../assets/images/OrderUsing/view/view25.png'
import view26 from '../../../../../assets/images/OrderUsing/view/view26.png'
import view27 from '../../../../../assets/images/OrderUsing/view/view27.png'
import view28 from '../../../../../assets/images/OrderUsing/view/view28.png'
import view29 from '../../../../../assets/images/OrderUsing/view/view29.png'
import view30 from '../../../../../assets/images/OrderUsing/view/view30.png'
import view31 from '../../../../../assets/images/OrderUsing/view/view31.png'
import view32 from '../../../../../assets/images/OrderUsing/view/view32.png'
import view33 from '../../../../../assets/images/OrderUsing/view/view33.png'
import view34 from '../../../../../assets/images/OrderUsing/view/view34.png'
import view35 from '../../../../../assets/images/OrderUsing/view/view35.png'
import view36 from '../../../../../assets/images/OrderUsing/view/view36.png'
import view37 from '../../../../../assets/images/OrderUsing/view/view37.png'
import view38 from '../../../../../assets/images/OrderUsing/view/view38.png'

const OrderDetailUsing = () => {
  return (
    <Box>
      <p style={{ fontSize: 18, marginBottom: 10 }}>
        <b>Điều kiện:</b> Có ít nhất một đơn hàng được tạo.
      </p>
      <GuideUsingStep
        descreption="<b>Bước 1:</b> Nhấn vào nút <b>Xem</b> ở đơn hàng mà bạn muốn xem chi tiết."
        image={[view1]}
      />
      <GuideUsingStep
        descreption="Sau khi nhấn nút <b>Xem</b>, màn hình Xem chi tiết đơn hàng sẽ hiển thị như ảnh dưới đây:"
        image={[view2]}
      />
      <GuideUsingStep
        descreption="<b>Lưu ý: </b>"
        note={[
          {
            title: 'Bạn có thể nhấn nút quay lại để quay lại màn hình Quản lý đơn hàng.',
            image: [view3],
          },
        ]}
      />
      <GuideUsingStep
        descreption="<b>In phiếu giao nhận</b>"
        orther={
          <Box margin={'20px'}>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đơn hàng thuộc một trong các trạng thái “Chưa thanh toán”, “Đã nhận
              tiền”, “Đang đóng gói”.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn <b>IN PHIẾU GIAO NHẬN</b> tại màn hình Xem chi tiết đơn hàng."
              image={[view4]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>IN PHIẾU GIAO NHẬN</b>, hộp thoại In phiếu giao nhận sẽ hiển thị như ảnh dưới đây:"
              image={[view5]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Điền đầy đủ thông tin và nhấn <b>IN</b> sẽ hiển thị hộp thoại cho phép xem trước thông tin và in phiếu giao nhận."
              image={[view6]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                {
                  title:
                    'Trường hợp chưa kết nối với máy in → Bạn có thể lưu phiếu giao nhận dưới dạng PDF như ảnh bên dưới',
                  image: [view7],
                  subNote: [
                    { title: 'Nhấn nút <b>Save</b> để hoàn thành thao tác lưu phiếu giao nhận.' },
                  ],
                },
                {
                  title: 'Bạn có thể nhấn <b>HUỶ BỎ</b> để huỷ thao tác in/lưu phiếu giao nhận.',
                  image: [view8],
                },
              ]}
            />
          </Box>
        }
      />
      <GuideUsingStep
        descreption="<b>Chuyển trạng thái đơn hàng</b>"
        orther={
          <Box margin={'20px'}>
            <GuideUsingStep
              descreption="<b>Chuyển trạng thái đơn hàng sang ĐÃ CHUYỂN TIỀN</b>"
              orther={
                <Box margin={'20px'}>
                  <GuideUsingStep
                    descreption="Mô tả khi chuyển sang trạng thái <b>ĐÃ CHUYỂN TIỀN</b> sẽ giống với thao tác chuyển trạng thái đơn hàng tại màn hình Quản lý đơn hàng."
                    image={[view9]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Chuyển trạng thái đơn hàng sang ĐÃ NHẬN TIỀN</b>"
              orther={
                <Box margin={'20px'}>
                  <GuideUsingStep
                    descreption="Mô tả khi chuyển sang trạng thái <b>ĐÃ NHẬN TIỀN</b> sẽ giống với thao tác chuyển trạng thái đơn hàng tại màn hình Quản lý đơn hàng."
                    image={[view10]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Chuyển trạng thái đơn hàng sang CHƯA THANH TOÁN</b>"
              orther={
                <Box margin={'20px'}>
                  <GuideUsingStep
                    descreption="Mô tả khi chuyển sang trạng thái <b>CHƯA THANH TOÁN</b> sẽ giống với thao tác chuyển trạng thái đơn hàng tại màn hình Quản lý đơn hàng."
                    image={[view11]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Chuyển trạng thái đơn hàng sang ĐANG ĐÓNG GÓI</b>"
              orther={
                <Box margin={'20px'}>
                  <GuideUsingStep
                    descreption="Mô tả khi chuyển sang trạng thái <b>ĐANG ĐÓNG GÓI</b> sẽ giống với thao tác chuyển trạng thái đơn hàng tại màn hình Quản lý đơn hàng."
                    image={[view12]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Chuyển trạng thái đơn hàng sang ĐANG GIAO</b>"
              orther={
                <Box margin={'20px'}>
                  <GuideUsingStep
                    descreption="<b>Bước 1:</b> Nhấn vào trạng thái <b>ĐANG GIAO</b> tại màn Xem chi tiết đơn hàng."
                    image={[view13]}
                  />
                  <GuideUsingStep
                    descreption="Sau khi nhấn <b>ĐANG GIAO</b>, hiển thị hộp thoại Nhập thông tin vận chuyển như ảnh dưới đây: "
                    image={[view14]}
                  />
                  <GuideUsingStep
                    descreption="<b>Bước 2:</b> Điền thông tin hợp lệ vào các trường bắt buộc và các trường mong muốn khác trong hộp thoại Nhập thông tin vận chuyển, sau đó nhấn nút <b>CHUYỂN TRẠNG THÁI</b>."
                    image={[view15]}
                  />
                  <GuideUsingStep
                    descreption="Sau khi nhấn <b>CHUYỂN TRẠNG THÁI</b>, trạng thái đơn hàng và thông tin thời gian giao hàng dự kiến được cập nhật tại đơn hàng tương ứng."
                    image={[view16]}
                  />
                  <GuideUsingStep
                    descreption="Đồng thời, đơn hàng được chuyển sang trạng thái “Đang giao” tại màn <b>Đơn hàng của tôi</b> phía người dùng."
                    image={[view17]}
                  />
                  <GuideUsingStep
                    descreption="<b>Lưu ý:</b>"
                    note={[
                      {
                        title:
                          'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác chuyển trạng thái đơn hàng sang ĐANG GIAO.',
                        image: [view18],
                      },
                    ]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Chuyển trạng thái đơn hàng sang ĐÃ GIAO</b>"
              orther={
                <Box margin={'20px'}>
                  <GuideUsingStep
                    descreption="<b>Bước 1:</b> Nhấn vào trạng thái <b>ĐÃ GIAO</b> tại màn Xem chi tiết đơn hàng."
                    image={[view19]}
                  />
                  <GuideUsingStep
                    descreption="Sau khi nhấn <b>ĐÃ GIAO</b>:"
                    note={[
                      {
                        title:
                          'Nếu đơn hàng có phương thức nhận hàng là <b>Lấy tại cửa hàng</b> → Hiển thị hộp thoại Nhập thông tin giao hàng như ảnh dưới đây: ',
                        image: [view20],
                      },
                      {
                        title:
                          'Nếu đơn hàng có phương thức giao hàng là <b>Giao hàng tận nơi</b> và trạng thái là <b>Đang giao</b>: ',
                        subNote: [
                          {
                            title:
                              'Phương thức thanh toán là <b>Chuyển khoản qua ngân hàng</b> hoặc <b>Sử dụng ví Momo</b> → Hiển thị hộp thoại Nhập thời gian giao hàng thực tế như ảnh dưới đây: ',
                            image: [view21],
                          },
                          {
                            title:
                              'Phương thức thanh toán là <b>Thanh toán khi nhận hàng</b> → Hiển thị hộp thoại Nhập thời gian giao hàng thực tế như ảnh dưới đây:',
                            image: [view22],
                          },
                        ],
                      },
                    ]}
                  />
                  <GuideUsingStep
                    descreption="<b>Bước 2:</b> Nhập thông tin vào các trường bắt buộc và các trường khác (nếu muốn), sau đó nhấn nút <b>CHUYỂN TRẠNG THÁI</b>."
                    noWrapper
                  />
                  <GuideUsingStep
                    descreption="Sau khi nhấn <b>CHUYỂN TRẠNG THÁI</b>, trạng thái đơn hàng và thông tin giao hàng được cập nhật tại đơn hàng tương ứng."
                    image={[view23]}
                  />
                  <GuideUsingStep
                    descreption="Đồng thời, đơn hàng được chuyển sang trạng thái “Đã giao” tại màn <b>Đơn hàng của tôi</b> phía người dùng."
                    noWrapper
                  />
                  <GuideUsingStep
                    descreption="<b>Lưu ý:</b>"
                    note={[
                      {
                        title:
                          'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác chuyển trạng thái đơn hàng sang ĐÃ GIAO.',
                        image: [view24],
                      },
                    ]}
                  />
                </Box>
              }
            />
            <GuideUsingStep
              descreption="<b>Huỷ đơn hàng</b>"
              orther={
                <Box margin={'20px'}>
                  <p style={{ fontSize: 18, marginBottom: 10 }}>
                    <b>Điều kiện:</b> Đơn hàng thuộc một trong các trạng thái “Chưa thanh toán”, “Đã
                    chuyển tiền”, “Đã nhận tiền”, “Đang đóng gói” hoặc “Đang giao”.
                  </p>
                  <GuideUsingStep
                    descreption="Bạn có thể nhấn <b>HỦY ĐƠN HÀNG</b> để hủy thông tin đơn hàng. "
                    image={[view25]}
                    note={[
                      {
                        title:
                          'Chức năng khi nhấn <b>HỦY ĐƠN HÀNG</b> ở màn hình Chi tiết đơn hàng sẽ giống với mô tả khi nhấn nút Hủy tại màn hình Quản lý đơn hàng.',
                        image: [view26],
                      },
                    ]}
                  />
                </Box>
              }
            />
          </Box>
        }
      />
      <GuideUsingStep
        descreption="<b>Chỉnh sửa thông tin vận chuyển</b>"
        orther={
          <Box margin={'20px'}>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đơn hàng có trạng thái “Đang giao”.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Chỉnh sửa</b> tại Thông tin vận chuyển."
              image={[view27]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Chỉnh sửa</b>, hộp thoại Sửa thông tin vận chuyển hiển thị như ảnh bên dưới:"
              image={[view28]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Cập nhật thông tin vào các trường mà bạn muốn sửa trong hộp thoại Sửa thông tin vận chuyển, sau đó nhấn nút <b>LƯU</b>."
              image={[view29]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>LƯU</b>, thông tin vận chuyển sẽ được cập nhật theo thông tin vừa sửa:"
              image={[view30]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác sửa thông tin vận chuyển:',
                  image: [view31],
                },
              ]}
            />
          </Box>
        }
      />
      <GuideUsingStep
        descreption="<b>Thêm hóa đơn đỏ</b>"
        orther={
          <Box margin={'20px'}>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đơn hàng có trạng thái “Đã giao” và chưa đính kèm hóa đơn đỏ trước
              đó.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>THÊM HÓA ĐƠN ĐỎ</b> tại mục Hóa đơn đỏ."
              image={[view32]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>THÊM HÓA ĐƠN ĐỎ</b> cần chọn file hóa đơn đỏ từ máy tính. Sau khi chọn xong file, file vừa chọn sẽ hiển thị ở mục Hóa đơn đỏ như ảnh bên dưới:"
              image={[view33]}
            />
            <GuideUsingStep
              descreption="Đồng thời, hóa đơn đỏ được thêm cũng sẽ hiển thị ở màn <b>Đơn hàng của tôi</b> phía người dùng."
              image={[view34]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý:</b>"
              note={[
                {
                  title:
                    'Bạn có thể nhấn nút <b>GỬI HÓA ĐƠN ĐỎ</b> để gửi thông tin hóa đơn đỏ qua email.',
                  image: [view35],
                  subNote: [
                    {
                      title:
                        'Sau khi nhấn <b>GỬI HÓA ĐƠN ĐỎ</b>, hộp thoại Gửi hóa đơn đỏ qua email sẽ hiển thị như ảnh bên dưới. Bạn cần điền thông tin email và nhấn nút <b>GỬI</b> (nếu đã điền email tại mục Viết hóa đơn đỏ trước đó, bạn có thể chỉnh sửa địa chỉ email).',
                      image: [view36],
                    },
                    {
                      title:
                        'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác Gửi hóa đơn đỏ qua email.',
                    },
                  ],
                },
                {
                  title:
                    'Bạn có thể nhấn nút <b>X</b> ở tên file hóa đơn đỏ để xóa file hóa đơn đỏ vừa tải lên.',
                  image: [view37],
                  subNote: [
                    {
                      title:
                        'Sau khi nhấn nút <b>X</b>, hộp thoại Xóa hóa đơn đỏ sẽ hiển thị như ảnh bên dưới. Bạn có thể nhấn nút <b>XÓA</b> để xóa hóa đơn đỏ.',
                      image: [view38],
                    },
                    { title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác Xóa hóa đơn đỏ.' },
                  ],
                },
              ]}
            />
          </Box>
        }
      />
    </Box>
  )
}

export default OrderDetailUsing
