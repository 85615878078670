/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Modal } from 'components'
import { Button, DatePicker, Input, RenderField } from 'components/admin'
import { Checkbox, DialogActions, DialogContent } from '@mui/material'
import { useAdminOrders } from './hook'
import { validatePhone, validateTypingName, validateTypingPhone } from 'utils'

interface Props {
  show: boolean
  id: string
  type?: 'create' | 'update' | String
  initialValues?: any
  onClose?: any
  onSuccess?: any
  oldStatus?: number | string
}

const ModalChangStatusSucsessfull: React.FC<Props> = (props) => {
  const { show, id, onClose, onSuccess, type, initialValues, oldStatus } = props
  const { updateOrder } = useAdminOrders()

  const [dirty, setDirty] = useState<any>({})
  const [timeDelivery, setTimeDelivery] = useState<any>(null)
  const [deliveryAddress, setDeliveryAddress] = useState('')
  const [adminName, setAdminName] = useState('')
  const [adminPhone, setAdminPhone] = useState('')
  const [customerName, setCustomerName] = useState('')
  const [customerPhone, setCustomerPhone] = useState('')
  const [status, setStatus] = useState(false)

  const isUpdate = type === 'update'

  const today = new Date()

  const update = () => {
    updateOrder(
      id,
      {
        status: 6,
        old_status: oldStatus,
        delivered_date: timeDelivery.getTime() / 1000,
        shipper_name: adminName,
        shipper_phone: adminPhone,
        full_name_receiver: customerName,
        phone_receiver: customerPhone,
        delivery_address_store: deliveryAddress,
        is_payment: status,
      },
      () => {
        onClose?.()
        onSuccess?.()
      }
    )
  }

  useEffect(() => {
    if (!show) return

    const {
      time_delivery,
      delivery_address,
      admin_name,
      admin_phone,
      customer_name,
      customer_phone,
    } = initialValues || {}

    setTimeDelivery(time_delivery ? time_delivery : null)
    setDeliveryAddress(delivery_address || '')
    setAdminName(admin_name || '')
    setAdminPhone(admin_phone || '')
    setCustomerName(customer_name || '')
    setCustomerPhone(customer_phone || '')
    setStatus(false)
  }, [show])

  const errorPhoneAdmin =
    !adminPhone || validatePhone().test(adminPhone) ? undefined : 'Số điện thoại không hợp lệ'
  const errorPhoneCustommer =
    !customerPhone || validatePhone().test(customerPhone)
      ? undefined
      : 'Số điện thoại khachs không hợp lệ'

  const isDisable =
    !timeDelivery ||
    !deliveryAddress ||
    !adminName ||
    !adminPhone ||
    !customerName ||
    !customerPhone ||
    !!errorPhoneAdmin ||
    !!errorPhoneCustommer ||
    !status

  const minDate = new Date('01/01/2021')
  minDate.setFullYear(today.getFullYear() - 1)

  return (
    <Modal title="Nhập thông tin giao hàng" show={show} size="sm" close={onClose}>
      <DialogContent style={{ paddingTop: 12 }}>
        <>
          <RenderField title="Thời gian giao hàng trực tiếp cho khách hàng" required>
            <DatePicker
              rootPortal
              popperPlacement="bottom"
              placeholder="Chọn ngày"
              date={timeDelivery}
              setDate={setTimeDelivery}
              minDate={minDate}
              maxDate={today}
            />
          </RenderField>
          <RenderField title="Địa điểm giao hàng" required>
            <Input
              value={deliveryAddress}
              onChange={(e) => setDeliveryAddress(e.target.value)}
              placeholder="Địa điểm giao hàng"
              maxLength={100}
            />
          </RenderField>
          <RenderField required title="Họ tên người giao hàng">
            <Input
              value={adminName}
              onChange={(e) => {
                if (validateTypingName(e)) setAdminName(e.target.value)
              }}
              placeholder="Họ và tên"
              maxLength={50}
            />
          </RenderField>
          <RenderField required title="Số điện thoại">
            <Input
              value={adminPhone}
              onChange={(e) => {
                if (e.target.value === '' || validateTypingPhone(e)) setAdminPhone(e.target.value)
              }}
              placeholder="Số điện thoại"
              error={errorPhoneAdmin}
              maxLength={20}
            />
          </RenderField>
          <RenderField required title="Họ tên người nhận hàng">
            <Input
              value={customerName}
              onChange={(e) => {
                if (validateTypingName(e)) setCustomerName(e.target.value)
              }}
              placeholder="Họ và tên"
              maxLength={50}
            />
          </RenderField>
          <RenderField required title="Số điện thoại">
            <Input
              value={customerPhone}
              onChange={(e) => {
                if (e.target.value === '' || validateTypingPhone(e))
                  setCustomerPhone(e.target.value)
              }}
              placeholder="Số điện thoại"
              error={errorPhoneCustommer}
              maxLength={20}
            />
          </RenderField>
          <RenderField title="Trạng thái thanh toán" required>
            <div className="d-f ai-c">
              <Checkbox value={status} onChange={() => setStatus(!status)} />
              <p>Đã thanh toán</p>
            </div>
          </RenderField>
        </>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Hủy bỏ
        </Button>
        <Button onClick={update} variant="contained" color="primary" disabled={isDisable}>
          {isUpdate ? 'Lưu' : 'Chuyển trạng thái'}
        </Button>
      </DialogActions>
    </Modal>
  )
}

export default ModalChangStatusSucsessfull
