import { UPDATE_BREADCRUMBS, UPDATE_BREADCRUMBS_RIGHT_MENU } from 'store/actionTypes'
import { ActionType } from 'types'

const initialState = {
  breadcrumbs: [],
  rightMenu: null,
}

const breadcrumbsReducer = (state = initialState, action: ActionType) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_BREADCRUMBS:
      return {
        ...state,
        rightMenu: null,
        breadcrumbs: payload,
      }

    case UPDATE_BREADCRUMBS_RIGHT_MENU:
      return { ...state, ...payload }

    default:
      return state
  }
}

export default breadcrumbsReducer
