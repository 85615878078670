import { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RequestType, RootState } from 'types'
import { removeAccentsKeepSpecial as removeAccents } from 'utils'

import {
  fetchArea,
  getAboutUs,
  getBannersApi,
  getBestSellerApi,
  resetAdvertisement,
} from './actions'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'

export default function useApp() {
  const dispatch = useDispatch()
  const appReducer = useSelector((state: RootState) => state.appReducer, shallowEqual)
  const { area } = appReducer
  const { apiGet } = useApis()

  return useMemo(
    () => ({
      ...appReducer,
      getBannersApi: () => dispatch(getBannersApi()),
      getBestSellerApi: (requestParams: RequestType) => dispatch(getBestSellerApi(requestParams)),
      resetAdvertisement: () => dispatch(resetAdvertisement()),
      // Get all cities, districts, towns
      fetchArea: () => {
        fetch('/dvhcvn.json')
          .then((res) => res.json())
          .then(({ data }) => dispatch(fetchArea(data)))
      },
      // Filter cities by keyword
      filterArea: (keyword: string) => {
        const newCitySearch = removeAccents(keyword)

        return area.map((i: any) => {
          const cityName = removeAccents(i.name)
          return { label: i.name, value: i, hide: !cityName.includes(newCitySearch) }
        })
      },
      getAboutUs: () => {
        apiGet(apiUrls.aboutUs(), {}, ({ status, data }) => {
          if (status) {
            dispatch(getAboutUs(data))
          }
        })
      },
    }),
    [dispatch, appReducer]
  )
}
