import { FETCH_CATEGORIES } from 'store/actionTypes'
import { ActionType } from 'types'

const initialState: any = {
  menu: [],
  flattenMenu: [],
}

const categoriesReducer = (state = initialState, action: ActionType) => {
  const { type, payload } = action

  switch (type) {
    case FETCH_CATEGORIES: {
      return payload
    }

    default:
      return state
  }
}

export default categoriesReducer
