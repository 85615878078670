import { MenuItem, Popover } from '@mui/material'
import React, { useRef, useState } from 'react'
import {
  mappingAdminOrderChange,
  mappingAdminOrderStatus,
  mappingAdminOrderStatusColor,
  mappingOrderChangeStatusPaymentDelivery,
} from './constants'

import { PAYMENT_METHODS } from 'configs/constants'

interface Props {
  status: number
  onChangeStatus: (status: number) => void
  disabled: boolean
  paymentMethod: number | string
}

const { PAYMENT_ON_DELIVERY } = PAYMENT_METHODS

const ChangeOrderStatus: React.FC<Props> = ({
  status,
  onChangeStatus,
  disabled,
  paymentMethod,
}) => {
  const { background, text, border } = mappingAdminOrderStatusColor[status]
  const anchorRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  const listNewStatuses =
    paymentMethod === PAYMENT_ON_DELIVERY
      ? mappingOrderChangeStatusPaymentDelivery[status]
      : mappingAdminOrderChange[status]
  const showList = !disabled && !!listNewStatuses?.length

  const style = {
    backgroundColor: background,
    color: text,
    border: `1px solid ${border}`,
    cursor: showList ? 'pointer' : 'default',
  }

  // Close the menu
  const handleClose = () => {
    setOpen(false)
  }

  // When click item, set selected item and close menu
  const handleClickItem = (item: number) => {
    onChangeStatus(item)
    handleClose()
  }

  return (
    <>
      <div
        className="ChangeOrderStatus"
        style={style}
        ref={anchorRef}
        onClick={() => {
          if (showList) setOpen(true)
        }}
      >
        {mappingAdminOrderStatus[status]}
        {showList && <i className="mn-icon-dropdown" />}
      </div>
      {showList && (
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{
            style: {
              width: anchorRef.current?.getBoundingClientRect().width || 0,
              borderRadius: 8,
            },
          }}
          disableAutoFocus
          open={open}
          onClose={handleClose}
          anchorEl={anchorRef.current}
        >
          {listNewStatuses.map((item: number) => (
            <MenuItem
              className="ChangeOrderStatus-item"
              key={item}
              onClick={() => handleClickItem(item)}
            >
              <i className="fas fa-arrow-right" />
              {mappingAdminOrderStatus[item]}
            </MenuItem>
          ))}
        </Popover>
      )}
    </>
  )
}

export default ChangeOrderStatus
