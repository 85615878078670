/* eslint-disable */
import { Card, Checkbox, ClickAwayListener, Radio, Tooltip } from '@mui/material'
import { Button, Input, RenderField, SearchSelect } from 'components/admin'
import Select from 'components/admin/dataTable/Select'
import { apiUrls } from 'configs/apis'
import {
  addressTypesList,
  AdminContext,
  DEFAULT_PAGE_SIZE,
  DELIVERY_METHODS,
  mappingDeliveryMethod,
  PAYMENT_METHODS,
} from 'configs/constants'
import { renderBackTitle } from 'configs/extensions'
import copy from 'copy-to-clipboard'
import { Form, FormikProvider, useFormik } from 'formik'
import { isEqual, isString, uniqWith } from 'lodash'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory as useHistoryBase } from 'react-router-dom'
import { useApis } from 'services/api'
import { useApp } from 'store/app'
import { useHistory } from 'store/confirmRedirect'
import { useModalConfirm } from 'store/modalConfirm'
import { AdminUserType, TableDataType } from 'types'
import {
  removeAccentsKeepSpecialEmail,
  validateEmail,
  validatePhone,
  validateTypingAddress,
  validateTypingMST,
  validateTypingName,
  validateTypingPhone,
} from 'utils'
import * as Yup from 'yup'
import BlockSelectedProducts from './BlockSelectedProducts'
import { defaultInitialValues, listPaymentMethod } from './constants'
import DropdownBank from './DropdownBank'

const { GET_AT_STORE, GET_AT_ADDRESS } = DELIVERY_METHODS

const { TRANSFER, PAYMENT_ON_DELIVERY, USE_MOMO } = PAYMENT_METHODS

/***
 * Validate input
 */
const validationSchema = (phoneNotExist: any) =>
  Yup.object().shape({
    order_delivery_address: Yup.object()
      .required()
      .shape({
        full_name: Yup.string().required(),
        phone: Yup.string()
          .required()
          .min(1, 'phone_format_is_incorrect')
          .max(20, 'phone_format_is_incorrect')
          .notOneOf([phoneNotExist.phone], phoneNotExist.text)
          .matches(validatePhone(), 'phone_format_is_incorrect'),
        note: Yup.string(),
      }),
    order_vat_invoice: Yup.object()
      .nullable()
      .when('has_invoice', {
        is: true,
        then: Yup.object().shape({
          company_name: Yup.string().required(),
          tax_identification_number: Yup.string().required(),
          company_address: Yup.string().required(),
          email: Yup.string().matches(validateEmail(), 'email_is_invalid'),
        }),
      }),
  })

interface Props {
  isUpdate?: boolean
  id?: string
  userId?: string
  userFullName?: any
  userPhone?: any
}

const AddEditOrders: React.FC<Props> = ({ isUpdate, id, userId, userFullName, userPhone }) => {
  const history = useHistory()
  const historyBase = useHistoryBase()
  const { push, allowRedirect, preventRedirect } = history
  const { callApi } = useContext(AdminContext)
  const page = useRef(1)

  const [initialValues, setInitialValues] = useState(defaultInitialValues)
  const [loading, setLoading] = useState(false)
  const [phoneNotExist, setPhoneNotExist] = useState({ phone: '', text: '' })

  const [searchUser, setSearchUser] = useState('')
  const [selectedUser, setSelectedUser] = useState('')
  const [users, setUsers] = useState<TableDataType<AdminUserType>>({
    items: [],
    loading: true,
    total: 0,
  })
  const [finalPrice, setFinalPrice] = useState(0)

  const [infoPayment, setInfoPayment] = useState<any>([])
  const [stores, setStores] = useState<any>([])
  const [status, setStatus] = useState<number>(0)

  const { apiPost, apiGet, apiPut } = useApis()
  const { t } = useTranslation()
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()

  const { area } = useApp()

  const [showDropDown, setShowDropDown] = useState<any>(false)
  const [copyBank, setCopyBank] = useState(false)

  const [orderCode, setOrderCode] = useState('')
  const [discountCodeError, setDiscountCodeError] = useState('')

  const childRef = useRef<any>()

  // Get cities after filter
  const cities = area.map((city: any) => ({
    label: city.name,
    value: city.name,
  }))

  /**
   * Formik
   */

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema(phoneNotExist),
    onSubmit: (values) => {
      setLoading(true)

      const {
        create_status,
        delivery_method,
        discount_code,
        order_delivery_address,
        order_payment_info,
        order_products,
        order_store_address,
        order_vat_invoice,
        payment_method,
        has_invoice,
      } = values

      const newDeliveryAddress =
        delivery_method == GET_AT_ADDRESS
          ? order_delivery_address
          : {
              ...order_delivery_address,
              address: '',
              city: '',
              town: '',
              district: '',
            }
      const body: any = {
        status,
        order_code: orderCode,
        create_status,
        delivery_method,
        discount_code,
        note: order_delivery_address?.note,
        order_delivery_address: {
          ...newDeliveryAddress,
          type: delivery_method === GET_AT_STORE ? 4 : order_delivery_address.type,
        },
        order_payment_info: {
          bank_name: order_payment_info?.bank_name ? order_payment_info?.bank_name : '',
          account_name: order_payment_info?.account_name ? order_payment_info?.account_name : '',
          bank_number: order_payment_info?.bank_number ? order_payment_info?.bank_number : '',
          payment_content: order_payment_info?.payment_content,
          momo_phone: order_payment_info?.momo_phone ? order_payment_info?.momo_phone : '',
        },
        order_products: order_products.map((item: any) => ({
          product_id: item.product_id,
          quantity: item.quantity,
          price: item?.product?.price,
          final_price: item?.product?.final_price,
        })),
        order_store_address: {
          address: order_store_address?.address,
          phone: order_store_address?.phone,
          store_name: order_store_address?.store_name,
          store_address_id: order_store_address?.store_address?.id,
        },
        order_vat_invoice,
        payment_method,
      }

      if (!discount_code) delete body.discount_code
      if (!has_invoice) delete body.order_vat_invoice
      if (!isUpdate && !id) delete body.status

      delete body.order_delivery_address.id

      callApi({
        method: isUpdate ? 'put' : 'post',
        url: isUpdate ? apiUrls.adminOrders(`detail/${id}`) : apiUrls.adminOrders(),
        data: body,
        successCallback: ({ id: messageId, status, text, data }) => {
          setLoading(false)
          if (status) {
            apiPut(
              apiUrls.adminSendEmailOrder(data.id),
              {
                link: `${window.location.origin}/admin/orders/${data.id}`,
              },
              () => {}
            )
            allowRedirect()
            historyBase.push(userId ? `/admin/users/${userId}/orders` : '/admin/orders', {
              full_name: userFullName,
              phone: userPhone,
            })
          } else if (messageId === '' || messageId === '323') {
            setPhoneNotExist({ phone: values.order_delivery_address.phone, text })
            validateForm()
          } else if (messageId === '313') {
            setDiscountCodeError(text)
          } else if (
            messageId === '317' ||
            messageId === '467' ||
            messageId === '440' ||
            messageId === '441' ||
            messageId === '448' ||
            messageId === '318' ||
            messageId === '467' ||
            messageId === '465' ||
            messageId === '454'
          ) {
            showModalConfirm({
              title: 'Thông báo',
              content: text,
              confirm: {
                text: 'Đã hiểu',
                action: () => {
                  hideModalConfirm()
                  if (messageId === '448' || messageId === '454') {
                    historyBase.push('/admin/orders')
                    allowRedirect()
                  } else if (messageId === '441') {
                    removeProductDeactive(data)
                  } else if (messageId === '467') {
                    updateProductDetail(data)
                    childRef?.current?.callProducts()
                  } else if (messageId === '440') {
                    updateQuantity(data)
                    childRef?.current?.callProducts()
                  } else if (messageId === '465') {
                    // getStore(true)
                    reloadStore(order_store_address?.store_address?.id, true)
                  }
                },
              },
              onBackdropClick: () => {
                if (messageId === '454') return
                hideModalConfirm()
              },
            })
          }
        },
        errorCallback: () => setLoading(false),
      })
    },
  })

  const {
    values,
    handleSubmit,
    getFieldProps,
    handleChange,
    setFieldValue,
    errors,
    dirty,
    isValid,
    validateForm,
    resetForm,
  } = formik

  const {
    order_delivery_address,
    order_store_address,
    delivery_method,
    order_products,
    payment_method,
    order_payment_info,
    has_invoice,
  } = values

  // remove products in order if this product deactive or deleted

  const removeProductDeactive = (productsDeactive: any[]) => {
    const newProducts = order_products.filter(
      (item: any) => !productsDeactive.some((i) => i.id === item?.product_id)
    )
    setFieldValue('order_products', newProducts)
  }

  // Update products in order if this products change price

  const updateProductDetail = (productsChanged: any[]) => {
    const newProducts = order_products?.map((item: any) => {
      const productChanged = productsChanged.find((i) => i.id === item.product_id)
      return productChanged ? { ...item, product: productChanged } : item
    })
    setFieldValue('order_products', newProducts)
  }

  const updateQuantity = (productsChanged: any[]) => {
    const newProducts = order_products?.map((item: any) => {
      const productChanged = productsChanged.find((i) => i.id === item.product_id)
      return productChanged
        ? {
            ...item,
            quantity: productChanged.inventory_number,
            product: { ...item.product, inventory_number: productChanged.inventory_number },
          }
        : item
    })
    const newProductsActive = newProducts.filter((item) => {
      return item?.product?.inventory_number > 0
    })
    setFieldValue('order_products', newProductsActive)
  }

  const validAddress =
    delivery_method === GET_AT_ADDRESS
      ? !!order_delivery_address.type &&
        !!order_delivery_address.address &&
        !!order_delivery_address.city &&
        !!order_delivery_address.district &&
        !!order_delivery_address.town
      : true

  const validProducts = order_products.length > 0

  const valid = validAddress && isValid && validProducts

  /**
   * create bank tranfer payment
   */
  const bankPayment: any = infoPayment.filter((payment: any) => !payment.momo_phone)

  /**
   * create momo payment
   */
  const momoPayment: any = infoPayment.filter((payment: any) => payment.momo_phone)

  // Get data User Nomal
  const getDataUser = useCallback(() => {
    // setUsers((prev) => ({ ...prev, loading: true }))
    apiPost(
      apiUrls.adminUsers('search'),
      {
        page: page.current,
        page_size: DEFAULT_PAGE_SIZE,
        keyword: searchUser,
        sort_by: 'full_name',
        order: 'asc',
        is_admin: false,
      },
      ({ status, data }) => {
        if (status) {
          setUsers((prev) => {
            return {
              total: data.total_pages,
              loading: false,
              items: uniqWith([...prev.items, ...data?.items], isEqual),
            }
          })
        }
      }
    )
  }, [searchUser, page])

  const handleIncreasePage = () => {
    if (!users.loading) {
      setUsers((prev) => ({ ...prev, loading: false }))
      page.current += 1
      getDataUser()
    }
  }

  useEffect(() => {
    getDataUser()
  }, [getDataUser])
  const getUserDetail = useCallback(() => {
    if (!userId) return
    if (!isUpdate && !id) {
      setInitialValues((prev) => ({
        ...prev,
        order_delivery_address: {
          ...prev.order_delivery_address,
          full_name: userFullName,
          phone: userPhone,
        },
      }))
    }
  }, [userId])

  // Get List Store
  const getStore = (reload?: boolean) => {
    apiGet(apiUrls.adminBranchs(), { status: 1 }, ({ status, data }) => {
      if (status) {
        if (!isUpdate && !id) {
          if (data.items.length === 0) {
            setInitialValues((prev) => ({
              ...prev,
              delivery_method: GET_AT_ADDRESS,
            }))
          }

          setInitialValues((prev) => ({
            ...prev,
            order_store_address: {
              ...data.items[0],
              store_address: { id: data.items[0]?.id },
            },
          }))
        }
        setStores(data.items)
      }
    })
    allowRedirect()
  }

  const reloadStore = (storeId: string, afterSubmit?: boolean) => {
    apiGet(apiUrls.adminBranchs(), { status: 1 }, ({ status, data }) => {
      if (status) {
        if (isUpdate && id) {
          if (data.items.length === 0) {
            if (afterSubmit) {
              setFieldValue('delivery_method', GET_AT_ADDRESS)
            } else {
              setInitialValues((prev) => ({
                ...prev,
                delivery_method: GET_AT_ADDRESS,
              }))
            }
          }

          if (!data?.items?.some(({ id }: any) => id === storeId)) {
            if (afterSubmit) {
              setFieldValue('order_store_address', {
                ...data.items[0],
                store_address: { id: data.items[0]?.id },
              })
            } else {
              setInitialValues((prev) => ({
                ...prev,
                order_store_address: {
                  ...data.items[0],
                  store_address: { id: data.items[0]?.id },
                },
              }))
            }
          }
        } else {
          if (data.items.length === 0) {
            setFieldValue('delivery_method', GET_AT_ADDRESS)
          }
          setFieldValue('order_store_address', {
            ...data.items[0],
            store_address: { id: data.items[0]?.id },
          })
        }

        setStores(data.items)
      }
    })
  }

  const getOrderCode = () => {
    apiGet(apiUrls.adminOrders('general_order_code'), {}, ({ status, data }) => {
      if (status) setOrderCode(data)
    })
  }

  const getInformationPayment = () => {
    apiGet(
      apiUrls.informationPayment(),
      {},
      ({ status, data }) => {
        if (status) {
          setInfoPayment(data.items)
          if (!isUpdate && !id) {
            setInitialValues((prev) => ({
              ...prev,
              order_payment_info: data.items.filter((payment: any) => !payment.momo_phone)[0],
            }))
          }
        }
      }
      // () => setLoading(false)
    )
    allowRedirect()
  }

  useEffect(() => {
    if (!area.length) return

    if (isString(order_delivery_address.city)) {
      const city: any = area.find((item: any) => item.name === order_delivery_address.city)
      const district: any = city?.level2s?.find(
        (item: any) => item.name === order_delivery_address.district
      )
      const town = district?.level3s?.find((item: any) => item.name === order_delivery_address.town)

      setFieldValue('city', city)
      setFieldValue('district', district)
      setFieldValue('town', town)
    }
  }, [order_delivery_address, area])

  const createTowns = (arr1: any, arr2: any) => {
    if (!arr1 || !arr1.length) return arr2
    return arr1
  }

  const towns = (
    values.district ? createTowns(values.district?.level3s, [values.district]) : []
  )?.map((item: any) => ({ label: item.name, value: item.name }))

  const handleChangeBank = (id: string) => {
    const index = bankPayment.findIndex((item: any) => item.id === id)
    setFieldValue('order_payment_info', bankPayment[index])
  }

  /**
   * handle Copy
   */
  const handleCopy = (e: any, content: string) => {
    e.stopPropagation()
    setCopyBank(true)
    copy(content)
    setTimeout(() => {
      setCopyBank(false)
    }, 1000)
  }

  useEffect(() => {
    if (isUpdate && id) {
      callApi({
        method: 'get',
        url: apiUrls.adminOrders(id),
        successCallback: ({ status, data }) => {
          setLoading(false)
          if (status) {
            const { order_delivery_address } = data
            const dataCity: any = area.find(
              (item: any) => item.name === order_delivery_address.city
            )
            const dataDistrict: any = dataCity?.level2s?.find(
              (item: any) => item.name === order_delivery_address.district
            )
            const dataTown = dataDistrict?.level3s?.find(
              (item: any) => item.name === order_delivery_address.town
            )
            setInitialValues({
              ...defaultInitialValues,
              ...data,
              order_delivery_address: { ...order_delivery_address, note: data?.note || '' },
              order_products: data.order_products.map((item: any) => ({
                product_id: item.product.id,
                product: item.product,
                quantity: item.quantity,
              })),
              order_vat_invoice: data.order_vat_invoice
                ? {
                    company_address: data.order_vat_invoice.company_address,
                    company_name: data.order_vat_invoice.company_name,
                    tax_identification_number: data.order_vat_invoice.tax_identification_number,
                    email: data.order_vat_invoice.email,
                  }
                : {
                    company_address: '',
                    company_name: '',
                    tax_identification_number: '',
                    email: '',
                  },
              has_invoice: data.order_vat_invoice ? true : false,
              city: dataCity,
              district: dataDistrict,
              town: dataTown,
              discount_code: data.discount_code || '',
            })
            setFinalPrice(data.final_total_price)
            setOrderCode(data.order_code)
            setStatus(data?.status)
            resetForm()
            validateForm()
            allowRedirect()
            reloadStore(data?.order_store_address?.store_address?.id)
          }
        },
        errorCallback: () => setLoading(false),
        popupIds: [
          {
            id: '334',
            confirm: () => {
              historyBase.goBack()
            },
          },
        ],
      })
    } else {
      setLoading(false)
      validateForm()
      getOrderCode()
    }
  }, [isUpdate, id])

  useEffect(() => {
    getStore()
    getInformationPayment()
    getUserDetail()
    allowRedirect()
  }, [])

  const handleChangPaymentMethod = (method: number) => {
    if (method === TRANSFER) {
      setFieldValue('order_payment_info', bankPayment[0])
    } else if (method === USE_MOMO) {
      setFieldValue('order_payment_info', momoPayment[0])
    }
  }
  const handleChangDeliveryMethod = (method: number) => {
    if (method === GET_AT_STORE) {
      setFieldValue('order_store_address', {
        ...stores[0],
        store_address: { id: stores[0]?.id },
      })
    }
  }

  const contentPayment = order_delivery_address.phone
    ? `${order_delivery_address.phone} - ${orderCode}`
    : orderCode

  useEffect(() => {
    if (dirty) preventRedirect()
    else allowRedirect()
  }, [dirty])

  return (
    <div className="AdminAddOrder">
      {renderBackTitle(history, `${isUpdate ? 'Sửa' : 'Thêm'} đơn hàng`)}
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <div className="AdminAddOrder-wrapper">
            <div className="left">
              <Card sx={{ p: 3 }}>
                <div className="block">
                  <div className="AdminAddOrder-">
                    <div className="AdminAddOrder-userInfor">
                      <div className="AdminAddOrder-userInfor-header d-f jc-sb ai-c">
                        <p className="title">Thông tin khách hàng</p>
                        <div className="select">
                          <SearchSelect
                            data={users?.items.map((item: any) => ({
                              value: item.id,
                              label: `<div >
                                        <p style='font-weight: 500;'>${item.full_name}</p>
                                        <p style="font-size:14px;">${item.phone}</p>
                                      </div>`,
                            }))}
                            placeholder="Tìm người dùng"
                            onSearch={(search) => {
                              page.current = 1
                              setUsers((prev) => ({ ...prev, items: [], loading: true }))
                              setSearchUser(search)
                            }}
                            labelNoItems="Không có kết quả"
                            setSelected={(value) => {
                              setSelectedUser(value)
                              const user = users.items.find((item) => item.id === value)
                              setFieldValue('order_delivery_address[full_name]', user?.full_name)
                              setFieldValue('order_delivery_address[phone]', user?.phone)
                              setTimeout(() => validateForm(), 10)
                            }}
                            onIncreasePage={() => handleIncreasePage()}
                            showLoadmore={page.current < users.total}
                            selected={selectedUser}
                            loadingSearch={users.loading}
                          />
                        </div>
                      </div>
                      <div>
                        <RenderField title="Họ tên" required>
                          <Input
                            {...getFieldProps('order_delivery_address[full_name]')}
                            autoFocus
                            maxLength={50}
                            onChange={(e) => {
                              if (validateTypingName(e)) handleChange(e)
                            }}
                          />
                        </RenderField>
                        <RenderField title="Số điện thoại" required>
                          <Input
                            {...getFieldProps('order_delivery_address[phone]')}
                            error={
                              !!errors.order_delivery_address?.phone &&
                              t(errors.order_delivery_address?.phone?.toString())
                            }
                            onChange={(e) => {
                              if (e.target.value === '' || validateTypingPhone(e)) handleChange(e)
                            }}
                            maxLength={20}
                          />
                        </RenderField>
                      </div>
                    </div>
                    <div className="AdminAddOrder-delivery">
                      <div className="AdminAddOrder-delivery-header d-f jc-sb ai-c">
                        <p className="title">Phương thức nhận hàng</p>
                        <div className="select">
                          {stores.length === 0 ? (
                            <div className="method">{t(mappingDeliveryMethod[GET_AT_ADDRESS])}</div>
                          ) : (
                            <Select
                              fullWidth
                              selected={delivery_method}
                              setSelected={(selected) => {
                                setFieldValue('delivery_method', selected)
                                handleChangDeliveryMethod(selected)
                                preventRedirect()
                              }}
                              data={[
                                {
                                  label: t(mappingDeliveryMethod[GET_AT_STORE]),
                                  value: GET_AT_STORE,
                                },
                                {
                                  label: t(mappingDeliveryMethod[GET_AT_ADDRESS]),
                                  value: GET_AT_ADDRESS,
                                },
                              ]}
                              required
                              placeholder={t('selectDeliveryMethod')}
                            />
                          )}
                        </div>
                      </div>
                      {delivery_method === GET_AT_STORE ? (
                        <>
                          {stores?.length === 1 ? (
                            <>
                              <p style={{ marginBottom: 8 }}>Địa chỉ nhận hàng</p>
                              <p
                                style={{ marginBottom: 12 }}
                              >{`${stores[0]?.store_name}: ${stores[0]?.address}`}</p>
                            </>
                          ) : (
                            <>
                              {stores.map((item: any, index: number) => {
                                return (
                                  <div className="d-f ai-c" key={item.id}>
                                    <Radio
                                      name="radio-buttons"
                                      checked={order_store_address?.store_address?.id === item.id}
                                      onChange={() => {
                                        const newItem = { ...item, store_address: { id: item.id } }
                                        setFieldValue('order_store_address', newItem)
                                      }}
                                    />
                                    <div>{`${item.store_name}: ${item.address}`}</div>
                                  </div>
                                )
                              })}
                            </>
                          )}
                        </>
                      ) : (
                        <div>
                          <RenderField title="Loại địa chỉ" required>
                            <Select
                              selected={order_delivery_address.type}
                              setSelected={(s) => setFieldValue('order_delivery_address[type]', s)}
                              data={addressTypesList}
                              fullWidth
                              required
                              placeholder="Nhà riêng/Cơ quan/Khác"
                            />
                          </RenderField>
                          <RenderField title="Địa chỉ" required>
                            <Input
                              fullWidth
                              {...getFieldProps('order_delivery_address[address]')}
                              placeholder="Số nhà, đường"
                              onChange={(e) => {
                                if (validateTypingAddress(e)) handleChange(e)
                              }}
                              required
                              maxLength={50}
                            />
                          </RenderField>
                          <RenderField title="Thành phố/Tỉnh" required>
                            <Select
                              selected={order_delivery_address.city}
                              setSelected={(s) => {
                                setFieldValue('order_delivery_address[city]', s)
                                if (order_delivery_address.district) {
                                  setFieldValue('order_delivery_address[district]', null)
                                  setFieldValue('order_delivery_address[town]', null)
                                }
                              }}
                              data={cities}
                              fullWidth
                              required
                              placeholder="Chọn Thành phố/Tỉnh"
                              popupSearch={{
                                searchFrontend: true,
                                timeout: 200,
                              }}
                              maxHeight={320}
                            />
                          </RenderField>
                          <RenderField title="Quận/Huyện" required>
                            <Select
                              selected={order_delivery_address.district}
                              setSelected={(s) => {
                                setFieldValue('order_delivery_address[district]', s)
                                if (order_delivery_address.town)
                                  setFieldValue('order_delivery_address[town]', null)
                              }}
                              data={values?.city?.level2s?.map((item: any) => ({
                                label: item.name,
                                value: item.name,
                              }))}
                              disabled={!order_delivery_address.city}
                              fullWidth
                              required
                              placeholder="Chọn Quận/Huyện"
                              maxHeight={380}
                            />
                          </RenderField>
                          <RenderField title="Phường/Xã" required>
                            <Select
                              selected={order_delivery_address.town}
                              setSelected={(s) => setFieldValue('order_delivery_address[town]', s)}
                              data={towns}
                              disabled={!order_delivery_address.district}
                              fullWidth
                              required
                              placeholder="Chọn Phường/Xã"
                              maxHeight={380}
                            />
                          </RenderField>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="AdminAddOrder-payment">
                      <div className="AdminAddOrder-delivery-header d-f jc-sb ai-c">
                        <p className="title">Phương thức thanh toán</p>
                        <div className="select">
                          <Select
                            fullWidth
                            selected={payment_method}
                            setSelected={(selected) => {
                              setFieldValue('payment_method', selected)
                              handleChangPaymentMethod(selected)
                              preventRedirect()
                            }}
                            data={listPaymentMethod}
                            required
                          />
                        </div>
                      </div>
                      {payment_method === PAYMENT_ON_DELIVERY ? (
                        <div style={{ marginBottom: 20 }}>Thanh toán khi nhận hàng</div>
                      ) : (
                        <RenderField
                          title={payment_method === TRANSFER ? 'Ngân hàng' : 'Thông tin ví momo'}
                        >
                          {payment_method === TRANSFER ? (
                            <DropdownBank
                              dropdown
                              showDropDown={showDropDown}
                              setShowDropDown={setShowDropDown}
                              bankList={bankPayment}
                              bankTranfer={order_payment_info}
                              handleChangeBank={handleChangeBank}
                            />
                          ) : (
                            <DropdownBank bankTranfer={momoPayment[0]} />
                          )}
                        </RenderField>
                      )}

                      {payment_method !== PAYMENT_ON_DELIVERY && (
                        <RenderField title="Nội dung chuyển khoản">
                          <div className="d-f ai-c jc-sb w-100 ">
                            <div>{contentPayment}</div>

                            <div
                              className="DropdownBank-copy"
                              onClick={(e: any) => handleCopy(e, contentPayment)}
                              // ref={anchorRef}
                            >
                              <ClickAwayListener onClickAway={() => setCopyBank(false)}>
                                <div
                                  className="DropdownBank-widthCopy"
                                  style={{ marginBottom: 12 }}
                                >
                                  <i className="mn-icon-copy" />
                                  <Tooltip
                                    PopperProps={{
                                      disablePortal: true,
                                    }}
                                    onClose={() => setCopyBank(false)}
                                    open={copyBank}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title="Sao chép"
                                  >
                                    <span className="label">Sao chép </span>
                                  </Tooltip>
                                </div>
                              </ClickAwayListener>
                            </div>
                          </div>
                        </RenderField>
                      )}
                      <RenderField title="Mã đơn hàng">
                        <div>{orderCode}</div>
                      </RenderField>
                    </div>
                    <div className="AdminAddOrder-vat-invoice">
                      <div className="AdminAddOrder-delivery-header d-f ai-c">
                        <Checkbox
                          checked={has_invoice}
                          onChange={() => setFieldValue('has_invoice', !has_invoice)}
                        />
                        <div className="title" style={{ marginLeft: 15 }}>
                          Viết hóa đơn đỏ
                        </div>
                      </div>
                      {has_invoice && (
                        <>
                          <RenderField title="Tên công ty" required>
                            <Input {...getFieldProps('order_vat_invoice[company_name]')} />
                          </RenderField>
                          <RenderField title="Mã số thuế" required>
                            <Input
                              {...getFieldProps('order_vat_invoice[tax_identification_number]')}
                              type="tel"
                              onChange={(e) => {
                                if (e.target.value === '' || validateTypingMST(e)) {
                                  handleChange(e)
                                }
                              }}
                              maxLength={20}
                            />
                          </RenderField>
                          <RenderField title="Địa chỉ công ty" required>
                            <Input {...getFieldProps('order_vat_invoice[company_address]')} />
                          </RenderField>
                          <RenderField title="Email nhận hóa đơn đỏ">
                            <Input
                              {...getFieldProps('order_vat_invoice[email]')}
                              maxLength={50}
                              error={
                                !!errors.order_vat_invoice?.email &&
                                t(errors.order_vat_invoice?.email?.toString())
                              }
                              onChange={(e) => {
                                const value = e.target.value
                                e.target.value = removeAccentsKeepSpecialEmail(value)
                                handleChange(e)
                                if (validateTypingAddress(e)) {
                                  setFieldValue('order_vat_invoice[email]', e.target.value)
                                  handleChange(e)
                                }
                              }}
                            />
                          </RenderField>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="AdminAddOrder-note">
                  <div className="AdminAddOrder-delivery-header d-f ai-c">
                    <div className="title">Ghi chú</div>
                  </div>
                  <Input
                    rows={4}
                    multiline
                    maxLength={200}
                    {...getFieldProps('order_delivery_address[note]')}
                  />
                </div>
              </Card>
            </div>
            <div className="right">
              <Card sx={{ p: 3 }}>
                <BlockSelectedProducts
                  ref={childRef}
                  formik={formik}
                  finalPrice={finalPrice}
                  setFinalPrice={setFinalPrice}
                  setDiscountCodeError={setDiscountCodeError}
                  discountCodeError={discountCodeError}
                />
                <div>
                  <div className="AdminAddOrder-btnWrapper">
                    <Button
                      style={{ marginRight: 24 }}
                      onClick={() => push('/admin/orders')}
                      type="button"
                      small={false}
                    >
                      Huỷ
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      small={false}
                      //   loading={loading}
                      style={{ width: isUpdate ? 70 : 160 }}
                      disabled={!dirty || !valid || !!discountCodeError}
                    >
                      {isUpdate ? 'Lưu' : 'Thêm đơn hàng'}
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </div>
  )
}

export default AddEditOrders
