import React, { useEffect, useMemo, useState } from 'react'
import Input from '../../../input/Input'
import Select from '../../../input/Select'
import { validateTypingAddress } from 'utils'
import { isString } from 'lodash'
import { useApp } from 'store/app'

interface Props {
  setInitialValues?: any
  formik: any
}

const SelectCity: React.FC<Props> = ({ formik, setInitialValues }) => {
  const { area, filterArea } = useApp()
  const [citySearch, setCitySearch] = useState('')

  // Get cities after filter
  const cities = useMemo(() => filterArea(citySearch), [filterArea, citySearch])

  const { getFieldProps, handleChange, values, setFieldValue } = formik

  useEffect(() => {
    if (!area.length) return

    if (setInitialValues && isString(values.city)) {
      const city: any = area.find((item: any) => item.name === values.city)
      const district: any = city?.level2s?.find((item: any) => item.name === values.district)
      const town = district?.level3s?.find((item: any) => item.name === values.town)

      setInitialValues((p: any) => ({ ...p, city, district, town }))
    }
  }, [values, area])

  const createTowns = (arr1: any, arr2: any) => {
    if (!arr1 || !arr1.length) return arr2
    return arr1
  }

  const towns = (
    values.district ? createTowns(values.district?.level3s, [values.district]) : []
  )?.map((item: any) => ({ label: item.name, value: item }))

  return (
    <>
      <Input
        fullWidth
        {...getFieldProps('address')}
        label="Địa chỉ"
        placeholder="Số nhà, đường"
        onChange={(e) => {
          if (validateTypingAddress(e)) handleChange(e)
        }}
        required
        maxLength={50}
      />
      <Select
        selected={values.city}
        setSelected={(s) => {
          setFieldValue('city', s)
          if (values.district) {
            setFieldValue('district', null)
            setFieldValue('town', null)
          }
        }}
        data={cities}
        fullWidth
        label="Thành phố/Tỉnh"
        required
        placeholder="Chọn Thành phố/Tỉnh"
        popupSearch={{
          onChange: setCitySearch,
          timeout: 200,
        }}
        maxHeight={320}
      />
      <Select
        selected={values.district}
        setSelected={(s) => {
          setFieldValue('district', s)
          if (values.town) setFieldValue('town', null)
        }}
        data={values.city?.level2s?.map((item: any) => ({ label: item.name, value: item }))}
        disabled={!values.city}
        fullWidth
        label="Quận/Huyện"
        required
        placeholder="Chọn Quận/Huyện"
        maxHeight={380}
      />
      <Select
        selected={values.town}
        setSelected={(s) => setFieldValue('town', s)}
        data={towns}
        disabled={!values.district}
        fullWidth
        label="Phường/Xã"
        required
        placeholder="Chọn Phường/Xã"
        maxHeight={380}
      />
    </>
  )
}

export default SelectCity
