/* eslint-disable */
import { DialogActions, DialogContent } from '@mui/material'
import { Button, Input, RenderField } from 'components/admin'
import Select from 'components/admin/dataTable/Select'
import Modal from 'components/modal/Modal'
import ModalButtonCancel from 'components/modal/ModalButtonCancel'
import { apiUrls } from 'configs/apis'
import { MAX_PAGE_SIZE } from 'configs/constants'
import { Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'
import { useApis } from 'services/api'
import { preventRedirect } from 'store/confirmRedirect/actions'
import { emailKeydownPreventSpace, isString, validatePhone, validateTypingPhone } from 'utils'
import * as Yup from 'yup'
import LogoMaynoQuotes from '../../../../assets/images/LogoMaynoQuotes.png'

interface Props {
  show: boolean
  id: string
  phone: string
  onClose?: any
  fullName: string
}

const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .required()
    .min(1, 'phone_format_is_incorrect')
    .matches(validatePhone(), 'phone_format_is_incorrect'),
  fullName: isString,
})

const ModalDeliveryNote: React.FC<Props> = (props) => {
  const { show, id, onClose, phone, fullName } = props

  const [dataUser, setDataUser] = useState<any>([])

  const { apiGet, apiPost } = useApis()

  const [error, setError] = useState('')

  const [inforDelivery, setInforDelivery] = useState<any>({})

  const [mappingPhoneAdmin, setMappingPhoneAdmin] = useState<any>({})

  const componentToPrint = useRef<any>()
  const { t } = useTranslation()

  const handleExport = useReactToPrint({
    documentTitle: `mayno.vn - Phiếu giao nhận`,
    content: () => componentToPrint.current,
    pageStyle: `@page {
      size: 630px 870px;
      margin: 0 auto
    }`,
  })

  const formik = useFormik({
    initialValues: { phone, fullName },
    enableReinitialize: true,
    validationSchema,
    onSubmit: ({ phone, fullName }) => {
      apiGet(
        apiUrls.adminPrintDelivery(id),
        {
          phone,
          user_id: fullName,
        },
        ({ status, data }) => {
          if (status) {
            setInforDelivery(data)
            handleExport()
            onClose?.()
          }
          onClose?.()
        }
      )
    },
  })

  const {
    values,
    getFieldProps,
    handleSubmit,
    handleChange,
    errors,
    setFieldValue,
    dirty,
    isValid,
    resetForm,
    validateForm,
  } = formik

  useEffect(() => {
    if (!show) return
    if (show)
      apiPost(
        apiUrls.adminUsers('search'),
        {
          page: 1,
          page_size: MAX_PAGE_SIZE,
          sort_by: 'full_name',
          order: 'asc',
          is_admin: true,
        },
        ({ status, data }) => {
          if (status)
            setDataUser(data?.items.map((item: any) => ({ value: item.id, label: item.full_name })))
          const mappingPhoneAdmin: any = {}
          data?.items.forEach((item: any) => {
            mappingPhoneAdmin[item.id] = item.phone === null ? '' : item.phone
          })
          setMappingPhoneAdmin(mappingPhoneAdmin)
        }
      )
    // if (phone) setFieldValue('phone', phone)
    resetForm()
    setTimeout(() => validateForm(), 10)
  }, [show])

  const Component: React.FC<any> = ({}) => {
    return (
      <div style={{ display: 'none' }}>
        <div
          id="body"
          ref={componentToPrint}
          style={{
            width: '100% !important',
            margin: 0,
            padding: 0,
            backgroundColor: 'white',
          }}
        >
          <div
            style={{
              boxSizing: 'border-box',
              width: '630px !important',
              margin: '0 auto',
              padding: '15px 0',
              maxWidth: 800,
              paddingBottom: 20,
              backgroundColor: 'white',
            }}
          >
            <div
              className="background"
              style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}
            >
              <img
                style={{
                  scale: '2.4',
                  opacity: '0.08',
                  position: 'absolute',
                  top: '47%',
                  left: '75%',
                  transform: 'translate(-50%, -50%) rotate(-35deg)',
                }}
                alt="logo"
                className="logo"
                src={LogoMaynoQuotes}
              />
            </div>
            <div className="header">
              <div>
                <img alt="logo" className="logo" src={LogoMaynoQuotes} />
                <h3 className="link_web" style={{ margin: 0, marginLeft: 10 }}>
                  Web: https://mayno.vn
                </h3>
                <h3 className="link_web" style={{ margin: 0, marginLeft: 10 }}>
                  Hotline: 0813131309
                </h3>
              </div>
            </div>
            <div className="content" style={{ margin: 30 }}>
              <div className="delivery-address">
                <h2 style={{ marginTop: 20, marginBottom: 20 }}>Địa chỉ giao hàng:</h2>
                <div className="infor" style={{ marginLeft: 15, marginBottom: 40 }}>
                  <h4 style={{ marginTop: 20, marginBottom: 20 }}>
                    CÔNG TY TNHH DỊCH VỤ KỸ THUẬT VÀ PHỤ TÙNG DPS
                  </h4>
                  <p style={{ marginTop: 16, marginBottom: 16 }} className="address">
                    B15-03, Khu chức năng đô thị Thành phố Xanh, Phường Cầu Diễn, Quận Nam Từ Liêm,
                    Thành phố Hà Nội, Việt Nam
                  </p>
                  <p style={{ marginTop: 16, marginBottom: 16 }}>
                    Người đại diện: {inforDelivery.full_name_representative}
                  </p>
                  <p style={{ marginTop: 16, marginBottom: 16 }}>
                    Số điện thoại: {inforDelivery.phone_representative}
                  </p>
                </div>
              </div>
              <div className="recieve-address">
                <h2 style={{ marginTop: 20, marginBottom: 20 }}>Địa chỉ nhận hàng:</h2>
                <div className="infor" style={{ marginLeft: 15, marginBottom: 40 }}>
                  <p style={{ marginTop: 16, marginBottom: 16 }}>
                    Địa chỉ: {inforDelivery.address}
                  </p>
                  <p style={{ marginTop: 16, marginBottom: 16 }}>
                    Người nhận hàng: {inforDelivery.full_name_order}
                  </p>
                  <p style={{ marginTop: 16, marginBottom: 16 }}>
                    Số điện thoại: {inforDelivery.phone_order}
                  </p>
                </div>
              </div>
              <div className="payment-method">
                <h2 style={{ marginTop: 20, marginBottom: 20 }}>Phương thức thanh toán:</h2>
                <div className="infor" style={{ marginLeft: 15, marginBottom: 40 }}>
                  {inforDelivery.payment}
                </div>
              </div>
              <div className="note">
                <h2 style={{ marginTop: 20, marginBottom: 20 }}>Ghi chú:</h2>
                <div className="infor" style={{ marginLeft: 15, marginBottom: 40 }}>
                  {inforDelivery.note}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Modal title="In phiếu giao nhận" show={show} size="sm" close={onClose} dirty={dirty}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <div style={{ marginTop: 10 }}>
              <RenderField title="Người đại diện " required>
                <Select
                  fullWidth
                  placeholder="Người đại diện"
                  selected={values.fullName}
                  data={dataUser}
                  setSelected={(value) => {
                    setFieldValue('fullName', value.toString())
                    setFieldValue('phone', mappingPhoneAdmin[value]?.toString())
                    setTimeout(() => validateForm(), 10)
                  }}
                  popupSearch={{
                    searchFrontend: true,
                    timeout: 200,
                  }}
                  hideSelected
                  maxHeight={220}
                />
              </RenderField>
              <RenderField title="Số điện thoại" required>
                <Input
                  fullWidth
                  {...getFieldProps('phone')}
                  onKeyDown={emailKeydownPreventSpace}
                  onChange={(e) => {
                    if (e.target.value === '' || validateTypingPhone(e)) {
                      handleChange(e)
                      preventRedirect()
                    }
                  }}
                  error={error || t(errors.phone?.toString() || '')}
                  //   disabled={loading}
                  errorFocused={!!error}
                  maxLength={50}
                />
              </RenderField>
            </div>
          </DialogContent>

          <DialogActions>
            <ModalButtonCancel onClick={onClose}>Hủy bỏ</ModalButtonCancel>
            <Button type="submit" variant="contained" color="primary" disabled={!isValid}>
              In
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
      <Component />
    </Modal>
  )
}

export default ModalDeliveryNote
