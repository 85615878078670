import { createTheme } from '@mui/material'

export const theme = createTheme({
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: 45,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '#919eab3d 0px 0px 3px 0px, #919eab3d 0px 15px 30px -4px',
          borderRadius: 12,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 12,
          fontSize: '13.5px',
          color: 'var(--cl-admin-secondary)',
          fontWeight: 500,
        },
        head: {
          fontWeight: 600,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          tableLayout: 'fixed',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: 'var(--cl-admin-secondary)',
          fontWeight: 500,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: 'var(--cl-dark)',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: 15,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 15,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 10,
          marginRight: 10,
        },
      },
    },
  },
})
