import { AdminUserType } from 'types'

export const listStatusUser = [
  {
    value: 'all',
    label: 'Trạng thái đăng ký TK',
  },
  {
    value: 1,
    label: 'Đã đăng ký',
  },
  {
    value: 0,
    label: 'Chưa đăng ký',
  },
]

export const listGenders = [
  {
    value: null,
    label: 'Giới tính',
  },
  {
    value: true,
    label: 'Nam',
  },
  {
    value: false,
    label: 'Nữ',
  },
]

export const defaultInitialValues: AdminUserType = {
  id: '',
  full_name: '',
  is_active: true,
  email: '',
  group_id: '',
}

export const defaultModal: {
  show: boolean
  type: string
  initialValues: AdminUserType
} = { show: false, type: 'create', initialValues: defaultInitialValues }

export const defaultCustomerlValues: AdminUserType = {
  full_name: '',
  customer_code: '',
  gender: null,
  email: '',
  date_of_birth: null,
  verify_method: null,
}
