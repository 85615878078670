import React from 'react'
import './index.scss'

interface Props {
  checked?: boolean
  onChange?: (value: boolean) => void
  disabled?: boolean
  color?: 'primary' | 'secondary'
}

const Checkbox: React.FC<Props> = ({ checked, onChange, disabled, color = 'primary' }) => {
  return (
    <i
      className={`Checkbox ${disabled ? ' disabled' : ''}${
        checked ? 'mn-icon-checkbox-active-square active' : 'mn-icon-checkbox'
      } ${color}`}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        if (!disabled) onChange?.(!checked)
      }}
    >
      {checked && <i className="mn-icon-checkbox-active-check" />}
    </i>
  )
}

export default Checkbox
