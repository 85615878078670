import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useBreadcrumbs } from 'store/breadcrumbs'
import './index.scss'
import SendQuestion from './SendQuestion'

const SendQuestionScreen: React.FC = () => {
  const { showBreadcrumbs } = useBreadcrumbs()
  const history = useHistory()

  const title = 'Gửi câu hỏi'

  useEffect(() => {
    showBreadcrumbs([
      { name: 'Câu hỏi thường gặp', path: '/cau-hoi-thuong-gap' },
      { name: title, path: '/' },
    ])
  }, [])

  return (
    <div className="SendQuestionScreen">
      <h3 className="Page-title">{title}</h3>
      <div className="SendQuestionScreen-wrapper">
        <SendQuestion onClose={() => history.goBack()} />
      </div>
    </div>
  )
}

export default SendQuestionScreen
