import {
  APP_FETCH_AREA,
  APP_GET_ABOUT_US,
  APP_RESET_ADVERTISEMENT,
  APP_UPDATE_BANNERS,
  APP_UPDATE_BEST_SELLER,
} from 'store/actionTypes'
import { LS_ADVERTISEMENT } from 'configs/constants'
import { ActionType, AppReducer } from 'types'
import { isArray } from 'lodash'

const initialState: AppReducer = {
  banners: [],
  bestSellerData: {
    items: [],
    total: 0,
  },
  advertisementData: {
    image: '',
    id: '',
  },
  area: [],
  aboutUs: {},
}

const appReducer = (state = initialState, action: ActionType) => {
  const { type, payload } = action

  switch (type) {
    case APP_UPDATE_BANNERS: {
      let advertisementData = { ...state.advertisementData }

      let data: string[] = []
      try {
        const newData = JSON.parse(localStorage.getItem(LS_ADVERTISEMENT) || '')
        if (isArray(newData)) data = newData
      } catch (e) {}

      const advertisement = payload?.find?.((item: any) => !data.includes(item.id))
      if (advertisement) {
        const { id: advertisementId = '', dialog_image_url = '' } = advertisement || {}
        advertisementData = { image: dialog_image_url, id: advertisementId }

        localStorage.setItem(LS_ADVERTISEMENT, JSON.stringify([...data, advertisement.id]))
      }

      return {
        ...state,
        banners: payload,
        advertisementData,
      }
    }
    case APP_UPDATE_BEST_SELLER: {
      const { items, total, page } = payload

      return {
        ...state,
        bestSellerData: {
          items: page === 1 ? items : [...state.bestSellerData.items, ...items],
          total,
        },
      }
    }
    case APP_RESET_ADVERTISEMENT: {
      return {
        ...state,
        advertisementData: {},
      }
    }
    case APP_FETCH_AREA: {
      return {
        ...state,
        area: payload,
      }
    }
    case APP_GET_ABOUT_US: {
      return {
        ...state,
        aboutUs: payload,
      }
    }
    default:
      return state
  }
}

export default appReducer
