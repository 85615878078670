import { Container } from '@mui/material'
import { NavLink } from 'components'

import { INFO_MENU, PHONE_NUMBER } from 'configs/constants'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useApp } from 'store/app'

export default function AppHeaderExtra() {
  const { t } = useTranslation()

  const { aboutUs } = useApp()

  const { store_address, about_us, vision_mission } = aboutUs

  const listMenu = useMemo(() => {
    if (isEmpty(about_us) && isEmpty(store_address) && isEmpty(vision_mission)) {
      return INFO_MENU.filter((menuItem) => menuItem.path !== '/ve-chung-toi')
    }
    return INFO_MENU
  }, [aboutUs])

  return (
    <div className="AppHeaderExtra-area">
      <Container className="AppHeaderExtra-container">
        <a className="AppHeaderExtra-phone" href={`tel:+84${PHONE_NUMBER}`}>
          {t('hotline')}:&nbsp;
          <strong>0813.131.309</strong>
        </a>
        <ul className="AppHeaderExtra-menu">
          {listMenu.map((menuItem, index) => (
            <li key={index}>
              <NavLink to={menuItem.path} exact>
                {t(menuItem.name)}
              </NavLink>
            </li>
          ))}
        </ul>
      </Container>
    </div>
  )
}
