import { Box } from '@mui/material'
import { Input } from 'components'
import { OTP_NUMBER_FIELDS } from 'configs/constants'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { validateTypingNumber } from 'utils'
import './index.scss'

const array = Array.from({ length: OTP_NUMBER_FIELDS })

export interface Props {
  disabled?: boolean
  error: any
  onChange: (value: any[]) => void
  onSubmit: () => void
  [key: string]: any
}

const InputOTP: React.FC<Props> = (props) => {
  const { onChange, error, disabled, onSubmit } = props
  const [otpFields, setOtpFields] = useState(array.map(() => ''))
  const [focus, setFocus] = useState(0)
  const otpFieldsRef = useRef<any[]>(array)

  /**
   * Focus field at index
   * @param index
   */
  const focusField = (index: number) => {
    otpFieldsRef.current?.[index]?.focus()
  }

  /**
   * Focus the prev field
   * @param index
   */
  const focusPrev = () => {
    if (focus > 0) focusField(focus - 1)
  }

  /**
   * Focus the next field
   * @param index
   */
  const focusNext = () => {
    if (focus < OTP_NUMBER_FIELDS) focusField(focus + 1)
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>, index: number) => {
    setFocus(index)
    e.target.select()
  }

  /**
   * Change value of OTP field at index, and focus the next field
   * @param e
   * @param index
   */
  const handleChangeOtpField = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newOtpFields = [...otpFields]
    newOtpFields[index] = e.target.value
    setOtpFields(newOtpFields)
    if (index < OTP_NUMBER_FIELDS - 1) focusField(index + 1)
  }

  /**
   * Clear field at index
   * @param index
   */
  const clearField = (index: number, noChangeFocus?: boolean) => {
    const newOtpFields = [...otpFields]
    newOtpFields[index] = ''
    setOtpFields(newOtpFields)
    if (!noChangeFocus && index > 0) focusField(index - 1)
  }

  /**
   * Handle cases of backspace, delete, left arrow, right arrow, space
   * @param e
   * @param index
   */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Enter') onSubmit()
    else if (e.key === 'Backspace') {
      e.preventDefault()
      clearField(index)
    } else if (e.key === 'Delete') {
      e.preventDefault()
      clearField(index, true)
    } else if (e.key === 'ArrowLeft') {
      e.preventDefault()
      focusPrev()
    } else if (e.key === 'ArrowRight') {
      e.preventDefault()
      focusNext()
    }
  }

  /**
   * When content not change and valid => go to next field
   * @param e
   */
  const handleInput = (e: any) => {
    if (/[0-9]/.test(e.target.value)) {
      focusNext()
    } else {
      // // This is a workaround for dealing with keyCode "229 Unidentified" on Android.
      // if (!this.props.isInputNum) {
      //   const { nativeEvent } = e
      //   if (nativeEvent.data === null && nativeEvent.inputType === 'deleteContentBackward') {
      //     e.preventDefault()
      //     this.changeCodeAtFocus('')
      //     this.focusPrevInput()
      //   }
      // }
    }
  }

  useEffect(() => {
    otpFieldsRef.current?.[0]?.focus()
  }, [otpFieldsRef])

  useEffect(() => {
    if (otpFieldsRef.current?.[focus]) otpFieldsRef.current?.[focus].select()
  }, [otpFieldsRef, focus])

  useEffect(() => {
    onChange(otpFields)
  }, [otpFields])

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        {otpFields.map((_, index) => (
          <Input
            autoFocus={index === 0}
            type="tel"
            innerRef={(ref: any) => (otpFieldsRef.current[index] = ref)}
            key={index}
            width={39}
            style={{ padding: 0, textAlign: 'center' }}
            value={otpFields[index]}
            onInput={handleInput}
            onChange={(e) => {
              if (validateTypingNumber(e)) handleChangeOtpField(e, index)
            }}
            onFocus={(e) => handleFocus(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            maxLength={1}
            border
            error={!!error}
            errorFocused
            disabled={disabled}
          />
        ))}
      </Box>
      {!!error && (
        <div className="InputOTP-invalid">
          <span className="label alert">{error}</span>
        </div>
      )}
    </Box>
  )
}

export default InputOTP
