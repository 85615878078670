import { TabsItem } from 'components/common/tabsItem'
import { USER_INFORMATION } from 'configs/constants'
import { matchPath, useLocation } from 'react-router-dom'
import { useHistory } from 'store/confirmRedirect'

const Sidebar: React.FC = () => {
  const history = useHistory()
  const location = useLocation()

  return (
    <div className="Sidebar">
      <ul className="Sidebar-wrapper">
        <li className="Sidebar-item">
          <TabsItem
            label="Đơn hàng của tôi"
            active={matchPath(location.pathname, {
              path: '/personal-information/my-order',
              exact: false,
            })}
            onClick={() => history.push('/personal-information/my-order')}
          />
        </li>
        <li className="Sidebar-item">
          <TabsItem label="Thông tin cá nhân" open disabled />
          <ul className="Sidebar-menu">
            {USER_INFORMATION.map((item: any) => {
              const path = item?.path ? '/personal-information' + item.path : ''

              return (
                <li key={item.id} className="Sidebar-menu--item">
                  <div className="Sidebar-menu--item__wrapper">
                    <TabsItem
                      label={item.label}
                      isPlus
                      active={matchPath(location.pathname, { path, exact: false })}
                      onClick={() => (path ? history.push(path) : item?.onClick?.())}
                    />
                  </div>
                </li>
              )
            })}
          </ul>
        </li>
      </ul>
    </div>
  )
}

export default Sidebar
