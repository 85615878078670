/*eslint-disable*/
import React, { useState } from 'react'
import { Box, Card, Tab, Tabs } from '@mui/material'
import GuideUsingStep from 'components/admin/guideUsingStep/GuideUsingStep'
import { renderBackTitle } from 'configs/extensions'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'store/confirmRedirect'

import add1 from '../../../../../assets/images/DocumentCategoriesUsing/add1.png'
import add2 from '../../../../../assets/images/DocumentCategoriesUsing/add2.png'
import add3 from '../../../../../assets/images/DocumentCategoriesUsing/add3.png'
import add4 from '../../../../../assets/images/DocumentCategoriesUsing/add4.png'
import add5 from '../../../../../assets/images/DocumentCategoriesUsing/add5.png'
import add6 from '../../../../../assets/images/DocumentCategoriesUsing/add6.png'
import add7 from '../../../../../assets/images/DocumentCategoriesUsing/add7.png'

import edit1 from '../../../../../assets/images/DocumentCategoriesUsing/edit1.png'
import edit2 from '../../../../../assets/images/DocumentCategoriesUsing/edit2.png'
import edit3 from '../../../../../assets/images/DocumentCategoriesUsing/edit3.png'
import edit4 from '../../../../../assets/images/DocumentCategoriesUsing/edit4.png'
import edit5 from '../../../../../assets/images/DocumentCategoriesUsing/edit5.png'
import edit6 from '../../../../../assets/images/DocumentCategoriesUsing/edit6.png'
import edit7 from '../../../../../assets/images/DocumentCategoriesUsing/edit7.png'

import delete1 from '../../../../../assets/images/DocumentCategoriesUsing/delete1.png'
import delete2 from '../../../../../assets/images/DocumentCategoriesUsing/delete2.png'
import delete3 from '../../../../../assets/images/DocumentCategoriesUsing/delete3.png'
import delete4 from '../../../../../assets/images/DocumentCategoriesUsing/delete4.png'
import delete5 from '../../../../../assets/images/DocumentCategoriesUsing/delete5.png'
import delete6 from '../../../../../assets/images/DocumentCategoriesUsing/delete6.png'
import delete7 from '../../../../../assets/images/DocumentCategoriesUsing/delete7.png'
import delete8 from '../../../../../assets/images/DocumentCategoriesUsing/delete8.png'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const DocumentCategoriesUsing = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      {renderBackTitle(history, t('Quản lý danh mục tài liệu - Hướng dẫn sử dụng'))}
      <Card sx={{ p: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="Thêm danh mục tài liệu" {...a11yProps(0)} />
            <Tab label="Sửa thông tin danh mục tài liệu" {...a11yProps(1)} />
            <Tab label="Xóa danh mục tài liệu" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Thêm danh mục</b> để thêm một danh mục tài liệu mới."
              image={[add1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>Thêm danh mục</b>, hộp thoại Thêm danh mục tài liệu sẽ hiển thị như ảnh bên dưới:"
              image={[add2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Điền thông tin hợp lệ vào các trường trong hộp thoại Thêm danh mục tài liệu, sau đó nhấn nút <b>THÊM</b>."
              image={[add3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>THÊM</b>, danh mục tài liệu sẽ được thêm thành công và hiển thị trong danh sách:"
              image={[add4]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                {
                  title:
                    'Danh mục tài liệu vừa thêm sẽ hiển thị tại dropdown <b>Danh mục</b> ở hộp thoại <b>Thêm/Sửa tài liệu</b> trong màn <b>Quản lý tài liệu kỹ thuật</b>:',
                  image: [add5],
                },
                {
                  title: 'Bạn có thể tìm kiếm danh mục tài liệu theo <b>Tên danh mục tài liệu</b>:',
                  image: [add6],
                },
                {
                  title:
                    'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác thêm danh mục tài liệu mới:',
                  image: [add7],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có ít nhất một danh mục tài liệu ở màn Quản lý danh mục tài liệu.
            </p>
            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Sửa</b> ở danh mục tài liệu mà bạn cần sửa thông tin."
              image={[edit1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Sửa</b>, hộp thoại Sửa danh mục tài liệu sẽ hiển thị như ảnh bên dưới:"
              image={[edit2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Cập nhật thông tin vào các trường mà bạn muốn sửa trong hộp thoại Sửa danh mục tài liệu, sau đó nhấn nút <b>LƯU</b>. "
              image={[edit3]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn <b>LƯU</b>, thông tin danh mục tài liệu sẽ được cập nhật theo thông tin vừa sửa:"
              image={[edit4]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                {
                  title:
                    'Danh mục tài liệu vừa sửa sẽ hiển thị tại dropdown <b>Danh mục</b> ở hộp thoại <b>Thêm/Sửa tài liệu</b> trong màn <b>Quản lý tài liệu kỹ thuật</b>:',
                  image: [edit5],
                },
                {
                  title:
                    'Nếu danh mục tài liệu đã được link với tài liệu kỹ thuật thì sau khi sửa thành công, danh mục tài liệu sẽ cập nhật theo thông tin vừa sửa ở trang <b>Góc kỹ thuật</b>:',
                  image: [edit6],
                },
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác sửa danh mục tài liệu:',
                  image: [edit7],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box>
            <p style={{ fontSize: 18, marginBottom: 10 }}>
              <b>Điều kiện:</b> Đã có ít nhất một danh mục tài liệu ở màn Quản lý danh mục tài liệu.
            </p>

            <GuideUsingStep
              descreption="<b>Bước 1:</b> Nhấn vào nút <b>Xóa</b> ở danh mục tài liệu mà bạn cần xóa thông tin."
              image={[delete1]}
            />
            <GuideUsingStep
              descreption="Sau khi nhấn nút <b>Xóa</b>, hộp thoại xác nhận Xóa danh mục tài liệu sẽ hiển thị như ảnh bên dưới:"
              image={[delete2]}
            />
            <GuideUsingStep
              descreption="<b>Bước 2:</b> Nhấn nút <b>XÓA</b>. "
              image={[delete3]}
              note={[
                {
                  title:
                    'Nếu danh mục tài liệu vẫn tồn tại và đã link với tài liệu kỹ thuật thì sau khi nhấn <b>XÓA</b> sẽ hiển thị hộp thoại <b>Thông báo</b> và danh mục tài liệu sẽ không bị xóa:',
                  image: [delete4],
                },
                {
                  title:
                    'Đồng thời, tài liệu kỹ thuật được gắn với danh mục tài liệu vẫn hiển thị ở trang <b>Góc kỹ thuật</b>: ',
                  image: [delete5],
                },
                {
                  title:
                    'Danh mục tài liệu sẽ vẫn hiển thị tại dropdown <b>Danh mục</b> ở hộp thoại <b>Thêm/Sửa tài liệu</b> trong màn <b>Quản lý tài liệu kỹ thuật</b>:',
                  image: [delete6],
                },
                {
                  title:
                    'Nếu danh mục tài liệu vẫn tồn tại và không link với tài liệu kỹ thuật nào thì sau khi nhấn <b>XÓA</b>, danh mục tài liệu sẽ bị xóa và không còn hiển thị trong danh sách:',
                  image: [delete7],
                },
                {
                  title:
                    'Danh mục tài liệu vừa xóa sẽ không hiển thị tại dropdown <b>Danh mục</b> ở hộp thoại <b>Thêm/Sửa tài liệu</b> trong màn <b>Quản lý tài liệu kỹ thuật</b>.',
                },
              ]}
            />
            <GuideUsingStep
              descreption="<b>Lưu ý: </b>"
              note={[
                {
                  title: 'Bạn có thể nhấn <b>HỦY BỎ</b> để hủy thao tác xóa danh mục tài liệu:',
                  image: [delete8],
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box></Box>
        </TabPanel>
      </Card>
    </>
  )
}

export default DocumentCategoriesUsing
