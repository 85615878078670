import qs from 'qs'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

interface Props {
  isBreadcrumb?: boolean
  placeholder?: string
  onSubmit: (keyword: string) => void
  maxLength?: number
}

let needSearch = false

const InputSearch: React.FC<Props> = ({ isBreadcrumb, placeholder, onSubmit, maxLength }) => {
  const { search } = useLocation() || {}
  const { keyword: defaultKeyword = '' } = qs.parse(search || '', { ignoreQueryPrefix: true }) || {}

  const [keyword, setKeyword] = useState<any>(defaultKeyword)
  const [focus, setFocus] = useState(false)
  const ref = useRef<any>()
  const timeout = useRef<any>(0)

  useEffect(() => {
    if (!needSearch) return

    if (timeout.current) clearTimeout(timeout.current)
    const trimmedKeyword: any = keyword?.trim()
    timeout.current = setTimeout(() => {
      onSubmit?.(trimmedKeyword)
    }, 500)
  }, [keyword])

  useEffect(() => {
    clearTimeout(timeout.current)
    needSearch = false
    setTimeout(() => (needSearch = true), 300)

    setKeyword(defaultKeyword)
  }, [placeholder])

  const handleClick = () => {
    if (!keyword.trim()) ref.current.blur()
    onSubmit(keyword.trim())
  }

  const handleSubmit = (e: any) => {
    if (e.key === 'Enter') {
      handleClick()
    }
  }

  const handleFocus = () => {
    setFocus(true)
    setTimeout(() => ref.current.focus(), 10)
  }

  const refX = useRef(false)

  return (
    <div
      className={`UserInputSearch${isBreadcrumb ? ' isBreadcrumb' : ''}${focus ? ' focus' : ''}`}
    >
      {(focus || !isBreadcrumb) && <i className="mn-icon-search adornment" />}
      <input
        ref={ref}
        className={focus ? 'focus' : ''}
        value={keyword}
        maxLength={maxLength || 200}
        onChange={(e) => setKeyword(e.target.value)}
        placeholder={placeholder}
        onKeyDown={handleSubmit}
        onBlur={() =>
          setTimeout(() => {
            if (!refX.current) setFocus(false)
            refX.current = false
          }, 200)
        }
      />
      {!!keyword.length && (isBreadcrumb ? focus : true) && (
        <i
          className="mn-icon-close icon-cp"
          onClick={() => {
            setKeyword('')
            ref.current.focus()
            refX.current = true
          }}
        />
      )}
      {isBreadcrumb && !focus && (
        <i className="mn-icon-search button icon-cp" onClick={handleFocus} />
      )}
    </div>
  )
}

export default InputSearch
