import React from 'react'
import { Checkbox as Cb } from 'components'

const Checkbox: React.FC<{
  checked?: boolean
  name?: string
  onChange?: any
  [key: string]: any
}> = ({ name, ...rest }) => {
  return (
    <div className="ProductsFilter-item-container">
      <div className="ProductsFilter-item">
        <Cb {...rest} />
        <span className="ProductsFilter-item__label">{name}</span>
      </div>
    </div>
  )
}

export default Checkbox
