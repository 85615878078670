import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { ModalConfirmContainer, ToastContainer, Updater } from 'components'
import { ScrollToTop } from 'components/common'

// Routes
import AppRoute from 'routes/AppRoute'
import AdminRoute from 'routes/AdminRoute'
import AuthLayout from 'components/screens/app/auth/AuthLayout'
import { getUserLS } from 'utils'
import { loading } from 'configs/extensions'
import { useDispatch } from 'react-redux'
import { updateAuth } from 'store/auth/actions'
import { Menu } from 'components/screens/app/personalInformation'

// App pages
import AppHome from 'pages/app/home'
import SignUp from 'pages/app/signUp'
import SignIn from 'pages/app/signIn'
import Products from 'pages/app/products'
import ForgotPassword from 'pages/app/forgotPassword'
import EngineParts from 'pages/app/engineParts'
import ProductDetail from 'pages/app/productDetail'
// import EngineDetail from 'pages/app/enginePartDetail'
import ShoppingCart from 'pages/app/shoppingCart'
import UserInformation from 'pages/app/userLayout'
import Payment from 'pages/app/payment'
import PaymentSuccess from 'pages/app/paymentSuccess'
import SimilarProducts from 'pages/app/similarProducts'
import ViewedProducts from 'pages/app/viewedProducts'
import Articles from 'pages/app/articles'
import Documents from 'pages/app/documents'
import Questions from 'pages/app/questions'
import SendQuestion from 'pages/app/sendQuestion'
import AboutUs from 'pages/app/aboutUs'

// Admin pages
const AdminSignIn = React.lazy(() => import('pages/admin/signIn'))
const AdminLayout = React.lazy(() => import('components/common/layouts/AdminLayout'))

// Common pages
const PageNotFound = React.lazy(() => import('pages/404'))

function App() {
  const [initializing, setInitializing] = useState(true)
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const history = useHistory()

  useEffect(() => {
    const userInfo = getUserLS()
    const { access_token, isAdmin, force_change_password } = userInfo
    if (access_token) {
      dispatch(updateAuth(userInfo))
      if (isAdmin && !force_change_password && !pathname.includes('/admin')) history.push('/admin')
    }
    setInitializing(false)
  }, [])

  if (initializing) return loading

  return (
    <div className="App">
      <ToastContainer />
      <ModalConfirmContainer />
      <Router>
        <Updater />
        <ScrollToTop>
          <AuthLayout modal />
          <Switch>
            {/* APP ROUTES */}
            <AppRoute exact path="/" component={AppHome} isFull />
            <AppRoute path="/payment" component={Payment} isNoPaddingSm />
            <AppRoute path="/payment-success" component={PaymentSuccess} isNoPaddingSm />
            <AppRoute exact path="/danh-sach-san-pham" component={Products} isFull />
            <AppRoute exact path="/san-pham/:productSlug" component={ProductDetail} isNoPaddingSm />
            <AppRoute exact path="/danh-muc/:id" component={EngineParts} isFull />
            <AppRoute path="/danh-muc/:idLevel1/:idLevel2" component={EngineParts} isFull />
            <AppRoute exact path="/hang-san-xuat" component={EngineParts} isFull />
            <AppRoute path="/hang-san-xuat/:id" component={EngineParts} isFull />
            <AppRoute exact path="/shopping-cart" component={ShoppingCart} isNoPaddingSm />
            <AppRoute path="/personal-information" component={UserInformation} isNoPaddingSm />
            <AppRoute path="/personal-menu" component={Menu} />
            <AppRoute path="/san-pham-tuong-tu" component={SimilarProducts} />
            <AppRoute path="/san-pham-da-xem" component={ViewedProducts} />
            <AppRoute exact path="/tin-tuc-su-kien" component={Articles} />
            <AppRoute exact path="/tin-tuc-su-kien/:id" component={Articles} isNoPaddingSm />
            <AppRoute exact path="/goc-ky-thuat" component={Documents} />
            <AppRoute exact path="/cau-hoi-thuong-gap" component={Questions} />
            <AppRoute exact path="/gui-cau-hoi" component={SendQuestion} isNoPaddingSm />
            <AppRoute exact path="/ve-chung-toi" component={AboutUs} isNoPaddingSm />

            {/* ADMIN ROUTES */}
            <Route exact path="/admin/sign-in" component={AdminSignIn} />
            <AdminRoute path="/admin" component={AdminLayout} />

            {/* BASIC ROUTES */}
            <Route exact path="/sign-up" component={SignUp} />
            <Route exact path="/sign-in" component={SignIn} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <PageNotFound />
          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  )
}

export default App
