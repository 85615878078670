import React from 'react'
import AboutUsWrapper from './AboutUsWrapper'
import { TYPR_OF_ABOT_US } from './constant'

const { ABOUT_US } = TYPR_OF_ABOT_US

const AboutUsManagement = () => {
  return (
    <div>
      <AboutUsWrapper type={ABOUT_US} />
    </div>
  )
}

export default AboutUsManagement
