import { Button, Drawer } from '@mui/material'
import React, { useState } from 'react'
import Filter from './Filter'

interface Props {
  filter: any
  onFilter: any
}

const ProductsHeader: React.FC<Props> = ({ filter, onFilter }) => {
  const [showFilter, setShowFilter] = useState(false)

  // Hide filter drawer
  const hideFilter = () => setShowFilter(false)

  return (
    <div className="ProductsHeader">
      <Drawer keepMounted anchor="right" open={showFilter} onClose={hideFilter}>
        <Filter hideFilter={hideFilter} defaultFilter={filter} onFilter={onFilter} />
      </Drawer>
      <span />
      {filter?.length > 0 && (
        <Button className="button-filter" onClick={() => setShowFilter(!showFilter)}>
          Bộ lọc <i className="mn-icon-filter" />
        </Button>
      )}
    </div>
  )
}

export default ProductsHeader
